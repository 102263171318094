import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import debounce from "lodash/debounce";

export default function PermissionAlert({
  open,
  handleClose,
  handleConfirm,
  message,
}) {
  const [isConfirming, setIsConfirming] = useState(false);
  const debouncedHandleConfirm = debounce(() => {
    setIsConfirming(false);
    handleConfirm();
  }, 500);

  const handleConfirmClick = () => {
    if (!isConfirming) {
      setIsConfirming(true);
      debouncedHandleConfirm();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleConfirmClick}
            autoFocus
            disabled={isConfirming}
          >
            {isConfirming ? "Confirming..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
