import React from "react";
import HamburgerMenu from "../HamburgerMenu";

function SuperAdminHamburgerMenu() {
  return (
    <HamburgerMenu
      misReports={[
        { text: "Rental MIS Reports", navigateTo: "/rental-mis-reports" },
        { text: "Graph Reports", navigateTo: "/graph-reports" },
        { text: "Rental Due Schedule", navigateTo: "/rent-paid-schedule" },
      ]}
      navigateHome={"super-admin-dashboard"}
      monthlyBtn="true"
      agreements={[
        {
          text: "All Agreements",
          navigateTo: "/super-admin-listing/in-procces-ag",
        },
        {
          text: "Active / In Active Agreements",
          navigateTo: "/super-admin-listing/approved-ag",
        },
        // { text: "Landlord List", navigateTo: "/list-landlord" },
        // {
        //   text: "Migrated Agreements ",
        //   navigateTo: "/finance-listing/mg-ag",
        // },
      ]}
      admin={[
        {
          text: "User Management",
          navigateTo: "/userManagement/all-user",
        },
      ]}
      monthly={[
        {
          text: "In-Process",
          navigateTo: "/superadmin-monthly-rent/in-process",
        },
        { text: "Paid", navigateTo: "/superadmin-monthly-rent/paid" },
      ]}
    />
  );
}

export default SuperAdminHamburgerMenu;
