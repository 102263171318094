/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ListingComponent from "../StyleComponents/ListingComponent";
import { Stack } from "@mui/material";
import DataTable from "./DataTable";
import { useDispatch, useSelector } from "react-redux";
import { get_renewal, get_search_renewal } from "../../Services/Services";
import HamburgerManager from "../Manager/HamburgerManager";
import moment from "moment";

export default function RenewalList() {
  const [searchValue, setsearchValue] = useState("");
  const [agreements, setAgreements] = useState({});
  const [agIds, setAgIds] = useState([]);
  const dispatch = useDispatch();
  const { auth, refresh } = useSelector((s) => s);
  const [loading, setLoading] = useState(false);

  async function SearchAPi(searchValue, id) {
    const search = await get_search_renewal(searchValue, id);
    setAgIds(search.data.ids);
    setAgreements(search.data.agreement);
  }

  function handleSerachChange(e) {
    setsearchValue(e.target.value);
  }

  const APICALL = async (id) => {
    try {
      setLoading(true);
      const result = await get_renewal(id);
      if (result.data.success) {
        setAgreements(result.data.agreement);
        setAgIds(result.data.ids);
        setLoading(false);
      }
    } catch (error) {
      return dispatch({
        open: true,
        variant: "error",
        message: "Something Went Wrong Please Try Again Later.",
      });
    }
  };

  useEffect(() => {
    APICALL(auth.id);
  }, [refresh]);

  const rows = agIds.map((row) => {
    var x = parseInt(agreements[row].tenure);
    var CurrentDate = new Date(agreements[row].final_agreement_date);

    CurrentDate.setMonth(CurrentDate.getMonth() + x);
    CurrentDate.setDate(CurrentDate.getDate() - 1);

    function datediff(first, second) {
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    return {
      id: agreements[row].id,
      status: agreements[row].status,
      renewal_status: agreements[row].renewal_status,
      terminate_status: agreements[row].terminate_status,
      code: agreements[row].code,
      type: agreements[row].type,
      name: agreements[row].name,
      location: agreements[row].location,
      rentalAmount: agreements[row].monthlyRent,
      state: agreements[row].state,
      city: agreements[row].city,
      deposit: agreements[row].deposit,

      agreement_end_date: agreements[row].agreement_end_date,
      expiry_day: agreements[row].final_agreement_date
        ? datediff(new Date(), CurrentDate)
        : "",
      address: agreements[row].address,
    };
  });
  let filteredRows =
    searchValue.length > 0
      ? rows.filter(
          (object) =>
            JSON.stringify(object)
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > 0
        )
      : rows;

  return (
    <>
      <Stack sx={{ flexWrap: "wap", flexDirection: "row" }}>
        <HamburgerManager />
        <ListingComponent
          title1={
            <header>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </header>
          }
          title={"Renewal / Termination"}
          Table={DataTable}
          dropDown={false}
          loading={loading}
          rows={filteredRows}
          searchValue={searchValue}
          setsearchValue={setsearchValue}
          handleSerachChange={handleSerachChange}
        />
      </Stack>
    </>
  );
}
