/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dialog,
  FormControl,
  Grid,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { addLandLoard } from "../../store/action/action";
import { useNavigate } from "react-router-dom";

const TextFieldWrapper = ({
  type,
  label,
  placeHolder,
  value,
  name,
  onChange,
  error,
  partLabel,
}) => {
  const fieldStyle = {
    height: "50px",
    color: "rgba(16, 99, 173, 0.47)",
    "@media(max-width:900px)": { height: "46px", p: 1 },
  };

  return (
    <Box sx={{ width: "100%" }} className="textFieldWrapper">
      <FormControl fullWidth>
        <TextField
          variant="outlined"
          label={label}
          name={name}
          type={type}
          onChange={(e) => onChange(e)}
          fullWidth
          required
          error={error ? true : false}
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: "15px",
            },
          }}
          placeholder={placeHolder}
          value={value}
          helperText={
            error ? (
              <span style={{ color: "red" }}>{error}</span>
            ) : (
              <span style={{ color: "rgb(198, 89, 17)" }}>{partLabel}</span>
            )
          }
          sx={fieldStyle}
          autoFocus={label === "Name of Landlord"}
        />
      </FormControl>
    </Box>
  );
};

const Landblord = ({ value, setValue, index }) => {
  function handleChange(e) {
    let name_regX = /^[a-zA-Z ]*$/;
    const capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = capitalizedValue;
    if (
      (e.target.name === "leeseName" &&
        capitalizedValue.match(name_regX) &&
        e.target.value.length < 50) ||
      e.target.name === "percentage"
    ) {
      if (value[index]) {
        setValue((old) =>
          old.map((row, i) => {
            if (index === i) {
              return {
                ...row,
                [e.target.name]: e.target.value,
              };
            }
            return row;
          })
        );
      } else {
        setValue((old) => [
          ...old,
          {
            [e.target.name]: e.target.value,
          },
        ]);
      }
    }
  }

  return (
    <>
      <Grid
        container
        sx={{ justifyContent: "space-evenly", mb: 2 }}
        spacing={2}
      >
        <Grid item xs={6}>
          <TextFieldWrapper
            type={"text"}
            label={"Name of Landlord"}
            placeHolder={"Name of Landlord"}
            name={"leeseName"}
            value={
              value[index] && value[index].leeseName
                ? value[index].leeseName
                : ""
            }
            onChange={handleChange}
            maxLength={5}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWrapper
            label={"Percentage Share%"}
            placeHolder={"Percentage Share%"}
            name={"percentage"}
            value={
              value[index] &&
              value[index].percentage &&
              value[index].percentage > 0 &&
              value[index].percentage <= 100
                ? value[index].percentage
                : ""
            }
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

function DialogBox({ value, setValue }) {
  const [open, setOpen] = useState(true);
  const [noLandlord, setLandlord] = useState(1);
  const [data, setData] = useState([
    {
      leeseName: "",
      percentage: 100,
    },
  ]);

  useEffect(() => {
    setData(data.splice(0, 1));
  }, [noLandlord]);

  const [alert, setAlert] = useState({ open: false, message: "" });

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    let percentage = 0;
    data.map((item) => (percentage += Number(item.percentage)));
    if (percentage > 100) {
      setAlert({
        open: true,
        message: "Sum Of Percentage Share Must Be Equal To 100 %",
      });
    } else if (percentage < 100) {
      setAlert({
        open: true,
        message: "Sum Of Percentage Share Must Be Equal To 100 %",
      });
    } else {
      setAlert({ open: false, message: "" });
      dispatch(addLandLoard(data));
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    if (e.target.value <= 10 && e.target.value >= 0) {
      setLandlord(e.target.value);
      setValue(Array.from({ length: e.target.value }, (i) => i));
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          style: { borderRadius: 18 },
        }}
      >
        <Grid
          container
          sx={{
            borderRadious: "30px ",
            backgroundColor: "#FFFFFF",
            justifyContent: "center",
            alignItems: "center",
            py: 5,
            "@media(max-width:900px)": { width: "300px", py: 6 },
          }}
        >
          <Grid item md={10}>
            <TextFieldWrapper
              label="Enter No of Landlord"
              placeHolder="Enter No of Landlord"
              type="number"
              grid="10"
              name={"landblord"}
              value={noLandlord}
              onChange={handleChange}
              maxLength={2}
              InputProps={{ inputProps: { min: 1, max: 10 } }}
            />
          </Grid>
          <Box component={"form"} onSubmit={handleSubmit}>
            <Grid
              container
              sx={{
                width: "600px",
                borderRadious: "30px ",
                backgroundColor: "#FFFFFF",
                justifyContent: "center",
                alignItems: "center",
                py: 2,
                "@media(max-width:900px)": { width: "300px", py: 6 },
              }}
            >
              <Grid item md={10} sx={{ marginTop: "20px" }}>
                {value.length > 0 ? (
                  <>
                    {value.map((_, i) => (
                      <Landblord
                        key={i}
                        index={i}
                        value={data}
                        setValue={setData}
                      />
                    ))}
                  </>
                ) : (
                  ""
                )}
                {alert.open ? (
                  <Typography
                    textAlign={"center"}
                    variant={"body2"}
                    color="red"
                  >
                    {alert.message}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item md={3}>
                {value.length > 0 ? (
                  <Button
                    disabled={data.length != noLandlord}
                    variant="contained"
                    type="submit"
                    sx={{
                      height: "40px",
                      width: "100%",
                      borderRadius: "20px",
                      fontSize: "16px",
                      color: "#FFFFFF",
                      lineHeight: "32px",
                      textTransform: "capitalize",
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item md={3}>
                <Button
                  variant="outlined"
                  sx={{
                    height: "40px",
                    width: "100%",
                    borderRadius: "20px",
                    fontSize: "16px",
                    lineHeight: "32px",
                    textTransform: "capitalize",
                    ml: 1,
                  }}
                  onClick={() => navigate(-1)}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Dialog>
    </>
  );
}

export default DialogBox;
