/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataFieldStyle,
  DocumentView,
  YearField,
} from "../StyleComponents/Rental";
import { MyHeader, SelectUser, TextFieldWrapper } from "../StyledComponent";
import { useEffect, useState } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  ApprovedByFinance,
  get_agreement_id_finance,
  get_data_recovery,
  get_deposit_amount,
  get_old_agreement_finance,
  get_renewal_recovery_data,
  send_back_to_manager,
  send_to_bhu,
  get_remarks_by_code,
  getUserList,
  notification_handler,
} from "../../Services/Services";
import { setAlert } from "../../store/action/action";
import { useDispatch, useSelector } from "react-redux";
import DialogBoxSBM from "../RentalPortal/DialogBoxSBM";
import FinanceHamburger from "./FinanceHamburger";
import UploadInvoice from "../MonthalyPayment/UploadInvoiceMonthly";

const Heading = ({ heading }) => {
  return (
    <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
      <Typography
        variant="body1"
        fontSize={"20px"}
        color={"primary"}
        fontWeight={"600"}
        sx={{ textDecoration: "underline" }}
      >
        {heading}
      </Typography>
    </Grid>
  );
};

function FinanceApproval() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { auth } = useSelector((s) => s);
  const login_manager_id = auth.id;
  const role = auth.role;
  const subType = "renewal";

  const [remark, setRemark] = useState("");

  const [agreement, setAgreement] = useState({});

  const [noofLandlords, setNoofLandlords] = useState();
  const [ids, setIds] = useState([]);
  const [allRemarks, setAllRemarks] = useState("");
  const [open, setopen] = useState(false);
  const [SrManagerList, setSrManagerList] = useState([]);
  const [BUHUsers, setBUHUsers] = useState([]);
  const [ManagerList, setManagerList] = useState([]);
  const [renewalAdjustment, setRenewalAdjustment] = useState({});

  const [unPaidMonthsAmount, setUnpaidMonthsAmount] = useState(0);
  const [upaid, setUnpaid] = useState([]);
  const [selectID, setSelectID] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [openUpload, setOpenUpload] = useState(false);
  const [monthlyId, setMonthlyId] = useState(0);
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  //renewal recovery data
  const [renewalRecovery, setRenewalRecovery] = useState({});

  const [utr, setUtr] = useState({ utr: "", paymentDate: "" });

  const dispatch = useDispatch();

  const [deposit, setDeposit] = useState("");

  const [recovery, setRecovery] = useState({});

  const [oldIds, setOldIds] = useState([]);

  const [partLabel, setPartLabel] = useState({});
  async function get_recovery_data(id) {
    try {
      const recovery = await get_data_recovery(id);
      if (recovery.status === 200) {
        setRecovery(recovery.data.data[0]);
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong!!",
        })
      );
    }
  }

  //termination
  async function handleTerminate() {
    if (remark.length <= 0) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required !.",
        })
      );
    } else {
      const response = await send_to_bhu(
        {
          status: "Approved for Termination",
          finance_id: auth.id,
          terminate_status: "Approved Termination",
          modify_date: new Date(),
          additionalRemark: {
            remarks: remark,
            step_name: auth.name,
            user_id: login_manager_id,
            agreement_code: agreement.code,
            agreement_id: agreement.agreement_id,
          },
        },
        agreement.agreement_id,
        role,
        "terminate"
      );
      if (response.data.success) {
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Agreement Approved For Termination.",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    }
  }

  async function get_deposit(code) {
    try {
      const deposit_amount = await get_deposit_amount(code);
      if (deposit_amount.data.success) {
        setDeposit(deposit_amount.data.deposit[0].deposit);
      } else {
        setDeposit(0);
      }
    } catch (error) {}
  }

  async function get_remarks_by_aggrement_code(code, agreement_id) {
    try {
      const remarks = await get_remarks_by_code(code, subType, agreement_id);

      if (remarks.data.success) {
        let remark = remarks.data.remarks.map(
          ({ step_name, remarks }) => `${step_name} : ${remarks}`
        );
        setAllRemarks(remark);
      }
    } catch (error) {}
  }

  async function get_renewal_recovery(code) {
    try {
      const renewalRecovery = await get_renewal_recovery_data(code);
      renewalRecovery.status === 200 &&
        setRenewalRecovery(renewalRecovery.data.data);
      setRenewalAdjustment(renewalRecovery.data.data);
    } catch (error) {}
  }

  async function get_old_data(id) {
    try {
      const oldvalue = await get_old_agreement_finance(id);

      oldvalue.status === 200 && setPartLabel(oldvalue.data.agreement);
      oldvalue.status === 200 && setOldIds(oldvalue.data.ids);
      oldvalue.status === 200 &&
        get_renewal_recovery(
          oldvalue.data.agreement[oldvalue.data.ids[0]].agreement_id?.[0]
        );
    } catch (error) {}
  }

  const getData = async (id) => {
    try {
      const agreement = await get_agreement_id_finance(id);

      if (agreement.data.success) {
        if (agreement.data.listHoldRow.length > 0) {
          setUnpaid(agreement.data.listHoldRow);
          let unpaid_amount = agreement.data.listHoldRow.reduce(
            (sum, row) =>
              (sum += Math.round(
                row.gstNo !== null && row.gstNo.length > 0
                  ? Number(row.rent_amount) +
                      Number((row.rent_amount / 100) * 18)
                  : row.rent_amount
              )),
            0
          );

          setUnpaidMonthsAmount(unpaid_amount);
        }
        if (agreement.data.agreement.length == 1) {
          setAgreement(agreement.data.agreement[0]);
        }
        setNoofLandlords(agreement.data.noofLandlords);
        getUserList("BUH", null).then(
          (response) => {
            setBUHUsers(response.data);
          },
          (error) => {
            console.info(error);
            setBUHUsers([]);
          }
        );
        getUserList("SR.MANAGER", agreement.data.agreement[0].buh_id).then(
          (response) => {
            setSrManagerList(response.data);
          },
          (error) => {
            console.info(error);
            setSrManagerList([]);
          }
        );
        getUserList("MANAGER", agreement.data.agreement[0].srm_id).then(
          (response) => {
            setManagerList(response.data);
          },
          (error) => {
            console.info(error);
            setManagerList([]);
          }
        );
        if (agreement.data.agreement[0].type === "Renewed") {
          get_old_data(id);
        }

        await get_recovery_data(agreement.data.agreement[0].agreement_id);
        await get_deposit(agreement.data.agreement[0].code);
        await get_remarks_by_aggrement_code(
          agreement.data.agreement[0].code,
          agreement.data.agreement[0].agreement_id
        );
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Something Went Wrong Please Try Again Later.",
          })
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    getData(id);
  }, []);

  async function handleSendBack() {
    if (remark.length <= 0) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required !.",
        })
      );
    } else {
      const agreement_id = agreement.agreement_id;
      const agreement_code = agreement.code;
      let response;
      if (agreement.status === "Terminated By Operations") {
        response = await send_back_to_manager(
          {
            status:
              agreement.status === "Terminated By Operations"
                ? "Sent Back From Finance Team Termination"
                : "Sent Back From Finance Team",
            remark: remark,
            additionalRemark: {
              remarks: remark,
              step_name: auth.name,
              user_id: login_manager_id,
              agreement_code: agreement.code,
              agreement_id: agreement.agreement_id,
            },
          },
          agreement.agreement_id,
          role,
          subType
        );
      } else {
        response = await send_back_to_manager(
          {
            status: "Sent Back From Finance Team",
            remark: remark,
            additionalRemark: {
              remarks: remark,
              step_name: auth.name,
              user_id: login_manager_id,
              agreement_code: agreement.code,
              agreement_id: agreement.agreement_id,
            },
          },
          agreement.agreement_id,
          role,
          subType
        );
      }
      if (response.data.success) {
        agreement.type === "New" || agreement.type === "Migrated"
          ? await notification_handler(
              "AG17",
              agreement_id,
              agreement_code,
              auth.id
            )
          : agreement.type === "Renewed" &&
            (await notification_handler(
              "RN29",
              agreement_id,
              agreement_code,
              auth.id
            ));

        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Send back For Rectification",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    }
  }

  const handleConfirm = async (e) => {
    const response = await ApprovedByFinance(
      {
        status: agreement.type === "Migrated" ? "Deposited" : "Approved",
        finance_id: login_manager_id,
        utr_number: utr.utr,
        payment_date: utr.paymentDate,
        modify_date: new Date(),
        additionalRemark: null,
      },
      agreement.agreement_id,
      role,
      subType
    );
    if (response.data.success) {
      dispatch(
        setAlert({
          variant: "success",
          open: true,
          message: "Agreement Approved.",
        })
      );
      navigate(-1);
    } else {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Something went wrong! Please again later.",
        })
      );
    }
  };

  async function handleSubmit() {
    if (remark.length > 0) {
      const agreement_id = agreement.agreement_id;
      const agreement_code = agreement.code;

      const response = await ApprovedByFinance(
        {
          // status: agreement.deposit - deposit === 0 ? "Deposited" : "Approved",
          status: "Approved",
          finance_id: auth.id,
          modify_date: new Date(),
          remark: remark,
          additionalRemark: {
            remarks: remark,
            step_name: auth.name,
            user_id: auth.id,
            agreement_code: agreement.code,
            agreement_id: agreement.agreement_id,
            modified_date: new Date(),
          },
        },
        agreement.agreement_id,
        role,
        subType
      );

      if (response.data.success) {
        agreement.type === "New" || agreement.type === "Migrated"
          ? await notification_handler(
              "AG15",
              agreement_id,
              agreement_code,
              auth.id
            )
          : agreement.type === "Renewed" &&
            (await notification_handler(
              "RN25",
              agreement_id,
              agreement_code,
              auth.id
            ));
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Agreement Approved.",
          })
        );
        navigate(-1);
      } else if (response.data.success === false) {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: response.data.message,
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    } else {
      dispatch(
        setAlert({ open: true, variant: "error", message: "Remark Required !" })
      );
    }
  }

  function getIncrement(rent, value, type) {
    let incrementType;
    rent = Number(rent);
    value = Number(value);
    if (type === "Percentage") {
      incrementType = `${Math.round(((value - rent) / rent) * 100)}%`;
    } else if (type === "Value") {
      incrementType = value - rent;
    }
    return incrementType;
  }

  return (
    <>
      {(Object.keys(agreement).length > 0 && agreement.type === "Renewed"
        ? oldIds.length > 0
        : true) && (
        <Stack sx={{ flexDirection: "row", mb: 4 }}>
          {/* <a id="button"></a> */}
          <DialogBoxSBM
            open={open}
            handleClose={() => setopen(false)}
            handleConfirm={handleConfirm}
            value={utr}
            setValue={setUtr}
          />

          <FinanceHamburger />
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              item
              xs={12}
              sx={{ justifyContent: "space-between", display: "flex" }}
            >
              <MyHeader>
                RMS{" "}
                <span className="small-heading">
                  (Rental Management System)
                </span>
              </MyHeader>
              <Typography mt="15px" mr="15px" fontWeight="600">
                Welcome {auth.name}
              </Typography>
            </Grid>
            <Divider />
            <Grid
              container
              sx={{
                px: 1,
                justifyContent: "space-between",
                my: 1,
                alignItems: "center",
              }}
            >
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  color="black"
                  fontSize="20px"
                  fontWeight="600"
                  alignSelf="center"
                  lineHeight="30px"
                  sx={{
                    "@media(max-width:600px)": {
                      fontSize: "17px",
                      lineHeight: "25px",
                    },
                  }}
                >
                  {agreement.type === "Migrated"
                    ? "View Migrated Agreement"
                    : "View Agreement"}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Box className="backButton">
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                size={"large"}
              >
                <ArrowCircleLeftIcon
                  sx={{ fontSize: "3rem" }}
                  color="#FFFFF !important"
                />
              </IconButton>
            </Box>

            <Grid container sx={{ justifyContent: "center", mt: 3 }}>
              {/* Basic Details */}
              <Grid item md={10}>
                <Heading heading={`Property Details`} />
                {agreement.status === "Deposited" && (
                  <>
                    <Grid container>
                      <DataFieldStyle
                        field={"Final Agreement"}
                        href={agreement.final_agreement}
                        name={"Final Agreement"}
                        cursor={true}
                      />
                      <DataFieldStyle
                        field={"Final Agreement Date"}
                        value={agreement.final_agreement_date}
                      />
                      <DataFieldStyle
                        field={"Monthly Rent Start Date"}
                        value={agreement.rent_start_date}
                      />
                    </Grid>
                    {/* <Grid container sx={{ mt: 1 }}>
                      {agreement.utr_number != null && (
                        <DataFieldStyle
                          field={"Deposit UTR Number"}
                          value={agreement.utr_number}
                        />
                      )}
                      {agreement.payment_date != null && (
                        <DataFieldStyle
                          field={"Deposit Payment Date"}
                          value={agreement.rent_start_date}
                        />
                      )}
                    </Grid> */}
                  </>
                )}

                {agreement.site_visit_date !== null && (
                  <>
                    <Grid container sx={{ alignItems: "baseline" }}>
                      <DataFieldStyle
                        field={"Site Visit date"}
                        value={agreement.site_visit_date}
                      />
                      <DataFieldStyle
                        field={"Site Visit Remark"}
                        value={agreement.site_visit_remark}
                      />
                    </Grid>
                  </>
                )}

                <Grid container sx={{ mt: 2 }}>
                  <DataFieldStyle field={"code"} value={agreement.code} />

                  <DataFieldStyle field={"state"} value={agreement.state} />
                  <DataFieldStyle field={"city"} value={agreement.city} />
                  <DataFieldStyle
                    field={"location"}
                    value={agreement.location}
                  />

                  <DataFieldStyle field={"pincode"} value={agreement.pincode} />
                  <DataFieldStyle field={"address"} value={agreement.address} />
                  <DataFieldStyle
                    field={"area"}
                    value={agreement.area + " sq. ft"}
                    partLabel={
                      agreement.type === "Renewed" &&
                      "Old Value: " + partLabel[oldIds[0]]?.area
                    }
                  />
                  <DataFieldStyle
                    field={"lock in Month"}
                    value={agreement.lockInYear}
                    partLabel={
                      agreement.type === "Renewed" &&
                      "Old Value: " + partLabel[oldIds[0]]?.lockInYear
                    }
                  />
                  <DataFieldStyle
                    field={"notice period in month"}
                    value={agreement.noticePeriod}
                    partLabel={
                      agreement.type === "Renewed" &&
                      "Old Value: " + partLabel[oldIds[0]]?.noticePeriod
                    }
                  />
                  <DataFieldStyle
                    field={"deposit"}
                    value={agreement.deposit}
                    partLabel={
                      agreement.type === "Renewed" &&
                      "Old Value: " + partLabel[oldIds[0]]?.deposit
                    }
                  />
                  <DataFieldStyle
                    field={"monthly rental"}
                    value={agreement.monthlyRent}
                    partLabel={
                      agreement.type === "Renewed" &&
                      "Old Value: " + partLabel[oldIds[0]]?.monthlyRent
                    }
                  />
                  <DataFieldStyle
                    field={"tenure"}
                    value={agreement.tenure}
                    partLabel={
                      agreement.type === "Renewed" &&
                      "Old Value: " + partLabel[oldIds[0]]?.tenure
                    }
                  />
                  {agreement.type === "Migrated" && (
                    <>
                      <SelectUser
                        label={"BUH"}
                        required={true}
                        value={agreement.buh_id}
                        options={BUHUsers}
                        disabled={true}
                        view={true}
                      />
                      <SelectUser
                        label={"Senior Manager"}
                        required={true}
                        value={agreement.srm_id}
                        options={SrManagerList}
                        disabled={true}
                        view={true}
                      />
                      <SelectUser
                        label={"Manager"}
                        required={true}
                        value={agreement.manager_id}
                        options={ManagerList}
                        disabled={true}
                        view={true}
                      />
                    </>
                  )}
                  {agreement.tenure > 12 && (
                    <>
                      <Grid container sx={{ mt: 6 }}>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                          <DataFieldStyle
                            field={"yearly Increment"}
                            value={agreement.yearlyIncrement}
                          />
                        </Grid>
                        <YearField
                          year={"Year 1"}
                          incrementType={agreement.yearlyIncrement}
                          Increment={0}
                          amount={Math.round(agreement.year1)}
                          partLabel={
                            agreement.type === "Renewed" &&
                            "Old Value: " + partLabel[oldIds[0]]?.year1
                          }
                        />
                        <YearField
                          year={"Year 2"}
                          incrementType={agreement.yearlyIncrement}
                          amount={Math.round(agreement.year2)}
                          Increment={getIncrement(
                            agreement.year1,
                            agreement.year2,
                            agreement.yearlyIncrement
                          )}
                          partLabel={
                            agreement.type === "Renewed" &&
                            getIncrement(
                              partLabel[oldIds[0]]?.year1,
                              partLabel[oldIds[0]]?.year2,
                              partLabel[oldIds[0]]?.yearlyIncrement
                            )
                          }
                        />
                        {agreement.tenure > 24 && (
                          <YearField
                            year={"Year 3"}
                            incrementType={agreement.yearlyIncrement}
                            amount={Math.round(agreement.year3)}
                            Increment={getIncrement(
                              agreement.year2,
                              agreement.year3,
                              agreement.yearlyIncrementm
                            )}
                            partLabel={
                              agreement.type === "Renewed" &&
                              getIncrement(
                                partLabel[oldIds[0]]?.year2,
                                partLabel[oldIds[0]]?.year3,
                                partLabel[oldIds[0]]?.yearlyIncrement
                              )
                            }
                          />
                        )}
                        {agreement.tenure > 36 && (
                          <YearField
                            year={"Year 4"}
                            incrementType={agreement.yearlyIncrement}
                            amount={Math.round(agreement.year4)}
                            Increment={getIncrement(
                              agreement.year3,
                              agreement.year4,
                              agreement.yearlyIncrement
                            )}
                            partLabel={
                              agreement.type === "Renewed" &&
                              getIncrement(
                                partLabel[oldIds[0]]?.year3,
                                partLabel[oldIds[0]]?.year4,
                                partLabel[oldIds[0]]?.yearlyIncrement
                              )
                            }
                          />
                        )}
                        {agreement.tenure > 48 && (
                          <YearField
                            year={"Year 5"}
                            incrementType={agreement.yearlyIncrement}
                            amount={Math.round(agreement.year5)}
                            Increment={getIncrement(
                              agreement.year4,
                              agreement.year5,
                              agreement.yearlyIncrement
                            )}
                            partLabel={
                              agreement.type === "Renewed" &&
                              getIncrement(
                                partLabel[oldIds[0]]?.year4,
                                partLabel[oldIds[0]]?.year5,
                                partLabel[oldIds[0]]?.yearlyIncrement
                              )
                            }
                          />
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid container sx={{ mt: 3 }}>
                    {/* <Grid item xs={12}>
                          <Typography variant="body1" fontWeight="600">
                            Landlord {id + 1} Details
                          </Typography>
                        </Grid> */}
                    <Heading heading={`Landlord Personal Details`} />
                    <DataFieldStyle
                      field={"name of lessor"}
                      value={agreement.name}
                    />
                    <DataFieldStyle
                      field={"aadhaar number"}
                      value={agreement.aadharNo}
                      href={agreement.aadhar_card}
                      name={"AadharCard"}
                      cursor={true}
                    />
                    <DataFieldStyle
                      field={"PAN number"}
                      value={agreement.panNo}
                      href={agreement.pan_card}
                      name={"pan_certicate"}
                      cursor={true}
                    />
                    {/* <DataFieldStyle
                        field={"GST number"}
                        value={agreement.gstNo}
                        href={agreement.gst}
                        name={"gst"}
                        bold={true}
                        cursor={true}
                        partLabel={
                          agreement.type === "Renewed" &&
                          "Old Value: " + partLabel[oldIds[0]]?.gstNo
                        }
                      /> */}

                    {agreement.gstNo != null && (
                      <DataFieldStyle
                        field={"GST number"}
                        value={agreement.gstNo}
                        href={agreement.gst}
                        name={"gst"}
                        cursor={true}
                        partLabel={
                          agreement.type === "Renewed" &&
                          "Old Value: " + partLabel[oldIds[0]]?.gstNo?.[0]
                        }
                      />
                    )}

                    <DataFieldStyle
                      field={"mobile number"}
                      value={agreement.mobileNo}
                      partLabel={
                        agreement.type === "Renewed" &&
                        "Old Value: " + partLabel[oldIds[0]]?.mobileNo?.[0]
                      }
                    />
                    {agreement.alternateMobile != null && (
                      <DataFieldStyle
                        field={"alternate mobile"}
                        value={agreement.alternateMobile}
                        partLabel={
                          agreement.type === "Renewed" &&
                          "Old Value: " +
                            partLabel[oldIds[0]]?.alternateMobile?.[0]
                        }
                      />
                    )}
                    <DataFieldStyle
                      field={"email"}
                      value={agreement.email}
                      partLabel={
                        agreement.type === "Renewed" &&
                        "Old Value: " + partLabel[oldIds[0]]?.email?.[0]
                      }
                    />
                    <DataFieldStyle
                      field={"Percentage Share"}
                      value={`${agreement.percentage}%`}
                      partLabel={
                        agreement.type === "Renewed" &&
                        "Old Value: " + partLabel[oldIds[0]]?.percentage?.[0]
                      }
                    />

                    {/* <DataFieldStyle
                      field={"Deposit Payable Amount"}
                      value={
                        ((agreement.deposit - deposit) / 100) *
                        parseInt(agreement.percentage)
                      }
                    /> */}
                  </Grid>

                  <Grid container sx={{ alignItems: "baseline", mt: 1 }}>
                    {agreement.utr_number != null && (
                      <DataFieldStyle
                        field={"Deposit UTR Number"}
                        value={agreement.utr_number}
                      />
                    )}
                    {agreement.payment_date != null && (
                      <Tooltip title="Calender" placement="right">
                        <DataFieldStyle
                          field={"Deposit Payment Date"}
                          value={agreement.payment_date}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Bank Details start here */}
              {/* <Heading heading={"Bank Details"} /> */}

              <Grid item md={10}>
                <Grid container>
                  <Grid container>
                    <Heading heading={`Landlord Bank Details`} />
                    {/* <DataFieldStyle
                      field={"bank name"}
                      value={agreement.bankName}
                      partLabel={
                        agreement.type === "Renewed" &&
                        "Old Value: " + partLabel[oldIds[0]]?.bankName
                      }
                    /> */}
                    <DataFieldStyle
                      field={"bank name"}
                      value={
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span>{agreement.bankName}</span>
                          <span>{agreement.branchName}</span>
                        </div>
                      }
                      partLabel={
                        agreement.type === "Renewed" &&
                        `Old Bank:   ${partLabel[oldIds[0]]?.bankName?.[0]}
                        Old Branch: ${partLabel[oldIds[0]]?.branchName?.[0]}`
                      }
                    />

                    <DataFieldStyle
                      field={"beneficiary name"}
                      value={agreement.benificiaryName}
                      partLabel={
                        agreement.type === "Renewed" &&
                        "Old Value: " +
                          partLabel[oldIds[0]]?.benificiaryName?.[0]
                      }
                    />
                    <DataFieldStyle
                      field={"bank A/c number"}
                      value={agreement.accountNo}
                      href={agreement.cheque}
                      name={"cheque"}
                      cursor={true}
                      partLabel={
                        agreement.type === "Renewed" &&
                        "Old Value: " + partLabel[oldIds[0]]?.accountNo?.[0]
                      }
                    />
                    <DataFieldStyle
                      field={"Bank IFSC"}
                      value={agreement.ifscCode}
                      // partLabel={agreement.branchName}
                      partLabel={
                        agreement.type === "Renewed" &&
                        "Old Value: " + partLabel[oldIds[0]]?.branchName?.[0]
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Bank Details Ends here */}

              {/* Document Section start here */}

              <Grid item md={10}>
                <Grid container spacing={4} sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <Heading heading={"Document View/Download"} />
                  </Grid>
                  <DocumentView
                    title={
                      agreement.type === "Migrated"
                        ? "Final Agreement"
                        : "draft agreement"
                    }
                    img={agreement.draft_agreement}
                  />
                  <DocumentView
                    title={"electricity bill"}
                    img={agreement.electricity_bill}
                  />
                  <DocumentView
                    title={"maintenance bill"}
                    img={agreement.maintaince_bill}
                  />
                  <DocumentView
                    title={"POA"}
                    img={agreement.poa}
                    cursor={PointerEvent}
                  />
                  <DocumentView
                    title={"Property tax receipt"}
                    img={agreement.tax_receipt}
                  />
                  {noofLandlords > 1 && (
                    <DocumentView
                      title={"NOC (if multiple owner)"}
                      img={agreement.noc}
                    />
                  )}

                  <DocumentView
                    title={"Property Picture"}
                    img={agreement.property_pic}
                  />
                </Grid>
              </Grid>
              {agreement.status !== "Deposited" &&
                agreement.type === "Migrated" && (
                  <Grid item md={10}>
                    <Grid container sx={{ alignItems: "baseline", mt: 2 }}>
                      <DataFieldStyle
                        field={"Final Agreement Date"}
                        value={agreement.final_agreement_date}
                      />
                      <DataFieldStyle
                        field={"Monthly Rent Start Date"}
                        value={agreement.rent_start_date}
                      />
                    </Grid>
                  </Grid>
                )}
              {/* document section ends here */}
              {/* <Grid item md={10} sx={{ marginTop: "10px" }}>
                <Grid
                  container sx={{ alignItems: "baseline" }}>
                  <Grid container sx={{ alignItems: "baseline" }}>
                    <DataFieldStyle
                      field={"Agreement Date"}
                      value={agreement.final_agreement_date}
                    />
                    <DataFieldStyle
                      field={"Monthly Rent Start Date"}
                      value={agreement.rent_start_date}
                    />
                  </Grid>
                </Grid>
              </Grid> */}
              {renewalAdjustment != undefined &&
                Object.keys(renewalAdjustment).length > 0 && (
                  <>
                    <Grid item xs={10} mt={3}>
                      <Typography
                        variant="body1"
                        color="var(--main-color)"
                        fontSize="25px"
                        lineHeight="28px"
                        fontWeight="600"
                        // my="20px"
                      >
                        Renewal Adjustments Form
                      </Typography>
                    </Grid>
                    <Grid item xs={10} mt={3}>
                      <Grid
                        coantiner
                        sx={{
                          display: "flex",
                          gap: "2rem",
                          flexDirection: "column",
                        }}
                      >
                        <Grid item xs={10}>
                          <Typography
                            variant="h6"
                            color={"primary"}
                            sx={{ fontWeight: 700 }}
                          >
                            Unpaid Months
                          </Typography>
                        </Grid>

                        {upaid.map((row) => (
                          <Grid item sx={{ display: "flex" }}>
                            <TextFieldWrapper
                              marginRight={true}
                              label={"Name"}
                              disabled={true}
                              value={row.name}
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label={"Rent Month (Unpaid)"}
                              placeHolder="Deposit Amount"
                              disabled={true}
                              value={
                                month[
                                  new Date(row.rent_date).getUTCMonth() + 1
                                ] +
                                "-" +
                                new Date(row.rent_date).getFullYear()
                              }
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label="Rent Amount"
                              disabled={true}
                              value={
                                row.gstNo !== null && row.gstNo.length > 0
                                  ? Math.round(
                                      Number(row.rent_amount) +
                                        Number((row.rent_amount / 100) * 18)
                                    )
                                  : row.rent_amount
                              }
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label="Status"
                              disabled={true}
                              value={row.status}
                            />
                            {row.gstNo ? (
                              <>
                                {row.invoice_number != null && (
                                  <Grid item sx={{ textAlign: "center" }}>
                                    {
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        style={{
                                          backgroundColor:
                                            "rgb(103 185 68 / 89%)",
                                          color: "white",
                                          fontSize: "12px",
                                          textTransform: "capitalize",
                                          minHeight: "37px",
                                          margin: "10px",
                                          minWidth: "100px",
                                        }}
                                        // disabled={row.invoice_number != (null)}
                                        onClick={(event) => {
                                          event.stopPropagation(); // don't select this row after clicking
                                          setSelectID(row.id);
                                          setOpenUpload(true);
                                          setInvoiceDetails({
                                            ...invoiceDetails,
                                            rentAmount: row.rent_amount,
                                            gstAmount:
                                              row?.gstNo != null &&
                                              row?.gstNo.length > 0
                                                ? Number(
                                                    parseInt(
                                                      row?.rent_amount * 0.18
                                                    ).toFixed(2)
                                                  )
                                                : 0,
                                            totalAmount:
                                              Number(row?.rent_amount) +
                                              Number(
                                                parseInt(
                                                  row?.rent_amount * 0.18
                                                ).toFixed(2)
                                              ),
                                            user_id: auth.id,
                                            agreement_id:
                                              agreement?.[ids[0]]
                                                ?.agreement_id[0],
                                            code: agreement?.[ids[0]]?.code,
                                          });
                                          setMonthlyId(row.id);
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "14px" }}>
                                          {"View"}
                                        </Typography>
                                      </Button>
                                    }
                                  </Grid>
                                )}
                              </>
                            ) : (
                              <Grid item>
                                <Button
                                  style={{
                                    display: "block",
                                    minHeight: "37px",
                                    margin: "10px",
                                    minWidth: "100px",
                                    background: "transparent",
                                  }}
                                ></Button>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        marginTop: "5px",
                        display: "flex",
                        gap: "2rem",
                        flexDirection: "row",
                      }}
                      rowSpacing={5}
                      xs={10}
                    >
                      <TextFieldWrapper
                        label="Deposit Amount (Paid)"
                        placeHolder="Deposit Amount"
                        name="depositedAmount"
                        disabled={true}
                        value={
                          partLabel[oldIds[0]]?.deposit
                            ? +partLabel[oldIds[0]]?.deposit *
                              (agreement.percentage / 100)
                            : 0
                        }
                      />
                      <TextFieldWrapper
                        label="New Deposit Amount"
                        placeHolder="New Deposit Amount"
                        name="new_deposit"
                        disabled={true}
                        value={
                          agreement?.deposit
                            ? agreement?.deposit * (agreement.percentage / 100)
                            : 0
                        }
                      />
                    </Grid>
                    <Grid
                      container
                      sx={{
                        marginTop: "5px",
                        display: "flex",
                        gap: "2rem",
                        flexDirection: "row",
                      }}
                      rowSpacing={5}
                      xs={10}
                    >
                      <TextFieldWrapper
                        label="Selected Un Paid Months Amount"
                        placeHolder="Selected UnPaid Months Amount"
                        // onBlur={(e) => handleOnBlur(e, i)}
                        // error = {errorObj.leeseName}
                        name="unPaidMonthsAmount"
                        disabled={true}
                        value={unPaidMonthsAmount}
                      />
                      <TextFieldWrapper
                        label="Balance Deposit Amount"
                        placeHolder="Balance Deposit Amount"
                        // onBlur={(e) => handleOnBlur(e, i)}
                        // error = {errorObj.leeseName}
                        name="depositedAmount"
                        disabled={true}
                        value={Math.abs(
                          agreement?.deposit * (agreement.percentage / 100) -
                            +partLabel[oldIds[0]]?.deposit *
                              (agreement.percentage / 100)
                        )}
                      />
                    </Grid>

                    <Grid
                      container
                      sx={{
                        marginTop: "5px",
                        display: "flex",
                        gap: "2rem",
                        flexDirection: "row",
                      }}
                      rowSpacing={5}
                      xs={10}
                    >
                      <TextFieldWrapper
                        label={`Balance Deposit Payable`}
                        placeHolder="Balance Deposit Payable"
                        // onBlur={(e) => handleOnBlur(e, i)}
                        // error = {errorObj.leeseName}
                        name="depositedAmount"
                        disabled={true}
                        value={
                          unPaidMonthsAmount +
                          Math.abs(
                            agreement?.deposit * (agreement.percentage / 100) -
                              +partLabel[oldIds[0]]?.deposit *
                                (agreement.percentage / 100)
                          )
                        }
                        // onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                  </>
                )}
              <Grid item container xs={10} sx={{ mt: 5 }}>
                <DataFieldStyle
                  field={"Landlord Assets"}
                  value={agreement.assets === '""' ? "" : agreement.assets}
                  partLabel={
                    agreement.type === "Renewed" &&
                    "Old Value: " + partLabel[oldIds[0]]?.assets
                  }
                />
              </Grid>
              {allRemarks && allRemarks.length > 0 && (
                <>
                  <Grid
                    item
                    container
                    sx={{ alignItems: "baseline", mt: 5 }}
                    xs={10}
                  >
                    <DataFieldStyle field={"Remark"} />
                    {allRemarks.map((remark, index) => (
                      <div
                        style={{
                          width: "100%",
                          padding: "10px 10px 10px 0",
                        }}
                        key={index}
                      >
                        {remark}
                      </div>
                    ))}
                  </Grid>
                </>
              )}

              {agreement.status === "Terminated By Operations" && (
                <>
                  {allRemarks && allRemarks.length > 0 && (
                    <>
                      <Grid
                        item
                        container
                        sx={{ alignItems: "baseline", mt: 5 }}
                        xs={10}
                      >
                        <DataFieldStyle field={" Termination Remarks"} />
                        {allRemarks.map((remark, index) => (
                          <div
                            style={{
                              width: "100%",
                              padding: "10px 10px 10px 0",
                            }}
                            key={index}
                          >
                            {remark}
                          </div>
                        ))}
                      </Grid>
                    </>
                  )}

                  {/* document section ends here */}

                  {/* Buttons start here*/}
                  <Grid item xs={10} sx={{ mt: 2 }}>
                    <Grid container sx={{ gap: "2rem" }}>
                      <DataFieldStyle
                        field="Deposit Amount (Paid)"
                        value={recovery.depositedAmount}
                      />
                    </Grid>
                    <Grid container sx={{ gap: "2rem", mt: 2 }}>
                      <DataFieldStyle
                        field="Remaining Months"
                        value={recovery.remainingMonth}
                      />
                      <DataFieldStyle
                        field="Adjustment Amount"
                        value={recovery.adjustmentAmount}
                      />
                      <DataFieldStyle
                        field="Remark"
                        value={recovery.adjustmentAmountRemark}
                      />
                    </Grid>

                    <Grid container sx={{ gap: "2rem", mt: 2 }}>
                      <DataFieldStyle
                        field="Expenses Amount"
                        value={recovery.expenses}
                      />
                      <DataFieldStyle
                        field="Remark"
                        value={recovery.expansesRemark}
                      />
                    </Grid>

                    <Grid item xs={12} container sx={{ gap: "2rem", mt: 2 }}>
                      <DataFieldStyle
                        field="Other Adjustments"
                        value={recovery.otherAdjustments}
                      />
                      <DataFieldStyle
                        field="Remark"
                        value={recovery.otherRemark}
                      />
                    </Grid>
                    <Grid item xs={12} container sx={{ gap: "2rem", mt: 2 }}>
                      <DataFieldStyle
                        field="Total Adjustment Amount "
                        value={recovery.totalAdjustmentAmount}
                      />
                      <DataFieldStyle
                        field="Balance Deposit "
                        value={recovery.balanceDeposit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DocumentView
                        title={"Termination File"}
                        img={agreement.file}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <UploadInvoice
                open={openUpload}
                view={true}
                handleClose={() => setOpenUpload(false)}
                value={invoiceDetails}
                setValue={setInvoiceDetails}
                monthlyId={monthlyId}
              />

              {/* Buttons start here*/}

              {/* termination */}
              {agreement.status === "Terminated By Operations" && (
                <>
                  <Grid
                    item
                    xs={10}
                    sx={{ mt: 5 }}
                    className={"textFieldWrapper"}
                  >
                    <Grid item xs={8}>
                      <TextField
                        type="text"
                        multiline
                        rows={3}
                        required
                        fullWidth
                        variant="outlined"
                        label="Remark "
                        placeholder="Remark *"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={8} sx={{ mt: 4, mb: 2 }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ justifyContent: "center" }}
                    >
                      <Grid item md={6} xs={11}>
                        <Button
                          variant="contained"
                          sx={{
                            height: "55px",
                            borderRadius: "12px",
                            backgroundColor: "primary",
                            width: "100%",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontSize: "18px",
                            lineHeight: "20px",
                          }}
                          onClick={handleTerminate}
                        >
                          Accept For Termination
                        </Button>
                      </Grid>
                      <Grid item md={6} xs={11}>
                        <Button
                          variant="outlined"
                          sx={{
                            height: "55px",
                            borderRadius: "12px",
                            width: "100%",
                            textTransform: "capitalize",
                            fontSize: "18px",
                          }}
                          onClick={handleSendBack}
                        >
                          Send Back To Manager
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {agreement.status === "Sent To Finance Team" && (
                <>
                  <Grid
                    item
                    xs={10}
                    sx={{ mt: 5 }}
                    className={"textFieldWrapper"}
                  >
                    <Grid item xs={8}>
                      <TextField
                        type="text"
                        multiline
                        rows={3}
                        fullWidth
                        variant="outlined"
                        label="Remark *"
                        placeholder="Remark *"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <Grid item md={8} sx={{ mt: 4, mb: 2 }}>
                    <Grid
                      container
                      spacing={1}
                      sx={{ justifyContent: "center" }}
                    >
                      <Grid item md={4} xs={11}>
                        <Button
                          variant="contained"
                          sx={{
                            height: "55px",
                            borderRadius: "12px",
                            backgroundColor: "primary",
                            width: "100%",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontSize: "18px",
                            lineHeight: "20px",
                          }}
                          onClick={handleSubmit}
                        >
                          Approve
                        </Button>
                      </Grid>
                      <Grid item md={4} xs={11}>
                        <Button
                          variant="outlined"
                          sx={{
                            height: "55px",
                            borderRadius: "12px",
                            width: "100%",
                            textTransform: "capitalize",
                            fontSize: "18px",
                          }}
                          onClick={handleSendBack}
                          disabled={agreement.type === "Migrated"}
                        >
                          Send Back To Manager
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {/* buttons end here */}
            </Grid>
          </Box>
        </Stack>
      )}
    </>
  );
}

export default FinanceApproval;
