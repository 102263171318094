/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { DocumentUpload } from "../StyledComponent";
import { setAlert } from "../../store/action/action";
import { useDispatch } from "react-redux";
import {
  getInvoiceData,
  invoice_validation,
  notification_handler,
  uploadDoc,
} from "../../Services/Services";

const labelStyle = {
  fontSize: "20px",
  lineHeight: "30px",
  color: "var(--main-color)",
  fontWeight: "600",
  "@media(max-width:900px)": { fontSize: "10px" },
};

const fieldStyle = {
  border: "1px solid var(--main-color)",
  borderRadius: "20px",
  p: 1,
  px: 2,
  color: "rgba(16, 99, 173, 0.90)",
  "@media(max-width:900px)": { height: "46px", p: 1 },
};
// Calender date selection 60+- code
const currentDate = moment();
const futureDate = moment(currentDate).add(60, "days");
const pastDate = moment(currentDate).subtract(60, "days");
function UploadInvoice({
  open,
  handleClose,
  handleConfirm,
  value,
  notificationData,
  setValue,
  code,
  monthlyId,
  rowDataForUpload,
  setRowDataForUpload,
  view,
  type,
}) {
  useEffect(() => {
    getdata(monthlyId);
  }, [monthlyId, view, open]);
  async function getdata(id) {
    const invoiceData = await getInvoiceData(monthlyId);
    const temp = invoiceData.data.finalData[0];
    setValue({
      ...value,
      totalAmount: temp?.total_amount,
      monthlyId: monthlyId,
      invoiceNo: temp?.invoice_number === '""' ? "" : temp?.invoice_number,
      invoiceDate: temp?.invoice_date === '""' ? "" : temp?.invoice_date,
      invoice_file_name: temp?.invoice,
      invoice: temp?.invoice === '""' ? "" : temp?.invoice,
      gstAmount: temp?.gst_amount,
      rentAmount: temp?.rent_amount,
    });
  }
  const [formError, setFormError] = useState({});
  useEffect(() => {
    let total = parseFloat(
      Number(value.rentAmount) + parseFloat(Number(value.gstAmount))
    );
    setValue({ ...value, totalAmount: total, monthlyId: monthlyId });
  }, [value.rentAmount, value.gstAmount, rowDataForUpload, monthlyId]);

  useEffect(() => {
    setValue({ ...value, monthlyId: monthlyId });
    setFormError({});
  }, [monthlyId]);

  function onChange(e) {
    if (e.target.name === "invoiceNo") {
      let data = {
        monthlyId: monthlyId,
        invoice: e.target.value,
        agreementCode: code,
      };
      handleValidateInvoiceNo(data);
    }
    if (e.target.name === "invoiceDate" || e.target.name === "invoiceNo") {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
      setFormError({ ...formError, [e.target.name]: "" });
    }

    if (e.target.value.match(/^[0-9]*$/)) {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
    }
  }

  const [ferror, setError] = useState(true);
  const [invoiceValidate, setInvoice] = useState(false);
  function validate() {
    let error = {};
    let hasError = false;

    if (value.invoiceNo === "") {
      error.invoiceNo = "Invoice Number Required!";
      hasError = true;
    }

    if (value.invoiceDate === "") {
      error.invoiceDate = "Please Select Invoice Date!";
      hasError = true;
    }

    if (value.invoice === "") {
      error.invoice = "Please Upload Invoice Copy.";
      hasError = true;
    }
    setError(hasError);
    setFormError(error);

    if (!hasError) {
      handleConfirm();
    }
  }
  // useEffect(() => {
  //   if (Object.keys(formError).length === 0 && !ferror && !invoiceValidate) {
  //     handleConfirm();
  //   }
  // }, [formError, ferror, invoiceValidate]);

  async function handleSubmit(e) {
    e.preventDefault();
    if (type === "MonthlyRent") {
      await notification_handler(
        "MR5",
        notificationData?.agreement_id,
        notificationData?.code,
        notificationData?.user_id,
        false,
        notificationData?.monthlyId,
        notificationData?.landlord_name
      );
    }

    if (invoiceValidate) {
    } else {
      validate();
    }
  }

  const dispatch = useDispatch();
  async function onfileChange(e) {
    const FD = new FormData();
    FD.append("folderName", code);
    FD.append("photo", e.target.files[0]);
    let response = await uploadDoc(FD);

    if (response.status === 200) {
      setFormError({ ...formError, [e.target.name]: "" });

      setValue({
        ...value,
        invoice: response.data.link,
        invoice_file_name: e.target.files[0].name,
      });
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: response.data.message,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message || "Something went wrong !!!",
        })
      );
    }
  }

  async function handleValidateInvoiceNo(data) {
    try {
      const validate = await invoice_validation(data);
      if (validate.data.alreadyInvoice) {
        setFormError((old) => ({
          ...old,
          invoiceNo: "Invoice Number Already Added.",
        }));
        setInvoice(true);
      } else {
        setInvoice(false);
        setFormError({});
      }
    } catch (error) {}
  }
  const resetForm = () => {
    setValue({
      gst: "---",
      invoiceNo: "",
      invoiceDate: "",
      invoice: "",
    });
    setError({});
  };
  const handleResetForm = () => {
    resetForm();
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 18 },
        }}
      >
        <Box sx={{ pt: 5, px: 2, pb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" sx={labelStyle}>
                    Invoice Number<span style={{ color: "#0164AE" }}>*</span>
                  </Typography>
                </FormLabel>
                <TextField
                  variant="standard"
                  onChange={(e) => onChange(e)}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      color: "rgba(16, 99, 173, 0.90)",
                      fontSize: "15px",
                    },
                  }}
                  inputProps={{ maxLength: 22 }}
                  value={value.invoiceNo}
                  fullWidth
                  name="invoiceNo"
                  sx={fieldStyle}
                  placeholder="Invoice Number"
                  disabled={view}
                  autoFocus
                />
                <Typography variant="caption" color="red" mt={1}>
                  {formError.invoiceNo}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" sx={labelStyle}>
                    Invoice Date <span style={{ color: "#0164AE" }}>*</span>
                  </Typography>
                </FormLabel>
                <input
                  type="date"
                  name="invoiceDate"
                  id="invoiceDate"
                  value={value.invoiceDate}
                  max={futureDate.format("YYYY-MM-DD")}
                  min={pastDate.format("YYYY-MM-DD")}
                  className="DatePicker"
                  onKeyDown={(e) => e.preventDefault()}
                  onChange={(e) => onChange(e)}
                  onFocus={(e) => e.currentTarget.blur()}
                  disabled={view}
                />
                <Typography variant="caption" color="red" mt={1}>
                  {formError.invoiceDate}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" sx={labelStyle}>
                    Rent Amount
                  </Typography>
                </FormLabel>
                <TextField
                  variant="standard"
                  onChange={(e) => onChange(e)}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      color: "rgba(16, 99, 173, 0.90)",
                      fontSize: "15px",
                    },
                  }}
                  disabled
                  value={
                    value.rentAmount !== undefined
                      ? parseInt(value.rentAmount).toFixed(2)
                      : rowDataForUpload?.rent_amount
                  }
                  fullWidth
                  name="rentAmount"
                  sx={fieldStyle}
                  placeholder="Rent Amount"
                  className={"textAlignRight"}
                />
                <Typography variant="caption" color="red" mt={1}>
                  {formError.rentAmount}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" sx={labelStyle}>
                    GST Amount
                  </Typography>
                </FormLabel>
                <TextField
                  variant="standard"
                  onChange={(e) => onChange(e)}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      color: "rgba(16, 99, 173, 0.90)",
                      fontSize: "15px",
                    },
                  }}
                  disabled
                  inputProps={{ maxLength: 22 }}
                  value={
                    value.gstAmount !== 0
                      ? parseInt(value.gstAmount).toFixed(2)
                      : rowDataForUpload?.gstNo !== (null || undefined) &&
                        rowDataForUpload?.gstNo.length > 0
                      ? parseInt(rowDataForUpload?.rent_amount * 0.18).toFixed(
                          2
                        )
                      : 0
                  }
                  className={"textAlignRight"}
                  fullWidth
                  name="gstAmount"
                  sx={fieldStyle}
                  placeholder="GST Amount"
                />
                <Typography variant="caption" color="red" mt={1}>
                  {formError.gstAmount}
                </Typography>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" sx={labelStyle}>
                    Total Amount
                  </Typography>
                </FormLabel>
                <TextField
                  variant="standard"
                  disabled
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      color: "rgba(16, 99, 173, 0.90)",
                      fontSize: "15px",
                    },
                  }}
                  inputProps={{ maxLength: 22 }}
                  value={
                    !isNaN(value.totalAmount)
                      ? parseInt(value.totalAmount).toFixed(2)
                      : rowDataForUpload?.totalAmount
                  }
                  fullWidth
                  name="totalAmount"
                  sx={fieldStyle}
                  placeholder="GST Amount"
                  className={"textAlignRight"}
                />
                <Typography variant="body1" color="red" mt={1}>
                  {formError.totalAmount}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DocumentUpload
                  placeHolder={"Upload Invoice*"}
                  label="Upload Invoice *"
                  name="invoice"
                  handleChange={onfileChange}
                  uploaded={value.invoice}
                  fileName={value.invoice?.split("/").pop()}
                  href={value.invoice}
                  error={formError.invoice}
                  disabled={view}
                />
              </FormControl>
              <Grid sx={{ justifyContent: "center" }}>
                <Typography
                  sx={{
                    variant: "body1",
                    color: "var(--main-color)",
                    fontSize: "11px",
                    lineHeight: "0px",
                    fontWeight: "400",
                    my: "20px",
                  }}
                >
                  Acceptable file format: png, jpeg, jpg, pdf
                </Typography>
                <Typography
                  sx={{
                    variant: "body1",
                    color: "var(--main-color)",
                    fontSize: "11px",
                    lineHeight: "0px",
                    fontWeight: "400",
                    my: "20px",
                  }}
                >
                  Maximum file size limit: 20 MB
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <DialogActions sx={{ mt: 2, marginTop: "0", textAlign: "center" }}>
            <Grid
              container
              spacing={3}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{
                    height: 45,
                    borderRadius: "15px",
                    textTransform: "capitalize",
                  }}
                  onClick={handleResetForm}
                >
                  Close
                </Button>
              </Grid>

              {!view && (
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      height: 45,
                      color: "#FFFFFF",
                      borderRadius: "15px",
                      textTransform: "capitalize",
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              )}
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default UploadInvoice;
