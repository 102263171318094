/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ListingComponent from "../../StyleComponents/ListingComponent";
import ListingTable from "./ListingTable";
import {
  get_monthlt_rent_opr,
  get_monthlt_rent_opr_paid,
  get_search_monthly_rent_operations,
  get_search_monthly_rent_operations_paid,
} from "../../../Services/Services";
import { useSelector } from "react-redux";

import OperationsHamburger from "../OperationsHamburger";
import moment from "moment";

function OperationsMonthlyPayement() {
  const { type } = useParams();
  const [agIDS, setAgIds] = useState([]);
  const [rentData, setRent] = useState({});
  const [searchValue, setsearchValue] = useState("");
  const { auth, refresh } = useSelector((s) => s);

  async function fetchData(id) {
    try {
      const data = await get_monthlt_rent_opr(id);
      if (data.data.success) {
        setAgIds(data.data.ids);
        setRent(data.data.agreement);
      } else {
      }
    } catch (error) {}
  }

  async function fetchDataPaid(id) {
    try {
      const data = await get_monthlt_rent_opr_paid(id);
      if (data.data.success) {
        setAgIds(data.data.ids);
        setRent(data.data.agreement);
      } else {
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (type === "in-process") {
      fetchData(auth.id);
    } else if (type === "paid") {
      fetchDataPaid(auth.id);
    }
  }, [refresh, type]);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const rows = agIDS.map((row) => {
    return {
      id: rentData[row].id,
      agreement_id: rentData[row].agreement_id,
      landlord_name: rentData[row].landlord_name,
      monthly_id: rentData[row].id,
      code: rentData[row].code,
      checkbox: rentData[row].status,
      status: rentData[row].status,
      utr: rentData[row].utr_no,
      name: rentData[row].landlord_name,
      location: rentData[row].location,
      gst: rentData[row].gst ? rentData[row].gst : "---",
      percentage: rentData[row].share,
      month_of_rent:
        month[new Date(rentData[row].rent_date).getUTCMonth()] +
        " " +
        new Date(rentData[row].rent_date).getFullYear(),
      total_month_rent: rentData[row].monthly_rent,
      rent_amount: parseFloat(rentData[row].rent_amount).toFixed(0),
      gst_fee: rentData[row].gst
        ? parseFloat((parseInt(rentData[row].rent_amount) / 100) * 18).toFixed(
            0
          )
        : 0,
      total_rent: rentData[row].gst
        ? parseFloat(
            parseInt(rentData[row].rent_amount) +
              (parseInt(rentData[row].rent_amount) / 100) * 18
          ).toFixed(0)
        : parseInt(rentData[row].rent_amount),
      manager: rentData[row].manager_name,
      srm: rentData[row].srm_name,
      buh: rentData[row].buh,
      rent_date: moment(rentData[row].rent_date).format("MMMM YY"),
    };
  });

  let filteredRows =
    searchValue.length > 0
      ? rows.filter(
          (object) =>
            JSON.stringify(object)
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > 0
        )
      : rows;

  async function SearchAPi(id, searchValue) {
    if (type === "in-process") {
      const search = await get_search_monthly_rent_operations(id, searchValue);
      setAgIds(search.data.ids);
      setRent(search.data.agreement);
    } else if (type === "paid") {
      const search = await get_search_monthly_rent_operations_paid(
        id,
        searchValue
      );
      setAgIds(search.data.ids);
      setRent(search.data.agreement);
    }
  }

  function handleSerachChange(e) {
    setsearchValue(e.target.value);
  }

  return (
    <>
      <Stack sx={{ flexWrap: "wap", flexDirection: "row" }}>
        <OperationsHamburger />
        <ListingComponent
          title1={
            <header>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </header>
          }
          title={
            type === "in-process"
              ? "Monthly Payment - In Process"
              : type === "paid"
              ? "Monthly Payment-Paid/Terminated"
              : "Monthly Payment"
          }
          buttonText="Upload"
          //   options={options}
          value={"New Agreement"}
          Table={ListingTable}
          rows={filteredRows}
          dropDown={false}
          searchValue={searchValue}
          setsearchValue={setsearchValue}
          handleSerachChange={handleSerachChange}
        />
      </Stack>
    </>
  );
}

export default OperationsMonthlyPayement;
