/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ListingComponent from "../StyleComponents/ListingComponent";
import { Stack } from "@mui/material";
import DataTable from "./DataTable";
import { useParams } from "react-router-dom";
import {
  get_paid_monthly_payment,
  get_search_monthly_rent_manager,
  get_search_monthly_rent_manager_paid,
  listMonthRent,
} from "../../Services/Services";
import { useSelector } from "react-redux";
import HamburgerManager from "../Manager/HamburgerManager";
import moment from "moment";

export default function MonthalyList() {
  const { type } = useParams();
  const [Select, setSelect] = useState("New Agreement");
  const [searchText, setSearchText] = useState("");
  const handleChange = (e) => {
    setSelect(e.target.value);
  };
  const [searchValue, setsearchValue] = useState("");
  const { auth, refresh } = useSelector((state) => state);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const APICALL = async () => {
    setLoading(true);
    setData([]);
    const result = await listMonthRent({ id: auth.id });
    if (result.status === 200) {
      setData(result.data);
      setLoading(false);
    }
  };

  //get monthly payment paid
  const get_monthly_payment_paid = async () => {
    setLoading(true);
    setData([]);
    const result = await get_paid_monthly_payment(auth.id);
    if (result.status === 200) {
      setData(result.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type === "in-process") {
      APICALL();
    } else if (type === "paid") {
      get_monthly_payment_paid();
    }
    setSearchText("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, type]);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const row =
    searchText.length === 0
      ? data.map((item, index) => {
          return parseRentObject(item, month);
        })
      : data
          .filter(
            (row) =>
              JSON.stringify(row)
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > 0
          )
          .map((item, index) => {
            return parseRentObject(item, month);
          });

  async function SearchAPi(id, searchValue) {
    if (type === "in-process") {
      const search = await get_search_monthly_rent_manager(id, searchValue);
      if (search.status === 200) {
        setData(search.data);
      }
    } else if (type === "paid") {
      const search = await get_search_monthly_rent_manager_paid(
        id,
        searchValue
      );
      if (search.status === 200) {
        setData(search.data);
      }
    }
  }

  function handleSerachChange(e) {
    setSearchText(e.target.value);
  }

  return (
    <>
      <Stack sx={{ flexWrap: "wap", flexDirection: "row" }}>
        <HamburgerManager />

        <ListingComponent
          title1={
            <header>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </header>
          }
          title={
            type === "in-process"
              ? "Monthly Payment - In Process"
              : type === "paid"
              ? "Monthly Payment-Paid/Terminated"
              : "Monthly Payment"
          }
          // options={options}
          value={Select}
          Table={DataTable}
          onChange={handleChange}
          dropDown={false}
          // setSortBy = {setSortBy}
          rows={row}
          searchValue={searchText}
          setsearchValue={setsearchValue}
          handleSerachChange={handleSerachChange}
        />
      </Stack>
    </>
  );
}
function parseRentObject(item, month) {
  return {
    id: item.id,
    agreement_id: item.agreement_id,
    landlord_name: item.landlord_name,
    rent_amount: parseInt(item.rent_amount),
    rent_date: moment(item.rent_date).format("MMMM YY"),
    payment_date: item.payment_date || "---",
    share: item.share,
    monthly_rent: parseInt(item.monthly_rent),
    code: item.code,
    location: item.location,
    gst: item.gst || "---",
    utr_no: item.utr_no || "---",
    status: item.status,
    checkbox: item.gst,
    gst_fee: item.gst_amount || "---",
    total_rent: item.gst
      ? parseInt(
          (Number(item.rent_amount) / 100) * 18 + Number(item.rent_amount)
        )
      : parseInt(item.rent_amount),
  };
}
