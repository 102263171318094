/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { MyHeader } from "../StyledComponent";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ListingComponent({
  title,
  title1,
  buttonText,
  buttonText1,
  addbtn,
  options,
  Table,
  value,
  setRows,
  onChange,
  rows,
  onButtonClick,
  addUserBtn,
  dropDown,
  searchValue,
  handleSerachChange,
  check,
  setCheck,
  setSortBy,
  addagreement,
  setsearchValue,
}) {
  const navigate = useNavigate();

  const { auth } = useSelector((s) => s);

  useEffect(() => {
    setsearchValue("");
  }, [title]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        item
        xs={12}
        sx={{ justifyContent: "space-between", display: "flex" }}
      >
        <MyHeader>{title1 ? title1 : title}</MyHeader>
        <Typography mt="15px" mr="15px" fontWeight="600">
          Welcome {auth.name}
        </Typography>
      </Grid>
      <Divider />
      <Grid
        container
        sx={{
          px: 1,
          justifyContent: "space-between",
          my: 1,
          alignItems: "center",
        }}
      >
        <Grid item xs={6}>
          <Typography
            variant="body1"
            color="black"
            fontSize="20px"
            fontWeight="600"
            alignSelf="center"
            lineHeight="30px"
            sx={{
              "@media(max-width:600px)": {
                fontSize: "17px",
                lineHeight: "25px",
              },
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Divider />

      <Grid
        container
        sx={{
          justifyContent: "space-between",
          p: 4,
          "@media(max-width:600px)": { p: 2 },
        }}
        spacing={2}
      >
        <Grid
          item
          md={8}
          xs={7}
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <TextField
            placeholder="Search By Field Name..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchValue}
            onChange={handleSerachChange}
            size="small"
          />
          {setSortBy && <SortBy sortBy={setSortBy} />}
        </Grid>

        {addbtn ? (
          <Grid
            item
            md={4}
            xs={5}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "15px",
                color: "white",
                textTransform: "capitalize",
                // mr:3
                "@media(maxwidth:900px)": {
                  fontSize: "12px",
                  lineHeight: "12px",
                },
              }}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => navigate(addagreement)}
            >
              {buttonText1}
            </Button>
          </Grid>
        ) : (
          ""
        )}
        {/* upload button */}
        {addUserBtn ? (
          <Button
            variant="contained"
            sx={{
              borderRadius: "15px",
              color: "white",
              backgroundColor: "var(--main-color)",
              textTransform: "capitalize",
            }}
            startIcon={<AddCircleOutlineIcon />}
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        ) : (
          ""
        )}
      </Grid>
      <Table rows={rows} setRows={setRows} setCheck={setCheck} check={check} />
    </Box>
  );
}

function SortBy({ sortBy }) {
  const sort = ["Code", "Name", "Rent Date", "Time"];
  return (
    <>
      <TextField
        id="outlined-select-currency-native"
        select
        size={"small"}
        fullWidth
        label="Sort By"
        defaultValue="Name"
        SelectProps={{
          native: true,
        }}
        onChange={(e) => sortBy(e.target.value)}
        helperText="Please select the sort order..."
      >
        {sort.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </TextField>
    </>
  );
}

export default ListingComponent;
