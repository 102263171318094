import React from "react";
import HamburgerMenu from "../HamburgerMenu";

function BuhHamburger() {
  return (
    <HamburgerMenu
      navigateHome={"BHUDashboard"}
      agreements={[
        {
          text: "In-Process",
          navigateTo: "/BHUListing/in-procces-ag",
        },
        {
          text: "Active / In Active Agreements",
          navigateTo: "/BHUListing/approved-ag",
        },
        // { text: "Migrated Agreements ", navigateTo: "/BHUListing/mg-ag" },
      ]}
    />
  );
}

export default BuhHamburger;
