/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Alert, Box, Button, Grid, Snackbar, Checkbox } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PermissionAlert from "./Alert";
import {
  populate_rent_data,
  delete_agreement,
  getModifyDate,
  send_to_bhu,
  notification_handler,
} from "../../Services/Services";
import { useDispatch, useSelector } from "react-redux";
import { setAlert, setRefreshBox } from "../../store/action/action";
import FinalAgreement from "./FinalAgreement";
import Remark from "../RentalPortal/Remark";

function DataTable({ rows, loading, check, setCheck, types }) {
  const { params } = useParams();
  const dispatch = useDispatch();
  const [ids, setIds] = useState([]);
  const [code, setCode] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { auth } = useSelector((s) => s);
  const role = auth.role;

  const subType = "terminate";

  const [open, setopen] = useState(false);

  const [final_agreement, setFinalAgreement] = useState({
    agreement_date: "",
    final_agreement: "",
    rent_start_date: "",
  });

  const [selectID, setSelectID] = useState("");
  const [notificationApiParams, setNotificationApiParams] = useState({
    event_id: "AG21",
    agreement_id: 0,
    agreement_code: "",
    user_id: auth ? auth.id : auth && auth.id,
  });

  const manager_id = auth.id;

  const [err, setErr] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [data, setData] = useState([]);
  const [terminate, setTerminate] = useState(true);

  const notificationCall = async (id, code) => {
    await notification_handler("TR1", id, code, auth.id);
  };

  //altet close
  const handleClose = () => {
    setErr({
      open: false,
      type: "",
      message: "",
    });
  };

  const deleteAPI = async (id) => {
    const deleteItem = await delete_agreement(id);
    if (deleteItem.data.success) {
      dispatch(setRefreshBox());
      setErr({
        open: true,
        type: "warning",
        message: deleteItem.data.message,
      });
    } else {
      setErr({
        open: true,
        type: "error",
        message: deleteItem.data.message,
      });
    }
  };

  const navigate = useNavigate();

  async function handleTerminate(id, code) {
    try {
      const response = await send_to_bhu(
        {
          terminate_status: "Sent To Sr Manager For Termination",
          additionalRemark: null,
        },
        id,
        role,
        subType
      );
      if (response.data.success) {
        notificationCall(id, code);
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Agreement Sent To Sr Manager For Terminate",
          })
        );
        dispatch(setRefreshBox());
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }

  const renderDetailsButton = (e) => {
    const id = e.id;
    const code = e.code;
    let utr_count = 0;
    e.row.utr_number.map((item) => {
      if (item === null) utr_count += 1;
    });

    return (
      <>
        {(e.row.status === "Sent Back From Sr Manager" ||
          e.row.status === "Sent Back From BUH" ||
          e.row.status === "Sent Back From Finance Team" ||
          e.row.status === "Sent Back From Operations") && (
          <Grid container>
            <Grid item md={6} sx={{ color: "white !important" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  backgroundColor: "#62CDFF",
                  color: "white",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
                startIcon={<EditIcon />}
                onClick={(event) => {
                  event.stopPropagation();
                  id && navigate(`/editAgreement/${id}`, { id });
                }}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        )}
        {e.row.status === "Draft" && (
          <Grid container>
            <Grid item md={6} sx={{ color: "white !important" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  backgroundColor: "#62CDFF",
                  color: "white",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
                startIcon={<EditIcon />}
                onClick={(event) => {
                  event.stopPropagation();
                  id && navigate(`/editAgreement/${id}`, { id });
                }}
              >
                Edit
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  backgroundColor: "#f13c3c",
                  color: "white",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
                startIcon={<DeleteIcon />}
                onClick={(event) => {
                  event.stopPropagation();
                  setDeleteAlert({ open: true, id: id });
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        )}
        {((e.row.status === "Approved" &&
          utr_count === 0 &&
          e.row.type !== "Migrated") ||
          (e.row.status === "Deposited" && e.row.rent_date === null)) && (
          <Grid container>
            <Grid item md={6} sx={{ color: "white !important" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{
                  backgroundColor: "#62CDFF",
                  color: "white",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
                //  startIcon={<EditIcon />}
                onClick={(event) => {
                  setNotificationApiParams({
                    ...notificationApiParams,
                    agreement_id: id,
                    agreement_code: e.row.code,
                  });
                  event.stopPropagation();
                  setSelectID(id);
                  getUpdateDate(id);
                  setCode(e.row.code);
                }}
              >
                Upload Final Agreement
              </Button>
            </Grid>
          </Grid>
        )}
        {params === "approved-ag" && (
          <Grid container>
            <Grid item md={6} sx={{ color: "white !important" }}>
              {(((e.row.terminate_status ===
                "Sent Back For Termination Rectification" ||
                e.row.terminate_status === null ||
                e.row.status === "Deposited") &&
                e.row.renewal_status !== "Sent To Sr Manager For Renewal" &&
                e.row.terminate_status !==
                  "Sent To Sr Manager For Termination" &&
                e.row.renewal_status !== "Request Approved for Renewal" &&
                e.row.terminate_status !==
                  "Request Approved for Termination") ||
                (e.row.type === "Renewed" &&
                  e.row.status === "Deposited" &&
                  e.row.terminate_status !==
                    "Sent To Sr Manager For Termination") ||
                (e.row.type === "New" &&
                  e.row.status === "Deposited" &&
                  e.row.terminate_status !==
                    "Sent To Sr Manager For Termination" &&
                  e.row.renewal_status !== "Request Approved for Renewal")) && (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: "#b53f3f",
                    color: "white",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    alignItems: "center",
                  }}
                  onClick={(event) => {
                    const checkData = rows.some((item) => item.id === id);
                    if (checkData) {
                      setTerminate(false);
                    } else {
                      setTerminate(true);
                    }
                    event.stopPropagation();
                    handleTerminate(id);
                  }}
                >
                  Terminate
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const detailsButton = (e) => {
    const id = e.id;

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{
          backgroundColor: "rgb(103 185 68 / 89%)",
          color: "white",
          fontSize: "12px",
          textTransform: "capitalize",
        }}
        onClick={(event) => {
          event.stopPropagation();
          e.row.type === "Renewed" &&
          (params === "in-procces-ag" || params === "approved-ag")
            ? navigate(`/renewal-view-agreement/${id}`)
            : navigate(`/managerApproval/${id}`);
        }}
      >
        View
      </Button>
    );
  };

  const handleSwitch = (e) => {
    if (ids.includes(e.target.name)) {
      setIds(ids.filter((i) => i !== e.target.name));
    } else {
      setIds([...ids, e.target.name]);
    }
  };
  const handleSort = (event) => {
    const { field, sortDirection } = event;
    let sortedData = [...data];

    sortedData.sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];

      if (valueA < valueB) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });

    if (sortDirection === "desc") {
      sortedData.reverse();
    }

    setData(sortedData);
  };

  const approvedColumns = [
    {
      field: "initiateDate",
      headerName: "Initiate Date",
      minWidth: 110,
      flex: 1,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      sortComparator: (a, b) => {
        return a.initiateDate - b.initiateDate;
      },
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 90,
      flex: 1,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      sortComparator: (a, b) => {
        return a.type - b.type;
      },
    },
    {
      field: "code",
      headerName: "Code",
      minWidth: 90,
      flex: 1,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      sortComparator: (a, b) => {
        return a.code - b.code;
      },
    },
    {
      field: "name",
      headerName: "Landlord Name",
      minWidth: 160,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      sortComparator: (a, b) => {
        return (a.name || "").localeCompare(b.name || "");
      },
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 160,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      sortComparator: (a, b) => {
        return (a.location || "").localeCompare(b.location || "");
      },
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 160,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      sortComparator: (a, b) => {
        return (a.address || "").localeCompare(b.address || "");
      },
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 160,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      sortComparator: (a, b) => {
        return (a.city || "").localeCompare(b.city || "");
      },
    },
    {
      field: "state",
      headerName: "State",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      sortComparator: (a, b) => {
        return (a.state || "").localeCompare(b.state || "");
      },
    },
    {
      field: "deposit",
      headerName: "Deposit Amount",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      sortComparator: (a, b) => {
        return a.deposit - b.deposit;
      },
    },
    {
      field: "rent_amount",
      headerName: "Rent Amount",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      sortComparator: (a, b) => {
        return a.rent_amount - b.rent_amount;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      sortComparator: (a, b) => {
        return (a.status || "").localeCompare(b.status || "");
      },
    },
    {
      field: "view",
      headerName: "View",
      minWidth: 150,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      renderCell: detailsButton,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      renderCell: renderDetailsButton,
    },
  ];

  const columns = [
    {
      field: "checkbox",
      disableColumnMenu: true,
      sortable: false,
      width: 20,

      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.formattedValue === "Draft" ? (
            <Checkbox
              onChange={handleSwitch}
              name={params.id}
              checked={ids.includes(params.id)}
              disabled={true}
            />
          ) : (
            <Checkbox disabled={true} />
          )}
        </>
      ),
    },
    {
      field: "initiateDate",
      headerName: "Initiate Date",
      minWidth: 110,
      flex: 1,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 90,
      flex: 1,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "code",
      headerName: "Code",
      minWidth: 90,
      flex: 1,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Landlord Name",
      minWidth: 160,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 160,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 160,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 160,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },

    {
      field: "state",
      headerName: "State",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "deposit",
      headerName: "Deposit Amount",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "rent_amount",
      headerName: "Rent Amount",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
    },
    {
      field: "view",
      headerName: "View",
      minWidth: 150,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      renderCell: detailsButton,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      flex: 1,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      renderCell: renderDetailsButton,
    },
  ];

  const [deleteAlert, setDeleteAlert] = useState({ open: false, id: "" });

  const handleConfirm = () => {
    deleteAPI(deleteAlert.id);
    setDeleteAlert({ open: false, id: "" });
  };

  const handleCancel = () => {
    setDeleteAlert({ open: false, id: "" });
  };

  async function AgreementFinal() {
    try {
      const response = await send_to_bhu(
        {
          final_agreement: final_agreement.final_agreement,
          final_agreement_date: final_agreement.agreement_date,
          rent_start_date: final_agreement.rent_start_date,
          status: "Deposited",
          additionalRemark: null,
        },
        selectID
      );

      const popilateData = await populate_rent_data(selectID);
      if (popilateData.status === 200) {
        setFinalAgreement({
          agreement_date: "",
          final_agreement: "",
          rent_start_date: "",
        });

        if (popilateData.status === 200) {
          setLoadingBtn(false);
          const notification = await notification_handler(
            notificationApiParams.event_id,
            notificationApiParams.agreement_id,
            notificationApiParams.agreement_code,
            notificationApiParams.user_id
          );
          await notification_handler(
            "MR1",
            notificationApiParams.agreement_id,
            notificationApiParams.agreement_code,
            notificationApiParams.user_id
          );

          dispatch(
            setAlert({
              variant: "success",
              open: true,
              message: "Final Agreement Submitted Successfully.",
            })
          );
          setopen(false);
          dispatch(setRefreshBox());
        } else {
          dispatch(
            setAlert({
              variant: "error",
              open: true,
              message: "Something went wrong! Please again later.",
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Something went wrong! Please again later.",
        })
      );
    }
  }

  const [remarkOpen, setRemarkOpen] = useState(false);

  const [remarkMSG, setRemarkMSG] = useState("");

  const handleSelectSend = (e) => {
    ids.map(async (id) => {
      const response = await send_to_bhu(
        {
          status: "Sent To Sr Manager",
          manager_id,
          remark: remarkMSG,
          additionalRemark: null,
        },
        id
      );

      if (response.data.success) {
        setIds([]);
        setRemarkOpen(false);
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Agreement Sent To Senior Manager",
          })
        );
        dispatch(setRefreshBox());
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something Went Wrong ! Please Try Again.",
          })
        );
      }
    });
  };

  const [modifyDate, setModifyDate] = useState("");
  const [renew_start_date, setRenew_start_date] = useState("");

  async function getUpdateDate(id) {
    try {
      const response = await getModifyDate(id);
      if (response.status === 200) {
        setModifyDate(response.data.modify_date);
        setRenew_start_date(response.data.renew_start_date);
        setopen(true);
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }

  return (
    <>
      {ids.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", m: 1, mx: 3 }}
            onClick={() => setRemarkOpen(true)}
          >
            Send To Sr Manager
          </Button>
        </Box>
      )}
      <Remark
        remark={remarkMSG}
        setRemark={setRemarkMSG}
        handleSend={handleSelectSend}
        open={remarkOpen}
        handleClose={() => setRemarkOpen(false)}
      />

      <FinalAgreement
        open={open}
        setOpen={setopen}
        loading={loadingBtn}
        setLoading={setLoadingBtn}
        handleConfirm1={AgreementFinal}
        value={final_agreement}
        setValue={setFinalAgreement}
        modifyDate={modifyDate}
        renew_start_date={renew_start_date}
        code={code}
      />

      <Snackbar
        open={err.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={err.type} sx={{ width: "100%" }}>
          {err.message}
        </Alert>
      </Snackbar>

      <PermissionAlert
        handleClose={handleCancel}
        handleConfirm={handleConfirm}
        open={deleteAlert.open}
        message={"Are you sure you want to delete this item?"}
      />

      <Box
        sx={{
          height: "430px",
          px: 2,
          "& .dataGridHeader": {
            color: "#CACACA",
            textAlign: "left",
          },
          "& .green": {
            backgroundColor: "#E7FFE9",
            color: "#41CF2A",
          },
          "& .yellow": {
            backgroundColor: "#FEFFC8",
            color: "#C5C05B",
          },
          "& .red": {
            backgroundColor: "#FFEBE7",
            color: "#CF482A",
          },
          "& .hold": {
            backgroundColor: "#CCCCCC",
            color: "#FFFFFF",
          },
          "& .statusCell": {
            maxHeight: "30px !important",
            minHeight: "25px !important",
            textAlign: "center !important",
            borderRadius: "10px !important",
            m: "auto",
            mx: 1,
          },
          "& .allCell": {
            justifyContent: "center !important",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={params === "approved-ag" ? approvedColumns : columns}
          pageSize={6}
          rowsPerPageOptions={[6]}
          loading={loading}
          sx={{ color: "black !important", minWidth: "50px" }}
          getCellClassName={(parms) => {
            let cellClass = [];
            if (
              parms.field === "status" &&
              (parms.row.status === "Approved" ||
                parms.row.status === "Deposited")
            ) {
              cellClass.push("green statusCell");
            } else if (
              parms.field === "status" &&
              (parms.row.status === "Sent To Sr Manager" ||
                parms.row.status === "Sent To BUH" ||
                parms.row.status === "Sent To Operations" ||
                parms.row.status === "Sent To Finance Team" ||
                parms.row.status === "Draft")
            ) {
              cellClass.push("yellow statusCell");
            } else if (
              parms.field === "status" &&
              (parms.row.status === "Sent Back From Sr Manager" ||
                parms.row.status === "Sent Back From BUH" ||
                parms.row.status === "Sent Back From Operations" ||
                parms.row.status === "Sent Back From Finance" ||
                parms.row.status === "Terminated By Manager" ||
                parms.row.status === "Terminated By Sr Manager" ||
                parms.row.status === "Terminated By Operations" ||
                parms.row.status === "Approved for Termination" ||
                parms.row.status === "Sent Back From Sr Manager Termination" ||
                parms.row.status === "Sent Back From BUH Termination" ||
                parms.row.status === "Sent Back From Operations Termination" ||
                parms.row.status ===
                  "Sent Back From Finance Team Termination" ||
                parms.row.status === "Sent Back From Finance Team" ||
                parms.row.status === "Terminated")
            ) {
              cellClass.push("red statusCell");
            }
            cellClass.push("allCell");

            return cellClass;
          }}
        ></DataGrid>
      </Box>
    </>
  );
}

export default DataTable;
