/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { excelDownload, graphReports, meta } from "../../Services/Services";
import { DashboardItem, MyHeader } from "../StyledComponent";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import SuperAdminHamburgerMenu from "./SuperAdminHamburgerMenu";

function SuperAdminDashboard() {
  const [metaData, setMeta] = useState({});

  const [graphData, setGraphData] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [raw_no_of_agreements, setRaw_No_of_agreements] = useState([]);
  const [no_of_agreements, setNo_of_agreements] = useState([]);
  const [raw_total_deposit, setRaw_Total_deposit] = useState([]);
  const [total_deposit, setTotal_deposit] = useState([]);
  const [total_rent, setTotal_rent] = useState([]);

  const { auth } = useSelector((s) => s);
  const { role, id } = auth;

  const [currentMonthIndex, setCurrentMonthIndex] = useState(null);
  const month = moment().format("MMM-YY");

  useEffect(() => {
    setRaw_No_of_agreements(
      raw_no_of_agreements.map((item, i) => {
        if (i < currentMonthIndex) {
          return item;
        } else {
          return { month: item?.month, no_of_offices: 0 };
        }
      })
    );
    setRaw_Total_deposit(
      raw_total_deposit.map((item, i) => {
        if (i < currentMonthIndex) {
          return item;
        } else {
          return { month: item?.month, total_deposit: 0 };
        }
      })
    );
    setTotal_rent(
      total_rent.map((item, i) => {
        if (i < currentMonthIndex) {
          return item;
        } else {
          return { month: item?.month, total_rent: 0 };
        }
      })
    );
  }, [currentMonthIndex]);

  useEffect(() => {
    getMetaData(auth.id);
    (async () => {
      const data = await graphReports(role);
      setGraphData(data);
      setCurrentMonthIndex(
        data?.no_of_agreements.findIndex((item) => item?.month === month)
      );
      if (data) {
        // eslint-disable-next-line array-callback-return
        data.no_of_agreements.map((val) => {
          setMonthList((monthList) => [...monthList, val.month]);

          setRaw_No_of_agreements((no_of_agreement) => [
            ...no_of_agreement,
            val.no_of_offices,
          ]);
        });
        // eslint-disable-next-line array-callback-return
        data.total_deposit.map((val) => {
          setRaw_Total_deposit((total_deposit) => [
            ...total_deposit,
            val.total_deposit,
          ]);
        });
        // eslint-disable-next-line array-callback-return
        data.total_rent.map((val) => {
          setTotal_rent((total_rent) => [...total_rent, val.total_rent]);
        });
      }
    })();
  }, []);

  const reports = [
    {
      report: "No. of Offices",
      datasets: {
        labels: monthList,
        datasets: [
          {
            label: "No. of Offices",
            data: raw_no_of_agreements,
            backgroundColor: "#0164AE",
          },
        ],
      },
    },
    {
      report: "Monthly Rent Paid",
      datasets: {
        labels: monthList,
        datasets: [
          {
            label: "Monthly Rent Paid",
            data: total_rent,
            backgroundColor: "#0164AE",
          },
        ],
      },
    },
    {
      report: "Monthly Deposit Paid",
      datasets: {
        labels: monthList,
        datasets: [
          {
            label: "Monthly Deposit Paid",
            data: raw_total_deposit,
            backgroundColor: "#0164AE",
          },
        ],
      },
    },
  ];
  const date = moment().format("yyyy");
  const startDate = `${date}-04-01`;
  const lastDateOfLastMonth = moment().subtract(1, "months").endOf("month");
  const endDate = lastDateOfLastMonth.format("YYYY-MM-DD");

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: { legend: { position: "top" } },
  };

  async function getMetaData() {
    let response = await meta();

    if (response) {
      setMeta(response.data);
    }
  }

  return (
    <>
      <Stack sx={{ flexWrap: "nowrap", flexDirection: "row" }}>
        {/* <AdminHamburgerMenu /> */}
        <SuperAdminHamburgerMenu />
        {/* <FinanceHamburger /> */}

        <Box sx={{ flexGrow: 1 }}>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid>
              {" "}
              <MyHeader>
                RMS{" "}
                <span className="small-heading">
                  (Rental Management System)
                </span>
              </MyHeader>
            </Grid>
            <Grid item md={10} sx={{ mt: 4 }}>
              <Grid container spacing={4}>
                <DashboardItem
                  service="Total Sr Manager"
                  value={metaData.Senior_Manager}
                />
                <DashboardItem
                  service="Total Manager"
                  value={metaData.Manager}
                />
                <DashboardItem
                  service="Total Operation"
                  value={metaData.Operations}
                />
                <DashboardItem service="Total BHU" value={metaData.BHU} />
                <DashboardItem
                  service="Total Finance"
                  value={metaData.Finance}
                />
              </Grid>
            </Grid>
            <Grid item md={10} sx={{ mt: 4 }}>
              <Grid
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {reports.map((val, i) => (
                  <div
                    key={i}
                    style={{ width: 500, cursor: "pointer" }}
                    onClick={() => {
                      excelDownload(val.report, id, role, startDate, endDate);
                    }}
                  >
                    <Bar options={options} data={val.datasets} />
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );
}

export default SuperAdminDashboard;
