/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { Box, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { excelDownload, graphReports } from "../../Services/Services";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Logo from "../../assest/pic/Dashboard/logo1 2.png";
import { isExpired, decodeToken } from "react-jwt";
import { styled } from "@mui/material/styles";

function MISDashboard() {
  const { token } = useParams();
  const myDecodedToken = decodeToken(token);
  const isMyTokenExpired = isExpired(token);
  const { role, id } = myDecodedToken?.body;
  const [graphData, setGraphData] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [monthDepositList, setdepositMonthList] = useState([]);
  const [raw_no_of_agreements, setRaw_No_of_agreements] = useState([]);
  const [no_of_agreements, setNo_of_agreements] = useState([]);
  const [raw_total_deposit, setRaw_Total_deposit] = useState([]);
  const [total_deposit, setTotal_deposit] = useState([]);
  const [monthlyRentList, setMonthlyRentList] = useState([]);
  const [total_rent, setTotal_rent] = useState([]);
  const [total_offices_surrendered, settotal_offices_surrendered] = useState(
    []
  );
  const [surrenderedMonthList, setSurrenderedMonthList] = useState([]);

  console.log(total_offices_surrendered, "total_offices_surrendered");
  const [currentMonthIndex, setCurrentMonthIndex] = useState(null);

  const month = moment().format("MMM-YY");

  useEffect(() => {
    setRaw_No_of_agreements(
      raw_no_of_agreements.map((item, i) => {
        return item;
      })
    );
    setRaw_Total_deposit(
      raw_total_deposit.map((item, i) => {
        return item;
      })
    );
    setTotal_rent(
      total_rent.map((item, i) => {
        return item;
      })
    );
    settotal_offices_surrendered(
      total_offices_surrendered.map((item, i) => {
        return item;
      })
    );
  }, [currentMonthIndex]);

  useEffect(() => {
    (async () => {
      const data = await graphReports(
        myDecodedToken?.body?.role,
        myDecodedToken?.body
      );

      setCurrentMonthIndex(
        data?.no_of_agreements.findIndex((item) => item?.month === month)
      );

      setGraphData(data);
      if (data) {
        data.no_of_agreements.map((val) => {
          setMonthList((monthList) => [...monthList, val.month]);

          setRaw_No_of_agreements((no_of_agreement) => [
            ...no_of_agreement,
            val.no_of_offices,
          ]);
        });
        data.total_deposit.map((val) => {
          setdepositMonthList((monthDepositList) => [
            ...monthDepositList,
            val.month,
          ]);

          setRaw_Total_deposit((total_deposit) => [
            ...total_deposit,
            val.total_deposit,
          ]);
        });
        data.total_rent.map((val) => {
          setMonthlyRentList((monthlyRentList) => [
            ...monthlyRentList,
            val.month,
          ]);
          setTotal_rent((total_rent) => [...total_rent, val.total_rent]);
        });
        data.offices_surrendered.map((val) => {
          settotal_offices_surrendered((no_of_offices) => [
            ...no_of_offices,
            val.no_of_offices,
          ]);
          setSurrenderedMonthList((surrenderedmonthList) => [
            ...surrenderedmonthList,
            val.month,
          ]);

          // setRaw_No_of_agreements((no_of_agreement) => [
          //   ...no_of_agreement,
          //   val.no_of_offices,
          // ]);
        });
      }
    })();
  }, []);

  // cummulative graph logic
  // useEffect(() => {
  //   let no_of_agreement_c = [];
  //   let monthly_deposit_c = [];
  //   raw_no_of_agreements.length > 0 &&
  //     raw_no_of_agreements.reduce((prev, next, i) => {
  //       if (i <= raw_no_of_agreements.length - 2) {
  //         if (i + 2 < month) {
  //           no_of_agreement_c = [...no_of_agreement_c, prev];
  //         } else {
  //           no_of_agreement_c = [...no_of_agreement_c, 0];
  //         }
  //       } else {
  //         if (i + 2 < month) {
  //           no_of_agreement_c = [...no_of_agreement_c, prev];
  //           no_of_agreement_c = [...no_of_agreement_c, prev + next];
  //         } else {
  //           no_of_agreement_c = [...no_of_agreement_c, 0];
  //         }
  //       }
  //       return prev + next;
  //     });

  //   raw_total_deposit.length > 0 &&
  //     raw_total_deposit.reduce((prev, next, i) => {
  //       if (i <= raw_total_deposit.length - 2) {
  //         if (i + 2 < month) {
  //           monthly_deposit_c = [...monthly_deposit_c, prev];
  //         } else {
  //           monthly_deposit_c = [...monthly_deposit_c, 0];
  //         }
  //       } else {
  //         if (i + 2 < month) {
  //           monthly_deposit_c = [...monthly_deposit_c, prev];
  //           monthly_deposit_c = [...monthly_deposit_c, prev + next];
  //         } else {
  //           monthly_deposit_c = [...monthly_deposit_c, 0];
  //         }
  //       }
  //       return prev + next;
  //     });

  //   setNo_of_agreements(no_of_agreement_c);
  //   setTotal_deposit(monthly_deposit_c);
  // }, [raw_no_of_agreements]);

  const reports = [
    {
      report: "No. of Offices",
      datasets: {
        labels: monthList,
        datasets: [
          {
            label: "No. of Offices",
            data: raw_no_of_agreements,
            backgroundColor: "#0164AE",
          },
        ],
      },
      visible: true,
    },
    {
      report: "Monthly Rent Paid",
      datasets: {
        labels: monthlyRentList,
        datasets: [
          {
            label: "Monthly Rent Paid",
            data: total_rent,
            backgroundColor: "#0164AE",
          },
        ],
      },
      visible: true,
    },
    {
      report: "Monthly Deposit Paid",
      datasets: {
        labels: monthDepositList,
        datasets: [
          {
            label: "Monthly Deposit Paid",
            data: raw_total_deposit,
            backgroundColor: "#0164AE",
          },
        ],
      },
      visible: true,
    },
    {
      report: "No. Of Offices Surrendered",
      datasets: {
        labels: surrenderedMonthList,
        datasets: [
          {
            label: "No Of Offices Surrendered",
            data: total_offices_surrendered,
            backgroundColor: "#0164AE",
          },
        ],
      },
      visible: role == "Finance",
    },
  ];

  const currentMonth = moment().format("MM");
  const date =
    currentMonth < "04"
      ? moment().subtract(1, "years").format("yyyy")
      : moment().format("yyyy");
  const startDate = `${date}-04-01`;
  const endDate = `${moment(date).add(12, "M").format("YYYY")}-03-31`;

  const boxPadding = "20px";
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const MyHeader = styled("div")(({ theme }) => ({
    fontSize: "24px",
    fontWeight: "bold",
    color: "#0164ae",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  }));
  const options = {
    responsive: true,
    plugins: { legend: { position: "top" } },
  };

  return (
    <>
      <Stack sx={{ flexWrap: "noWrap", flexDirection: "row" }}>
        <Grid
          container
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "space-between", display: "flex" }}
          >
            <Box
              sx={{
                background: `url(${Logo})`,
                height: "66px",
                width: "89px",
                backgroundSize: "cover",
              }}
            />
            <MyHeader>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </MyHeader>
            <Typography mt="15px" mr="15px" fontWeight="600"></Typography>
          </Grid>

          <Grid item md={10} sx={{ mt: 4 }}>
            <Grid container spacing={2}>
              {reports.map(
                (val) =>
                  val.visible && (
                    <>
                      <Grid item xs={12} sm={6} md={6} key={val.report}>
                        <Box
                          sx={{
                            width: "100%",
                            cursor: "pointer",
                            p: 2,
                            padding: boxPadding,
                          }}
                          onClick={() => {
                            excelDownload(
                              val.report,
                              id,
                              role,
                              startDate,
                              endDate
                            );
                          }}
                        >
                          <Bar options={options} data={val.datasets} />
                          <Box sx={{ textAlign: "center", mt: "2rem" }}>
                            <Typography
                              variant="h3"
                              sx={{ fontSize: "18px", fontWeight: "bolder" }}
                            >
                              {val?.report}
                            </Typography>
                            {val?.report !== "No. of Offices " ||
                              (val?.report !== "No. of Offices Surrendered" && (
                                <span
                                  style={{
                                    fontSize: "11px",
                                    marginLeft: "400px",
                                  }}
                                >
                                  {" (Amount in Lacs)"}
                                </span>
                              ))}
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

export default MISDashboard;
