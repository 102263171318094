/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/** @format */
import React, { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  Box,
  Button,
  Grid,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  TextField,
  FormControl,
  IconButton,
  Collapse,
  FormLabel,
  Divider,
} from "@mui/material";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  DocumentUpload,
  MyHeader,
  SelectComponent,
  SelectUser,
  TextFieldWrapper,
} from "../StyledComponent";
import { useNavigate } from "react-router-dom";
import YearlyIncrement from "./IncrementType";
import DialogBox from "./DialogBox";
import {
  add_agreement,
  add_landlord,
  uploadDoc,
  getBankName,
  getLocation,
  getUserList,
  create_landlord_id,
} from "../../Services/Services";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../store/action/action";
import PermissionAlert from "./Alert";
import HamburgerManager from "./HamburgerManager";

function AgreementMigration({ history }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { landloard, auth } = useSelector((state) => state);
  const migrate_id = auth.id;
  const codeGenerater = () => {
    var length = 6,
      charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      random = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      random += charset.charAt(Math.floor(Math.random() * n));
      setData({ ...data, code: random });
    }
  };
  const labelStyle = {
    fontSize: "20px",
    lineHeight: "30px",
    color: "var(--main-color)",
    fontWeight: "600",
    "@media(max-width:900px)": { fontSize: "10px" },
  };
  useEffect(() => {
    codeGenerater();
  }, []);

  const [i, setIndex] = useState(0);
  const [data, setData] = useState({
    landlord: [...landloard],
    code: "",
    lockInYear: "",
    noticePeriod: "",
    monthlyRent: "",
    yearlyIncrement: "",
    tenure: "",
    state: "",
    deposit: "",
    pincode: "",
    location: "",
    city: "",
    area: "",
    migrate_id: auth.id,
    wrongPin: false,
  });

  useEffect(() => {
    setData((old) => ({ ...old, landlord: [...landloard] }));
    setFormError((old) => ({ ...old, landlord: [...landloard] }));
  }, [landloard]);

  const [landblord, setLandblord] = useState([1]);
  const [BUHUsers, setBUHUsers] = useState([]);
  const [SrManagerList, setSrManagerList] = useState([]);
  const [ManagerList, setManagerList] = useState([]);
  const [yearlyIncreentChanged, setYearlyIncreentChanged] = useState(false);
  useEffect(() => {
    getUserList("BUH", null).then(
      (response) => {
        setBUHUsers(response.data);
      },
      (error) => {
        console.info(error);
        setBUHUsers([]);
      }
    );
  }, [landloard]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [formError, setFormError] = useState({
    landlord: [...landloard],
    code: "",
    lockInYear: "",
    noticePeriod: "",
    deposit: "",
    monthlyRent: "",
    yearlyIncrement: "",
    tenure: "",
    state: "",
    pincode: "",
    location: "",
    city: "",
    area: "",
  });

  const [duplicateAadhaarNoss, setDuplicateAdharNos] = useState([]);
  const [duplicatePanrNoss, setDuplicatePanNos] = useState([]);
  const [duplicateMobileNoss, setDuplicateMobileNos] = useState([]);
  const [landLordDuplicateError, setLandLordDuplicateError] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    const duplicateAadhaarNos = [];
    const duplicatePanNo = [];
    const duplicateMobileNo = [];
    const duplicateGstNo = [];
    const aadhaarNoSet = new Set();
    const panNoSet = new Set();
    const mobileNoSet = new Set();
    const gstNoSet = new Set();

    for (const item of data.landlord) {
      if (item.aadharNo !== undefined && aadhaarNoSet.has(item.aadharNo)) {
        duplicateAadhaarNos.push(item.aadharNo);
      } else {
        aadhaarNoSet.add(item.aadharNo);
      }

      if (item.panNo !== undefined && panNoSet.has(item.panNo)) {
        duplicatePanNo.push(item.panNo);
      } else {
        panNoSet.add(item.panNo);
      }

      if (item.mobileNo !== undefined && mobileNoSet.has(item.mobileNo)) {
        duplicateMobileNo.push(item.mobileNo);
      } else {
        mobileNoSet.add(item.mobileNo);
      }

      if (gstNoSet.has(item.gstNo)) {
        duplicateGstNo.push(item.gstNo);
      } else {
        gstNoSet.add(item.gstNo);
      }
    }
    setDuplicateAdharNos(duplicateAadhaarNos);
    setDuplicateMobileNos(duplicateMobileNo);
    setDuplicatePanNos(duplicatePanNo);

    if (
      duplicateAadhaarNos.length > 0 ||
      duplicateMobileNo.length > 0 ||
      duplicatePanNo.length > 0
    ) {
      setLandLordDuplicateError(true);
    } else {
      setLandLordDuplicateError(false);
    }
  }, [data.landlord]);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [expand, setExpand] = useState(0);
  const [docExpand, setDocExpand] = useState(0);

  const [increment, setIncrement] = useState({
    year1: "",
    year2: "",
    year3: "",
    year4: "",
    year5: "",
    year6: "",
    year7: "",
    year8: "",
    year9: "",
    year10: "",
  });
  const [yearValue, setYearValue] = useState({
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  });
  async function handleChangeFile(e) {
    const FD = new FormData();
    FD.append("photo", e.target.files[0]);
    let response = await uploadDoc(FD);

    if (response.status === 200) {
      setFormError((old) => ({
        ...old,
        [e.target.name + "_name"]: "",
        [e.target.name]: "",
      }));

      setData((old) => ({
        ...old,
        [e.target.name]: response.data.link,
        [e.target.name + "_name"]: e.target.files[0].name,
      }));
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: response.data.message,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message || "Something went wrong !!!",
        })
      );
    }
  }

  function checksum(g) {
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
      g
    );
    if (regTest) {
      console.log(checksum);
    }
    return regTest;
  }
  function handleOnBlur(e, i) {
    let error = { state: false, message: null };
    switch (e.target.name) {
      case "leeseName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "aadharNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 12 && e.target.value.length > 0)
          error = { ...error, message: "Aadhaar number must be of 12 digit." };
        else error = { ...error, message: null };
        break;
      case "mobileNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "alternateMobile":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;

      case "bankName":
        if (!e.target.value === "Not Found")
          error = { state: true, message: "Value must be Correct" };
        break;
      case "benificiaryName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;

      case "accountNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length > 17 && e.target.value.length > 0)
          error = { ...error, message: "Account can be of 17 digit only." };
        else error = { ...error, message: null };
        break;

      case "email":
        e.target.value = e.target.value.toLowerCase();
        if (!e.target.value.match(/^[a-zA-Z0-9@.]*$/))
          error = { state: true, message: null };
        else if (
          !e.target.value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "Email should be like example@gmail.com.",
          };
        else error = { ...error, message: null };
        break;
      case "panNo":
        e.target.value = e.target.value.toUpperCase();
        if (
          !e.target.value.match(/^.{0,10}$/) &&
          !e.target.value.match(/^[^@#$%^&*<>'\"/;`%]*$/)
        )
          error = { state: true, message: null };
        if (
          !e.target.value.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "PAN number should be like NOOPS8327K",
          };
        else error = { ...error, message: null };
        break;
      case "gstNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^.{0,15}$/))
          error = { state: true, message: null };
        if (!checksum(e.target.value) && e.target.value.length > 0)
          error = { ...error, message: "GST should be like 18AABCU9603R1ZM." };
        else error = { ...error, message: null };
        break;
      case "ifscCode":
        if (
          !e.target.value.match(/^[A-Z]{4}0[A-Z0-9]{6}$/) &&
          e.target.value !== ""
        )
          error = { ...error, message: "Incorrect IFSC" };
        else error = { ...error, message: null };
        break;
      default:
        break;
    }

    if (error.message) {
      if (formError.landlord[i]) {
        setFormError((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: error.message,
              };
            }
            return row;
          }),
        }));
      } else {
        setFormError((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: error.message,
            },
          ],
        }));
      }
    } else {
      if (formError.landlord[i]) {
        setFormError((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: "",
              };
            }
            return row;
          }),
        }));
      } else {
        setFormError((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: "",
            },
          ],
        }));
      }
    }
  }

  async function handleChange(e, i) {
    let error = { state: false, message: null };
    switch (e.target.name) {
      case "leeseName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "pincode":
        if (!e.target.value.match(/^.{0,6}$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "aadharNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 12 && e.target.value.length > 0)
          error = { ...error, message: "Aadhaar number must be of 12 digit." };
        else error = { ...error, message: null };
        break;
      case "mobileNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "alternateMobile":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "bankName":
        if (e.target.value === "Not Found")
          error = { state: true, message: "Value must be Correct" };
        break;
      case "benificiaryName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "accountNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length > 17 && e.target.value.length > 0)
          error = { ...error, message: "Account can be of 17 digit only." };
        else error = { ...error, message: null };
        break;

      case "email":
        e.target.value = e.target.value.toLowerCase();
        if (!e.target.value.match(/^[a-zA-Z0-9@.]*$/))
          error = { state: true, message: null };
        else if (
          !e.target.value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "Email address should be like example@gmail.com.",
          };
        else error = { ...error, message: null };
        break;

      case "panNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };
        else if (
          !e.target.value.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "PAN number should be like NOOPS8327K",
          };
        else error = { ...error, message: null };
        break;
      case "gstNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };
        if (!checksum(e.target.value) && e.target.value.length > 0)
          error = {
            ...error,
            message: "GST number should be like 27AAPFU0939F1ZV.",
          };
        else error = { ...error, message: null };
        break;
      case "ifscCode":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };
        break;
      case "buh_id":
        const srmanagers = await getUserList("SR.MANAGER", e.target.value);
        setSrManagerList(srmanagers.data);
        setManagerList([]);
        setData((old) => ({ ...old, srm_id: 0, manager_id: 0 }));
        setFormError((old) => ({ ...old, [e.target.name]: "" }));
        break;
      case "srm_id":
        const managers = await getUserList("MANAGER", e.target.value);
        setManagerList(managers.data);
        setFormError((old) => ({ ...old, [e.target.name]: "" }));
        break;
      case "manager_id":
        setFormError((old) => ({ ...old, [e.target.name]: "" }));
        break;
      default:
        break;
    }
    if (e.target.name === "ifscCode") {
      getBankDetails(e.target.value, i);
    }
    if (
      !error.state &&
      e.target.name !== "buh_id" &&
      e.target.name !== "srm_id" &&
      e.target.name !== "manager_id" &&
      e.target.name !== "rent_start_date" &&
      e.target.name !== "final_agreement_date"
    ) {
      if (data.landlord[i]) {
        setData((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: e.target.value,
              };
            }
            return row;
          }),
        }));
      } else {
        setData((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: e.target.value,
            },
          ],
        }));
      }
    } else {
      setData((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  }

  function handleCommonChange(e, i) {
    var error = { state: false };
    switch (e.target.name) {
      case "assets":
        if (!e.target.value.match(/^[^@#$%^&*<>'}{)(!?~:_+=";`%]*$/))
          error = { state: true, message: "Value must be correct" };
        break;
      case "location":
        if (!e.target.value.match(/^[^@#$%^&*<>~_+=)(:}{?|\'\"/;!`%]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "address":
        if (!e.target.value.match(/^[^@#$%^&*~<>'!?)(}{:_+=\";`%]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "lockInYear":
        if (e.target.value !== 0 && e.target.value.length <= 2) {
          if (!e.target.value.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      case "pincode":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "area":
        if (e.target.value.length <= 5) {
          if (!e.target.value.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      case "rental_amount":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "monthlyRent":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "deposit":
        if (!e.target.value.match(/^[0-9]*$/)) error = { state: true };
        break;
      case "noticePeriod":
        if (e.target.value !== 0 && e.target.value.length <= 2) {
          if (!e.target.value.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      case "tenure":
        if (e.target.value !== 0 && e.target.value.length <= 3) {
          if (!e.target.value.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      default:
        break;
    }
    if (!error.state) {
      setData((old) => ({ ...old, [e.target.name]: e.target.value }));
      setFormError((old) => ({ ...old, [e.target.name]: "" }));
    }
  }
  const handleConfirm = (id) => {
    setOpen(false);
    const {
      code,
      lockInYear,
      monthlyRent,
      noticePeriod,
      yearlyIncrement,
      deposit,
      gst_certificate,
      draft_agreement,
      electricity_bill,
      poa,
      maintaince_bill,
      cheque,
      tax_receipt,
      noc,
      tenure,
      year1,
      year2,
      year3,
      year4,
      year5,
      year6,
      year7,
      year8,
      year9,
      year10,
      pincode,
      state,
      address,
      location,
      city,
      area,
      assets,
      property_pic,
      buh_id,
      srm_id,
      manager_id,
      migrate_id,
      final_agreement_date,
      rent_start_date,
      final_agreement,
    } = data;

    const { landlord } = data;
    landlord.length > 0 &&
      landlord.map((obj) => {
        if (!obj.email) {
          return (obj["email"] = "dummy@gmail.com");
        }
      });

    APICall(
      {
        modify_date: new Date(),
        property_pic,
        assets,
        area,
        code,
        lockInYear,
        monthlyRent,
        noticePeriod,
        yearlyIncrement: tenure <= 12 ? "" : yearlyIncrement,
        deposit,
        gst_certificate,
        electricity_bill,
        poa,
        maintaince_bill,
        cheque,
        tax_receipt,
        noc,
        tenure,
        year1,
        year2,
        year3,
        year4,
        year5,
        year6,
        year7,
        year8,
        year9,
        year10,
        percentage1: tenure <= 12 ? "" : yearValue.year1,
        percentage2: tenure <= 12 ? "" : yearValue.year2,
        percentage3: tenure <= 12 ? "" : yearValue.year3,
        percentage4: tenure <= 12 ? "" : yearValue.year4,
        percentage5: tenure <= 12 ? "" : yearValue.year5,
        percentage6: tenure <= 12 ? "" : yearValue.year6,
        percentage7: tenure <= 12 ? "" : yearValue.year7,
        percentage8: tenure <= 12 ? "" : yearValue.year8,
        percentage9: tenure <= 12 ? "" : yearValue.year9,
        percentage10: tenure <= 12 ? "" : yearValue.year10,
        pincode,
        state,
        address,
        location,
        city,
        //   manager_id: id,
        type: "Migrated",
        remark: "Migrated",
        status: "Sent To Operations",
        buh_id,
        srm_id,
        manager_id,
        migrate_id,
        final_agreement_date,
        rent_start_date,
        final_agreement,
      },
      landlord
    );
  };

  function handleHold(id) {
    setData({ ...data, ...increment });
    handleHoldApiCall(id, { ...data, ...increment });
  }

  async function handleHoldApiCall(id, data) {
    const {
      area,
      pincode,
      state,
      address,
      location,
      city,
      code,
      lockInYear,
      monthlyRent,
      noticePeriod,
      yearlyIncrement,
      deposit,
      gst_certificate,
      draft_agreement,
      electricity_bill,
      poa,
      maintaince_bill,
      cheque,
      tax_receipt,
      noc,
      tenure,
      year1,
      year2,
      year3,
      year4,
      year5,
      year6,
      year7,
      year8,
      year9,
      year10,
      assets,
      property_pic,
      buh_id,
      srm_id,
      manager_id,
      migrate_id,
      final_agreement_date,
      rent_start_date,
      final_agreement,
    } = data;

    const { landlord } = data;
    APICall(
      {
        modify_date: new Date(),
        area,
        code,
        lockInYear,
        monthlyRent,
        noticePeriod,
        yearlyIncrement: tenure <= 12 ? "" : yearlyIncrement,
        deposit,
        gst_certificate,
        draft_agreement: final_agreement,
        electricity_bill,
        poa,
        maintaince_bill,
        cheque,
        tax_receipt,
        noc,
        tenure,
        year1,
        year2,
        year3,
        year4,
        year5,
        year6,
        year7,
        year8,
        year9,
        year10,
        percentage1: tenure <= 12 ? "" : yearValue.year1,
        percentage2: tenure <= 12 ? "" : yearValue.year2,
        percentage3: tenure <= 12 ? "" : yearValue.year3,
        percentage4: tenure <= 12 ? "" : yearValue.year4,
        percentage5: tenure <= 12 ? "" : yearValue.year5,
        percentage6: tenure <= 12 ? "" : yearValue.year6,
        percentage7: tenure <= 12 ? "" : yearValue.year7,
        percentage8: tenure <= 12 ? "" : yearValue.year8,
        percentage9: tenure <= 12 ? "" : yearValue.year9,
        percentage10: tenure <= 12 ? "" : yearValue.year10,
        pincode,
        state,
        address,
        location,
        city,
        manager_id: manager_id,
        migrate_id,
        status: "Draft",
        remark: "",
        assets,
        property_pic,
        type: "Migrated",
        buh_id,
        srm_id,
        final_agreement_date,
        rent_start_date,
        final_agreement,
      },
      landlord
    );
  }

  async function APICall(values, landlordData) {
    const agreement = await add_agreement(values);
    if (agreement.data.success) {
      const agreement_id = agreement.data.agreement[0];
      landlordData = landlordData.map((row, index) => {
        let aadhar_card = `${(row.leeseName + "@aadhar_card").replace(
          " ",
          ""
        )}`;
        let pan_card = `${(row.leeseName + "@pan_card").replace(" ", "")}`;
        let gst = `${(row.leeseName + "@gst").replace(" ", "")}`;
        let cheque = `${(row.leeseName + "@cheque").replace(" ", "")}`;
        return {
          ...row,
          name: row.leeseName,
          agreement_id,
          aadhar_card: data[aadhar_card],
          pan_card: data[pan_card],
          gst: data[gst],
          cheque: data[cheque],
        };
      });

      const result = await add_landlord(landlordData);
      const result1 = await create_landlord_id(agreement_id, auth.id);
      if (result) {
        navigate("/listing/mg-ag");
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message: "Agreement Submitted.",
          })
        );
      }
    }
  }

  async function getBankDetails(data, i) {
    try {
      let res = await getBankName(data);
      if (res.status === 200) {
        setData((old) => ({
          ...old,
          landlord: old.landlord.map((row, index) => {
            if (index === i) {
              return {
                ...row,
                bankName: res.data.BANK,
                branchName: res.data.BRANCH,
              };
            } else return row;
          }),
        }));
      }
    } catch (err) {
      setData((old) => ({
        ...old,
        landlord: old.landlord.map((row, index) => {
          if (index === i) {
            return { ...row, bankName: "Not Found", branchName: "" };
          } else return row;
        }),
      }));
    }
  }

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      setOpen(true);
    }
  }, [formError]);
  function validateFields(data) {
    let field = [
      "deposit",
      "monthlyRent",
      "tenure",
      "buh_id",
      "srm_id",
      "manager_id",
      "final_agreement_date",
      "rent_start_date",
      "final_agreement",
    ];

    let dataError = [];
    if (data.landlord && data.landlord.length > 0) {
      dataError = data.landlord.map((row, i) => ({}));
    }

    let finalCheck = field.map((row) => {
      if (!data[row] || data[row] === "") {
        setFormError((old) => ({
          ...old,
          [row]: "Field required.",
          landlord: dataError,
        }));
        return true;
      } else {
        setFormError((old) => ({ ...old, [row]: "", landlord: dataError }));
        return false;
      }
    });

    dataError.map((row, i) => {
      finalCheck.push(Object.values(row).includes("Incorrect IFSC"));
      finalCheck.push(Object.values(row).includes("Field is required."));
    });

    if (!finalCheck.includes(true)) {
      return true;
    } else return false;
  }

  const [open, setOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(data);
    setData((old) => ({ ...old, ...increment }));
    if (Number(data.noticePeriod) > Number(data.tenure)) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Notice Period should be less than tenure",
        })
      );
    } else if (Number(data.lockInYear) > Number(data.tenure)) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Lock in month should be less than tenure",
        })
      );
    } else if (landLordDuplicateError) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Duplicate Landlord details",
        })
      );
    } else {
      if (
        validateFields(data) &&
        !data?.areaInvalid &&
        !data.monthlyRentalInvalid &&
        !data.depositInvalid &&
        !data.tenureInvalid
      ) {
        if (
          new Date(data.final_agreement_date) > new Date(data.rent_start_date)
        ) {
          dispatch(
            setAlert({
              open: true,
              variant: "error",
              message: "Rent start date must be greater that agreement date.",
            })
          );
          return;
        }
        setIsSubmit(true);
        setOpen(true);
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Please Fill/Upload Mandatory Fields.",
          })
        );
      }
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  async function handleStateSearch(e, i) {
    if (e.target.value.length === 6) {
      let response = await getLocation(e.target.value);
      if (response.data[0].PostOffice) {
        let address = response.data[0].PostOffice[0];
        setFormError((old) => ({
          ...old,
          city: "",
          state: "",
          pincode: null, // Clear pincode error
        }));
        return setData((old) => ({
          ...old,
          state: address.State,
          city: address.District,
          wrongPin: false,
        }));
      } else {
        setFormError((old) => ({
          ...old,
          city: "",
          state: "",
          pincode: "Invalid pin code.", // Set pincode error
        }));
        return setData((old) => ({
          ...old,
          state: "",
          city: "",
          wrongPin: true,
        }));
      }
    } else {
      return setData((old) => ({
        ...old,
        state: "",
        city: "",
        wrongPin: false,
      }));
    }
  }

  return (
    <>
      {/* alert for submit form */}
      <PermissionAlert
        handleClose={handleCancel}
        handleConfirm={() => handleConfirm()}
        open={open}
        message={"Please check agreement carefully before submission."}
      />

      {/* dialog box ( popup box ) */}
      <DialogBox value={landblord} setValue={setLandblord} />
      <Stack sx={{ flexWrap: "nowrap", flexDirection: "row" }}>
        <HamburgerManager />

        <Box className="backButton">
          <IconButton
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
            size={"large"}
          >
            <ArrowCircleLeftIcon
              sx={{ fontSize: "3rem" }}
              color="#FFFFF !important"
            />
          </IconButton>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "space-between", display: "flex" }}
          >
            <MyHeader>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </MyHeader>
            <Typography mt="15px" mr="15px" fontWeight="600">
              Welcome {auth.name}
            </Typography>
          </Grid>
          <Divider />
          <Grid
            container
            sx={{
              px: 1,
              justifyContent: "space-between",
              my: 1,
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <Typography
                variant="body1"
                color="black"
                fontSize="20px"
                fontWeight="600"
                alignSelf="center"
                lineHeight="30px"
                sx={{
                  "@media(max-width:600px)": {
                    fontSize: "17px",
                    lineHeight: "25px",
                  },
                }}
              >
                {"New Migrated Agreement"}
              </Typography>
            </Grid>
          </Grid>
          <Divider />

          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={10}>
              {/* agreement form start here */}
              <Box
                component="form"
                sx={{
                  py: 5,
                  backgroundColor: "white",
                  mx: 3,
                  borderRadius: "15px",
                  maxWidth: "1050px",
                  "@media(max-width:900px)": { mx: 0 },
                }}
              >
                {/* Basic details start here */}
                <Typography
                  variant="body1"
                  color="var(--main-color)"
                  fontSize="25px"
                  lineHeight="28px"
                  fontWeight="600"
                  my="20px"
                >
                  Property Details
                </Typography>

                <Grid container spacing={isSmall ? 2 : 4}>
                  <TextFieldWrapper
                    label="Code"
                    disabled={true}
                    placeHolder="code"
                    backgroundColor="rgba(3, 193, 243, 0.2);"
                    value={data.code}
                    name="code"
                  />

                  <TextFieldWrapper
                    label="Pincode"
                    placeHolder="Pincode"
                    backgroundColor="rgba(3, 193, 243, 0.2);"
                    value={data.pincode}
                    maxLength={6}
                    name="pincode"
                    onChange={(e) => {
                      handleCommonChange(e);
                      handleStateSearch(e);
                      const pincodeValue = e.target.value;
                      const pincodeRegex = /^[1-9][0-9]{5}$/;
                      if (
                        (pincodeValue.length === 6 &&
                          !pincodeRegex.test(pincodeValue)) ||
                        data.wrongPin
                      ) {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          pincode: "Invalid pin code.",
                        }));
                      } else {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          pincode: null,
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value.length < 6) {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          pincode: "Pin code must be 6 digits long.",
                        }));
                      } else if (data.wrongPin) {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          pincode: "Invalid pin code.",
                        }));
                      } else {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          pincode: null,
                        }));
                      }
                    }}
                    error={
                      data.wrongPin ? "Invalid pin code." : formError.pincode
                    }
                  />

                  <TextFieldWrapper
                    label="State"
                    disabled={true}
                    name="state"
                    maxLength={6}
                    value={data.state || ""}
                    error={formError.state}
                    onChange={handleCommonChange}
                  />

                  <TextFieldWrapper
                    label="City"
                    disabled={true}
                    fullWidth
                    name="city"
                    value={data.city || ""}
                    error={formError.city}
                    onChange={handleCommonChange}
                  />

                  <TextFieldWrapper
                    label="Location"
                    placeHolder="Enter Location"
                    name="location"
                    error={formError.location}
                    value={data.location}
                    onChange={handleCommonChange}
                    index={i}
                  />

                  <TextFieldWrapper
                    label="Area"
                    placeHolder="Area in sq. ft"
                    name="area"
                    maxLength={5}
                    notationVal="sq. ft"
                    textAlignRight={"textAlignRight"}
                    error={
                      data?.areaInvalid
                        ? "Value must be greater than zero ! "
                        : formError.area
                    }
                    value={data.area}
                    onChange={(e) => {
                      handleCommonChange(e);
                      const areaValue = e.target.value;

                      if (areaValue === 0) {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          area: "Value must be greater than zero !",
                        }));
                        setData((previousdata) => ({
                          ...previousdata,
                          areaInvalid: true,
                        }));
                      } else {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          area: null,
                        }));
                        setData((previousdata) => ({
                          ...previousdata,
                          areaInvalid: false,
                        }));
                      }
                    }}
                    index={i}
                  />

                  <TextFieldWrapper
                    label="Address"
                    placeHolder="Enter Address"
                    name="address"
                    value={data.address}
                    error={formError.address}
                    onChange={handleCommonChange}
                    index={i}
                  />
                  <TextFieldWrapper
                    required={true}
                    label="Deposit Amount"
                    placeHolder="Enter Deposit Amount"
                    name="deposit"
                    maxLength={8}
                    error={
                      data.depositInvalid
                        ? "Value must be greater than zero !"
                        : formError.deposit
                    }
                    textAlignRight={"textAlignRight"}
                    value={data.deposit || ""}
                    onChange={(e) => {
                      handleCommonChange(e);
                      const depositValue = e.target.value;

                      if (depositValue === 0) {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          deposit: "Value must be greater than zero !",
                        }));
                        setData((previousdata) => ({
                          ...previousdata,
                          depositInvalid: true,
                        }));
                      } else {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          deposit: null,
                        }));
                        setData((previousdata) => ({
                          ...previousdata,
                          depositInvalid: false,
                        }));
                      }
                    }}
                  />

                  <TextFieldWrapper
                    label="Monthly Rental"
                    placeHolder="Enter Rental"
                    required={true}
                    maxLength={8}
                    error={
                      data.monthlyRentalInvalid
                        ? "Value must be greater than zero !"
                        : formError.monthlyRent
                    }
                    textAlignRight={"textAlignRight"}
                    name="monthlyRent"
                    value={data.monthlyRent}
                    onChange={(e) => {
                      handleCommonChange(e);
                      const rentalValue = e.target.value;

                      if (rentalValue === 0) {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          monthlyRent: "Value must be greater than zero !",
                        }));
                        setData((previousdata) => ({
                          ...previousdata,
                          monthlyRentalInvalid: true,
                        }));
                      } else {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          monthlyRent: null,
                        }));
                        setData((previousdata) => ({
                          ...previousdata,
                          monthlyRentalInvalid: false,
                        }));
                      }
                    }}
                  />
                  {/* Dipali changes */}
                  <TextFieldWrapper
                    label="Agreement Tenure"
                    required={true}
                    placeHolder="Tenure In Months"
                    name="tenure"
                    notationVal="Month's"
                    textAlignRight={"textAlignRight"}
                    error={
                      data.tenureInvalid
                        ? "Value must be greater than zero !"
                        : formError.tenure
                    }
                    value={data.tenure}
                    onChange={(e) => {
                      if (e.target.value <= 120) {
                        handleCommonChange(e);
                        const rentalValue = e.target.value;

                        if (rentalValue === 0) {
                          setFormError((prevFormError) => ({
                            ...prevFormError,
                            tenure: "Value must be greater than zero !",
                          }));
                          setData((previousdata) => ({
                            ...previousdata,
                            tenureInvalid: true,
                          }));
                        } else {
                          setFormError((prevFormError) => ({
                            ...prevFormError,
                            tenure: null,
                          }));
                          setData((previousdata) => ({
                            ...previousdata,
                            tenureInvalid: false,
                          }));
                        }
                      }
                    }}
                    index={i}
                    maxLength={3}
                  />
                  <TextFieldWrapper
                    label="Lock In Months"
                    placeHolder="Enter Lock in Months"
                    name="lockInYear"
                    maxLength={2}
                    // type="number"
                    value={data.lockInYear}
                    error={
                      Number(data.lockInYear) > Number(data.tenure)
                        ? "Lock in months should be less than tenure"
                        : formError.lockInYear
                    }
                    onChange={(e) => handleCommonChange(e)}
                  />
                  <TextFieldWrapper
                    label="Notice Period In Months"
                    placeHolder="Enter Notice Period"
                    maxLength={2}
                    name="noticePeriod"
                    value={data.noticePeriod}
                    error={
                      Number(data.noticePeriod) > Number(data.tenure)
                        ? "Notice Period should be less than tenure"
                        : formError.noticePeriod
                    }
                    onChange={(e) => handleCommonChange(e)}
                  />
                  {data.tenure === "" ? null : data.tenure <= 12 ? null : (
                    <SelectComponent
                      label={"Yearly Increment"}
                      required={true}
                      name="yearlyIncrement"
                      options={["Percentage", "Value"]}
                      value={data.yearlyIncrement}
                      onChange={(e) => {
                        handleCommonChange(e);
                        setYearlyIncreentChanged(true);
                      }}
                    />
                  )}
                  <SelectUser
                    label={"Business Unit Head *"}
                    required={true}
                    name="buh_id"
                    options={BUHUsers}
                    onChange={handleChange}
                    error={formError.buh_id}
                  />
                  <SelectUser
                    label={"Senior Manager *"}
                    required={true}
                    name="srm_id"
                    options={SrManagerList}
                    onChange={handleChange}
                    error={formError.srm_id}
                  />
                  <SelectUser
                    label={"Manager *"}
                    required={true}
                    name="manager_id"
                    options={ManagerList}
                    onChange={handleChange}
                    error={formError.manager_id}
                  />
                </Grid>
                {/* basic details end here */}
                <br />
                <YearlyIncrement
                  yearlyIncreentChanged={yearlyIncreentChanged}
                  yearValue={yearValue}
                  setYearValue={setYearValue}
                  tenure={data.tenure}
                  value={data.yearlyIncrement}
                  rent={data.monthlyRent}
                  increment={increment}
                  monthlyRent={data.monthlyRent}
                  setIncrement={setIncrement}
                />

                {/* uncommon fields Details start here*/}
                <Typography
                  variant="body1"
                  color="var(--main-color)"
                  fontSize="25px"
                  lineHeight="28px"
                  fontWeight="600"
                  my="20px"
                >
                  Landlord Details
                </Typography>

                {landblord.map((_, i) => (
                  <>
                    {landloard.length > 0 && (
                      <Box
                        mb={2}
                        size="small"
                        fullWidth
                        variant="outlined"
                        component={Button}
                        onClick={() => setExpand(expand === i ? -1 : i)}
                        sx={{
                          color: "black",
                          justifyContent: "space-between",
                          backgroundColor: "#b0d6f773",
                        }}
                      >
                        <Typography color={"var( --main-color)"}>
                          {" "}
                          {landloard.length > 0
                            ? landloard[i].leeseName
                            : ""}{" "}
                          Personal Details
                        </Typography>
                        <IconButton
                          onClick={() => setExpand(expand === i ? -1 : i)}
                        >
                          {expand === i ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </Box>
                    )}
                    <Collapse in={expand === i} timeout="auto" unmountOnExit>
                      <Grid
                        container
                        sx={{ px: 3, mb: "25px" }}
                        spacing={isSmall ? 2 : 4}
                      >
                        <Grid item xs={12}></Grid>

                        <TextFieldWrapper
                          label="Name Of Lessor"
                          placeHolder="Enter Name Of Lessor"
                          name="leeseName"
                          disabled={true}
                          value={
                            data.landlord[i] && data.landlord[i].leeseName
                              ? data.landlord[i].leeseName
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                        />

                        <TextFieldWrapper
                          label="Aadhaar Number"
                          placeHolder="Enter Aadhaar No."
                          name="aadharNo"
                          maxLength={12}
                          value={
                            data.landlord[i] && data.landlord[i].aadharNo
                              ? data.landlord[i].aadharNo
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                          onBlur={(e) => handleOnBlur(e, i)}
                          index={i}
                          error={
                            duplicateAadhaarNoss.length > 0 &&
                            data.landlord[i].aadharNo &&
                            data.landlord[i].aadharNo.length === 12 &&
                            duplicateAadhaarNoss.includes(
                              data.landlord[i].aadharNo
                            )
                              ? "Duplicate Aadhar"
                              : formError.landlord[i] &&
                                formError.landlord[i].aadharNo
                              ? formError.landlord[i].aadharNo
                              : ""
                          }
                        />
                        <TextFieldWrapper
                          label="PAN Number"
                          placeHolder="Enter PAN No."
                          name="panNo"
                          onBlur={(e) => handleOnBlur(e, i)}
                          maxLength={10}
                          value={
                            data.landlord[i] && data.landlord[i].panNo
                              ? data.landlord[i].panNo
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                          index={i}
                          error={
                            duplicatePanrNoss.length > 0 &&
                            data.landlord[i].panNo &&
                            data.landlord[i].panNo.length === 10 &&
                            duplicatePanrNoss.includes(data.landlord[i].panNo)
                              ? "Duplicate Pan"
                              : formError.landlord[i] &&
                                formError.landlord[i].panNo
                              ? formError.landlord[i].panNo
                              : ""
                          }
                        />

                        <TextFieldWrapper
                          label="Mobile Number"
                          placeHolder="Enter Mobile No."
                          name="mobileNo"
                          maxLength={10}
                          onBlur={(e) => handleOnBlur(e, i)}
                          value={
                            data.landlord[i] && data.landlord[i].mobileNo
                              ? data.landlord[i].mobileNo
                              : ""
                          }
                          error={
                            duplicateMobileNoss.length > 0 &&
                            data.landlord[i].mobileNo &&
                            data.landlord[i].mobileNo.length === 10 &&
                            duplicateMobileNoss.includes(
                              data.landlord[i].mobileNo
                            )
                              ? "Duplicate Mobile no"
                              : formError.landlord[i] &&
                                formError.landlord[i].mobileNo
                              ? formError.landlord[i].mobileNo
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                          index={i}
                        />
                        <TextFieldWrapper
                          label="Alternate Number"
                          placeHolder="Enter Alternate No."
                          name="alternateMobile"
                          onBlur={(e) => handleOnBlur(e, i)}
                          maxLength={10}
                          value={
                            data.landlord[i] && data.landlord[i].alternateMobile
                              ? data.landlord[i].alternateMobile
                              : ""
                          }
                          error={
                            formError.landlord[i] &&
                            formError.landlord[i].alternateMobile
                              ? formError.landlord[i].alternateMobile
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                          index={i}
                        />

                        <TextFieldWrapper
                          label="Email"
                          placeHolder="Enter Email"
                          name="email"
                          onBlur={(e) => handleOnBlur(e, i)}
                          value={
                            data.landlord[i] && data.landlord[i].email
                              ? data.landlord[i].email
                              : ""
                          }
                          error={
                            formError.landlord[i] && formError.landlord[i].email
                              ? formError.landlord[i].email
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                          index={i}
                        />

                        <TextFieldWrapper
                          label="GST Number"
                          placeHolder="Enter GST No."
                          name="gstNo"
                          onBlur={(e) => handleOnBlur(e, i)}
                          maxLength={15}
                          value={
                            data.landlord[i] && data.landlord[i].gstNo
                              ? data.landlord[i].gstNo
                              : ""
                          }
                          error={
                            formError.landlord[i] && formError.landlord[i].gstNo
                              ? formError.landlord[i].gstNo
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                        />
                        <TextFieldWrapper
                          label="Bank IFSC"
                          placeHolder="Enter IFSC Code"
                          name="ifscCode"
                          maxLength={11}
                          onBlur={(e) => handleOnBlur(e, i)}
                          value={
                            data.landlord[i] && data.landlord[i].ifscCode
                              ? data.landlord[i].ifscCode
                              : ""
                          }
                          error={
                            formError.landlord[i] &&
                            formError.landlord[i].ifscCode
                              ? formError.landlord[i].ifscCode
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                        />

                        <TextFieldWrapper
                          label="Bank Name"
                          placeHolder="Enter Bank Name"
                          name="bankName"
                          partLabel={
                            data.landlord[i] && data.landlord[i].branchName
                              ? data.landlord[i].branchName
                              : ""
                          }
                          error={
                            data.landlord[i] &&
                            data.landlord[i].bankName === "Not Found"
                              ? "Bank Name not found."
                              : ""
                          }
                          disabled={true}
                          value={
                            data.landlord[i] && data.landlord[i].bankName
                              ? data.landlord[i].bankName
                              : ""
                          }
                          onChange={(e) => handleOnBlur(e, i)}
                        />

                        <TextFieldWrapper
                          label="Beneficiary Name"
                          placeHolder="Enter Beneficiary Name"
                          name="benificiaryName"
                          onBlur={(e) => handleOnBlur(e, i)}
                          value={
                            data.landlord[i] && data.landlord[i].benificiaryName
                              ? data.landlord[i].benificiaryName
                              : ""
                          }
                          error={
                            formError.landlord[i] &&
                            formError.landlord[i].benificiaryName
                              ? formError.landlord[i].benificiaryName
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                          maxLength={50}
                        />

                        <TextFieldWrapper
                          label="Bank A/c Number "
                          placeHolder="Enter Account No."
                          name="accountNo"
                          maxLength={17}
                          onBlur={(e) => handleOnBlur(e, i)}
                          value={
                            data.landlord[i] && data.landlord[i].accountNo
                              ? data.landlord[i].accountNo
                              : ""
                          }
                          error={
                            formError.landlord[i] &&
                            formError.landlord[i].accountNo
                              ? formError.landlord[i].accountNo
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                        />
                      </Grid>
                    </Collapse>
                  </>
                ))}

                {/* Bank Details ends here*/}

                {/* Document upload section start here */}

                {/* Document */}
                <Typography
                  variant="body1"
                  color="var(--main-color)"
                  fontSize="25px"
                  lineHeight="28px"
                  fontWeight="600"
                  my="20px"
                >
                  Upload Documents
                </Typography>
                <Typography
                  variant="body1"
                  color="var(--main-color)"
                  fontSize="13px"
                  lineHeight="5px"
                  fontWeight="400"
                  my="20px"
                >
                  ( Upload file in png, jpeg, jpg, pdf format & maximum file
                  size limit less than 20 MB )
                </Typography>
                {landblord.map((_, i) => (
                  <>
                    {landloard.length > 0 && (
                      <Box
                        mb={2}
                        size="small"
                        fullWidth
                        variant="outlined"
                        component={Button}
                        onClick={() => setDocExpand(docExpand === i ? -1 : i)}
                        sx={{
                          color: "black",
                          justifyContent: "space-between",
                          backgroundColor: "#b0d6f773",
                        }}
                      >
                        <Typography color={"var( --main-color)"}>
                          {" "}
                          {landloard.length > 0
                            ? landloard[i].leeseName
                            : ""}{" "}
                          Document Upload
                        </Typography>
                        <IconButton
                          onClick={() => setDocExpand(docExpand === i ? -1 : i)}
                        >
                          {docExpand === i ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </Box>
                    )}

                    <Collapse in={docExpand === i} timeout="auto" unmountOnExit>
                      <Grid
                        container
                        spacing={isSmall ? 2 : 4}
                        sx={{ px: 1, justifyContent: "", mb: 3 }}
                      >
                        <Grid item xs={6}>
                          <DocumentUpload
                            uploaded={
                              landloard[i]
                                ? data[
                                    `${(
                                      landloard[i].leeseName + "@aadhar_card"
                                    ).replace(" ", "")}`
                                  ]
                                  ? true
                                  : false
                                : false
                            }
                            label="Upload Aadhaar Card"
                            placeHolder="Upload Aadhaar Card"
                            handleChange={handleChangeFile}
                            name={
                              landloard[i]
                                ? `${(
                                    landloard[i].leeseName + "@aadhar_card"
                                  ).replace(" ", "")}`
                                : "adhar"
                            }
                            href={
                              landloard[i]
                                ? data[
                                    `${
                                      landloard[i].leeseName + "@aadhar_card"
                                    }`.replace(" ", "")
                                  ]
                                : ""
                            }
                            fileName={
                              landloard[i]
                                ? data[
                                    `${
                                      landloard[i].leeseName +
                                      "@aadhar_card_name"
                                    }`.replace(" ", "")
                                  ]
                                : ""
                            }
                            error={
                              landloard[i] &&
                              formError[
                                `${
                                  landloard[i].leeseName + "@aadhar_card_name"
                                }`.replace(" ", "")
                              ]
                            }
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <DocumentUpload
                            label="Upload PAN Card"
                            uploaded={
                              landloard[i]
                                ? data[
                                    `${(
                                      landloard[i].leeseName + "@pan_card"
                                    ).replace(" ", "")}`
                                  ]
                                  ? true
                                  : false
                                : false
                            }
                            placeHolder={"Upload PAN Card"}
                            handleChange={handleChangeFile}
                            name={
                              landloard[i]
                                ? `${(
                                    landloard[i].leeseName + "@pan_card"
                                  ).replace(" ", "")}`
                                : "pan"
                            }
                            href={
                              landloard[i]
                                ? data[
                                    `${
                                      landloard[i].leeseName + "@pan_card"
                                    }`.replace(" ", "")
                                  ]
                                : ""
                            }
                            fileName={
                              landloard[i]
                                ? data[
                                    `${
                                      landloard[i].leeseName + "@pan_card_name"
                                    }`.replace(" ", "")
                                  ]
                                : ""
                            }
                            error={
                              landloard[i] &&
                              formError[
                                `${
                                  landloard[i].leeseName + "@pan_card_name"
                                }`.replace(" ", "")
                              ]
                            }
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <DocumentUpload
                            label="Upload Cancel Cheque"
                            placeHolder="Upload Cancel Cheque"
                            uploaded={
                              landloard[i]
                                ? data[
                                    `${(
                                      landloard[i].leeseName + "@cheque"
                                    ).replace(" ", "")}`
                                  ]
                                  ? true
                                  : false
                                : false
                            }
                            fileName={
                              landloard[i]
                                ? data[
                                    `${
                                      landloard[i].leeseName + "@cheque_name"
                                    }`.replace(" ", "")
                                  ]
                                : ""
                            }
                            error={
                              landloard[i] &&
                              formError[
                                `${
                                  landloard[i].leeseName + "@cheque_name"
                                }`.replace(" ", "")
                              ]
                            }
                            handleChange={handleChangeFile}
                            name={
                              landloard[i]
                                ? `${(
                                    landloard[i].leeseName + "@cheque"
                                  ).replace(" ", "")}`
                                : "cheque"
                            }
                            href={
                              landloard[i]
                                ? data[
                                    `${
                                      landloard[i].leeseName + "@cheque"
                                    }`.replace(" ", "")
                                  ]
                                : ""
                            }
                          />
                        </Grid>

                        {data.landlord[i] ? (
                          data.landlord[i].gstNo ? (
                            <Grid item xs={6}>
                              <DocumentUpload
                                label="Upload GST Certificate"
                                uploaded={
                                  landloard[i]
                                    ? data[
                                        `${(
                                          landloard[i].leeseName + "@gst"
                                        ).replace(" ", "")}`
                                      ]
                                      ? true
                                      : false
                                    : false
                                }
                                fileName={
                                  landloard[i]
                                    ? data[
                                        `${
                                          landloard[i].leeseName + "@gst_name"
                                        }`.replace(" ", "")
                                      ]
                                    : ""
                                }
                                placeHolder="Upload GST Certificate"
                                error={
                                  landloard[i] &&
                                  formError[
                                    `${
                                      landloard[i].leeseName + "@gst_name"
                                    }`.replace(" ", "")
                                  ]
                                }
                                handleChange={handleChangeFile}
                                name={
                                  landloard[i]
                                    ? `${(
                                        landloard[i].leeseName + "@gst"
                                      ).replace(" ", "")}`
                                    : "gst"
                                }
                                href={
                                  landloard[i]
                                    ? data[
                                        `${
                                          landloard[i].leeseName + "@gst"
                                        }`.replace(" ", "")
                                      ]
                                    : ""
                                }
                              />
                            </Grid>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Collapse>
                  </>
                ))}

                {landblord.length > 0 ? (
                  <>
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="25px"
                      lineHeight="28px"
                      fontWeight="600"
                      my="20px"
                    >
                      Upload Documents
                    </Typography>
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="13px"
                      lineHeight="5px"
                      fontWeight="400"
                      my="20px"
                    >
                      ( Upload file in png, jpeg, jpg, pdf format & maximum file
                      size limit less than 20 MB )
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                <Grid
                  container
                  spacing={isSmall ? 2 : 4}
                  sx={{ px: 1, justifyContent: "" }}
                >
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload Electricity Bill"
                      uploaded={data.electricity_bill && true}
                      href={data.electricity_bill}
                      placeHolder={"Upload Electricity Bill"}
                      fileName={data.electricity_bill_name}
                      handleChange={handleChangeFile}
                      name={"electricity_bill"}
                      error={formError.electricity_bill}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload POA"
                      placeHolder="Upload POA"
                      uploaded={data.poa && true}
                      href={data.poa}
                      fileName={data.poa_name}
                      handleChange={handleChangeFile}
                      name={"poa"}
                      error={formError.poa}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload Maintenance Bill"
                      uploaded={data.maintaince_bill && true}
                      href={data.maintaince_bill}
                      placeHolder={"Upload Maintenance Bill"}
                      fileName={data.maintaince_bill_name}
                      handleChange={handleChangeFile}
                      name={"maintaince_bill"}
                      error={formError.maintaince_bill}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload Property Tax Receipt"
                      uploaded={data.tax_receipt && true}
                      href={data.tax_receipt}
                      fileName={data.tax_receipt_name}
                      placeHolder={"Upload Property Tax Receipt"}
                      handleChange={handleChangeFile}
                      name={"tax_receipt"}
                      error={formError.tax_receipt}
                    />
                  </Grid>
                  {data.landlord.length > 1 && (
                    <Grid item xs={6}>
                      <DocumentUpload
                        uploaded={data.noc && true}
                        href={data.noc}
                        label="Upload NOC (If Multiple Owners)"
                        placeHolder="NOC"
                        fileName={data.noc_name}
                        handleChange={handleChangeFile}
                        name={"noc"}
                        error={formError.noc}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload Property Picture"
                      uploaded={data.property_pic && true}
                      href={data.property_pic}
                      fileName={data.property_pic_name}
                      placeHolder={"Upload Property Picture"}
                      handleChange={handleChangeFile}
                      name={"property_pic"}
                      error={formError.property_pic}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DocumentUpload
                      label={"Final Agreement *"}
                      placeHolder={"Upload Final Agreement"}
                      name="final_agreement"
                      handleChange={handleChangeFile}
                      uploaded={data.final_agreement && true}
                      href={data.final_agreement}
                      fileName={data.final_agreement_name}
                      error={formError.final_agreement}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <FormLabel>
                        <Typography variant="body1" sx={labelStyle}>
                          Agreement Date *
                        </Typography>
                      </FormLabel>
                      <input
                        type="date"
                        name="final_agreement_date"
                        value={data.final_agreement_date}
                        className="DatePicker"
                        onChange={(e) => handleChange(e)}
                        onBlur={handleCommonChange}
                      />
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {formError.final_agreement_date}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <FormLabel>
                        <Typography variant="body1" sx={labelStyle}>
                          Monthly Rent Start Date *
                        </Typography>
                      </FormLabel>
                      <input
                        type="date"
                        name="rent_start_date"
                        value={data?.rent_start_date}
                        disabled={data?.final_agreement_date === "" && true}
                        className="DatePicker"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleCommonChange}
                      />
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {formError.rent_start_date}
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Document upload section end here */}

                <Grid
                  item
                  xs={10}
                  sx={{ mt: 5 }}
                  className={"textFieldWrapper"}
                >
                  <Grid item xs={8}>
                    <TextField
                      type="text"
                      multiline
                      rows={3}
                      fullWidth
                      variant="outlined"
                      label="Landlord Assets"
                      name="assets"
                      placeholder="Landlord Assets"
                      value={data.assets}
                      onChange={handleCommonChange}
                    />
                    <Typography sx={{ color: "red" }} variant="caption">
                      {formError.assets}
                    </Typography>
                  </Grid>
                </Grid>
                {/* Landlord Assets */}

                {/* Button Start from here */}
                <Grid
                  container
                  sx={{ justifyContent: "center", mt: 2 }}
                  spacing={4}
                >
                  <Grid item md={3} xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      color="primary"
                      sx={{
                        height: "60px",
                        width: "100%",
                        borderRadius: "20px",
                        fontSize: "16px",
                        color: "#FFFFFF",
                        lineHeight: "32px",
                        textTransform: "capitalize",
                        "@media(max-width:900px)": {
                          fontSize: "11px",
                          lineHeight: "12px",
                          height: "40px",
                        },
                      }}
                    >
                      Submit To Operations
                    </Button>
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleHold()}
                      sx={{
                        height: "60px",
                        width: "100%",
                        borderRadius: "20px",
                        fontSize: "16px",
                        lineHeight: "32px",
                        textTransform: "capitalize",
                        "@media(max-width:900px)": {
                          fontSize: "10px",
                          lineHeight: "20px",
                          height: "40px",
                        },
                      }}
                    >
                      Save As Draft
                    </Button>
                  </Grid>
                </Grid>

                {/* Button Ends Here */}
              </Box>

              {/* agreemenet from end here */}
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );
}

export default AgreementMigration;
