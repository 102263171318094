/* eslint-disable no-unused-vars */
import { Box, Button, Checkbox, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  notification_handler,
  send_to_bhu,
} from "../../../Services/Services";
import { setAlert, setRefreshBox } from "../../../store/action/action";

import Remark from "../../RentalPortal/Remark";

function DataTable({ rows, loading }) {
  const dispatch = useDispatch();
  const { auth, refresh } = useSelector((state) => state);
  const subType = "renewal";

  const role = auth.role;
  const [remarkMSG, setRemarkMSG] = useState("");
  const [remarkOpen, setRemarkOpen] = useState({ open: false });
  const [agreementCode, setAgreementCode] = useState();
  const [agreementId, setAgreementId] = useState();
  // setAgreementCode(e.row.code)
  //   setAgreementId(e.row.id)

  async function handleApprove(id) {
    try {
      const response = await send_to_bhu(
        {
          renewal_status: "Request Approved for Renewal",
          additionalRemark: null,
        },
        id
      );
      if (response.data.success) {
        await notification_handler("RN5", agreementId, agreementCode, auth.id);
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Agreement Approved For Renewal.",
          })
        );
        dispatch(setRefreshBox());
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }

  async function handleTerminate(id) {
    try {
      const response = await send_to_bhu(
        {
          terminate_status: "Request Approved for Termination",
          additionalRemark: null,
        },
        id
      );
      if (response.data.success) {
        await notification_handler("TR5", agreementId, agreementCode, auth.id);
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Agreement Approved For Terminate.",
          })
        );
        dispatch(setRefreshBox());
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }
  // View Button Dipali
  const renderDetailsButton = (e) => {
    const id = e.id;
    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{
            backgroundColor: "rgb(103 185 68 / 89%)",
            color: "white",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
          onClick={(ev) => {
            ev.stopPropagation();
            e.row.type === "Terminate"
              ? navigate(`/srManagerApprovalForTerminate/${id}`)
              : navigate(`/renewal-view-agreement/${id}`);
          }}
        >
          View
        </Button>
      </Box>
    );
  };
  // View Button Dipali

  const actionButton = (e) => {
    const id = e.id;
    const status = e.row.status;
    setAgreementCode(e.row.code);
    setAgreementId(e.row.id);
    const renewal_status = e.row.renewal_status;
    const terminate_status = e.row.terminate_status;
    return (
      <>
        {((e.row.status === "Deposited" &&
          e.row.renewal_status === "Sent To Sr Manager For Renewal") ||
          (e.row.terminate_status === "Sent To Sr Manager For Termination" &&
            e.row.status !== "Terminated By Manager" &&
            e.row.status !== "Terminated By Sr Manager" &&
            e.row.status !== "Terminated By Operations" &&
            e.row.renewal_status !== "Sent To Sr Manager For Renewal" &&
            e.row.status !== "Sent Back From Operations Termination" &&
            e.row.status !== "Sent Back From Finance Team Termination")) &&
          !(
            e.row.status === "Sent Back From Sr Manager Termination" ||
            e.row.status === "Pending For Renewal" ||
            e.row.renewal_status === "Sent To Sr Manager For Termination"
          ) && (
            <Grid container>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  // color="primary"
                  size="small"
                  style={{
                    backgroundColor: "rgb(103 185 68 / 89%)",
                    color: "white",
                    fontSize: "12px",
                    textTransform: "capitalize",
                  }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (renewal_status === "Sent To Sr Manager For Renewal") {
                      handleApprove(id);
                    } else if (
                      terminate_status === "Sent To Sr Manager For Termination"
                    ) {
                      handleTerminate(id);
                    }
                  }}
                >
                  Approve
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  // color="primary"
                  size="small"
                  style={{
                    backgroundColor: "#b53f3f",
                    color: "white",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    // width:"100%"
                  }}
                  onClick={async (e) => {
                    e.stopPropagation();

                    setRemarkOpen({
                      open: true,
                      id,
                      status: status,
                      renewal_status: renewal_status,
                      terminate_status: terminate_status,
                    });
                  }}
                >
                  Send back
                </Button>
              </Grid>
            </Grid>
          )}
      </>
    );
  };

  const navigate = useNavigate();

  const [ids, setIds] = useState([]);

  //handle Checkbox
  const handleSwitch = (e) => {
    let idVar = Number(e.target.name);
    if (ids.includes(idVar)) {
      setIds(ids.filter((i) => i !== idVar));
    } else {
      setIds([...ids, idVar]);
    }
  };

  const columns = [
    {
      field: "checkbox",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 30,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.formattedValue === "Pending For Renewal" ? (
            <Checkbox
              onChange={handleSwitch}
              name={params.id}
              checked={!!ids.includes(params.id)}
            />
          ) : (
            <Checkbox disabled={true} />
          )}
        </>
      ),
    },
    {
      field: "code",
      headerName: "Code",
      minWidth: 130,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 130,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "name",
      headerName: " Landlord Name",
      minWidth: 230,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },

    {
      field: "location",
      headerName: "Location",
      minWidth: 230,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "manager",
      headerName: "Manager",
      minWidth: 160,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 230,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 230,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "state",
      headerName: "State",
      minWidth: 230,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "deposit",
      headerName: "Deposit Amount",
      minWidth: 230,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "rentalAmount",
      headerName: "Rent Amount",
      minWidth: 200,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "expiry_day",
      headerName: "Day In Expire",
      minWidth: 230,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "expiry_date",
      headerName: "Agreement Expiry Date",
      minWidth: 230,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "renewal_status",
      headerName: "Renewal Status",
      minWidth: 200,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "terminate_status",
      headerName: "Terminate Status",
      minWidth: 200,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "view",
      headerName: "View",
      minWidth: 200,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
      renderCell: renderDetailsButton,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 200,
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      flex: 1,
      renderCell: actionButton,
    },
  ];
  //send back
  async function handleSendBackTerminate() {
    try {
      let renewal_data = {
        renewal_status: "Sent Back For Renewal Rectification",
        renewal_remark: remarkMSG,
        additionalRemark: {
          remarks: remarkMSG,
          step_name: auth.name,
          user_id: auth.id,
          agreement_code: agreementCode,
          agreement_id: agreementId,
        },
      };

      let terminate_data = {
        terminate_status: "Sent Back For Termination Rectification",
        termination_remark: remarkMSG,
        additionalRemark: {
          remarks: remarkMSG,
          step_name: auth.name,
          user_id: auth.id,
          agreement_code: agreementCode,
          agreement_id: agreementId,
        },
      };
      const response = await send_to_bhu(
        remarkOpen.renewal_status ===
          ("Sent To Sr Manager For Renewal" || "Pending For Renewal")
          ? renewal_data
          : terminate_data,
        remarkOpen.id,
        role,
        remarkOpen.renewal_status ===
          ("Sent To Sr Manager For Renewal" || "Pending For Renewal")
          ? "renewal"
          : "terminate"
      );
      if (response.data.success) {
        const res =
          remarkOpen.terminate_status === "Sent To Sr Manager For Termination"
            ? await notification_handler(
                "TR7",
                agreementId,
                agreementCode,
                auth.id
              )
            : await notification_handler(
                "RN7",
                agreementId,
                agreementCode,
                auth.id
              );
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Agreement Sent Back To Manager.",
          })
        );
        setRemarkOpen({ ...remarkOpen, open: false });
        setRemarkMSG("");
        dispatch(setRefreshBox());
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }

  return (
    <>
      <Remark
        remark={remarkMSG}
        setRemark={setRemarkMSG}
        handleSend={handleSendBackTerminate}
        open={remarkOpen.open}
        handleClose={() => setRemarkOpen({ ...remarkOpen, open: false })}
      />
      <Box
        sx={{
          height: "430px",
          px: 2,
          "& .dataGridHeader": {
            color: "#CACACA",
            textAlign: "left",
          },
          "& .green": {
            backgroundColor: "#E7FFE9",
            color: "#41CF2A",
          },
          "& .yellow": {
            backgroundColor: "#FEFFC8",
            color: "#C5C05B",
          },
          "& .red": {
            backgroundColor: "#FFEBE7",
            color: "#CF482A",
          },
          "& .statusCell": {
            maxHeight: "30px !important",
            minHeight: "25px !important",
            textAlign: "center !important",
            borderRadius: "10px !important",
            m: "auto",
          },
          "& .allCell": {
            justifyContent: "center !important",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={6}
          rowsPerPageOptions={[6]}
          loading={loading}
          sx={{ color: "black !important", minWidth: "50px" }}
          getCellClassName={(parms) => {
            let cellClass = [];
            if (parms.field === "status" || parms.field === "renewal_status") {
              if (
                (parms.field === "status" ||
                  parms.field === "renewal_status") &&
                (parms.row.status === "Approved for Renewal" ||
                  parms.row.status === "Deposited" ||
                  parms.row.status === "Request Approved for Renewal" ||
                  parms.row.renewal_status === "Pending For Renewal" ||
                  parms.row.status === "Sent To Finance Team" ||
                  parms.row.status === "Approved")
              ) {
                cellClass.push("green statusCell");
              } else if (
                parms.field === "renewal_status" &&
                (parms.row.status === "Pending For Renewal" ||
                  parms.row.status === "Terminated By Manager" ||
                  parms.row.status === "Terminated By Sr Manager" ||
                  parms.row.status === "Sent To Sr Manager For Termination" ||
                  parms.row.renewal_status ===
                    "Sent To Operations For Renewal" ||
                  parms.row.status ===
                    "Sent Back From Sr Manager Termination" ||
                  parms.row.status ===
                    "Sent Back From Operations Termination" ||
                  parms.row.renewal_status === "Sent To Sr Manager For Renewal")
              ) {
                cellClass.push("green statusCell");
              } else if (
                parms.field === "status" &&
                (parms.row.status === "Pending For Renewal" ||
                  parms.row.status === "Sent To Sr Manager For Renewal" ||
                  parms.row.status === "Sent For Termination" ||
                  parms.row.status === "Sent To Operations" ||
                  parms.row.status === "Sent To Sr Manager" ||
                  parms.row.status === "Sent To BUH")
              ) {
                cellClass.push("yellow statusCell");
              } else if (
                (parms.field === "terminate_status" ||
                  parms.field === "status") &&
                (parms.row.terminate_status ===
                  "Sent To Sr Manager For Termination" ||
                  parms.row.terminate_status ===
                    "Sent Back For Termination Rectification" ||
                  parms.row.status === "Terminated By Manager" ||
                  parms.row.status ===
                    "Sent Back From Operations Termination" ||
                  parms.row.status === "Sent Back From Sr Manager" ||
                  parms.row.status === "Sent Back From Finance Team")
              ) {
                cellClass.push("red statusCell");
              }
            } else if (
              parms.field === "terminate_status" ||
              parms.field === "status"
            ) {
              if (
                (parms.field === "terminate_status" ||
                  parms.field === "status") &&
                (parms.row.terminate_status ===
                  "Request Approved for Termination" ||
                  parms.row.terminate_status ===
                    "Sent To Sr Manager For Termination" ||
                  parms.row.terminate_status ===
                    "Sent Back For Termination Rectification")
              ) {
                cellClass.push("red statusCell");
              }
            }
            cellClass.push("allCell");

            return cellClass;
          }}
        ></DataGrid>
      </Box>
    </>
  );
}

export default DataTable;
