/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardItem, MyHeader } from "../StyledComponent";
import { useSelector } from "react-redux";
import {
  excelDownload,
  getMetaData_finance,
  graphReports,
} from "../../Services/Services";
import FinanceHamburger from "./FinanceHamburger";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

function FinanceDashboard() {
  const navigate = useNavigate();
  const { auth } = useSelector((s) => s);
  const { role, isAuth, id } = auth;

  const [graphData, setGraphData] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [raw_no_of_agreements, setRaw_No_of_agreements] = useState([]);
  const [no_of_agreements, setNo_of_agreements] = useState([]);
  const [raw_total_deposit, setRaw_Total_deposit] = useState([]);
  const [monthDepositList, setdepositMonthList] = useState([]);
  const [monthlyRentList, setMonthlyRentList] = useState([]);
  const [total_deposit, setTotal_deposit] = useState([]);
  const [total_rent, setTotal_rent] = useState([]);
  const [currentMonthIndex, setCurrentMonthIndex] = useState(null);
  const month = moment().format("MMM-YY");
  const [total_offices_surrendered, settotal_offices_surrendered] = useState(
    []
  );
  const [surrenderedMonthList, setSurrenderedMonthList] = useState([]);

  useEffect(() => {
    setRaw_No_of_agreements(
      raw_no_of_agreements.map((item, i) => {
        return item;
      })
    );
    setRaw_Total_deposit(
      raw_total_deposit.map((item, i) => {
        return item;
      })
    );
    setTotal_rent(
      total_rent.map((item, i) => {
        return item;
      })
    );
    settotal_offices_surrendered(
      total_offices_surrendered.map((item, i) => {
        return item;
      })
    );
  }, [currentMonthIndex]);

  useEffect(() => {
    getMetaData(auth.id);
    (async () => {
      const data = await graphReports(role);
      setGraphData(data);
      setCurrentMonthIndex(
        data?.no_of_agreements.findIndex((item) => item?.month === month)
      );
      if (data) {
        data.no_of_agreements.map((val) => {
          setMonthList((monthList) => [...monthList, val.month]);

          setRaw_No_of_agreements((no_of_agreement) => [
            ...no_of_agreement,
            val.no_of_offices,
          ]);
        });
        data.total_deposit.map((val) => {
          setdepositMonthList((monthDepositList) => [
            ...monthDepositList,
            val.month,
          ]);

          setRaw_Total_deposit((total_deposit) => [
            ...total_deposit,
            val.total_deposit,
          ]);
        });
        data.total_rent.map((val) => {
          setMonthlyRentList((monthlyRentList) => [
            ...monthlyRentList,
            val.month,
          ]);
          setTotal_rent((total_rent) => [...total_rent, val.total_rent]);
        });
        data.offices_surrendered.map((val) => {
          settotal_offices_surrendered((no_of_offices) => [
            ...no_of_offices,
            val.no_of_offices,
          ]);
          setSurrenderedMonthList((surrenderedmonthList) => [
            ...surrenderedmonthList,
            val.month,
          ]);
        });
      }
    })();
  }, []);

  const reports = [
    {
      report: "No. of Offices",
      datasets: {
        labels: monthList,
        datasets: [
          {
            label: "No. of Offices",
            data: raw_no_of_agreements,
            backgroundColor: "#0164AE",
          },
        ],
      },
      visible: true,
    },
    {
      report: "Monthly Rent Paid",
      datasets: {
        labels: monthlyRentList,
        datasets: [
          {
            label: "Monthly Rent Paid",
            data: total_rent,
            backgroundColor: "#0164AE",
          },
        ],
      },
      visible: true,
    },
    {
      report: "Monthly Deposit Paid",
      datasets: {
        labels: monthDepositList,
        datasets: [
          {
            label: "Monthly Deposit Paid",
            data: raw_total_deposit,
            backgroundColor: "#0164AE",
          },
        ],
      },
      visible: true,
    },
    {
      report: "No. of Offices Surrendered",
      datasets: {
        labels: surrenderedMonthList,
        datasets: [
          {
            label: "No Of Offices Surrendered",
            data: total_offices_surrendered,
            backgroundColor: "#0164AE",
          },
        ],
      },
      visible: role == "Finance",
    },
  ];

  const currentMonth = moment().format("MM");
  const date =
    currentMonth < "04"
      ? moment().subtract(1, "years").format("yyyy")
      : moment().format("yyyy");
  const startDate = `${date}-04-01`;
  const endDate = `${moment(date).add(12, "M").format("YYYY")}-03-31`;
  const lastDateOfLastMonth = moment().subtract(1, "months").endOf("month");

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: { legend: { position: "top" } },
  };

  const [meta, setMeta] = useState({
    totalAgreement: 0,
    Pending: 0,
    Approved: 0,
    Renewal: 0,
    Send_Back: 0,
  });

  const data = [
    {
      service: "Total Agreement",
      value: meta.totalAgreement,
      onClick: () => navigate("/finance-listing/total-ag"),
    },
    {
      service: "Pending Approval",
      value: meta.Pending,
      onClick: () => navigate("/finance-listing/pendingList"),
    },
    {
      service: "Approved Agreement",
      value: meta.Approved,
      onClick: () => navigate("/finance-listing/approved-ag"),
    },
    // {
    //   service: "Total Monthly Payments",
    //   value: meta.Renewal,
    //   onClick: () => navigate(""),
    // },
    // {
    //   service: "Pending Monthly Payments",
    //   value: meta.Renewal,
    //   onClick: () => navigate("/finance-monthly-rent/in-process"),
    // },
    // {
    //   service: "Paid/Terminated Monthly Payments",
    //   value: meta.Renewal,
    //   onClick: () => navigate("/finance-monthly-rent/paid"),
    // },
    // {
    //   service: "Total Renewal/Termination",
    //   value: meta.Renewal,
    //   onClick: () => navigate(""),
    // },
    // {
    //   service: "Pending Renewal/Termination",
    //   value: meta.Renewal,
    //   onClick: () => navigate(""),
    // },
  ];

  async function getMetaData(id) {
    const metaData = await getMetaData_finance(id);
    setMeta(metaData.data);
  }

  return (
    <>
      <Stack sx={{ flexWrap: "noWrap", flexDirection: "row" }}>
        <FinanceHamburger />

        <Grid container sx={{ justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "space-between", display: "flex" }}
          >
            <MyHeader>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </MyHeader>{" "}
            <Typography mt="15px" mr="15px" fontWeight="600">
              Welcome {auth.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={10}
            sx={{
              mt: 4,
              borderBottom: "1px solid dodgerBlue",
              paddingBottom: 5,
            }}
          >
            <Grid container spacing={4}>
              {data.map((item, index) => {
                return (
                  <DashboardItem
                    service={item.service}
                    value={item.value}
                    key={index}
                    onClick={item.onClick}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Grid item md={10} sx={{ mt: 4 }}>
            <Grid
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {reports.map((val, i) => (
                <div
                  key={i}
                  style={{ width: 500, cursor: "pointer" }}
                  onClick={() => {
                    excelDownload(val.report, id, role, startDate, endDate);
                  }}
                >
                  <Bar options={options} data={val.datasets} />
                </div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

export default FinanceDashboard;
