import React from "react";
import HamburgerMenu from "../HamburgerMenu";

function OperationsHamburger() {
  return (
    <HamburgerMenu
      navigateHome={"operationsDashboard"}
      agreements={[
        {
          text: "In-Process",
          navigateTo: "/operationsListing/in-procces-ag",
        },
        {
          text: "Active / In Active Agreements",
          navigateTo: "/operationsListing/approved-ag",
        },
        {
          text: "Migrated Agreements ",
          navigateTo: "/operationsListing/mg-ag",
        },
      ]}
      monthly={[
        { text: "In-Process", navigateTo: "/opr-monthly-rent/in-process" },
        { text: "Paid", navigateTo: "/opr-monthly-rent/paid" },
      ]}
      monthlyBtn="true"
      misReports={[
        { text: "Rental MIS Reports", navigateTo: "/rental-mis-reports" },
        { text: "Graph Reports", navigateTo: "/graph-reports" },
        { text: "Rental Due Schedule", navigateTo: "/rent-paid-schedule" },
      ]}
    />
  );
}

export default OperationsHamburger;
