/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";

const ValueWrapper = ({ label, value }) => {
  return (
    <>
      <Grid
        item
        md={4}
        xs={4}
        sx={{
          height: "66px",
        }}
      >
        <FormControl fullWidth>
          <FormLabel>
            <Typography
              variant="body1"
              fontWeight="600"
              fontSize="20px"
              color="var(--main-color)"
              minHeight="30px"
              sx={{
                "@media(max-width:900px)": {
                  fontSize: "10px",
                  minHeight: "15px",
                },
              }}
            >
              {label}
            </Typography>
          </FormLabel>

          <Grid
            container
            sx={{
              backgroundColor: "var(--main-color)",
              borderRadius: "20px",
              height: "66px",
              justifyContent: "center",
              alignItems: "center",
              "@media(max-width:900px)": { height: "46px" },
            }}
          >
            <Typography
              fontSize="20px"
              lineHeight="30px"
              color="#FFFFFF"
              sx={{
                "@media(max-width:900px)": {
                  fontSize: "12px",
                  lineHeight: "20px",
                },
              }}
            >
              {value}
            </Typography>
          </Grid>
        </FormControl>
      </Grid>
    </>
  );
};

function IncrementType({
  disabled,
  Year,
  Percentage,
  Amount,
  label,
  increment,
  onChange,
  name,
  maxLength,
}) {
  return (
    <>
      <Grid item container spacing={2}>
        <ValueWrapper value={Year} />
        <TextFieldWrapper
          value={increment}
          label={label}
          onChange={onChange}
          name={name}
          disabled={disabled}
          maxLength={maxLength}
        />
        <TextFieldWrapper
          textAlignRight={"textAlignRight"}
          disabled={true}
          value={Math.round(Amount)}
          label="Rental Amount"
        />
      </Grid>
    </>
  );
}

const YearlyIncrement = ({
  yearlyIncreentChanged,
  monthlyRent,
  value,
  rent,
  increment,
  setIncrement,
  tenure,
  yearValue,
  setYearValue,
  disabled,
}) => {
  rent = Number(rent);
  let total1;
  let total2;
  let total3;
  let total4;
  let total5;
  let total6;
  let total7;
  let total8;
  let total9;
  let total10;
  if (value === "Percentage") {
    total1 = (rent / 100) * yearValue.year1 + rent;
    total2 = (total1 / 100) * yearValue.year2 + total1;
    total3 = (total2 / 100) * yearValue.year3 + total2;
    total4 = (total3 / 100) * yearValue.year4 + total3;
    total5 = (total4 / 100) * yearValue.year5 + total4;
    total6 = (total5 / 100) * yearValue.year6 + total5;
    total7 = (total6 / 100) * yearValue.year7 + total6;
    total8 = (total7 / 100) * yearValue.year8 + total7;
    total9 = (total8 / 100) * yearValue.year9 + total8;
    total10 = (total9 / 100) * yearValue.year10 + total9;

    total1 = parseInt(total1);
    total2 = parseInt(total2);
    total3 = parseInt(total3);
    total4 = parseInt(total4);
    total5 = parseInt(total5);
    total6 = parseInt(total6);
    total7 = parseInt(total7);
    total8 = parseInt(total8);
    total9 = parseInt(total9);
    total10 = parseInt(total10);
  } else if (value === "Value") {
    total1 = rent + parseInt(yearValue.year1);
    total2 = total1 + parseInt(yearValue.year2);
    total3 = total2 + parseInt(yearValue.year3);
    total4 = total3 + parseInt(yearValue.year4);
    total5 = total4 + parseInt(yearValue.year5);
    total6 = total5 + parseInt(yearValue.year6);
    total7 = total6 + parseInt(yearValue.year7);
    total8 = total7 + parseInt(yearValue.year8);
    total9 = total8 + parseInt(yearValue.year9);
    total10 = total9 + parseInt(yearValue.year10);
  }
  useEffect(() => {
    if (tenure <= 12) {
      setIncrement({
        year1: 0,
        year2: 0,
        year3: 0,
        year4: 0,
        year5: 0,
        year6: 0,
        year7: 0,
        year8: 0,
        year9: 0,
        year10: 0,
      });
    } else if (tenure > 12 && tenure <= 24) {
      let index = parseInt(tenure.split(" ")[0]);
      let arr = [total1, total2];
      let final = {};
      arr.map((total, i) => {
        if (i + 1 <= index) {
          final = { ...final, ["year" + (i + 1)]: total };
        }
      });

      setIncrement(final);
    } else if (tenure > 24 && tenure <= 36) {
      let index = parseInt(tenure.split(" ")[0]);
      let arr = [total1, total2, total3];
      let final = {};
      arr.map((total, i) => {
        if (i + 1 <= index) {
          final = { ...final, ["year" + (i + 1)]: total };
        }
      });

      setIncrement(final);
    } else if (tenure > 36 && tenure <= 48) {
      let index = parseInt(tenure.split(" ")[0]);
      let arr = [total1, total2, total3, total4];
      let final = {};
      arr.map((total, i) => {
        if (i + 1 <= index) {
          final = { ...final, ["year" + (i + 1)]: total };
        }
      });

      setIncrement(final);
    } else if (tenure > 48 && tenure <= 60) {
      let index = parseInt(tenure.split(" ")[0]);
      let arr = [total1, total2, total3, total4, total5];
      let final = {};
      arr.map((total, i) => {
        if (i + 1 <= index) {
          final = { ...final, ["year" + (i + 1)]: total };
        }
      });

      setIncrement(final);
    } else if (tenure > 60 && tenure <= 72) {
      let index = parseInt(tenure.split(" ")[0]);
      let arr = [total1, total2, total3, total4, total5, total6];
      let final = {};
      arr.map((total, i) => {
        if (i + 1 <= index) {
          final = { ...final, ["year" + (i + 1)]: total };
        }
      });

      setIncrement(final);
    } else if (tenure > 72 && tenure <= 84) {
      let index = parseInt(tenure.split(" ")[0]);
      let arr = [total1, total2, total3, total4, total5, total6, total7];
      let final = {};
      arr.map((total, i) => {
        if (i + 1 <= index) {
          final = { ...final, ["year" + (i + 1)]: total };
        }
      });

      setIncrement(final);
    } else if (tenure > 84 && tenure <= 96) {
      let index = parseInt(tenure.split(" ")[0]);
      let arr = [
        total1,
        total2,
        total3,
        total4,
        total5,
        total6,
        total7,
        total8,
      ];
      let final = {};
      arr.map((total, i) => {
        if (i + 1 <= index) {
          final = { ...final, ["year" + (i + 1)]: total };
        }
      });

      setIncrement(final);
    } else if (tenure > 96 && tenure <= 108) {
      let index = parseInt(tenure.split(" ")[0]);
      let arr = [
        total1,
        total2,
        total3,
        total4,
        total5,
        total6,
        total7,
        total8,
        total9,
      ];
      let final = {};
      arr.map((total, i) => {
        if (i + 1 <= index) {
          final = { ...final, ["year" + (i + 1)]: total };
        }
      });

      setIncrement(final);
    } else if (tenure > 108 && tenure <= 120) {
      let index = parseInt(tenure.split(" ")[0]);
      let arr = [
        total1,
        total2,
        total3,
        total4,
        total5,
        total6,
        total7,
        total8,
        total9,
        total10,
      ];
      let final = {};
      arr.map((total, i) => {
        if (i + 1 <= index) {
          final = { ...final, ["year" + (i + 1)]: total };
        }
      });

      setIncrement(final);
    }
  }, [yearValue, value, tenure, monthlyRent]);

  useEffect(() => {
    if (yearlyIncreentChanged) {
      setYearValue({
        year1: 0,
        year2: 0,
        year3: 0,
        year4: 0,
        year5: 0,
        year6: 0,
        year7: 0,
        year8: 0,
        year9: 0,
        year10: 0,
      });
    } else {
      console.log("No Update");
    }
  }, [value]);

  function handleChange(e) {
    if (e.target.value.match(/^[0-9]*$/)) {
      setYearValue({
        ...yearValue,
        [e.target.name]: e.target.value,
      });
    } else {
    }
  }

  return (
    <>
      {tenure <= 12 ? null : value === "Percentage" ? (
        <Grid
          container
          sx={{ justifyContent: "space-evenly", minHeight: "200px", py: 3 }}
          spacing={2}
        >
          <IncrementType
            Year="Year 1"
            key={"year1"}
            Percentage="0%"
            Amount={increment.year1}
            label="Percentage"
            name="year1"
            increment={0}
            onChange={handleChange}
            disabled={true}
            maxLength={value === "Percentage" ? 3 : 6}
          />
          <>
            <IncrementType
              Year="Year 2"
              key={"year2"}
              Percentage="10%"
              Amount={increment.year2}
              label="Percentage"
              name="year2"
              increment={yearValue.year2 || ""}
              onChange={handleChange}
              disabled={disabled}
              maxLength={value === "Percentage" ? 3 : 6}
            />
          </>
          {tenure > 24 || tenure > 36 || tenure > 48 ? (
            <>
              <IncrementType
                Year="Year 3"
                key={"year3"}
                Percentage="15%"
                Amount={increment.year3}
                label="Percentage"
                increment={yearValue.year3 || ""}
                onChange={handleChange}
                name="year3"
                disabled={disabled}
                maxLength={value === "Percentage" ? 3 : 6}
              />
            </>
          ) : null}
          {tenure > 36 || tenure > 48 ? (
            <>
              <IncrementType
                Year="Year 4"
                key={"year4"}
                Percentage="15%"
                Amount={increment.year4}
                label="Percentage"
                increment={yearValue.year4 || ""}
                onChange={handleChange}
                name="year4"
                disabled={disabled}
                maxLength={value === "Percentage" ? 3 : 6}
              />
            </>
          ) : null}
          {tenure > 48 || tenure > 60 ? (
            <>
              <IncrementType
                Year="Year 5"
                key={"year5"}
                Percentage="15%"
                Amount={increment.year5}
                label="Percentage"
                increment={yearValue.year5 || ""}
                onChange={handleChange}
                name="year5"
                disabled={disabled}
                maxLength={value === "Percentage" ? 3 : 6}
              />
            </>
          ) : null}
          {tenure > 60 || tenure > 72 ? (
            <IncrementType
              Year="Year 6"
              key={"year6"}
              Percentage="15%"
              Amount={increment.year6}
              label="Percentage"
              increment={yearValue.year6 || ""}
              onChange={handleChange}
              name="year6"
              disabled={disabled}
              maxLength={value === "Percentage" ? 3 : 6}
            />
          ) : null}
          {tenure > 72 || tenure > 84 ? (
            <IncrementType
              Year="Year 7"
              key={"year7"}
              Percentage="15%"
              Amount={increment.year7}
              label="Percentage"
              increment={yearValue.year7 || ""}
              onChange={handleChange}
              name="year7"
              disabled={disabled}
              maxLength={value === "Percentage" ? 3 : 6}
            />
          ) : null}
          {tenure > 84 || tenure > 96 ? (
            <IncrementType
              Year="Year 8"
              key={"year8"}
              Percentage="15%"
              Amount={increment.year8}
              label="Percentage"
              increment={yearValue.year8 || ""}
              onChange={handleChange}
              name="year8"
              disabled={disabled}
              maxLength={value === "Percentage" ? 3 : 6}
            />
          ) : null}
          {tenure > 96 || tenure > 108 ? (
            <IncrementType
              Year="Year 9"
              key={"year9"}
              Percentage="15%"
              Amount={increment.year9}
              label="Percentage"
              increment={yearValue.year9 || ""}
              onChange={handleChange}
              name="year9"
              disabled={disabled}
              maxLength={value === "Percentage" ? 3 : 6}
            />
          ) : null}
          {tenure > 108 || tenure > 120 ? (
            <>
              <IncrementType
                Year="Year 10"
                key={"year10"}
                Percentage="15%"
                Amount={increment.year10}
                label="Percentage"
                increment={yearValue.year10 || ""}
                onChange={handleChange}
                name="year10"
                disabled={disabled}
                maxLength={value === "Percentage" ? 3 : 6}
              />
            </>
          ) : null}
        </Grid>
      ) : value === "Value" ? (
        <Grid
          container
          sx={{ justifyContent: "space-evenly", minHeight: "200px", py: 3 }}
          spacing={2}
        >
          <IncrementType
            Year="Year 1"
            key={"year1"}
            Percentage="0%"
            Amount={increment.year1}
            label="Value"
            name="year1"
            increment={0}
            onChange={handleChange}
            disabled={true}
            maxLength={value === "Percentage" ? 3 : 6}
          />
          <>
            <IncrementType
              Year="Year 2"
              key={"year2"}
              Percentage="10%"
              Amount={increment.year2}
              label="Value"
              name="year2"
              increment={yearValue.year2 || ""}
              onChange={handleChange}
              disabled={disabled}
              maxLength={value === "Percentage" ? 3 : 6}
            />
          </>
          {tenure > 24 || tenure > 36 || tenure > 48 ? (
            <>
              <IncrementType
                Year="Year 3"
                key={"year3"}
                Percentage="15%"
                Amount={increment.year3}
                label="Value"
                increment={yearValue.year3 || ""}
                onChange={handleChange}
                name="year3"
                disabled={disabled}
                maxLength={value === "Percentage" ? 3 : 6}
              />
            </>
          ) : null}
          {tenure > 36 || tenure > 48 ? (
            <>
              <IncrementType
                Year="Year 4"
                key={"year4"}
                Percentage="15%"
                Amount={increment.year4}
                label="Value"
                increment={yearValue.year4 || ""}
                onChange={handleChange}
                name="year4"
                disabled={disabled}
                maxLength={value === "Percentage" ? 3 : 6}
              />
            </>
          ) : null}
          {tenure > 48 || tenure > 60 ? (
            <>
              <IncrementType
                Year="Year 5"
                key={"year5"}
                Percentage="15%"
                Amount={increment.year5}
                label="Value"
                increment={yearValue.year5 || ""}
                onChange={handleChange}
                name="year5"
                disabled={disabled}
                maxLength={value === "Percentage" ? 3 : 6}
              />
            </>
          ) : null}
          {tenure > 60 || tenure > 72 ? (
            <IncrementType
              Year="Year 6"
              key={"year6"}
              Percentage="15%"
              Amount={increment.year6}
              label="Value"
              increment={yearValue.year6 || ""}
              onChange={handleChange}
              name="year6"
              disabled={disabled}
              maxLength={value === "Percentage" ? 3 : 6}
            />
          ) : null}
          {tenure > 72 || tenure > 84 ? (
            <IncrementType
              Year="Year 7"
              key={"year7"}
              Percentage="15%"
              Amount={increment.year7}
              label="Value"
              increment={yearValue.year7 || ""}
              onChange={handleChange}
              name="year7"
              disabled={disabled}
              maxLength={value === "Percentage" ? 3 : 6}
            />
          ) : null}
          {tenure > 84 || tenure > 96 ? (
            <IncrementType
              Year="Year 8"
              key={"year8"}
              Percentage="15%"
              Amount={increment.year8}
              label="Value"
              increment={yearValue.year8 || ""}
              onChange={handleChange}
              name="year8"
              disabled={disabled}
              maxLength={value === "Percentage" ? 3 : 6}
            />
          ) : null}
          {tenure > 96 || tenure > 108 ? (
            <IncrementType
              Year="Year 9"
              key={"year9"}
              Percentage="15%"
              Amount={increment.year9}
              label="Value"
              increment={yearValue.year9 || ""}
              onChange={handleChange}
              name="year9"
              disabled={disabled}
              maxLength={value === "Percentage" ? 3 : 6}
            />
          ) : null}
          {tenure > 108 || tenure > 120 ? (
            <>
              <IncrementType
                Year="Year 10"
                key={"year10"}
                Percentage="15%"
                Amount={increment.year10}
                label="Value"
                increment={yearValue.year10 || ""}
                onChange={handleChange}
                name="year10"
                disabled={disabled}
                maxLength={value === "Percentage" ? 3 : 6}
              />
            </>
          ) : null}
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default YearlyIncrement;

const fieldStyle = {
  height: "66px !important",
  color: "rgba(16, 99, 173, 0.47)",
  mt: "30px",
  "@media(max-width:900px)": { height: "46px", p: 1 },
};

const TextFieldWrapper = ({
  textAlignRight,
  disabled,
  label,
  placeHolder,
  value,
  name,
  onChange,
  maxLength,
}) => {
  return (
    <Grid item md={4} xs={4}>
      <FormControl fullWidth className="textFieldWrapper">
        <TextField
          variant="outlined"
          name={name}
          disabled={disabled}
          className={textAlignRight}
          label={label}
          placeholder={placeHolder}
          value={value}
          fullWidth
          sx={fieldStyle}
          onChange={onChange}
          inputProps={{
            maxLength: maxLength,
          }}
        />
      </FormControl>
    </Grid>
  );
};
