/* eslint-disable no-unused-vars */
import {
  Box,
  Divider,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataFieldStyle,
  DocumentView,
  YearField,
} from "../StyleComponents/Rental";
import { MyHeader, TextFieldWrapper } from "../StyledComponent";
import { useEffect, useState } from "react";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

//download file
import {
  get_agreement_id,
  get_data_recovery,
  get_old_agreement,
  get_renewal_recovery_data,
  send_back_to_manager,
  send_to_bhu,
  get_remarks_by_code,
  notification_handler,
  get_deposit_amount
} from "../../Services/Services";
import { setAlert } from "../../store/action/action";
import { useDispatch, useSelector } from "react-redux";
import SRMHamburger from "./SRMHAmburger";
import UploadInvoice from "../MonthalyPayment/UploadInvoiceMonthly";
const Heading = ({ heading }) => {
  return (
    <Grid item xs={11} sx={{ mt: 6, mb: 2 }}>
      <Typography
        variant="body1"
        fontSize={"20px"}
        color={"primary"}
        fontWeight={"600"}
        sx={{ textDecoration: "underline" }}
      >
        {heading}
      </Typography>
    </Grid>
  );
};

function SrManagerApprovalForRenewal() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { auth } = useSelector((s) => s);
  const login_manager_id = auth.id;
  const role = auth.role;

  const [agreement, setAgreement] = useState({});
  const [ids, setIds] = useState([]);
  const [upaid, setUnpaid] = useState([]);
  const [finalLockedInData, setFinalLockedInData] = useState([]);
  const [recovery, setRecovery] = useState({});
  const [open, setOpen] = useState(false);
  const [selectID, setSelectID] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [monthlyId, setMonthlyId] = useState(0);
  const [code, setCode] = useState(0);
  const dispatch = useDispatch();

  const [allRemarks, setAllRemarks] = useState("");

  const [remark, setRemark] = useState("");

  const [deposit, setDeposit] = useState("");

  const [oldIds, setOldIds] = useState([]);

  const subType = "renewal";
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [partLabel, setPartLabel] = useState({
    landlord: [
      {
        accountNo: "",
        alternateMobile: "",
        area: "",
        bankName: "",
        benificiaryName: "",
        branchName: "",
        cheque: "",
        email: "",
        gst: null,
        gstNo: null,
        ifscCode: "",
      },
    ],
  });

  async function get_deposit(code) {
    try {
      const deposit_amount = await get_deposit_amount(code);
      if (deposit_amount.data.success) {
        setDeposit(deposit_amount.data.deposit);
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }

  //renewal recovery data
  const [renewalRecovery, setRenewalRecovery] = useState({});

  async function get_old_data(code) {
    try {
      const oldvalue = await get_old_agreement(code);
      oldvalue.status === 200 && setPartLabel(oldvalue.data.agreement);
      oldvalue.status === 200 && setOldIds(oldvalue.data.ids);
    } catch (error) {}
  }

  async function get_renewal_recovery(code) {
    try {
      const renewalRecovery = await get_renewal_recovery_data(code);
      renewalRecovery.status === 200 &&
        setRenewalRecovery(renewalRecovery.data.data);
    } catch (error) {}
  }

  async function get_remarks_by_aggrement_code(code) {
    try {
      const remarks = await get_remarks_by_code(code, subType, id);

      if (remarks.data.success) {
        let remark = remarks.data.remarks.map(
          ({ step_name, remarks }) => `${step_name} : ${remarks}`
        );
        return remark;
        // setAllRemarks(remark)
      }
      return;
    } catch (error) {}
  }

  const getData = async (id) => {
    try {
      const agreement = await get_agreement_id(id);
      if (agreement.data.success) {
        if (agreement.data.listUnpaidRow.length > 0)
          setUnpaid(agreement.data.listUnpaidRow);
        if (agreement.data.finalLockedIn.length > 0) {
          setFinalLockedInData(agreement.data.finalLockedIn);
        }
        get_recovery_data(agreement.data.agreement[agreement.data.ids[0]].id);
        setAgreement(agreement.data.agreement);
        if (
          agreement.data.agreement[agreement.data.ids[0]].type === "Renewed"
        ) {
          get_old_data(agreement.data.agreement[agreement.data.ids[0]].code);
          get_renewal_recovery(id);
        }

        setIds(agreement.data.ids);
        get_deposit(agreement.data.agreement[agreement.data.ids[0]].code);
        
        const remark = await get_remarks_by_aggrement_code(
          agreement.data.agreement[agreement.data.ids[0]].code
        );
        setAllRemarks(remark);
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Something Went Wrong Please Try Again Later.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  };

  async function get_recovery_data(id) {
    try {
      const recovery = await get_data_recovery(id);
      if (recovery.status === 200) {
        setRecovery(recovery.data.data[0]);
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong!!",
        })
      );
    }
  }

  useEffect(() => {
    getData(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSendBack() {
    if (remark.length <= 0) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required !.",
        })
      );
    } else {
      const agreement_id = agreement[ids[0]].id;
      const agreement_code = agreement[ids[0]].code;

      const response = await send_back_to_manager(
        {
          status:
            agreement[ids[0]].status === "Terminated By Manager"
              ? "Sent Back From Sr Manager Termination"
              : "Sent Back From Sr Manager",
          remark: remark,
          additionalRemark: {
            remarks: remark,
            step_name: auth.name,
            user_id: login_manager_id,
            agreement_code: agreement[ids[0]].code,
            agreement_id: agreement[ids[0]].id,
          },
        },
        id,
        role,
        subType
      );

      if (response.data.success) {
        agreement[ids[0]].type === "New" ||
        agreement[ids[0]].type === "Migrated"
          ? await notification_handler(
              "AG3",
              agreement_id,
              agreement_code,
              auth.id
            )
          : agreement[ids[0]].type === "Renewed" &&
            (await notification_handler(
              "RN13",
              agreement_id,
              agreement_code,
              auth.id
            ));
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Send back From Sr Manager",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    }
  }

  const handleSubmit = async (e) => {
    if (remark.length <= 0) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required !.",
        })
      );
    } else {
      const agreement_id = agreement[ids[0]].id;
      const agreement_code = agreement[ids[0]].code;

      const response = await send_to_bhu(
        {
          status:
            agreement[ids[0]].op_id === 0 ||
            (agreement[ids[0]].op_id === null &&
              agreement[ids[0]].deposit - deposit !== 0)
              ? "Sent To BUH"
              : "Sent To Operations",
          srm_id: login_manager_id,
          remark: remark,
          additionalRemark: {
            remarks: remark,
            step_name: auth.name,
            user_id: login_manager_id,
            agreement_code: agreement[ids[0]].code,
            agreement_id: agreement[ids[0]].id,
          },
        },
        id,
        role,
        subType
      );

      if (response.data.success) {
        agreement[ids[0]].type === "New" ||
        agreement[ids[0]].type === "Migrated"
          ? await notification_handler(
              "AG5",
              agreement_id,
              agreement_code,
              auth.id
            )
          : agreement[ids[0]].type === "Renewed" &&
            (await notification_handler(
              "RN15",
              agreement_id,
              agreement_code,
              auth.id
            ));
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message:
              agreement[ids[0]].op_id === 0 || agreement[ids[0]].op_id === null
                ? // &&
                  // agreement[ids[0]].deposit - deposit !== 0
                  "Agreement Sent To BUH"
                : "Agreement Sent To Operations ",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    }
  };

  //termination
  async function handleTerminate() {
    if (remark.length <= 0) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required !.",
        })
      );
    } else {
      const response = await send_to_bhu(
        {
          status: "Terminated By Sr Manager",
          additionalRemark: {
            remarks: remark,
            step_name: auth.name,
            user_id: login_manager_id,
            agreement_code: agreement[ids[0]].code,
            agreement_id: agreement[ids[0]].id,
          },
        },
        id,
        role,
        "terminate"
      );
      ////
      if (response.data.success) {
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Agreement Sent to Operations For Termination.",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    }
  }

  function getIncrement(rent, value, type) {
    let incrementType;
    rent = Number(rent);
    value = Number(value);
    if (type === "Percentage") {
      incrementType = `${Math.round(((value - rent) / rent) * 100)}%`;
    } else if (type === "Value") {
      incrementType = value - rent;
    }
    return incrementType;
  }

  return (
    <>
      {ids &&
        ids.length > 0 &&
        (agreement[ids[0]].type === "Renewed" ? oldIds.length > 0 : true) && (
          <Stack sx={{ flexDirection: "row", mb: 4 }}>
            {/* <a id="button"></a> */}
            <SRMHamburger />
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                item
                xs={12}
                sx={{ justifyContent: "space-between", display: "flex" }}
              >
                <MyHeader>
                  RMS{" "}
                  <span className="small-heading">
                    (Rental Management System)
                  </span>
                </MyHeader>
                <Typography mt="15px" mr="15px" fontWeight="600">
                  Welcome {auth.name}
                </Typography>
              </Grid>
              <Divider />
              <Grid
                container
                sx={{
                  px: 1,
                  justifyContent: "space-between",
                  my: 1,
                  alignItems: "center",
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    color="black"
                    fontSize="20px"
                    fontWeight="600"
                    alignSelf="center"
                    lineHeight="30px"
                    sx={{
                      "@media(max-width:600px)": {
                        fontSize: "17px",
                        lineHeight: "25px",
                      },
                    }}
                  >
                    {agreement.type === "Migrated"
                      ? "View Migrated Agreement"
                      : "View Agreement"}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Box className="backButton">
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(-1)}
                  size={"large"}
                >
                  <ArrowCircleLeftIcon
                    sx={{ fontSize: "3rem" }}
                    color="#FFFFF !important"
                  />
                </IconButton>
              </Box>

              <Grid container sx={{ justifyContent: "center", mt: 3 }}>
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "flex-end", display: "flex" }}
                ></Grid>
                {/* Basic Details */}
                <Grid item md={10}>
                  <Heading heading={`Property Details`} />
                  {agreement[ids[0]].status === "Deposited" && (
                    <>
                      <Grid container>
                        <DataFieldStyle
                          field={"Final Agreement"}
                          href={agreement[ids[0]].final_agreement}
                          name={"Final Agreement"}
                          bold={true}
                          cursor={true}
                        />
                        <DataFieldStyle
                          field={"Final Agreement Date"}
                          value={agreement[ids[0]].final_agreement_date}
                        />
                        <DataFieldStyle
                          field={"Monthly Rent Start Date"}
                          value={agreement[ids[0]].rent_start_date}
                        />
                      </Grid>
                    </>
                  )}

                  {agreement[ids[0]].site_visit_date !== null && (
                    <>
                      <Grid container>
                        <DataFieldStyle
                          field={"Site Visit date"}
                          value={agreement[ids[0]].site_visit_date}
                        />
                        <DataFieldStyle
                          field={"Site Visit Remark"}
                          value={agreement[ids[0]].site_visit_remark}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid container sx={{ mt: 2 }}>
                    <DataFieldStyle
                      field={"code"}
                      value={agreement[ids[0]].code}
                    />

                    <DataFieldStyle
                      field={"state"}
                      value={agreement[ids[0]].state}
                    />
                    <DataFieldStyle
                      field={"city"}
                      value={agreement[ids[0]].city}
                    />
                    <DataFieldStyle
                      field={"location"}
                      value={agreement[ids[0]].location}
                    />

                    <DataFieldStyle
                      field={"pincode"}
                      value={agreement[ids[0]].pincode}
                    />
                    <DataFieldStyle
                      field={"address"}
                      value={agreement[ids[0]].address}
                    />
                    <DataFieldStyle
                      field={"area"}
                      value={agreement[ids[0]].area + " sq. ft"}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" && (
                          <div
                            style={{
                              color: "rgb(198, 89, 17)",
                              fontSize: "12px",
                            }}
                          >
                            {"Old Value: " + partLabel[oldIds[0]].area}
                          </div>
                        )
                      }
                    />
                    <DataFieldStyle
                      field={"lock in Month"}
                      value={agreement[ids[0]].lockInYear}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" && (
                          <div
                            style={{
                              color: "rgb(198, 89, 17)",
                              fontSize: "12px",
                            }}
                          >
                            {"Old Value: " + partLabel[oldIds[0]].lockInYear}
                          </div>
                        )
                      }
                    />
                    <DataFieldStyle
                      field={"notice period in month"}
                      value={agreement[ids[0]].noticePeriod}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" && (
                          <div
                            style={{
                              color: "rgb(198, 89, 17)",
                              fontSize: "12px",
                            }}
                          >
                            {"Old Value: " + partLabel[oldIds[0]].noticePeriod}
                          </div>
                        )
                      }
                    />
                    <DataFieldStyle
                      field={"deposit"}
                      value={agreement[ids[0]].deposit}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" && (
                          <div
                            style={{
                              color: "rgb(198, 89, 17)",
                              fontSize: "12px",
                            }}
                          >
                            {"Old Value: " + partLabel[oldIds[0]].deposit}
                          </div>
                        )
                      }
                    />
                    <DataFieldStyle
                      field={"monthly rental"}
                      value={agreement[ids[0]].monthlyRent}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" && (
                          <div
                            style={{
                              color: "rgb(198, 89, 17)",
                              fontSize: "12px",
                            }}
                          >
                            {"Old Value: " + partLabel[oldIds[0]].monthlyRent}
                          </div>
                        )
                      }
                    />
                    <DataFieldStyle
                      field={"tenure"}
                      value={agreement[ids[0]].tenure}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" && (
                          <div
                            style={{
                              color: "rgb(198, 89, 17)",
                              fontSize: "12px",
                            }}
                          >
                            {"Old Value: " + partLabel[oldIds[0]].tenure}
                          </div>
                        )
                      }
                    />
                    {agreement[ids[0]].tenure > 12 && (
                      <>
                        <Grid container sx={{ mt: 6 }}>
                          <Grid item xs={12} sx={{ mb: 1 }}>
                            <DataFieldStyle
                              field={"yearly Increment"}
                              value={agreement[ids[0]].yearlyIncrement}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" && (
                                  <div
                                    style={{
                                      color: "rgb(198, 89, 17)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {"Old Value: " +
                                      partLabel[oldIds[0]].yearlyIncrement}
                                  </div>
                                )
                              }
                            />
                          </Grid>
                          <YearField
                            year={"Year 1"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            Increment={0}
                            amount={Math.round(agreement[ids[0]].year1)}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" && (
                                <div
                                  style={{
                                    color: "rgb(198, 89, 17)",
                                    fontSize: "12px",
                                  }}
                                >
                                  {"Old Value: " + partLabel[oldIds[0]].year1}
                                </div>
                              )
                            }
                          />
                          <YearField
                            year={"Year 2"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year2)}
                            Increment={getIncrement(
                              agreement[ids[0]].year1,
                              agreement[ids[0]].year2,
                              agreement[ids[0]].yearlyIncrement
                            )}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              getIncrement(
                                partLabel[oldIds[0]].year1,
                                partLabel[oldIds[0]].year2,
                                partLabel[oldIds[0]].yearlyIncrement
                              )
                            }
                          />
                          {agreement[ids[0]].tenure > 24 && (
                            <YearField
                              year={"Year 3"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year3)}
                              Increment={getIncrement(
                                agreement[ids[0]].year2,
                                agreement[ids[0]].year3,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year2,
                                  partLabel[oldIds[0]].year3,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 36 && (
                            <YearField
                              year={"Year 4"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year4)}
                              Increment={getIncrement(
                                agreement[ids[0]].year3,
                                agreement[ids[0]].year4,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year3,
                                  partLabel[oldIds[0]].year4,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 48 && (
                            <YearField
                              year={"Year 5"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year5)}
                              Increment={getIncrement(
                                agreement[ids[0]].year4,
                                agreement[ids[0]].year5,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year4,
                                  partLabel[oldIds[0]].year5,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                        </Grid>
                      </>
                    )}

                    {Array.from(
                      { length: agreement[ids[0]].leeseName.length },
                      (row, id) => (
                        <>
                          <Grid container sx={{ mt: 3 }}>
                            {/* <Grid item xs={12}>
                            <Typography variant="body1" fontWeight="600">
                              Landlord {id + 1} Details
                            </Typography>
                          </Grid> */}
                            <Heading
                              heading={`Landlord ${id + 1} Personal Details`}
                            />
                            <DataFieldStyle
                              field={"Name of Lessor"}
                              value={agreement[ids[0]].name[id]}
                            />
                            <DataFieldStyle
                              field={"aadhaar number"}
                              value={agreement[ids[0]].aadharNo[id]}
                              href={agreement[ids[0]].aadhar_card[id]}
                              name={"AadharCard"}
                              cursor={true}
                            />
                            <DataFieldStyle
                              field={"PAN number"}
                              value={agreement[ids[0]].panNo[id]}
                              href={agreement[ids[0]].pan_card[id]}
                              name={"pan_certicate"}
                              cursor={true}
                            />
                            {/* <DataFieldStyle
                                field={"GST number"}
                                value={agreement[ids[0]].gstNo[id]}
                                href={agreement[ids[0]].gst[id]}
                                name={"gst"}
                                bold={true}
                                cursor={true}
                                partLabel={
                                  agreement[ids[0]].type === "Renewed" &&
                                  "Old Value: " +
                                    partLabel[oldIds[0]].gstNo[id]
                                }
                              /> */}
                            {agreement[ids[0]].gstNo[id] != null && (
                              <DataFieldStyle
                                field={"GST number"}
                                value={agreement[ids[0]].gstNo[id]}
                                href={agreement[ids[0]].gst[id]}
                                name={"gst"}
                                cursor={true}
                                partLabel={
                                  agreement[ids[0]].type === "Renewed" && (
                                    <div
                                      style={{
                                        color: "rgb(198, 89, 17)",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {"Old Value: " +
                                        partLabel[oldIds[0]].gstNo[id]}
                                    </div>
                                  )
                                }
                              />
                            )}

                            <DataFieldStyle
                              field={"mobile number"}
                              value={agreement[ids[0]].mobileNo[id]}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" && (
                                  <div
                                    style={{
                                      color: "rgb(198, 89, 17)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {"Old Value: " +
                                      partLabel[oldIds[0]].mobileNo[id]}
                                  </div>
                                )
                              }
                            />
                            {agreement.alternateMobile != null && (
                              <DataFieldStyle
                                field={"alternate mobile"}
                                value={agreement.alternateMobile}
                                partLabel={
                                  agreement.type === "Renewed" && (
                                    <div
                                      style={{
                                        color: "rgb(198, 89, 17)",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {"Old Value: " +
                                        partLabel[oldIds[0]].alternateMobile}
                                    </div>
                                  )
                                }
                              />
                            )}
                            <DataFieldStyle
                              field={"email"}
                              value={agreement[ids[0]].email[id]}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" && (
                                  <div
                                    style={{
                                      color: "rgb(198, 89, 17)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {"Old Value: " +
                                      partLabel[oldIds[0]].email[id]}
                                  </div>
                                )
                              }
                            />
                            <DataFieldStyle
                              field={"Percentage Share"}
                              value={`${agreement[ids[0]].percentage[id]}%`}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" && (
                                  <div
                                    style={{
                                      color: "rgb(198, 89, 17)",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {"Old Value: " +
                                      partLabel[oldIds[0]].percentage[id]}
                                  </div>
                                )
                              }
                            />
                          </Grid>
                          <Grid
                            container
                            sx={{ alignItems: "baseline", mt: 1 }}
                          >
                            {agreement[ids[0]].utr_number[id] != null && (
                              <DataFieldStyle
                                field={"Deposit UTR Number"}
                                value={agreement[ids[0]].utr_number[id]}
                              />
                            )}
                            {agreement[ids[0]].payment_date[id] != null && (
                              <Tooltip title="Calender" placement="right">
                                <DataFieldStyle
                                  field={"Deposit Payment Date"}
                                  value={agreement[ids[0]].payment_date[id]}
                                />
                              </Tooltip>
                            )}
                          </Grid>
                        </>
                      )
                    )}
                  </Grid>
                </Grid>

                {/* Bank Details start here */}
                {/* <Heading heading={"Bank Details"} /> */}

                <Grid item md={10}>
                  <Grid container>
                    {Array.from(
                      { length: agreement[ids[0]].leeseName.length },
                      (row, id) => (
                        <Grid container>
                          {/* <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
                            <Typography variant="body1" fontWeight="600">
                              Landlord {id + 1} Details
                            </Typography>
                          </Grid> */}
                          <Heading
                            heading={`Landlord ${id + 1} Bank Details`}
                          />
                          {/* <DataFieldStyle
                              field={"bank name"}
                              value={agreement[ids[0]].bankName[id]}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                "Old Value: " +
                                  partLabel[oldIds[0]].bankName[id]
                              }
                            /> */}
                          <DataFieldStyle
                            field={"bank name"}
                            value={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>{agreement[ids[0]].bankName[id]}</span>
                                <span>{agreement[ids[0]].branchName[id]}</span>
                              </div>
                            }
                            partLabel={
                              agreement[ids[0]].type === "Renewed" && (
                                <div
                                  style={{
                                    color: "rgb(198, 89, 17)",
                                    fontSize: "12px",
                                  }}
                                >
                                  {" "}
                                  `Old Value: $
                                  {partLabel[oldIds[0]].bankName[id]}
                                  Branch Name: $
                                  {partLabel[oldIds[0]].branchName[id]}`
                                </div>
                              )
                            }
                          />
                          <DataFieldStyle
                            field={"beneficiary name"}
                            value={agreement[ids[0]].benificiaryName[id]}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" && (
                                <div
                                  style={{
                                    color: "rgb(198, 89, 17)",
                                    fontSize: "12px",
                                  }}
                                >
                                  {"Old Value: " +
                                    partLabel[oldIds[0]].benificiaryName[id]}
                                </div>
                              )
                            }
                          />
                          <DataFieldStyle
                            field={"bank A/c number"}
                            value={agreement[ids[0]].accountNo[id]}
                            href={agreement[ids[0]].cheque[id]}
                            name={"cheque"}
                            cursor={true}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" && (
                                <div
                                  style={{
                                    color: "rgb(198, 89, 17)",
                                    fontSize: "12px",
                                  }}
                                >
                                  {"Old Value: " +
                                    partLabel[oldIds[0]].accountNo[id]}
                                </div>
                              )
                            }
                          />
                          <DataFieldStyle
                            field={"Bank IFSC"}
                            // partLabel={agreement[ids[0]].branchName[id]}
                            value={agreement[ids[0]].ifscCode[id]}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" && (
                                <div
                                  style={{
                                    color: "rgb(198, 89, 17)",
                                    fontSize: "12px",
                                  }}
                                >
                                  {"Old Value: " +
                                    partLabel[oldIds[0]].ifscCode[id]}
                                </div>
                              )
                            }
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>

                {/* Bank Details Ends here */}

                {/* Bank Details Ends here */}

                {/* Document Section start here */}

                <Grid item md={10}>
                  <Grid container spacing={4} sx={{ mt: 1 }}>
                    <Heading heading={"Document View/Download"} />
                    <DocumentView
                      title={"draft agreement"}
                      img={agreement[ids[0]].draft_agreement}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].draft_agreement
                      }
                    />
                    <DocumentView
                      title={"electricity bill"}
                      img={agreement[ids[0]].electricity_bill}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].electricity_bill
                      }
                    />
                    {/* <DocumentView
                      title={"Cancel bank cheque"}
                      img={agreement[ids[0]].cheque}
                    /> */}
                    <DocumentView
                      title={"Maintenance bill"}
                      img={agreement[ids[0]].maintaince_bill}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].maintaince_bill
                      }
                    />
                    <DocumentView
                      title={"POA"}
                      img={agreement[ids[0]].poa}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].poa
                      }
                    />
                    <DocumentView
                      title={"Property tax receipt"}
                      img={agreement[ids[0]].tax_receipt}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].tax_receipt
                      }
                    />

                    {agreement[ids[0]].leeseName.length > 1 && (
                      <DocumentView
                        title={"NOC (if multiple owner)"}
                        img={agreement[ids[0]].noc}
                        partLabel={
                          agreement[ids[0]].type === "Renewed" &&
                          partLabel[oldIds[0]].noc
                        }
                      />
                    )}
                    <DocumentView
                      title={"Property Picture"}
                      img={agreement[ids[0]].property_pic}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].property_pic
                      }
                    />
                  </Grid>
                </Grid>

                {agreement[ids[0]].type === "Renewed" && (
                  <>
                    <Grid
                      item
                      container
                      sx={{ alignItems: "baseline", mt: 5 }}
                      xs={10}
                      justifyContent="space-between"
                    >
                      <DataFieldStyle
                        field={"Old Deposited Amount"}
                        value={renewalRecovery.deposited}
                        sx={{ textAlign: "left" }}
                      />

                      <DataFieldStyle
                        field={"New Deposit Amount"}
                        value={renewalRecovery.new_deposit}
                        sx={{ textAlign: "center" }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      sx={{ alignItems: "baseline", mt: 2 }}
                      xs={10}
                      justifyContent="space-between"
                    >
                      <DataFieldStyle
                        field={"Unpaid Monthly Rental"}
                        value={renewalRecovery.unpaid_amount}
                        sx={{ textAlign: "right" }}
                      />
                      <DataFieldStyle
                        field={"New Deposit AmountReceivable/Payable"}
                        value={renewalRecovery.receivable}
                        sx={{ textAlign: "right" }}
                      />
                      {/* <DataFieldStyle
                          field={"Deposit Amount"}
                          value={renewalRecovery.balance_amount}
                        /> */}
                    </Grid>
                  </>
                )}

                {/* {(agreement[ids[0]].status === "Terminated By Manager" ||
                    agreement[ids[0]].status === "Terminated" ||
                    agreement[ids[0]].terminate_status  ===
                      "Sent For Termination") && (
                    <>
                      {/* document section ends here */}

                {/* Buttons start here
                      <Grid item xs={10} sx={{ mt: 2 }}>
                        <Grid container sx={{ gap: "2rem" }}>
                          <DataFieldStyle
                            field="Deposit Amount (Paid)"
                            value={recovery.depositedAmount}
                          />
                        </Grid>
                        <Grid container sx={{ gap: "2rem", mt: 2 }}>
                          <DataFieldStyle
                            field="Remaining Months"
                            value={recovery.remainingMonth}
                          />
                          <DataFieldStyle
                            field="Adjustment Amount"
                            value={recovery.adjustmentAmount}
                          />
                          <DataFieldStyle
                            field="Remark"
                            value={recovery.adjustmentAmountRemark}
                          />
                        </Grid>
  
                        <Grid container sx={{ gap: "2rem", mt: 2 }}>
                          <DataFieldStyle
                            field="Expenses Amount"
                            value={recovery.expenses}
                          />
                          <DataFieldStyle
                            field="Remark"
                            value={recovery.expansesRemark}
                          />
                        </Grid>
  
                        <Grid item xs={12} container sx={{ gap: "2rem", mt: 2 }}>
                          <DataFieldStyle
                            field="Other Adjustments"
                            value={recovery.otherAdjustments}
                          />
                          <DataFieldStyle
                            field="Remark"
                            value={recovery.otherRemark}
                          />
                        </Grid>
                        <Grid item xs={12} container sx={{ gap: "2rem", mt: 2 }}>
                          <DataFieldStyle
                            field="Total Adjustment Amount "
                            value={recovery.totalAdjustmentAmount}
                          />
                          <DataFieldStyle
                            field="Balance Deposit "
                            value={recovery.balanceDeposit}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <DocumentView
                            title={"Termination File"}
                            img={agreement[ids[0]].file}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )} */}
                {Object.keys(recovery).length > 0 && (
                  <>
                    <Grid item xs={10}>
                      <Typography
                        variant="body1"
                        color="var(--main-color)"
                        fontSize="25px"
                        lineHeight="20px"
                        fontWeight="600"
                        marginTop="35px"
                        // my="20px"
                      >
                        Deposit Adjustments Details
                      </Typography>
                    </Grid>
                    {upaid.length > 0 && (
                      <Grid mt={1} mb={1} item container xs={10}>
                        <Grid
                          coantiner
                          sx={{
                            display: "flex",
                            gap: "2rem",
                            flexDirection: "column",
                          }}
                        >
                          <Grid item>
                            <Typography
                              color={"primary"}
                              fontWeight="600"
                              marginTop="15px"
                            >
                              Unpaid Months
                            </Typography>
                          </Grid>
                          {upaid.map((row) => (
                            <Grid item sx={{ display: "flex" }}>
                              <TextFieldWrapper
                                marginRight={true}
                                label={"Name"}
                                disabled={true}
                                value={row.name}
                              />
                              <TextFieldWrapper
                                marginRight={true}
                                label={"Rent Month (Unpaid)"}
                                placeHolder="Deposit Amount"
                                disabled={true}
                                value={
                                  month[new Date(row.rent_date).getUTCMonth()] +
                                  "-" +
                                  new Date(row.rent_date).getFullYear()
                                }
                              />
                              <TextFieldWrapper
                                marginRight={true}
                                label="Rent Amount"
                                disabled={true}
                                value={row.rent_amount}
                              />
                              <TextFieldWrapper
                                marginRight={true}
                                label="Status"
                                disabled={true}
                                value={row.status}
                              />
                              {agreement[ids[0]].gstNo && row.gstNo ? (
                                <>
                                  {row.invoice_number != null && (
                                    <Grid item sx={{ textAlign: "center" }}>
                                      {
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          size="small"
                                          style={{
                                            backgroundColor:
                                              "rgb(103 185 68 / 89%)",
                                            color: "white",
                                            fontSize: "12px",
                                            textTransform: "capitalize",
                                            minHeight: "37px",
                                            margin: "10px",
                                            minWidth: "100px",
                                          }}
                                          onClick={(event) => {
                                            event.stopPropagation(); // don't select this row after clicking
                                            setSelectID(row.id);
                                            setOpen(true);
                                            setInvoiceDetails({
                                              ...invoiceDetails,
                                              rentAmount: row.rent_amount,
                                              gstAmount:
                                                row?.gstNo !==
                                                  (null || undefined) &&
                                                row?.gstNo.length > 0
                                                  ? Number(
                                                      parseInt(
                                                        row?.rent_amount * 0.18
                                                      ).toFixed(2)
                                                    )
                                                  : 0,
                                              totalAmount:
                                                Number(row?.rent_amount) +
                                                Number(
                                                  parseInt(
                                                    row?.rent_amount * 0.18
                                                  ).toFixed(2)
                                                ),
                                              user_id: auth.id,
                                              agreement_id:
                                                agreement?.[ids[0]]
                                                  ?.agreement_id[0],
                                              code: agreement?.[ids[0]]?.code,
                                            });
                                            setMonthlyId(row.id);
                                          }}
                                        >
                                          <Typography sx={{ fontSize: "14px" }}>
                                            {"View"}
                                          </Typography>
                                        </Button>
                                      }
                                    </Grid>
                                  )}
                                </>
                              ) : (
                                <Grid item>
                                  <Button
                                    style={{
                                      display: "block",
                                      minHeight: "37px",
                                      margin: "10px",
                                      minWidth: "100px",
                                      background: "transparent",
                                    }}
                                  ></Button>
                                </Grid>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                    {finalLockedInData.length > 0 && (
                      <Grid mt={1} mb={1} item xs={10}>
                        <Grid
                          coantiner
                          sx={{
                            display: "flex",
                            gap: "2rem",
                            flexDirection: "column",
                          }}
                        >
                          <Grid item>
                            <Typography
                              color={"primary"}
                              fontWeight="600"
                              marginTop="15px"
                            >
                              Lock In Months
                            </Typography>
                          </Grid>
                          {finalLockedInData.map((row) => (
                            <>
                              <Grid item sx={{ display: "flex" }}>
                                <TextFieldWrapper
                                  marginRight={true}
                                  label={"Name"}
                                  disabled={true}
                                  value={row.name}
                                />
                                <TextFieldWrapper
                                  marginRight={true}
                                  label={"Rent Month (Locked In Month)"}
                                  placeHolder="Deposit Amount"
                                  disabled={true}
                                  value={
                                    month[
                                      new Date(row.rent_date).getUTCMonth()
                                    ] +
                                    "-" +
                                    new Date(row.rent_date).getFullYear()
                                  }
                                />
                                <TextFieldWrapper
                                  marginRight={true}
                                  label="Rent Amount"
                                  disabled={true}
                                  value={row.rent_amount}
                                />
                                <TextFieldWrapper
                                  marginRight={true}
                                  label="Status"
                                  disabled={true}
                                  value={row.status}
                                />
                                {agreement[ids[0]].gstNo && row.gstNo ? (
                                  <>
                                    <Grid item sx={{ textAlign: "center" }}>
                                      {row.invoice_number != null && (
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          size="small"
                                          style={{
                                            backgroundColor:
                                              "rgb(103 185 68 / 89%)",
                                            color: "white",
                                            fontSize: "12px",
                                            textTransform: "capitalize",
                                            minHeight: "37px",
                                            margin: "10px",
                                            minWidth: "100px",
                                          }}
                                          onClick={(event) => {
                                            event.stopPropagation(); // don't select this row after clicking
                                            setSelectID(row.id);
                                            setOpen(true);
                                            setInvoiceDetails({
                                              ...invoiceDetails,
                                              rentAmount: row.rent_amount,
                                              gstAmount:
                                                row?.gstNo !==
                                                  (null || undefined) &&
                                                row?.gstNo.length > 0
                                                  ? Number(
                                                      parseInt(
                                                        row?.rent_amount * 0.18
                                                      ).toFixed(2)
                                                    )
                                                  : 0,
                                              totalAmount:
                                                Number(row?.rent_amount) +
                                                Number(
                                                  parseInt(
                                                    row?.rent_amount * 0.18
                                                  ).toFixed(2)
                                                ),
                                              user_id: auth.id,
                                              agreement_id:
                                                agreement?.[ids[0]]
                                                  ?.agreement_id[0],
                                              code: agreement?.[ids[0]]?.code,
                                            });
                                            setMonthlyId(row.id);
                                          }}
                                        >
                                          <Typography sx={{ fontSize: "14px" }}>
                                            {"View"}
                                          </Typography>
                                        </Button>
                                      )}
                                    </Grid>
                                  </>
                                ) : (
                                  <Grid item>
                                    <Button
                                      style={{
                                        display: "block",
                                        minHeight: "37px",
                                        margin: "10px",
                                        minWidth: "100px",
                                        background: "transparent",
                                      }}
                                    ></Button>
                                  </Grid>
                                )}
                              </Grid>
                            </>
                          ))}
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      container
                      sx={{ mt: "25px", mb: "25px" }}
                      spacing={3}
                      item
                      xs={10}
                    >
                      <Grid item xs={10} container>
                        <TextFieldWrapper
                          label="Deposit Amount (Paid)"
                          placeHolder="Deposit Amount"
                          name="depositedAmount"
                          disabled={true}
                          value={recovery.depositedAmount}
                        />
                      </Grid>
                      <Grid item xs={10} container spacing={3}>
                        <Grid item xs={12}>
                          <Typography
                            color={"var( --main-color)"}
                            fontWeight="600"
                            marginTop="15px"
                          >
                            {"Adjustment Amount"}
                          </Typography>
                        </Grid>
                        <TextFieldWrapper
                          label="Number of Months"
                          placeHolder="Number of Months"
                          name="remainingMonth"
                          disabled={true}
                          value={recovery.remainingMonth}
                        />

                        <TextFieldWrapper
                          label="Adjustment Amount"
                          placeHolder="Adjustment Amount"
                          name="adjustmentAmount"
                          disabled={true}
                          value={recovery.adjustmentAmount}
                        />
                        <TextFieldWrapper
                          label="Remark"
                          placeHolder="Remark"
                          disabled={true}
                          name="adjustmentAmountRemark"
                          value={
                            recovery.adjustmentAmountRemark === "'\"\"'"
                              ? ""
                              : recovery.adjustmentAmountRemark
                          }
                        />
                      </Grid>
                      <UploadInvoice
                        open={open}
                        view={true}
                        handleClose={() => setOpen(false)}
                        value={invoiceDetails}
                        setValue={setInvoiceDetails}
                        code={code}
                        monthlyId={monthlyId}
                      />
                      <Grid item xs={10} container spacing={2}>
                        <TextFieldWrapper
                          label="Monthly adjusted rental amount"
                          placeHolder="Monthly adjusted rental amount"
                          name="monthlyAdjustedRentalAmount"
                          value={recovery.monthlyAdjustedRentalAmount}
                          disabled={true}
                        />
                      </Grid>

                      <Grid item xs={10} container spacing={2}>
                        <TextFieldWrapper
                          label="Adjust Towards Expenses"
                          placeHolder="Adjustment Amount"
                          disabled={true}
                          name="expenses"
                          value={recovery.expenses}
                        />
                        <TextFieldWrapper
                          label="Remark"
                          placeHolder="Remark"
                          disabled={true}
                          name="expansesRemark"
                          value={
                            recovery.expansesRemark === "'\"\"'"
                              ? ""
                              : recovery.expansesRemark
                          }
                        />
                      </Grid>

                      <Grid item xs={10} container spacing={2}>
                        <TextFieldWrapper
                          label="Other Adjustments"
                          placeHolder="Other Adjustments"
                          disabled={true}
                          name="otherAdjustments"
                          value={recovery.otherAdjustments}
                        />
                        <TextFieldWrapper
                          label="Remark"
                          placeHolder="Remark"
                          disabled={true}
                          name="otherRemark"
                          value={
                            recovery.otherRemark === "'\"\"'"
                              ? ""
                              : recovery.otherRemark
                          }
                        />
                      </Grid>
                      <Grid item xs={10} container spacing={2}>
                        <TextFieldWrapper
                          label="Total Adjustment Amount"
                          placeHolder="Adjustment Amount"
                          disabled={true}
                          name="totalAdjustmentAmount"
                          value={recovery.totalAdjustmentAmount}
                        />
                        <TextFieldWrapper
                          label={"Balance Deposit Receivable"}
                          placeHolder="Balance Deposit"
                          name="balanceDeposit"
                          disabled={true}
                          value={recovery.balanceDeposit}
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={10}>
                      <Grid container>
                        <DocumentView
                          title={"Adjustment Document"}
                          img={agreement[ids[0]].file}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {/* // Landlord assets */}
                <Grid item container xs={10} sx={{ mt: 3 }}>
                  <DataFieldStyle
                    field={"Landlord Assets"}
                    value={agreement[ids[0]].assets}
                    partLabel={
                      agreement[ids[0]].type === "Renewed" && (
                        <div
                          style={{
                            color: "rgb(198, 89, 17)",
                            fontSize: "12px",
                          }}
                        >
                          {"Old Value: " + partLabel[oldIds[0]].assets}
                        </div>
                      )
                    }
                  />
                </Grid>
                {/* document section ends here */}

                {/* // termination  points */}
                {/* // Landlord assets */}
                {allRemarks?.length > 0 && (
                  <>
                    <Grid
                      item
                      container
                      sx={{ alignItems: "baseline", mt: 5 }}
                      xs={10}
                    >
                      <DataFieldStyle field={"Remarks"} />
                      {allRemarks.map((remark, index) => (
                        <div
                          style={{
                            width: "100%",
                            padding: "10px 10px 10px 0",
                          }}
                          key={index}
                        >
                          {remark}
                        </div>
                      ))}
                    </Grid>
                  </>
                )}
                {agreement[ids[0]].status === "Sent To Sr Manager" && (
                  <>
                    <Grid
                      item
                      xs={10}
                      sx={{ mt: 5, gap: "2rem" }}
                      className={"textFieldWrapper"}
                    >
                      <Grid item xs={8}>
                        <TextField
                          type="text"
                          multiline
                          rows={3}
                          fullWidth
                          variant="outlined"
                          label="Remark *"
                          placeholder="Remark *"
                          value={remark}
                          onChange={(e) => setRemark(e.target.value)}
                        />
                      </Grid>
                    </Grid>

                    <Grid item md={8} sx={{ mt: 4, mb: 2 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ justifyContent: "center" }}
                      >
                        <Grid item md={6} xs={11}>
                          <Button
                            variant="contained"
                            sx={{
                              height: "55px",
                              borderRadius: "12px",
                              backgroundColor: "primary",
                              width: "100%",
                              color: "#FFFFFF",
                              textTransform: "capitalize",
                              fontSize: "18px",
                              lineHeight: "20px",
                            }}
                            onClick={handleSubmit}
                          >
                            {agreement[ids[0]].op_id === 0 ||
                            agreement[ids[0]].op_id === null
                              ? //  &&
                                //   agreement[ids[0]].deposit - deposit !== 0
                                " Send to BUH"
                              : " Send To Operations"}
                          </Button>
                        </Grid>
                        <Grid item md={6} xs={11}>
                          <Button
                            variant="outlined"
                            sx={{
                              height: "55px",
                              borderRadius: "12px",
                              width: "100%",
                              textTransform: "capitalize",
                              fontSize: "18px",
                            }}
                            onClick={handleSendBack}
                          >
                            Send Back To Manager
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                {/* termonation */}
                {agreement[ids[0]].status === "Terminated By Manager" && (
                  <>
                    <Grid
                      item
                      xs={10}
                      sx={{ mt: 5 }}
                      className={"textFieldWrapper"}
                    >
                      <Grid item xs={8}>
                        <TextField
                          type="text"
                          multiline
                          rows={3}
                          fullWidth
                          variant="outlined"
                          label="Remark *"
                          placeholder="Remark *"
                          value={remark}
                          onChange={(e) => setRemark(e.target.value)}
                        />
                      </Grid>
                    </Grid>

                    <Grid item md={8} sx={{ mt: 4, mb: 2 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ justifyContent: "center" }}
                      >
                        <Grid item md={6} xs={11}>
                          <Button
                            variant="contained"
                            sx={{
                              height: "55px",
                              borderRadius: "12px",
                              backgroundColor: "primary",
                              width: "100%",
                              color: "#FFFFFF",
                              textTransform: "capitalize",
                              fontSize: "18px",
                              lineHeight: "20px",
                            }}
                            onClick={handleTerminate}
                          >
                            Send To Operations
                          </Button>
                        </Grid>
                        <Grid item md={6} xs={11}>
                          <Button
                            variant="outlined"
                            sx={{
                              height: "55px",
                              borderRadius: "12px",
                              width: "100%",
                              textTransform: "capitalize",
                              fontSize: "18px",
                            }}
                            onClick={handleSendBack}
                          >
                            Send Back To Manager
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                {/* buttons end here */}
              </Grid>
            </Box>
          </Stack>
        )}
    </>
  );
}

export default SrManagerApprovalForRenewal;
