/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  FormControl,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Divider,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import "react-datepicker/dist/react-datepicker.css";
import { TextFieldWrapper, MyHeader } from "../StyledComponent";
import { setAlert } from "../../store/action/action";
import { useDispatch, useSelector } from "react-redux";
import {
  insertRecoveryLog,
  getRecoveryLog,
  send_to_bhu,
  notification_handler,
} from "../../Services/Services";
import FinanceHamburger from "./FinanceHamburger";
import moment from "moment";

const labelStyle = {
  fontSize: "20px",
  lineHeight: "30px",
  color: "var(--main-color)",
  fontWeight: "600",
  "@media(max-width:900px)": { fontSize: "10px" },
};

const fieldStyle = {
  border: "1px solid var(--main-color)",
  borderRadius: "20px",
  //   height: "50px",
  p: 1,
  px: 2,
  // width: "450px",

  color: "rgba(16, 99, 173, 0.47)",
  "@media(max-width:900px)": { height: "46px", p: 1 },
};

function UploadInvoice({ open, handleClose, handleConfirm, value, setValue }) {
  const [formError, setFormError] = useState({});
  const [history, setHistory] = useState([]);
  const [agreementCode, setAgreementCode] = useState([]);
  const [agreementStatus, setAgreementStatus] = useState([]);
  const [balance, setBalance] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const theme = useTheme();

  const { auth } = useSelector((s) => s);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchData(id);
  }, [id]);

  async function fetchData(id) {
    let res = await getRecoveryLog(id);
    console.log(res.data.agreement_code, "res.data");
    if (res.status === 200) {
      setHistory(res.data.history);
      setAgreementCode(res.data.agreement_code);
      setAgreementStatus(res.data.agreement_status);
      setBalance({
        balance: res.data.balance.balanceDeposit,
        total: res.data.history.reduce(
          (sum, row) => (sum += row.receivedAmount),
          0
        ),
      });
    } else {
      setHistory([]);
      setAgreementCode("");
    }
  }

  function onChange(e) {
    if (e.target.name === "invoiceDate" || e.target.name === "invoiceNo") {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
      setFormError({ ...formError, [e.target.name]: "" });
    }

    if (e.target.value.match(/^[0-9]*$/)) {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
    }
  }
  const maxDate = moment().format("YYYY-MM-DD");
  

  const [error, setError] = useState(true);
  const [data, setData] = useState({
    agreement_id: id,
    receivedDate: "",
    receivedAmount: "",
    paymentDetails: "",
    remark: "",
    agreement_code: "",
  });

  async function handleSubmit(e) {
    e.preventDefault();
    let res = await insertRecoveryLog(data);

    if (res.status === 200) {
      if (balance.balance - balance.total === parseInt(data.receivedAmount)) {
        //checking the agreement status to Terminated
        // let res2 = await send_to_bhu(
        //   {
        //     status: "Terminated",
        //     additionalRemark:  null
        //   },
        //   id);
        // if (res2.status === 200) {
        //   dispatch(
        //     setAlert({
        //       open: true,
        //       variant: "success",
        //       message: "Log inserted successfully.",
        //     })
        //   );
        //   window.location.reload();
        // }
        window.location.reload();
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message: "Log inserted successfully.",
          })
        );
        window.location.reload();
      }
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong !!!",
        })
      );
    }
  }

  async function handleTerminate(e) {
    e.preventDefault();
    if (balance.balance === balance.total) {
      //checking the agreement status to Terminated
      let res2 = await send_to_bhu(
        {
          status: "Terminated",
          additionalRemark: null,
        },
        id
      );
      if (res2.status === 200) {
        await notification_handler("TR25", id, agreementCode, auth.id);
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message: "Terminated successfully.",
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: "Terminated successfully.",
        })
      );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  const dispatch = useDispatch();

  // use on Change for uncommon fields
  function handleChange(e) {
    let error = { state: false, message: null };
    switch (e.target.name) {
      case "receivedAmount":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        else if (e.target.value > balance.balance - balance.total)
          error = { ...error, state: true };
        break;
      default:
        break;
    }

    if (!error.state) {
      setData((old) => ({
        ...old,
        [e.target.name]: e.target.value,
      }));
    }
  }

  return (
    <>
      <Stack sx={{ flexWrap: "warap", flexDirection: "row" }}>
        <FinanceHamburger />
        <Box sx={{ flexGrow: 1 }}>
          {/* <MyHeader>Recovery Balance</MyHeader> */}
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "space-between", display: "flex" }}
          >
            <MyHeader>RMS <span className="small-heading">(Rental Management System)</span></MyHeader>
            <Typography mt="15px" mr="15px" fontWeight="600">
              Welcome {auth.name}
            </Typography>
          </Grid>
          <Divider />
          <Grid
            container
            sx={{
              px: 1,
              justifyContent: "space-between",
              my: 1,
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <Typography
                variant="body1"
                color="black"
                fontSize="20px"
                fontWeight="600"
                alignSelf="center"
                lineHeight="30px"
                sx={{
                  "@media(max-width:600px)": {
                    fontSize: "17px",
                    lineHeight: "25px",
                  },
                }}
              >
                Deposit Recovery Form
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Box className="backButton">
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              size={"large"}
            >
              <ArrowCircleLeftIcon
                sx={{ fontSize: "3rem" }}
                color="#FFFFF !important"
              />
            </IconButton>
          </Box>
          {/* <Grid
            container
            pt={3}
            pr={3}
            pl={3}
            spacing={isSmall ? 2 : 4}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid item xs={4}>
              <Typography
                sx={{ fontSize: "1.3rem", fontWeight: 700 }}
                color="primary"
                variant="h6"
              >
                Agreement Code: {agreementCode}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{ fontSize: "1.3rem", fontWeight: 700 }}
                color="primary"
                variant="h6"
              >
                Balance Deposit: {balance.balance}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{ fontSize: "1.3rem", fontWeight: 700 }}
                color="primary"
                variant="h6"
              >
                Receivable Amount: {balance.balance - balance.total}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{ fontSize: "1.3rem", fontWeight: 700 }}
                color="primary"
                variant="h6"
              >
                Deposit Recovered: {balance.total}
              </Typography>
            </Grid>
          </Grid> */}
          <Grid
            container
            pt={3}
            pr={3}
            pl={3}
            spacing={isSmall ? 2 : 4}
            sx={{
              paddingTop: "0px",
              marginTop: "1rem",
              marginBottom: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: "18px", fontWeight: 700 }}
                color="primary"
                variant="h6"
              >
                Agreement Code: {agreementCode}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: "18px", fontWeight: 700 }}
                color="primary"
                variant="h6"
              >
                Balance Deposit: {balance.balance}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: "18px", fontWeight: 700 }}
                color="primary"
                variant="h6"
              >
                Receivable Amount: {balance.balance - balance.total}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{ fontSize: "18px", fontWeight: 700 }}
                color="primary"
                variant="h6"
              >
                Deposit Recovered: {balance.total}
              </Typography>
            </Grid>
          </Grid>

          <BasicTable row={history} />
          {balance.balance === balance.total &&
            agreementStatus !== "Terminated" && (
              <Grid
                item
                xs={12}
                mb={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  size="larger"
                  style={{
                    backgroundColor: "#b53f3f",
                    color: "white",
                    textTransform: "capitalize",
                    width: "200px",
                    height: "50px",
                    fontSize: "15px",
                    padding: "5px",
                  }}
                  onClick={(e) => {
                    handleTerminate(e);
                  }}
                >
                  Terminate Agreement
                </Button>
              </Grid>
            )}
          {agreementStatus === "Terminated" && (
            <Grid
              item
              xs={12}
              mb={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {/* <Button
              variant="contained"
              size="larger"
              style={{
                backgroundColor: "white",
                color: "#b53f3f",
                fontSize: "12px",
                textTransform: "capitalize",
                width: "200px",
                height: "50px",
                fontSize: "15px",
                padding:'5px'
              }}
              // onClick={(e) => {
              //   handleTerminate(e)
              // }}
            >
              Agreement Terminated
            </Button> */}
              <marquee
                style={{
                  backgroundColor: "white",
                  color: "#b53f3f",
                  textTransform: "capitalize",
                  width: "600px",
                  height: "50px",
                  fontSize: "15px",
                  padding: "5px",
                }}
                behavior="scroll"
                direction="right"
              >
                This Agreement is Terminated!
              </marquee>
            </Grid>
          )}

          {balance.balance !== balance.total && (
            <>
              <Grid
                p={3}
                container
                sx={{
                  mt: "25px",
                  mb: "25px",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                component={"form"}
                onSubmit={handleSubmit}
                method="post"
              >
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="body1"
                    color="var(--main-color)"
                    fontSize="1.5rem"
                    lineHeight="28px"
                    fontWeight="600"
                    // my="20px"
                  >
                    Recovery Form
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <input
                      type="date"
                      name="receivedDate"
                      value={data.receivedDate}
                      onKeyDown={(e) => e.preventDefault()} // Prevent manual input by disabling keydown events
                      max={maxDate} // Set maximum date as the current date
                      className="DatePicker"
                      onChange={(e) => handleChange(e)}
                      // error={formError.date && true}
                    />
                    <Typography variant="caption" sx={{ color: "red" }}>
                      {formError.agreement_date}
                    </Typography>
                  </FormControl>
                </Grid>

                <TextFieldWrapper
                  label="Received Amount"
                  placeHolder="Received Amount"
                  // onBlur={(e) => handleOnBlur(e, i)}
                  // error = {errorObj.leeseName}
                  name="receivedAmount"
                  // disabled = {true}
                  value={data.receivedAmount}
                  onChange={(e) => handleChange(e)}
                />
                <TextFieldWrapper
                  label="Payment Details"
                  placeHolder="paymentDetails"
                  // onBlur={(e) => handleOnBlur(e, i)}
                  // error = {errorObj.leeseName}
                  name="paymentDetails"
                  // disabled = {true}
                  value={data.paymentDetails}
                  onChange={(e) => handleChange(e)}
                />
                <TextFieldWrapper
                  label="Remark"
                  placeHolder="Remark"
                  // onBlur={(e) => handleOnBlur(e, i)}
                  // error = {errorObj.leeseName}
                  name="remark"
                  value={data.remark}
                  onChange={(e) => handleChange(e)}
                />

                <Grid
                  item
                  xs={12}
                  mt={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    type="submit"
                    size="larger"
                    sx={{ borderRadius: "50px", padding: "12px" }}
                    variant="contained"
                  >
                    Save Payment{" "}
                  </Button>
                </Grid>
              </Grid>{" "}
            </>
          )}
        </Box>
      </Stack>
    </>
  );
}

export default UploadInvoice;

function BasicTable({ row }) {
  function createData(receivedDate, receivedAmount, paymentDetails, remark) {
    return {
      receivedDate,
      receivedAmount,
      paymentDetails,
      remark,
    };
  }

  const rows = row.map((data) => {
    const { receivedDate, receivedAmount, paymentDetails, remark } = data;
    return createData(
      new Date(receivedDate).toDateString(),
      receivedAmount,
      paymentDetails,
      remark
    );
  });

  return (
    <Box p={3}>
      <Box mb={2}>
        <Typography
          variant="body1"
          color="var(--main-color)"
          fontSize="18px"
          lineHeight="28px"
          fontWeight="600"
          // my="20px"
        >
          Payment History
        </Typography>
      </Box>
      <TableContainer component={Paper} sx={{ borderRadius: "10px" }}>
        <Table
          sx={{ minWidth: 400, borderRadius: "50px" }}
          dense
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontSize: "1rem", fontWeight: 700 }}
                align="center"
              >
                Received Date
              </TableCell>
              <TableCell
                sx={{ fontSize: "1rem", fontWeight: 700 }}
                align="center"
              >
                Received Amount
              </TableCell>
              <TableCell
                sx={{ fontSize: "1rem", fontWeight: 700 }}
                align="center"
              >
                Payment Details
              </TableCell>
              <TableCell
                sx={{ fontSize: "1rem", fontWeight: 700 }}
                align="center"
              >
                Remark
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow>
                <TableCell align="center">{row.receivedDate}</TableCell>
                <TableCell align="center">{row.receivedAmount}</TableCell>
                <TableCell align="center">{row.paymentDetails}</TableCell>
                <TableCell align="center">{row.remark}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
