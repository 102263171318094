/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Typography, Button, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import FinanceHam from "../FinancePanel/FinanceHamburger";
import ManagerHam from "../Manager/HamburgerManager";
import SrMHam from "../SrManager/SRMHAmburger";
import OPHam from "../Operations/OperationsHamburger";
import { TextFieldWrapper, DocumentUpload, MyHeader } from "../StyledComponent";

import {
  EditLandlordInfo,
  getBankName,
  updateLandlord,
  uploadDoc,
} from "../../Services/Services";

import { useNavigate, useParams } from "react-router-dom";
import { setAlert, setForm } from "../../store/action/action";
import { useDispatch } from "react-redux";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

const Edit = (props) => {
  const {
    auth: { role },
    form,
  } = useSelector((state) => state);
  let { id } = useParams();
  const { auth } = useSelector((s) => s);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [formError, setFormError] = useState({});
  const [old, setOldData] = useState({});
  const [fieldsError, setFieldsError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setPreData();
  }, []);

  function setPreData() {
    if (form) {
      setOldData(form);
      setData(form);
    }
  }

  console.log({ old });
  function checksum(g) {
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
      g
    );

    if (regTest) {
    }

    return regTest;
  }
  const handleValue = (e) => {
    let error = { state: false, message: null };
    const capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = capitalizedValue;
    switch (e.target.name) {
      case "mobileNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digits." };
        if (e.target.value.match(/^[0-5]/)) {
          error = { ...error, message: "Incorrect Phone Number" };
          setFieldsError(true);
        } else {
          error = { ...error, message: null };
          setFieldsError(false);
        }

        break;
      case "aadharNo":
        if (!/^[0-9]*$/.test(e.target.value) || e.target.value.length > 12) {
          error = {
            state: true,
            message:
              "Aadhaar number must be numeric and should not exceed 12 digits.",
          };
        } else {
          error = { state: false, message: null };
        }
        break;

      case "alternateMobile":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };

        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digits." };

        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };

        break;

      case "benificiaryName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };

        break;

      // case "accountNo":
      //   if (!/^[0-9]{1,17}$/.test(e.target.value)) {
      //     error = { ...error, state: true };
      //   }

      //   if (e.target.value.length !== 17 && e.target.value.length > 0) {
      //     error = {
      //       ...error,
      //       message: "Account number should be exactly 17 digits.",
      //     };

      //     setFieldsError(true);
      //   } else {
      //     error = { ...error, message: null };

      //     setFieldsError(false);
      //   }

      //   break;
      case "accountNo":
        if (!/^[0-9]{0,17}$/.test(e.target.value)) {
          error = { ...error, state: true };
          error = {
            ...error,
            message:
              "Account number should contain only digits (0-9) and should not exceed 17 digits.",
          };
          setFieldsError(true);
        } else {
          error = { ...error, message: null };
          setFieldsError(false);
        }
        break;

      case "email":
        if (
          !e.target.value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zAZ]{2,}))$/
          ) &&
          e.target.value.length > 0
        )
          error = {
            ...error,

            message: "Email should be like example@gmail.com.",
          };
        else error = { ...error, message: null };

        break;

      case "panNo":
        e.target.value = e.target.value.toUpperCase();
        if (!capitalizedValue.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };
        else if (
          !e.target.value.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "PAN number should be like NOOPS8327K",
          };
        else error = { ...error, message: null };
        break;

      case "gstNo":
        e.target.value = e.target.value.toUpperCase();
        if (!/^[A-Za-z0-9]{0,15}$/.test(e.target.value)) {
          error = {
            state: true,
            message: "GST number should not contain special characters.",
          };
        }
        if (!checksum(e.target.value) && e.target.value.length > 0) {
          error = { ...error, message: "GST should be like 18AABCU9603R1ZM." };
          setFieldsError(true);
        } else {
          error = { ...error, message: null };
          setFieldsError(false);
        }
        break;

      case "ifscCode":
        e.target.value = e.target.value.toUpperCase();

        if (!e.target.value.match(/^[^@#$%^&*<>'\"/;`%]*$/))
          error = { state: true, message: null };

        break;
      default:
        break;
    }
    if (e.target.name === "ifscCode" && e.target.value.length === 11) {
      getBankDetails(e.target.value);
    }
    if (!error.state) {
      setData((old) => ({
        ...old,
        [e.target.name]: e.target.value,
      }));
    }
  };

  function handleOnBlur(e) {
    let error = { state: false, message: null };
    const capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = capitalizedValue;
    switch (e.target.name) {
      case "leeseName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };

        break;

      case "pincode":
        if (!e.target.value.match(/^.{0,6}$/))
          error = { state: true, message: "Value must be Correct" };

        if (e.target.value.length < 6 && e.target.value.length > 0)
          error = { ...error, message: "Pincode number must be of 6 digit." };
        else error = { ...error, message: null };

        break;

      case "aadharNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };

        if (e.target.value.length < 12 && e.target.value.length > 0)
          error = { ...error, message: "Aadhaar number must be of 12 digit." };
        else error = { ...error, message: null };

        break;

      case "mobileNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };

        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };

        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };

        break;

      case "alternateMobile":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };

        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };

        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };

        break;

      case "benificiaryName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };

        break;

      case "accountNo":
        if (!/^[0-9]{1,17}$/.test(e.target.value)) {
          error = { ...error, state: true };
        }

        if (e.target.value.length !== 17 && e.target.value.length > 0) {
          error = {
            ...error,
            message: "Account number should be exactly 17 digits.",
          };

          setFieldsError(true);
        } else {
          error = { ...error, message: null };

          setFieldsError(false);
        }

        break;

      case "email":
        if (
          !e.target.value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
          e.target.value.length > 0
        ) {
          error = {
            ...error,
            message: "Email should be like example@gmail.com.",
          };

          setFieldsError(true);
        } else {
          error = { ...error, message: null };

          setFieldsError(false);
        }

        break;
      case "panNo":
        e.target.value = e.target.value.toUpperCase();
        if (
          !e.target.value.match(/^.{0,10}$/) &&
          !e.target.value.match(/^[^@#$%^&*<>'\"/;`%]*$/)
        )
          error = { state: true, message: null };
        if (
          !e.target.value.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "PAN number should be like NOOPS8327K",
          };
        else error = { ...error, message: null };
        break;

      case "gstNo":
        e.target.value = e.target.value.toUpperCase();

        if (!e.target.value.match(/^.{0,15}$/))
          error = { state: true, message: null };

        if (!checksum(e.target.value) && e.target.value.length > 0) {
          error = { ...error, message: "GST should be like 18AABCU9603R1ZM." };

          setFieldsError(true);
        } else {
          error = { ...error, message: null };

          setFieldsError(false);
        }
        setData((prevData) => ({
          ...prevData,
          gstNo: e.target.value,
          gst: "",
        }));
        break;

      case "ifscCode":
        const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        if (
          !e.target.value.match(ifscCodeRegex) &&
          e.target.value.trim() !== ""
        ) {
          error = {
            state: true,
            message: "Incorrect IFSC code. Please enter a valid IFSC code.",
          };
          formError.bankName = "Bank not found";
          formError.ifscCode = "Incorrect IFSC";
        } else {
          error = {
            state: false,
            message: null,
          };
          formError.bankName = null;
          formError.ifscCode = null;
        }
        break;
      default:
        break;
    }

    if (error.message) {
      setFormError((old) => ({
        ...old,
        [e.target.name]: error.message,
      }));
    } else {
      setFormError((old) => ({
        ...old,
        [e.target.name]: "",
      }));
    }
  }

  function validate() {
    const fields = [
      "mobileNo",
      "email",
      "bankName",
      "benificiaryName",
      "accountNo",
      "ifscCode",
      "panNo",
      "aadharNo",
    ];

    let check = fields.map((field) => {
      if (!data[field]) {
        setFormError((old) => ({ ...old, [field]: "Field is required" }));
        return true;
      }
      return false;
    });

    if (data.gstNo !== old.gstNo) {
      if (data.gstNo === "") {
        setFormError((old) => ({ ...old, gst: "" }));
      } else if (!data.gst) {
        check.push(true);
        setFormError((old) => ({ ...old, gst: "Document Required" }));
      }
    }

    if (
      (data.benificiaryName !== old.benificiaryName ||
        data.bankName !== old.bankName ||
        data.ifscCode !== old.ifscCode ||
        data.accountNo !== old.accountNo) &&
      !data.cheque
    ) {
      check.push(true);
      setFormError((old) => ({ ...old, cheque: "Document Required" }));
    } else {
      setFormError((old) => ({ ...old, cheque: "" }));
    }

    if (check.includes(true)) {
      return false;
    } else {
      return true;
    }
  }

  async function handleChangeFile(e) {
    const FD = new FormData();
    FD.append("photo", e.target.files[0]);
    FD.append("folderName", "Sapan");
    setFormError((old) => ({
      ...old,
      gst: null,
    }));
    let response = await uploadDoc(FD);

    if (response.status === 200) {
      setData((old) => ({
        ...old,
        [e.target.name]: response.data.link,
        [e.target.name + "_name"]: e.target.files[0].name,
      }));
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: response.data.message,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message || "Something went wrong !!!",
        })
      );
    }
  }

  async function handleSubmit(e) {
    if (fieldsError) {
    } else {
      try {
        e.preventDefault();

        const {
          id,
          gstNo,
          mobileNo,
          alternateMobile,
          email,
          bankName,
          benificiaryName,
          accountNo,
          ifscCode,
          cheque,
          gst,
          panNo,
          aadharNo,
        } = data;

        let formData = {
          id,
          gstNo,
          mobileNo,
          alternateMobile,
          email,
          bankName,
          benificiaryName,
          accountNo,
          ifscCode,
          panNo,
          aadharNo,
        };

        if (data.gst) {
          formData = { ...formData, gst: data.gst };
        }

        if (data.cheque) {
          formData = { ...formData, cheque: data.cheque };
        }

        if (!validate() && !fieldsError) {
          return false;
        }

        let res = await updateLandlord(formData);

        if (res.status === 200) {
          dispatch(
            setAlert({
              open: true,
              variant: "success",
              message: "Landlord Updated Successfully.",
            })
          );

          props.history(-1);
        } else {
          dispatch(
            setAlert({
              open: true,
              variant: "error",
              message: "Erro Occured !!!",
            })
          );
        }
      } catch (error) {}
    }

    e.preventDefault();
  }

  const [error, setError] = useState({
    state: false,
    message: null,
  });

  const [showIfscError, setShowIfscError] = useState(false);

  async function getLandloardDetails() {
    try {
      let res = await EditLandlordInfo(id);
      setData(res.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getLandloardDetails();
  }, [id]);

  async function getBankDetails(data) {
    try {
      let res = await getBankName(data);

      if (res.status === 200) {
        setData((old) => ({
          ...old,

          bankName: res.data.BANK,

          branchName: res.data.BRANCH,
        }));

        setShowIfscError(false);
      }
    } catch (err) {
      setData((old) => ({
        ...old,

        bankName: "Not Found",

        branchName: "",
      }));

      setShowIfscError(true);
    }
  }

  return (
    <Box sx={style.container}>
      <Box>
        {/* Hamburger */}

        {role.includes("Finance") && <FinanceHam />}

        {role.includes("Manager") && <ManagerHam />}

        {role.includes("Senior_Manager") && <SrMHam />}

        {role.includes("Operations") && <OPHam />}

        {/* Hamburger ends */}
      </Box>

      <Box sx={style.contentContainer}>
        {/* Header  */}

        <Box sx={style.header}>
          <MyHeader>
            RMS{" "}
            <span className="small-heading">(Rental Management System)</span>
          </MyHeader>

          <Typography sx={{ fontWeight: 700 }} variant="body1">
            Welcome {auth.name}
          </Typography>
        </Box>

        {/* Header ends */}

        {/* module Name */}

        <Box>
          <Typography sx={{ fontWeight: 700 }} variant="h6">
            Edit Landlord
          </Typography>
        </Box>

        {/* module Name ends */}

        {/* Edit Starts from here */}

        <Box
          sx={style.fromContainer}
          component={"form"}
          method="post"
          onSubmit={handleSubmit}
        >
          <Box sx={style.item}>
            <TextFieldWrapper
              label="Name Of Lessor"
              value={data.name}
              required={true}
              disabled={true}
              fullWidth
            />
          </Box>

          <Box sx={style.item}>
            {/* <TextFieldWrapper
              label="Aadhaar Number"
              required={true}
              fullWidth
              value={data.aadharNo || ""}
              error={formError.aadharNo}
              onChange={handleValue}
            /> */}
            <TextFieldWrapper
              label="Aadhaar Number"
              required={true}
              fullWidth
              value={data.aadharNo || ""}
              error={formError.aadharNo}
              onChange={handleValue}
              name="aadharNo"
              onBlur={handleOnBlur}
            />
          </Box>

          <Box sx={style.item}>
            {/* <TextFieldWrapper
              label="PAN Number"
              required={true}
              fullWidth
              name="panNo"
              value={data.panNo || ""}
              error={formError.panNo}
              onChange={handleValue}
            /> */}
            <TextFieldWrapper
              label="PAN Number"
              required={true}
              maxLength={10}
              fullWidth
              name="panNo"
              value={data.panNo || ""}
              error={formError.panNo}
              onChange={handleValue}
              onBlur={handleOnBlur} // Add onBlur event handler for validation
            />
          </Box>

          <Box sx={style.item}>
            <TextFieldWrapper
              label="GST Number"
              fullWidth
              name="gstNo"
              error={formError.gstNo}
              value={data.gstNo || ""}
              onChange={handleValue}
              onBlur={(e) => handleOnBlur(e)}
            />
          </Box>

          <Box sx={style.item}>
            <TextFieldWrapper
              label="Mobile Number"
              required={true}
              fullWidth
              error={formError.mobileNo}
              name="mobileNo"
              maxLength={10}
              value={data.mobileNo || ""}
              onBlur={(e) => handleOnBlur(e)}
              // error={formError.city}

              onChange={handleValue}
            />
          </Box>

          <Box sx={style.item}>
            <TextFieldWrapper
              label="Alternate Number"
              fullWidth
              error={formError.alternateMobile}
              name="alternateMobile"
              onBlur={(e) => handleOnBlur(e)}
              value={data.alternateMobile || ""}
              maxLength={10}
              // error={formError.city}

              onChange={handleValue}
            />
          </Box>

          <Box sx={style.item}>
            <TextFieldWrapper
              label="Email"
              fullWidth
              error={formError.email}
              name="email"
              onBlur={(e) => handleOnBlur(e)}
              value={data.email || ""}
              onChange={handleValue}
            />
          </Box>
          <Box sx={style.item}>
            <TextFieldWrapper
              label="Beneficiary Name"
              required={true}
              onBlur={(e) => handleOnBlur(e)}
              fullWidth
              name="benificiaryName"
              error={formError.benificiaryName}
              value={data.benificiaryName || ""}
              maxLength={30}
              onChange={handleValue}
            />
          </Box>

          <Box sx={style.item}>
            <TextFieldWrapper
              label="Bank A/C Number"
              required={true}
              onBlur={(e) => handleOnBlur(e)}
              fullWidth
              name="accountNo"
              error={formError.accountNo}
              value={data.accountNo || ""}
              maxLength={17}
              onChange={handleValue}
            />
          </Box>

          <Box sx={style.item}>
            <TextFieldWrapper
              label="Bank IFSC"
              required={true}
              onBlur={(e) => handleOnBlur(e)}
              name="ifscCode"
              error={
                showIfscError
                  ? "Incorrect IFSC code. Please enter a valid IFSC code."
                  : null || formError.ifscCode
              }
              value={data.ifscCode || ""}
              onChange={handleValue}
              maxLength={11}
            />
          </Box>

          <Box sx={style.item}>
            {
              <TextFieldWrapper
                label="Bank Name"
                required={true}
                disabled={true}
                onBlur={(e) => handleOnBlur(e)}
                fullWidth
                error={formError.bankName}
                name="bankName"
                value={data.bankName || ""}
              />
            }
          </Box>

          <Box sx={style.docConatiner}>
            {data.gstNo !== old.gstNo && data.gstNo !== "" && (
              <Box sx={style.item}>
                <DocumentUpload
                  uploaded={data.gst !== old.gst ? true : false}
                  label="Upload GST Certificate"
                  placeHolder="Upload GST Certificate"
                  handleChange={handleChangeFile}
                  name={"gst"}
                  error={formError.gst}
                  fileName={data[`gst`]?.split("/").pop()}
                  href={data.gst}
                />
              </Box>
            )}

            {(data.benificiaryName !== old.benificiaryName ||
              data.bankName !== old.bankName ||
              data.ifscCode !== old.ifscCode ||
              data.accountNo !== old.accountNo) && (
              <Box sx={style.item}>
                <DocumentUpload
                  placeHolder="Upload Cancel Cheque"
                  uploaded={data.cheque !== old.cheque ? true : false}
                  label="Upload Cancel Cheque"
                  handleChange={handleChangeFile}
                  name={"cheque"}
                  error={formError.cheque}
                  fileName={data[`cheque`]?.split("/").pop()}
                  href={data.cheque}
                />
              </Box>
            )}
          </Box>

          <Box sx={style.button} mt={5}>
            <Button
              onClick={handleSubmit}
              type="submit"
              sx={style.btn}
              variant="contained"
              disabled={error.state}
            >
              Submit
            </Button>

            <Button
              onClick={() => {
                props.history(-1);
              }}
              sx={style.btn}
              variant="outlined"
            >
              Cancel
            </Button>
          </Box>
        </Box>

        {/* Edit Ends from here */}

        <Box className="backButton">
          <IconButton
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
            size={"large"}
          >
            <ArrowCircleLeftIcon
              sx={{ fontSize: "3rem" }}
              color="#FFFFF !important"
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

// style Css (Inline CSS part here)

const style = {
  container: {
    display: "flex",

    width: "100%",

    gap: "1rem",

    padding: "0.3%",
  },

  contentContainer: {
    flex: 1,

    justifyContent: "space-between",

    padding: "1rem",

    gap: "2rem",

    display: "flex",

    flexDirection: "column",
  },

  header: {
    display: "flex",

    justifyContent: "space-between",
  },

  fromContainer: {
    display: "flex",

    width: "100%",

    gap: "2rem",

    flexWrap: "wrap",

    // justifyContent: "space-evenly",
  },

  item: {
    minWidth: "300px",
  },

  button: {
    display: "flex",

    gap: "2rem",

    width: "100%",

    justifyContent: "center",
  },

  btn: {
    padding: "1% 2%",

    borderRadius: "20px",
  },

  docConatiner: {
    display: "flex",

    width: "100%",

    gap: "2rem",
  },
};

export default Edit;
