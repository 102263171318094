import React from "react";
import HamburgerMenu from "../HamburgerMenu";

function FinanceHamburger() {
  return (
    <HamburgerMenu
      misReports={[
        { text: "Rental MIS Reports", navigateTo: "/rental-mis-reports" },
        { text: "Graph Reports", navigateTo: "/graph-reports" },
        { text: "Rental Due Schedule", navigateTo: "/rent-paid-schedule" },
      ]}
      navigateHome={"finance-dashboard"}
      monthlyBtn="true"
      agreements={[
        {
          text: "In-Process",
          navigateTo: "/finance-listing/in-procces-ag",
        },
        {
          text: "Active / In Active Agreements",
          navigateTo: "/finance-listing/approved-ag",
        },
        {
          text: "Migrated Agreements ",
          navigateTo: "/finance-listing/mg-ag",
        },
      ]}
      monthly={[
        { text: "In-Process", navigateTo: "/finance-monthly-rent/in-process" },
        { text: "Paid", navigateTo: "/finance-monthly-rent/paid" },
      ]}
    />
  );
}

export default FinanceHamburger;
