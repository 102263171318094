/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ListingComponent from "../StyleComponents/ListingComponent";
import { get_All_agreements, get_search } from "../../Services/Services";
import SuperAdminTable from "./DataTable";
import { useSelector } from "react-redux";
import moment from "moment";
import SuperAdminHamburgerMenu from "./SuperAdminHamburgerMenu";

function SuperAdminListing() {
  const navigate = useNavigate();

  const { auth, refresh } = useSelector((s) => s);
  const { type } = useParams();

  const [data, setData] = useState([]);
  const [SelectValue, setSelectValue] = useState("Senior Manager");
  const [rows, setRows] = useState([]);
  const [searchValue, setsearchValue] = useState("");
  const super_id = auth.id;

  async function SearchAPi(searchValue) {
    const search = await get_search(searchValue);
    setData(search.data);
  }

  function handleSerachChange(e) {
    setsearchValue(e.target.value);
  }

  const getData = async (id, type) => {
    const response = await get_All_agreements(id, type);
    if (response.status === 200) {
      setData(response.data.agreement[0]);
    }
  };

  useEffect(() => {
    if (type === "in-procces-ag") {
      getData(super_id, "Agreement");
    } else if (type === "approved-ag") {
      getData(super_id, "Active");
    }
  }, [type, refresh]);

  useEffect(() => {
    data &&
      data.length > 0 &&
      setRows(
        data.map((item) => {
          return {
            checkbox: item.status,
            id: item.id,
            landlord_id: item.landlords,
            i: item.id,
            status: item.status,
            code: item.code,
            name: item.name,
            location: item.location,
            address: item.address,
            rentalAmount: parseFloat(
              (Number(item.monthlyRent) / 100) * Number(item.percentage)
            ).toFixed(0),
            deposit: item.deposit,
            state: item.state,
            manager_name: item.manager_name,
            srm_name: item.srm_name,
            buh: item.buh,
            op_name: item.op_name,
            city: item.city,
            initiateDate: moment(item.time).format("DD-MM-YYYY"),
            type: item.type ? item.type : "New",
            utr_number: item.utr_number,
            modify_date: item.modify_date,
            payable_deposit:
              item.type === "Renewed"
                ? item.deposit_payable
                : parseFloat(
                    (Number(item.deposit) / 100) * Number(item.percentage)
                  ).toFixed(0),
          };
        })
      );
  }, [data]);

  const handleChange = (e) => {
    setSelectValue(e.target.value);
  };

  let filteredRows =
    searchValue.length > 0
      ? rows.filter(
          (object) =>
            JSON.stringify(object)
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > 0
        )
      : rows;

  const handleAddUser = () => {
    navigate("/super-admin-newuser");
  };

  return (
    <>
      <Stack sx={{ flexWrap: "wap", flexDirection: "row" }}>
        <SuperAdminHamburgerMenu />
        <ListingComponent
          title={
            type === "in-procces-ag"
              ? " All Agreements"
              : "Active/ In Active Agreements "
          }
          title1={
            <header>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </header>
          }
          onChange={handleChange}
          value={SelectValue}
          Table={SuperAdminTable}
          rows={filteredRows}
          onButtonClick={handleAddUser}
          dropDown={false}
          searchValue={searchValue}
          handleSerachChange={handleSerachChange}
          setsearchValue={setsearchValue}
        />
      </Stack>
    </>
  );
}

export default SuperAdminListing;
