/* eslint-disable no-unused-vars */
import { Route, Routes, useNavigate } from "react-router-dom";
import React, { useState } from "react";

//components

//manager Section
import Login from "./Components/Login/Login";
import Dashboard from "./Components/Manager/Dashboard";
import Listing from "./Components/Manager/Listing";
import Agreement from "./Components/Manager/Agreement";
import EditAgreement from "./Components/Manager/EditAgreement";
import ManagerApproval from "./Components/Manager/ManagerApproval";
import MonthalyList from "./Components/MonthalyPayment/MonthalyList";
import RenewalList from "./Components/Renewal/RenewalList";
import RenewalEditAgreement from "./Components/Renewal/EditAgreement";
import RenewalDepositeRefund from "./Components/Manager/DepositeRefund";

import RentPaidSchedule from "./Components/SuperAdmin/RentPaidSchedule";
import GraphReports from "./Components/SuperAdmin/GraphReports";
import ExportReports from "./Components/SuperAdmin/ExportReport";
import RentalMisReports from "./Components/SuperAdmin/RentalMisReport";
//sr MAnager section
import SrManagerDashboard from "./Components/SrManager/SrManagerDashboard";
import SrManagerListing from "./Components/SrManager/SrManagerListing";
import SrManagerApproval from "./Components/SrManager/SrManagerApproval";

//opertaions Section
import OperationsListing from "./Components/Operations/OperationsListing";
import OperationsDashboard from "./Components/Operations/OperationsDashboard";
import ApprovalRequest from "./Components/Operations/ApprovalRequest";
// import RejectApproval from './Components/Operations/RejectApproval'

//BHU Section
import BHUListing from "./Components/BHU/BHUListing";
import BHUDashBoard from "./Components/BHU/BHUDashboard";
import BHUapproval from "./Components/BHU/BHUapproval";

//Admin Section
import NewUser from "./Components/AdminPanel/NewUser";
import UserDashboard from "./Components/AdminPanel/UserDashboard";
import UserManagement from "./Components/AdminPanel/UserManagement";

//Reset Password Section
import ForgotPassword from "./Components/ResetPassword/ForgotPassword";
import EmailVerify from "./Components/ResetPassword/EmailVerify";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import EditUser from "./Components/AdminPanel/EditUser";
import { useDispatch, useSelector } from "react-redux";
import SuperAdminDashboard from "./Components/SuperAdmin/Super-admin-dashboard";
import SuperAdminNewUser from "./Components/SuperAdmin/NewUser";
import SuperAdminListing from "./Components/SuperAdmin/SuperUserListing";
import SuperAdminUserEdit from "./Components/SuperAdmin/EditUser";
import FinanceDashboard from "./Components/FinancePanel/FinanceDashboard";
import FinanceListing from "./Components/FinancePanel/FinanceListing";
import FinanceApproval from "./Components/FinancePanel/ApprovalRequest";
import MonthlyPayement from "./Components/SrManager/Montly-rent/MonthlyPayement";
import FinanceMonthlyPayement from "./Components/FinancePanel/Montly-rent/MonthlyPayement";
import OperationsMonthlyPayement from "./Components/Operations/Montly-rent/MonthlyPayement";
import MonthalyRentView from "./Components/Operations/Montly-rent/MonthalyRentView";
import SRMMonthlyRentView from "./Components/SrManager/Montly-rent/SRMMonthlyRentView";
import FinanceMonthlyRentView from "./Components/FinancePanel/Montly-rent/FinanceMonthlyView";
import Recovery from "./Components/FinancePanel/Recovery";
import EditInvoice from "./Components/MonthalyPayment/EditInvoice";
import ViewPage from "./Components/MonthalyPayment/ViewPage";
import SrmRenwalList from "./Components/SrManager/Renewal/RenewalListing";
import RenewalViewPage from "./Components/Renewal/ViewPage";
import RentalPropertyDumpReport from "./Components/SuperAdmin/RentalPropertyDumpReport";
import RentalPaymentMIS from "./Components/SuperAdmin/RentalPaymentMIS";
import RentalOnboardingAllStatus from "./Components/SuperAdmin/RentalOnboardingAllStatus";
import RentalOnboardingDeposited from "./Components/SuperAdmin/RentalOnboardingDeposited";
import EditLandlord from "./Components/landlordOperations/Edit";
import LandlordList from "./Components/landlordOperations/Listing";
import OldAgreements from "./Components/Manager/OldAgreement";
import AgreementMigration from "./Components/Manager/AgreementMigration";
import Pending from "./Components/Manager/Pending";
import SrManagerApprovalForTerminate from "./Components/SrManager/SrManagerApprovalForTerminate";
import ApprovalRequestTerminate from "./Components/Operations/ApprovalRequestTerminate";
import FinanceApprovalTerminate from "./Components/FinancePanel/ApprovalRequestFinance";
import SrManagerApprovalRenewal from "./Components/BHU/BHUapprovalRenewal";
import ApprovalRequestRenewal from "./Components/Operations/ApprovalRequestRenewal";
import FinanceApprovalRenewal from "./Components/FinancePanel/ApprovalRequestFinanceRenewal";
import SrManagerApprovalForRenewal from "./Components/SrManager/SrManagerApprovalForRenewal";
import MISDashboard from "./Components/FinancePanel/MISDashboard";
import SuperAdminMonthlyPayement from "./Components/AdminPanel/Monthly-rent/MonthlyPayement";
import ViewUser from "./Components/SuperAdmin/ViewUser";

function MyRouter() {
  const [idle, setIdle] = useState("Active");
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);

  const history = useNavigate();

  const { auth } = useSelector((s) => s);
  const { role, isAuth, name } = auth;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // logout function
  function logout() {
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
    navigate("/");
  }

  return (
    <>
      <Routes>
        {/* Route Added by Yashwant Sahu  */}
        <Route
          exact
          path="/list-landlord"
          element={<LandlordList history={history} />}
        />
        <Route
          exact
          path="/edit-landlord/:id"
          element={<EditLandlord history={history} />}
        />

        <Route exact path="/" element={<Login />} />

        <Route
          exact
          path="/dashboard"
          element={
            isAuth && role.includes("Manager") ? <Dashboard /> : <Login />
          }
        />
        <Route
          exact
          path="/newAgreement"
          history={history}
          element={
            isAuth && role.includes("Manager") ? <Agreement /> : <Login />
          }
        />
        <Route
          exact
          path="/editAgreement/:id"
          history={history}
          element={
            (isAuth && role.includes("Manager")) ||
            (isAuth && role.includes("Operations")) ||
            (isAuth && role.includes("Finance")) ? (
              <EditAgreement />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/listing/:params"
          element={isAuth && role.includes("Manager") ? <Listing /> : <Login />}
        />
        <Route
          exact
          path="/pendinglist"
          element={
            (isAuth && role.includes("Manager")) ||
            (isAuth && role.includes("Senior_Manager")) ||
            (isAuth && role.includes("Operations")) ||
            (isAuth && role.includes("BUH")) ||
            (isAuth && role.includes("Finance")) ? (
              <Pending />
            ) : (
              <Login />
            )
          }
        />

        <Route
          exact
          path="/monthly-payment/:type"
          element={
            isAuth && role.includes("Manager") ? <MonthalyList /> : <Login />
          }
        />
        <Route
          exact
          path="/monthly-payment-edit/:id"
          element={
            isAuth && role.includes("Manager") ? <EditInvoice /> : <Login />
          }
        />
        <Route
          exact
          path="/monthly-payment-view/:id"
          element={
            isAuth && role.includes("Manager") ? <ViewPage /> : <Login />
          }
        />
        <Route
          exact
          path="/renewal-edit-agreement/:id"
          element={
            isAuth && role.includes("Manager") ? (
              <RenewalEditAgreement />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/renewal-view-agreement/:id"
          element={
            (isAuth && role.includes("Manager")) ||
            (isAuth && role.includes("Senior_Manager")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <RenewalViewPage />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/renewal-deposit-refund/:id"
          element={
            isAuth && role.includes("Manager") ? (
              <RenewalDepositeRefund />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/renewal"
          element={
            isAuth && role.includes("Manager") ? <RenewalList /> : <Login />
          }
        />
        <Route
          exact
          path="/managerApproval/:id"
          element={
            (isAuth && role.includes("Manager")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <ManagerApproval />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/old-agreement"
          element={
            isAuth && role.includes("Manager") ? <OldAgreements /> : <Login />
          }
        />
        <Route
          exact
          path="/migrate-agreement"
          element={
            isAuth &&
            role.includes("Manager") &&
            name.toLowerCase().includes("migration") ? (
              <AgreementMigration />
            ) : (
              <Login />
            )
          }
        />

        {/* Sr Manager */}
        <Route
          exact
          path="/srManagerDashboard"
          element={
            isAuth && role.includes("Senior_Manager") ? (
              <SrManagerDashboard />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/srManagerListing/:type"
          element={
            isAuth && role.includes("Senior_Manager") ? (
              <SrManagerListing />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/srManagerApproval/:id"
          element={
            (isAuth && role.includes("Senior_Manager")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <SrManagerApproval />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/srManagerApprovalForRenewal/:id"
          element={
            (isAuth && role.includes("Senior_Manager")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <SrManagerApprovalForRenewal />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/srManagerApprovalForTerminate/:id"
          element={
            (isAuth && role.includes("Senior_Manager")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <SrManagerApprovalForTerminate />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/srm-monthly-rent/:type"
          element={
            isAuth && role.includes("Senior_Manager") ? (
              <MonthlyPayement />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/srm-monthly-view/:id"
          element={
            isAuth && role.includes("Senior_Manager") ? (
              <SRMMonthlyRentView />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/srm-renewal-list"
          element={
            isAuth && role.includes("Senior_Manager") ? (
              <SrmRenwalList />
            ) : (
              <Login />
            )
          }
        />
        {/* Operations Section */}
        <Route
          exact
          path="/operationsDashboard"
          element={
            isAuth && role.includes("Operations") ? (
              <OperationsDashboard />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/operationsListing/:type"
          element={
            isAuth && role.includes("Operations") ? (
              <OperationsListing />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/operations-approval/:id"
          element={
            (isAuth && role.includes("Operations")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <ApprovalRequest />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/operations-approval-terminate/:id"
          element={
            (isAuth && role.includes("Operations")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <ApprovalRequestTerminate />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/operations-approval-renewal/:id"
          element={
            (isAuth && role.includes("Operations")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <ApprovalRequestRenewal />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/opr-monthly-rent/:type"
          element={
            isAuth && role.includes("Operations") ? (
              <OperationsMonthlyPayement />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/opr-monthly-view/:id"
          element={
            isAuth && role.includes("Operations") ? (
              <MonthalyRentView />
            ) : (
              <Login />
            )
          }
        />
        {/* BHU Section */}
        <Route
          exact
          path="/BHUListing/:type"
          element={isAuth && role.includes("BUH") ? <BHUListing /> : <Login />}
        />
        <Route
          exact
          path="/BHUDashboard"
          element={
            isAuth && role.includes("BUH") ? <BHUDashBoard /> : <Login />
          }
        />
        <Route
          exact
          path="/BHUapproval/:id"
          element={
            (isAuth && role.includes("BUH")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <BHUapproval />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/BHUapproval-renewal/:id"
          element={
            (isAuth && role.includes("BUH")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <SrManagerApprovalRenewal />
            ) : (
              <Login />
            )
          }
        />
        {/* Admin Section */}
        <Route
          exact
          path="/userDashboard"
          element={isAuth ? <UserDashboard /> : <Login />}
        />
        <Route
          exact
          path="/userManagement/:type"
          element={isAuth ? <UserManagement /> : <Login />}
        />
        <Route
          exact
          path="/newUser"
          element={isAuth ? <NewUser /> : <Login />}
        />
        <Route
          exact
          path="/editUser/:id"
          element={isAuth ? <EditUser /> : <Login />}
        />
        <Route
          exact
          path="/viewUser/:id"
          element={isAuth ? <ViewUser /> : <Login />}
        />
        {/* super Admin */}
        <Route
          exact
          path="/super-admin-dashboard"
          element={
            isAuth && role.includes("Super Admin") ? (
              <SuperAdminDashboard />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/superadmin-monthly-rent/:type"
          element={
            isAuth && role.includes("Super Admin") ? (
              <SuperAdminMonthlyPayement />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/super-admin-newuser"
          element={
            isAuth && role.includes("Super Admin") ? (
              <SuperAdminNewUser />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/super-admin-listing/:type"
          element={
            isAuth && role.includes("Super Admin") ? (
              <SuperAdminListing />
            ) : (
              <Login />
            )
          }
        />
        <Route
          exact
          path="/super-admin-edit/:id"
          element={
            isAuth && role.includes("Super Admin") ? (
              <SuperAdminUserEdit />
            ) : (
              <Login />
            )
          }
        />

        {/* mis  */}
        <Route
          exact
          path="/rental-property-dump-report"
          element={isAuth ? <RentalPropertyDumpReport /> : <Login />}
        />
        <Route
          exact
          path="/rental-payment-mis"
          element={isAuth ? <RentalPaymentMIS /> : <Login />}
        />
        <Route
          exact
          path="/rental-onboarding-all-status"
          element={isAuth ? <RentalOnboardingAllStatus /> : <Login />}
        />
        <Route
          exact
          path="/rental-onboarding-deposited"
          element={isAuth ? <RentalOnboardingDeposited /> : <Login />}
        />
        <Route
          exact
          path="/rent-paid-schedule"
          element={isAuth ? <RentPaidSchedule /> : <Login />}
        />
        {/*Super Admin Ends Here */}
        {/* Finance team start here */}
        <Route path="/finance-dashboard" element={<FinanceDashboard />} />
        <Route path="/MIS-Dashboard/:token" element={<MISDashboard />} />
        <Route
          path="/finance-listing/:type"
          element={
            isAuth && role.includes("Finance") ? <FinanceListing /> : <Login />
          }
        />
        <Route
          path="/finance-approval/:id"
          element={
            (isAuth && role.includes("Finance")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <FinanceApproval />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/finance-approval-terminate/:id"
          element={
            (isAuth && role.includes("Finance")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <FinanceApprovalTerminate />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/finance-approval-renewal/:id"
          element={
            (isAuth && role.includes("Finance")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <FinanceApprovalRenewal />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/finance-monthly-rent/:type"
          element={
            isAuth && role.includes("Finance") ? (
              <FinanceMonthlyPayement />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/finance-monthly-view/:id"
          element={
            (isAuth && role.includes("Finance")) ||
            (isAuth && role.includes("Super Admin")) ? (
              <FinanceMonthlyRentView />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/balance_recovery/:id"
          element={
            isAuth && role.includes("Finance") ? <Recovery /> : <Login />
          }
        />
        {/* Finance team end here */}
        {/* passowrd section Reset */}
        <Route
          path={"/resetPassword"}
          element={<ForgotPassword history={history} />}
        />
        <Route path={"/emailVerify"} element={<EmailVerify />} />
        <Route
          path={"/newPassword/:token"}
          element={<ResetPassword history={history} />}
        />

        <Route
          exact
          path="/rental-mis-reports"
          element={isAuth ? <RentalMisReports /> : <Login />}
        />
        <Route
          exact
          path="/graph-reports"
          element={isAuth ? <GraphReports /> : <Login />}
        />
        <Route
          exact
          path="/export-reports"
          element={isAuth ? <ExportReports /> : <Login />}
        />
        <Route
          exact
          path="/rent-paid-schedule"
          element={isAuth ? <RentPaidSchedule /> : <Login />}
        />
      </Routes>
    </>
  );
}

export default MyRouter;
