/* eslint-disable no-unused-vars */
import {
  Divider,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DataFieldStyle, YearField } from "../StyleComponents/Rental";
import { MyHeader, TextFieldWrapper } from "../StyledComponent";
import { useEffect, useState } from "react";
import {
  get_agreement_buh_id,
  get_old_agreement,
  get_renewal_recovery_data,
  send_back_to_manager,
  get_remarks_by_code,
  notification_handler,
  send_to_operations,
} from "../../Services/Services";
import { useDispatch, useSelector } from "react-redux";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

//download file
import { setAlert } from "../../store/action/action";
import UploadInvoice from "../MonthalyPayment/UploadInvoiceMonthly";
import BuhHamburger from "./BuhHamburger";

const Heading = ({ heading }) => {
  return (
    <Grid item xs={11} sx={{ mt: 6, mb: 2 }}>
      <Typography
        variant="body1"
        fontSize={"20px"}
        color={"primary"}
        fontWeight={"400"}
        sx={{ textDecoration: "underline" }}
      >
        {heading}
      </Typography>
    </Grid>
  );
};

function SrManagerApproval() {
  const { id } = useParams();
  const navigate = useNavigate();

  const subType = "agreement";

  const [agreement, setAgreement] = useState({});
  const [ids, setIds] = useState([]);
  const [allRemarks, setAllRemarks] = useState("");
  const [sendBackMsg, setSendBackMsg] = useState("");
  const [disable, setDisable] = useState(false);
  const [renewalAdjustment, setRenewalAdjustment] = useState({});
  const [unPaidMonthsAmount, setUnpaidMonthsAmount] = useState(0);
  const [upaid, setUnpaid] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectID, setSelectID] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [monthlyId, setMonthlyId] = useState(0);
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //renewal recovery data
  const [renewalRecovery, setRenewalRecovery] = useState({});

  const [partLabel, setPartLabel] = useState({
    landlord: [
      {
        accountNo: "",
        alternateMobile: "",
        area: "",
        bankName: "",
        benificiaryName: "",
        branchName: "",
        cheque: "",
        email: "",
        gst: null,
        gstNo: null,
        ifscCode: "",
      },
    ],
  });

  const { auth } = useSelector((s) => s);

  const srm_id = auth.id;
  const role = auth.role;
  const dispatch = useDispatch();

  const [oldIds, setOldIds] = useState([]);
  const [loading, setLoading] = useState(false);

  async function get_old_data(code) {
    try {
      const oldvalue = await get_old_agreement(code);
      oldvalue.status === 200 && setPartLabel(oldvalue.data.agreement);
      oldvalue.status === 200 && setOldIds(oldvalue.data.ids);
    } catch (error) {}
  }

  async function get_renewal_recovery(code) {
    try {
      const renewalRecovery = await get_renewal_recovery_data(code);
      renewalRecovery.status === 200 &&
        setRenewalRecovery(renewalRecovery.data.data);
      setRenewalAdjustment(renewalRecovery.data.data);
    } catch (error) {}
  }

  async function get_remarks_by_aggrement_code(code) {
    try {
      const remarks = await get_remarks_by_code(code, subType, id);

      if (remarks.data.success) {
        let remark = remarks.data.remarks.map(
          ({ step_name, remarks }) => `${step_name} : ${remarks}`
        );
        setAllRemarks(remark);
      }
    } catch (error) {}
  }

  const getData = async (id) => {
    const agreement = await get_agreement_buh_id(id);
    setAgreement(agreement.data.data[0]);
    if (agreement.data.listHoldRow.length > 0) {
      setUnpaid(agreement.data.listHoldRow);
      let unpaid_amount = agreement.data.listHoldRow.reduce(
        (sum, row) =>
          (sum += Math.round(
            row.gstNo !== null && row.gstNo.length > 0
              ? Number(row.rent_amount) + Number((row.rent_amount / 100) * 18)
              : row.rent_amount
          )),
        0
      );

      setUnpaidMonthsAmount(unpaid_amount);
    }

    if (agreement.data.data[0].type === "Renewed") {
      get_old_data(agreement.data.data[0].code);
      get_renewal_recovery(id);
    }
    get_remarks_by_aggrement_code(agreement.data.data[0].code);
  };

  useEffect(() => {
    getData(id);
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);
    if (sendBackMsg.length <= 0) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required !.",
        })
      );
    } else {
      setDisable(true);
      const agreement_id = agreement.id;
      const agreement_code = agreement.code;

      const response = await send_to_operations(
        {
          status: "Sent To Operations",
          buh_id: srm_id,
          remark: sendBackMsg,
          additionalRemark: {
            remarks: sendBackMsg,
            step_name: auth.name,
            user_id: srm_id,
            agreement_code: agreement.code,
            agreement_id: agreement.id,
          },
        },
        id,
        role,
        subType
      );
      if (response.data.success) {
        setDisable(false);
        const notification = await notification_handler(
          "AG7",
          agreement_id,
          agreement_code,
          auth.id
        );
        setLoading(false);
        dispatch(
          setAlert({
            vatiant: "success",
            open: true,
            message: "Approved And Sent To Operations",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            vatiant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    }
  };

  //Send Back to manager
  async function handleSendBack() {
    if (sendBackMsg.length <= 0) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required !.",
        })
      );
    } else {
      const agreement_id = agreement.id;
      const agreement_code = agreement.code;

      const response = await send_back_to_manager(
        {
          status: "Sent Back From BUH",
          remark: sendBackMsg,
          additionalRemark: {
            remarks: sendBackMsg,
            step_name: auth.name,
            user_id: srm_id,
            agreement_code: agreement.code,
            agreement_id: agreement.id,
          },
        },
        id,
        role,
        subType
      );
      const notification = await notification_handler(
        "AG9",
        agreement_id,
        agreement_code,
        auth.id
      );

      if (response.data.success) {
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Send back For Rectification",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    }
  }

  function getIncrement(rent, value, type) {
    let incrementType;
    rent = Number(rent);
    value = Number(value);
    if (type === "Percentage") {
      incrementType = `${Math.round(((value - rent) / rent) * 100)}%`;
    } else if (type === "Value") {
      incrementType = value - rent;
    }
    return incrementType;
  }

  return (
    <>
      {(agreement.type === "Renewed" ? oldIds.length > 0 : true) && (
        <Stack sx={{ flexDirection: "row", mb: 4 }}>
          <BuhHamburger />
          <Box className="backButton">
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              size={"large"}
            >
              <ArrowCircleLeftIcon
                sx={{ fontSize: "3rem" }}
                color="#FFFFF !important"
              />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container sx={{ justifyContent: "center", mt: 2 }}>
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "space-between", display: "flex" }}
                >
                  <MyHeader>
                    RMS{" "}
                    <span className="small-heading">
                      (Rental Management System)
                    </span>
                  </MyHeader>

                  <Typography mt="15px" mr="15px" fontWeight="600">
                    Welcome {auth.name}
                  </Typography>
                </Grid>
                <Divider />
                <Grid
                  container
                  sx={{
                    px: 1,
                    justifyContent: "space-between",
                    my: 1,
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      color="black"
                      fontSize="20px"
                      fontWeight="600"
                      alignSelf="center"
                      lineHeight="30px"
                      sx={{
                        "@media(max-width:600px)": {
                          fontSize: "17px",
                          lineHeight: "25px",
                        },
                      }}
                    >
                      {agreement.type === "Migrated"
                        ? "View Migrated Agreement"
                        : "View Agreement"}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />

                <Box className="backButton">
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(-1)}
                    size={"large"}
                  >
                    <ArrowCircleLeftIcon
                      sx={{ fontSize: "3rem" }}
                      color="#FFFFF !important"
                    />
                  </IconButton>
                </Box>
              </Grid>

              {/* Basic Details */}
              <Grid item md={10} sx={{ mt: 2 }}>
                <Heading heading={`Property Details`} />
                {agreement.status === "Deposited" && (
                  <>
                    <Grid container>
                      <DataFieldStyle
                        field={"Final Agreement"}
                        href={agreement.final_agreement}
                        name={"Final Agreement"}
                        bold={true}
                        cursor={true}
                      />
                      <DataFieldStyle
                        field={"Final Agreement Date"}
                        value={agreement.final_agreement_date}
                      />
                      <DataFieldStyle
                        field={"Monthly Rent Start Date"}
                        value={agreement.rent_start_date}
                      />
                    </Grid>
                    <Grid container sx={{ mt: 1 }}>
                      {agreement.utr_number != null && (
                        <DataFieldStyle
                          field={"Deposit UTR Number"}
                          value={agreement.utr_number}
                        />
                      )}
                      {agreement.payment_date != null && (
                        <Tooltip title="Calender" placement="right">
                          <DataFieldStyle
                            field={"Deposit Payment Date"}
                            value={agreement.payment_date}
                          />
                        </Tooltip>
                      )}
                    </Grid>
                  </>
                )}

                {agreement.site_visit_date !== null && (
                  <>
                    <Grid container sx={{ alignItems: "baseline" }}>
                      <DataFieldStyle
                        field={"Site Visit date"}
                        value={agreement.site_visit_date}
                      />
                      <DataFieldStyle
                        field={"Site Visit Remark"}
                        value={agreement.site_visit_remark}
                      />
                    </Grid>
                  </>
                )}

                <Grid container sx={{ mt: 2 }}>
                  <DataFieldStyle
                    field={"manager"}
                    value={agreement.manager_name}
                  />
                  <DataFieldStyle
                    field={"Senior manager"}
                    value={agreement.srm_name}
                  />
                </Grid>
                <Grid container sx={{ mt: 2 }}>
                  <DataFieldStyle field={"code"} value={agreement.code} />

                  <DataFieldStyle field={"state"} value={agreement.state} />
                  <DataFieldStyle field={"city"} value={agreement.city} />
                  <DataFieldStyle
                    field={"location"}
                    value={agreement.location}
                  />

                  <DataFieldStyle field={"pincode"} value={agreement.pincode} />
                  <DataFieldStyle field={"address"} value={agreement.address} />
                  <DataFieldStyle
                    field={"area"}
                    value={agreement.area + " sq. ft"}
                    partLabel={
                      agreement.type === "Renewed" &&
                      "Old Value: " + partLabel[oldIds[0]].area
                    }
                  />
                  <DataFieldStyle
                    field={"lock in month"}
                    value={agreement.lockInYear}
                    partLabel={
                      agreement.type === "Renewed" &&
                      "Old Value: " + partLabel[oldIds[0]].lockInYear
                    }
                  />
                  <DataFieldStyle
                    field={"notice period in month"}
                    value={agreement.noticePeriod}
                    partLabel={
                      agreement.type === "Renewed" &&
                      "Old Value: " + partLabel[oldIds[0]].noticePeriod
                    }
                  />
                  <DataFieldStyle
                    field={"deposit"}
                    value={agreement.deposit}
                    partLabel={
                      agreement.type === "Renewed" &&
                      "Old Value: " + partLabel[oldIds[0]].deposit
                    }
                  />
                  <DataFieldStyle
                    field={"monthly rental"}
                    value={agreement.monthlyRent}
                    partLabel={
                      agreement.type === "Renewed" &&
                      "Old Value: " + partLabel[oldIds[0]].monthlyRent
                    }
                  />
                  <DataFieldStyle
                    field={"tenure"}
                    value={agreement.tenure}
                    partLabel={
                      agreement.type === "Renewed" &&
                      "Old Value: " + partLabel[oldIds[0]].tenure
                    }
                  />

                  {agreement.tenure > 12 && (
                    <>
                      <Grid container spacing={1} sx={{ mt: 6 }}>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                          <DataFieldStyle
                            field={"yearly Increment"}
                            value={agreement.yearlyIncrement}
                          />
                        </Grid>
                        <YearField
                          year={"Year 1"}
                          incrementType={agreement.yearlyIncrement}
                          Increment={0}
                          amount={Math.round(agreement.year1)}
                          partLabel={
                            agreement.type === "Renewed" &&
                            partLabel[oldIds[0]].year1
                          }
                        />
                        <YearField
                          year={"Year 2"}
                          incrementType={agreement.yearlyIncrement}
                          amount={Math.round(agreement.year2)}
                          Increment={getIncrement(
                            agreement.year1,
                            agreement.year2,
                            agreement.yearlyIncrement
                          )}
                          partLabel={
                            agreement.type === "Renewed" &&
                            getIncrement(
                              partLabel[oldIds[0]].year1,
                              partLabel[oldIds[0]].year2,
                              partLabel[oldIds[0]].yearlyIncrement
                            )
                          }
                        />
                        {agreement.tenure > 24 && (
                          <YearField
                            year={"Year 3"}
                            incrementType={agreement.yearlyIncrement}
                            amount={Math.round(agreement.year3)}
                            Increment={getIncrement(
                              agreement.year2,
                              agreement.year3,
                              agreement.yearlyIncrement
                            )}
                            partLabel={
                              agreement.type === "Renewed" &&
                              getIncrement(
                                partLabel[oldIds[0]].year2,
                                partLabel[oldIds[0]].year3,
                                partLabel[oldIds[0]].yearlyIncrement
                              )
                            }
                          />
                        )}
                        {agreement.tenure > 36 && (
                          <YearField
                            year={"Year 4"}
                            incrementType={agreement.yearlyIncrement}
                            amount={Math.round(agreement.year4)}
                            Increment={getIncrement(
                              agreement.year3,
                              agreement.year4,
                              agreement.yearlyIncrement
                            )}
                            partLabel={
                              agreement.type === "Renewed" &&
                              getIncrement(
                                partLabel[oldIds[0]].year3,
                                partLabel[oldIds[0]].year4,
                                partLabel[oldIds[0]].yearlyIncrement
                              )
                            }
                          />
                        )}
                        {agreement.tenure > 48 && (
                          <YearField
                            year={"Year 5"}
                            incrementType={agreement.yearlyIncrement}
                            amount={Math.round(agreement.year5)}
                            Increment={getIncrement(
                              agreement.year4,
                              agreement.year5,
                              agreement.yearlyIncrement
                            )}
                            partLabel={
                              agreement.type === "Renewed" &&
                              getIncrement(
                                partLabel[oldIds[0]].year4,
                                partLabel[oldIds[0]].year5,
                                partLabel[oldIds[0]].yearlyIncrement
                              )
                            }
                          />
                        )}
                      </Grid>
                    </>
                  )}
                  {agreement.tenure > 60 && (
                    <YearField
                      year={"Year 6"}
                      incrementType={agreement.yearlyIncrement}
                      amount={Math.round(agreement.year6)}
                      Increment={getIncrement(
                        agreement.year5,
                        agreement.year6,
                        agreement.yearlyIncrement
                      )}
                      partLabel={
                        agreement.type === "Renewed" &&
                        getIncrement(
                          partLabel[oldIds[0]].year5,
                          partLabel[oldIds[0]].year6,
                          partLabel[oldIds[0]].yearlyIncrement
                        )
                      }
                    />
                  )}
                  {agreement.tenure > 72 && (
                    <YearField
                      year={"Year 7"}
                      incrementType={agreement.yearlyIncrement}
                      amount={Math.round(agreement.year7)}
                      Increment={getIncrement(
                        agreement.year6,
                        agreement.year7,
                        agreement.yearlyIncrement
                      )}
                      partLabel={
                        agreement.type === "Renewed" &&
                        getIncrement(
                          partLabel[oldIds[0]].year6,
                          partLabel[oldIds[0]].year7,
                          partLabel[oldIds[0]].yearlyIncrement
                        )
                      }
                    />
                  )}
                  {agreement.tenure > 84 && (
                    <YearField
                      year={"Year 8"}
                      incrementType={agreement.yearlyIncrement}
                      amount={Math.round(agreement.year8)}
                      Increment={getIncrement(
                        agreement.year7,
                        agreement.year8,
                        agreement.yearlyIncrement
                      )}
                      partLabel={
                        agreement.type === "Renewed" &&
                        getIncrement(
                          partLabel[oldIds[0]].year7,
                          partLabel[oldIds[0]].year8,
                          partLabel[oldIds[0]].yearlyIncrement
                        )
                      }
                    />
                  )}
                  {agreement.tenure > 96 && (
                    <YearField
                      year={"Year 9"}
                      incrementType={agreement.yearlyIncrement}
                      amount={Math.round(agreement.year9)}
                      Increment={getIncrement(
                        agreement.year8,
                        agreement.year9,
                        agreement.yearlyIncrement
                      )}
                      partLabel={
                        agreement.type === "Renewed" &&
                        getIncrement(
                          partLabel[oldIds[0]].year8,
                          partLabel[oldIds[0]].year9,
                          partLabel[oldIds[0]].yearlyIncrement
                        )
                      }
                    />
                  )}
                  {agreement.tenure > 108 && (
                    <YearField
                      year={"Year 10"}
                      incrementType={agreement.yearlyIncrement}
                      amount={Math.round(agreement.year10)}
                      Increment={getIncrement(
                        agreement.year9,
                        agreement.year10,
                        agreement.yearlyIncrement
                      )}
                      partLabel={
                        agreement.type === "Renewed" &&
                        getIncrement(
                          partLabel[oldIds[0]].year9,
                          partLabel[oldIds[0]].year10,
                          partLabel[oldIds[0]].yearlyIncrement
                        )
                      }
                    />
                  )}
                </Grid>
              </Grid>

              {Object.keys(renewalAdjustment).length > 0 && (
                <>
                  <Grid item xs={10} mt={3}>
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="25px"
                      lineHeight="28px"
                      fontWeight="600"
                      // my="20px"
                    >
                      Renewal Adjustments Form
                    </Typography>
                  </Grid>
                  <Grid item xs={10} mt={3}>
                    <Grid
                      coantiner
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        flexDirection: "column",
                      }}
                    >
                      <Grid item xs={10}>
                        <Typography
                          variant="h6"
                          color={"primary"}
                          sx={{ fontWeight: 700 }}
                        >
                          Unpaid Months
                        </Typography>
                      </Grid>

                      {upaid.map((row) => (
                        <Grid item sx={{ display: "flex" }}>
                          <TextFieldWrapper
                            marginRight={true}
                            label={"Name"}
                            disabled={true}
                            value={row.name}
                          />
                          <TextFieldWrapper
                            marginRight={true}
                            label={"Rent Month (Unpaid)"}
                            placeHolder="Deposit Amount"
                            disabled={true}
                            value={
                              month[new Date(row.rent_date).getUTCMonth()] +
                              "-" +
                              new Date(row.rent_date).getFullYear()
                            }
                          />
                          <TextFieldWrapper
                            marginRight={true}
                            label="Rent Amount"
                            disabled={true}
                            value={
                              row.gstNo !== null && row.gstNo.length > 0
                                ? Math.round(
                                    Number(row.rent_amount) +
                                      Number((row.rent_amount / 100) * 18)
                                  )
                                : row.rent_amount
                            }
                          />
                          <TextFieldWrapper
                            marginRight={true}
                            label="Status"
                            disabled={true}
                            value={row.status}
                          />
                          {row.gstNo ? (
                            <>
                              {row.invoice_number != null && (
                                <Grid item sx={{ textAlign: "center" }}>
                                  {
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      style={{
                                        backgroundColor:
                                          "rgb(103 185 68 / 89%)",
                                        color: "white",
                                        fontSize: "12px",
                                        textTransform: "capitalize",
                                        minHeight: "37px",
                                        margin: "10px",
                                        minWidth: "100px",
                                      }}
                                      // disabled={row.invoice_number != (null)}
                                      onClick={(event) => {
                                        event.stopPropagation(); // don't select this row after clicking
                                        setSelectID(row.id);
                                        setOpen(true);
                                        setInvoiceDetails({
                                          ...invoiceDetails,
                                          rentAmount: row.rent_amount,
                                          gstAmount:
                                            row?.gstNo != null &&
                                            row?.gstNo.length > 0
                                              ? Number(
                                                  parseInt(
                                                    row?.rent_amount * 0.18
                                                  ).toFixed(2)
                                                )
                                              : 0,
                                          totalAmount:
                                            Number(row?.rent_amount) +
                                            Number(
                                              parseInt(
                                                row?.rent_amount * 0.18
                                              ).toFixed(2)
                                            ),
                                          user_id: auth.id,
                                          agreement_id:
                                            agreement?.[ids[0]]
                                              ?.agreement_id[0],
                                          code: agreement?.[ids[0]]?.code,
                                        });
                                        setMonthlyId(row.id);
                                      }}
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {"View"}
                                      </Typography>
                                    </Button>
                                  }
                                </Grid>
                              )}
                            </>
                          ) : (
                            <Grid item>
                              <Button
                                style={{
                                  display: "block",
                                  minHeight: "37px",
                                  margin: "10px",
                                  minWidth: "100px",
                                  background: "transparent",
                                }}
                              ></Button>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      marginTop: "5px",
                      display: "flex",
                      gap: "2rem",
                      flexDirection: "row",
                    }}
                    rowSpacing={5}
                    xs={10}
                  >
                    <TextFieldWrapper
                      label="Deposit Amount (Paid)"
                      placeHolder="Deposit Amount"
                      name="depositedAmount"
                      disabled={true}
                      value={renewalAdjustment.deposited}
                    />
                    <TextFieldWrapper
                      label="New Deposit Amount"
                      placeHolder="New Deposit Amount"
                      name="new_deposit"
                      disabled={true}
                      value={agreement.deposit}
                    />
                  </Grid>
                  <Grid
                    container
                    sx={{
                      marginTop: "5px",
                      display: "flex",
                      gap: "2rem",
                      flexDirection: "row",
                    }}
                    rowSpacing={5}
                    xs={10}
                  >
                    <TextFieldWrapper
                      label="Selected Un Paid Months Amount"
                      placeHolder="Selected UnPaid Months Amount"
                      name="unPaidMonthsAmount"
                      disabled={true}
                      value={unPaidMonthsAmount}
                    />
                    <TextFieldWrapper
                      label="Balance Deposit Amount"
                      placeHolder="Balance Deposit Amount"
                      name="depositedAmount"
                      disabled={true}
                      value={renewalAdjustment.balance_amount}
                    />
                  </Grid>

                  <Grid
                    container
                    sx={{
                      marginTop: "5px",
                      display: "flex",
                      gap: "2rem",
                      flexDirection: "row",
                    }}
                    rowSpacing={5}
                    xs={10}
                  >
                    <TextFieldWrapper
                      label={`Balance Deposit Payable`}
                      placeHolder="Balance Deposit Payable"
                      name="depositedAmount"
                      disabled={true}
                      value={renewalAdjustment.receivable}
                    />
                  </Grid>
                </>
              )}
              <UploadInvoice
                open={open}
                view={true}
                handleClose={() => setOpen(false)}
                value={invoiceDetails}
                setValue={setInvoiceDetails}
                monthlyId={monthlyId}
              />
              {allRemarks?.length > 0 && (
                <>
                  <Grid
                    item
                    container
                    sx={{ alignItems: "baseline", mt: 5 }}
                    xs={10}
                  >
                    <DataFieldStyle field={"Remarks"} />
                    {allRemarks.map((remark, index) => (
                      <div
                        style={{
                          width: "100%",
                          padding: "10px 10px 10px 0",
                        }}
                        key={index}
                      >
                        {remark}
                      </div>
                    ))}
                  </Grid>
                </>
              )}

              {agreement.status === "Sent To BUH" &&
                !auth.role?.includes("Super Admin") && (
                  <>
                    <Grid item container xs={10} sx={{ mt: 5 }}>
                      <Grid item xs={8} className="textFieldWrapper">
                        <TextField
                          type="text"
                          multiline
                          rows={3}
                          fullWidth
                          variant="outlined"
                          label="Remark *"
                          placeholder="Remark *"
                          value={sendBackMsg}
                          onChange={(e) => setSendBackMsg(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    {!auth.role?.includes("Super Admin") && (
                      <Grid item md={8} sx={{ mt: 4, mb: 2 }}>
                        <Grid
                          container
                          spacing={1}
                          sx={{ justifyContent: "space-evenly" }}
                        >
                          <Grid item md={4} xs={11}>
                            <Button
                              variant="contained"
                              sx={{
                                height: "55px",
                                borderRadius: "12px",
                                backgroundColor: "primary",
                                width: "100%",
                                color: "#FFFFFF",
                                textTransform: "capitalize",
                                fontSize: "18px",
                                lineHeight: "20px",
                              }}
                              onClick={handleSubmit}
                              disabled={disable}
                            >
                              Send To Operations
                            </Button>
                          </Grid>
                          <Grid item md={4} xs={11}>
                            <Button
                              variant="outlined"
                              sx={{
                                color: "var(--main-color)",
                                height: "55px",
                                borderRadius: "12px",
                                backgroundColor: "primary",
                                width: "100%",
                                textTransform: "capitalize",
                                fontSize: "18px",
                                lineHeight: "20px",
                              }}
                              onClick={handleSendBack}
                            >
                              Send Back To Manager
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}

              {/* buttons end here */}
            </Grid>
          </Box>
        </Stack>
      )}
    </>
  );
}

export default SrManagerApproval;
