/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import LoginComponent from "./LoginComponent";
import { useNavigate } from "react-router-dom";

import {
  LoginAPI,
  UserCheck,
} from "../../Services/Services";
import { setAlert, setAuth } from "../../store/action/action";
import { useDispatch } from "react-redux";

export default function Login() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
    role: "",
  });

  const [rolesDropdown, setRolesDropdown] = useState([]);
  const validEmail = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/;
    //text Field onchnage
    const handleChange = (e) => {
      const emailRegex = /^[a-zA-Z0-9_.@]+$/;
      if (e.target.name === "email") {
        if (emailRegex.test(e.target.value) || e.target.value === '') {
          setFormValue({
            ...formValue,
            [e.target.name]: e.target.value,
          });
        }
      } else{
        setFormValue({
          ...formValue,
          [e.target.name]: e.target.value,
        });
      }
      
    };

  useEffect(() => {
    validEmail.test(formValue.email) &&
      (async () => {
        const user = await UserCheck(formValue);
        const { data } = user;
        if (data.success) {
          let role = data.result[0][0].role.split(",");
          setRolesDropdown((prev) => role);
        } else {
          setRolesDropdown((prev) => []);
        }
      })();
  }, [formValue.email]);


  const { role } = formValue;

  const Get_DATA = async (dispatch) => {
    try {
      const user = await LoginAPI(formValue);
      const data = user.data;

      if (data.success) {
        const login_user = data.result[0];

        if (login_user.is_auth === 1 || role === "Admin") {
          login_user.role = JSON.parse(login_user.role);
          if (login_user.role.includes("Senior_Manager")) {
            dispatch(setAlert({ open: false, variant: "", message: "" }));
            dispatch(
              setAuth({
                name: login_user.name,
                role: login_user.role,
                id: login_user.id,
              })
            );
            navigate(`/srManagerDashboard`);
          } else if (login_user.role.includes("Manager")) {
            dispatch(setAlert({ open: false, variant: "", message: "" }));
            dispatch(
              setAuth({
                name: login_user.name,
                role: login_user.role,
                id: login_user.id,
              })
            );
            navigate(`/dashboard`);
            // } else if (login_user.role.includes("Super Admin") && role==="Super Admin" || role==="") {
          } else if (login_user.role.includes("Super Admin")) {
            dispatch(setAlert({ open: false, variant: "", message: "" }));
            dispatch(
              setAuth({
                name: login_user.name,
                role: login_user.role,
                id: login_user.id,
              })
            );
            navigate(`/super-admin-dashboard`);
          } else if (login_user.role.includes("BUH")) {
            dispatch(setAlert({ open: false, variant: "", message: "" }));
            dispatch(
              setAuth({
                name: login_user.name,
                role: login_user.role,
                id: login_user.id,
              })
            );
            navigate(`/BHUDashboard`);
          } else if (login_user.role.includes("Operations")) {
            dispatch(setAlert({ open: false, variant: "", message: "" }));
            dispatch(
              setAuth({
                name: login_user.name,
                role: login_user.role,
                id: login_user.id,
              })
            );
            // navigate(`/operationsListing`)
            navigate(`/operationsDashboard`);
          } else if (login_user.role.includes("Admin")) {
            dispatch(setAlert({ open: false, variant: "", message: "" }));
            dispatch(
              setAuth({
                name: login_user.name,
                role: login_user.role,
                id: login_user.id,
                state: login_user.state,
                city: login_user.city,
              })
            );
            navigate(`/userDashboard`);
          } else if (login_user.role.includes("Finance")) {
            dispatch(setAlert({ open: false, variant: "", message: "" }));
            dispatch(
              setAuth({
                name: login_user.name,
                role: login_user.role,
                id: login_user.id,
                state: login_user.state,
                city: login_user.city,
              })
            );
            navigate(`/finance-dashboard`);
          }
        } else {
          navigate(`/newPassword/${data.result[1]}`);
        }
      } else {
        dispatch(
          setAlert({ open: true, variant: "error", message: data.message })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Something Went Wrong, Please Try Again !",
        })
      );
    }
  };

  //on form Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    Get_DATA(dispatch);
  };
  return (
    <>
      <LoginComponent
        buttons={true}
        title="RMS"
        discription={"Rental Management System"}
        handleSubmit={handleSubmit}
        formValue={formValue}
        handleChange={handleChange}
        rolesDropdown={rolesDropdown}
        className="title-description-container"
      />
    </>
  );
}