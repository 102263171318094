import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import React, { useState } from "react";

//icons hero section
import Logo from "../assest/pic/Dashboard/logo1 2.png";

import "../assest/CSS/hamburgerMenu.css";
import { useNavigate } from "react-router-dom";
import { Vector1, Vector2, VectorLogout, VectorUser } from "./Vector";
import { NavExpand, NavItem } from "./StyleComponents/HamburgerStyled";
import Dashboard from "../assest/pic/Dashboard/chart.png";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../store/action/action";

function HamburgerMenu({
  handleListing,
  misReports,
  navigateHome,
  monthlyRent,
  renewal,
  monthlyBtn,
  monthly,
  agreements,
  admin,
}) {
  agreements = agreements || [];

  const migrationAgreements = [
    { text: "Migrated Agreements ", navigateTo: "/listing/mg-ag" },
    {
      text: "Active / In Active Agreements ",
      navigateTo: "/listing/approved-ag-mg",
    },
  ];

  const [expand, setExpand] = useState(false);

  const { auth } = useSelector((s) => s);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [collapse, setCollaps] = useState("");

  // logout function
  // function logout() {
  //   localStorage.clear();
  //   dispatch({ type: "LOGOUT" });
  //   navigate("/");
  // }
  function logout() {
    try {
      localStorage.clear();
      dispatch({ type: "LOGOUT" });
      navigate("/");
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: "You have been successfully logged out.",
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong !",
        })
      );
    }
  }
  return (
    <>
      {/* hambergur menu  */}
      <Grid
        sx={{ ml: "10px" }}
        className={expand ? "HeroSectionMenu" : ""}
        onBlur={() => setExpand(false)}
      >
        <Stack sx={{ flexDirection: "column" }} spacing={2}>
          <Box
            sx={{
              background: `url(${Logo})`,
              height: "66px",
              width: "89px",
              backgroundSize: "cover",
            }}
          />
          <Box sx={{ display: "grid", placeItems: "center", width: "89px" }}>
            <Box
              sx={{
                background: `url(${Dashboard})`,
                backgroundSize: "cover",
                height: "45px",
                width: "45px",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/${navigateHome}`)}
              title="Dashboard"
            />
          </Box>

          {!expand ? (
            <>
              {auth?.isAuth &&
                (auth.role.includes("Admin") ||
                  auth.role.includes("Super Admin")) && (
                  <NavItem
                    Vector={VectorUser}
                    onClick={() => setExpand(!expand)}

                    // onClick={() => navigate("/userManagement")}
                  />
                )}
              {auth?.isAuth &&
                (auth.role.includes("Manager") ||
                  auth.role.includes("BUH") ||
                  auth.role.includes("Senior_Manager") ||
                  auth.role.includes("Operations") ||
                  auth.role.includes("Super Admin") ||
                  auth.role.includes("Finance")) && (
                  <NavItem
                    Vector={Vector1}
                    onClick={() => setExpand(!expand)}
                    title="Agreement"
                  />
                )}

              {monthlyBtn &&
                auth?.isAuth &&
                !auth.name.includes("Migration".toLowerCase()) && (
                  <>
                    <NavItem
                      Vector={Vector2}
                      onClick={() => setExpand(!expand)}
                      title="Monthly Payments"
                    />
                  </>
                )}
              {misReports &&
                auth?.isAuth &&
                !auth.name.includes("Migration".toLowerCase()) && (
                  <NavItem
                    Vector={Vector1}
                    onClick={() => setExpand(!expand)}
                    title="MIS Reports"
                  />
                )}

              {auth?.isAuth &&
                auth.role.includes("Finance") &&
                !auth.name.includes("Migration".toLowerCase()) && (
                  <NavItem
                    Vector={Vector1}
                    onClick={() => setExpand(!expand)}
                    title="UTR Bulk Upload"
                  />
                )}
              <NavItem Vector={VectorLogout} onClick={logout} title="Logout" />
            </>
          ) : (
            <Stack container spacing={2}>
              {/* onclick */}
              {admin && (
                <>
                  <NavExpand
                    msg="Users"
                    Vector={VectorUser}
                    NavItem={NavItem}
                    onClick={() => setCollaps("admin")}
                  />
                  <Collapse
                    in={collapse === "admin"}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      {admin.map((row) => (
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => navigate(`${row.navigateTo}`)}
                          >
                            <ListItemText
                              primary={row.text}
                              sx={{ color: "primary" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </>
              )}
              {agreements.length > 0 && (
                <>
                  <NavExpand
                    msg="Agreements"
                    // onClick={handleListing}
                    Vector={Vector1}
                    onClick={() => setCollaps("Agreement")}
                  />
                  <Collapse
                    in={collapse === "Agreement"}
                    timeout="auto"
                    unmountOnExit
                  >
                    {auth?.isAuth &&
                    auth.name.includes("Migration".toLowerCase()) ? (
                      <List>
                        {migrationAgreements.map((row) => (
                          <ListItem disablePadding>
                            <ListItemButton
                              onClick={() => navigate(`${row.navigateTo}`)}
                            >
                              <ListItemText
                                primary={row.text}
                                sx={{ color: "primary" }}
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <List>
                        {agreements.map((row) => (
                          <ListItem disablePadding>
                            <ListItemButton
                              onClick={() => navigate(`${row.navigateTo}`)}
                            >
                              <ListItemText
                                primary={row.text}
                                sx={{ color: "primary" }}
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Collapse>
                </>
              )}
              {monthlyBtn &&
                auth?.isAuth &&
                !auth.name.includes("Migration".toLowerCase()) && (
                  <>
                    <NavExpand
                      msg="Monthly Payments"
                      // onClick={monthlyRent}
                      onClick={() => setCollaps("Monthly Payment")}
                      Vector={Vector2}
                    />

                    <Collapse
                      in={collapse === "Monthly Payment"}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List>
                        {monthly.map((row, i) => (
                          <ListItem disablePadding>
                            <ListItemButton
                              onClick={() => navigate(row.navigateTo)}
                            >
                              <ListItemText
                                primary={row.text}
                                sx={{ color: "primary" }}
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </>
                )}

              {misReports &&
                auth?.isAuth &&
                !auth.name.includes("Migration".toLowerCase()) && (
                  <>
                    <NavExpand
                      msg="MIS Report"
                      // onClick={handleListing}
                      Vector={Vector1}
                      onClick={() => setCollaps("MIS")}
                    />
                    <Collapse
                      in={collapse === "MIS"}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List>
                        {misReports.map((row) => (
                          <ListItem disablePadding>
                            <ListItemButton
                              onClick={() => navigate(`${row.navigateTo}`)}
                            >
                              <ListItemText
                                primary={row.text}
                                sx={{ color: "primary" }}
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </>
                )}
              {auth.role.includes("Finance") && (
                <>
                  <NavExpand
                    msg={"UTR Bulk Upload"}
                    Vector={Vector1}
                    onClick={() => setCollaps("Export")}
                  />
                  <Collapse
                    in={collapse === "Export"}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() => navigate("/export-reports")}
                        >
                          <ListItemText
                            primary={"UTR Bulk Upload"}
                            sx={{ color: "primary" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )}
              <NavExpand msg="Logout" Vector={VectorLogout} onClick={logout} />
            </Stack>
          )}
        </Stack>
      </Grid>
    </>
  );
}

export default HamburgerMenu;
