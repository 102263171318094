/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

//icons
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

// MUI Components
import {
  Box,
  Button,
  Grid,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  Autocomplete,
  TextField,
  MenuItem,
  IconButton,
  FormControl,
  Collapse,
  Dialog,
  DialogTitle,
  Divider,
  FormLabel,
} from "@mui/material";

import {
  DocumentUpload,
  MyHeader,
  SelectComponent,
  SelectUser,
  TextFieldWrapper,
} from "../StyledComponent";

// Components

import HamburgerMenu from "../HamburgerMenu";
import YearlyIncrement from "./IncrementType";
// import DialogBox from "./DialogBox";
import {
  add_agreement,
  add_landlord,
  getStateList,
  uploadDoc,
  getDetails,
  getCityList,
  editAgreement,
  getBankName,
  getLocation,
  getState,
  get_agreement_id,
  get_data_recovery,
  get_remarks_by_code,
  getUserList,
  get_agreement_id_finance,
  send_to_bhu,
  ApprovedByFinance,
  create_landlord_id,
  notification_handler,
} from "../../Services/Services";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../store/action/action";
import PermissionAlert from "./Alert";
import { useParams, useNavigate } from "react-router-dom";
import { DataFieldStyle, ImageView } from "../StyleComponents/Rental";
import HamburgerManager from "./HamburgerManager";

function EditAgreement({ history }) {
  const navigate = useNavigate();
  const { landloard, auth } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [allRemarks, setAllRemarks] = useState("");

  const [recovery, setRecovery] = useState({});

  const [agreement, setAgreement] = useState([]);
  const { id } = useParams();

  const [buh_id, setBuh_ID] = useState(null);
  const [manager_id, setManager_ID] = useState(null);
  const [srm_id, setSRM_ID] = useState(null);
  const [op_id, setOP_ID] = useState(
    auth.isAuth && auth.role.includes("Operations") ? auth.id : null
  );
  const [finance_id, setFinance_ID] = useState(null);
  const [BUHUsers, setBUHUsers] = useState([]);
  const [SrManagerList, setSrManagerList] = useState([]);
  const [ManagerList, setManagerList] = useState([]);
  const [remark, setRemark] = useState("");
  const [isError, setIsError] = useState(false);
  const [adharData, setAdharData] = useState([]);
  const [duplicateData, setDuplicateData] = useState([]);
  const [duplicateAdhaar, setDuplicateAdhaar] = useState([]);
  const [yearlyIncreentChanged, setYearlyIncreentChanged] = useState(false);
  const [apiErrorStatus, setApiErrorStatus] = useState(false);
  let oldAdhaarData = [];

  useEffect(() => {
    getUserList("BUH", buh_id).then(
      (response) => {
        setBUHUsers(response.data);
      },
      (error) => {
        console.info(error);
        setBUHUsers([]);
      }
    );
  }, []);

  const labelStyle = {
    fontSize: "20px",
    lineHeight: "30px",
    color: "var(--main-color)",
    fontWeight: "600",
    "@media(max-width:900px)": { fontSize: "10px" },
  };

  const [preData, setPreData] = useState({
    landlord: [],
    area: "",
    code: "",
    lockInYear: "",
    address: "",
    pincode: "",
    state: "",
    city: "",
    locaiton: "",
    noticePeriod: "",
    deposit: "",
    monthlyRent: "",
    yearlyIncrement: "",
    status: "",
    gst_certificate: "",
    draft_agreement: "",
    electricity_bill: "",
    poa: "",
    maintaince_bill: "",
    cheque: "",
    tax_receipt: "",
    noc: "",
    remark: "",
    assets: "",
    property_pic: "",
    type: "",
    buh_id: null,
    manager_id: null,
    srm_id: null,
    op_id: null,
    fin_id: null,
    final_agreement_date: "",
    rent_start_date: "",
    wrongPin: false,
    final_agreement: "",
  });

  const [duplicateAadhaarNoss, setDuplicateAdharNos] = useState([]);
  const [duplicatePanrNoss, setDuplicatePanNos] = useState([]);
  const [duplicateMobileNoss, setDuplicateMobileNos] = useState([]);
  const [landLordDuplicateError, setLandLordDuplicateError] = useState(false);

  useEffect(() => {
    const duplicateAadhaarNos = [];
    const duplicatePanNo = [];
    const duplicateMobileNo = [];
    const duplicateGstNo = [];
    const aadhaarNoSet = new Set();
    const panNoSet = new Set();
    const mobileNoSet = new Set();
    const gstNoSet = new Set();

    for (const item of preData.landlord) {
      if (
        item.aadharNo !== undefined &&
        item.aadharNo !== null &&
        aadhaarNoSet.has(item.aadharNo)
      ) {
        duplicateAadhaarNos.push(item.aadharNo);
      } else {
        aadhaarNoSet.add(item.aadharNo);
      }

      if (
        item.panNo !== undefined &&
        item.panNo !== null &&
        panNoSet.has(item.panNo)
      ) {
        duplicatePanNo.push(item.panNo);
      } else {
        panNoSet.add(item.panNo);
      }

      if (
        item.mobileNo !== undefined &&
        item.mobileNo !== null &&
        mobileNoSet.has(item.mobileNo)
      ) {
        duplicateMobileNo.push(item.mobileNo);
      } else {
        mobileNoSet.add(item.mobileNo);
      }

      if (gstNoSet.has(item.gstNo)) {
        duplicateGstNo.push(item.gstNo);
      } else {
        gstNoSet.add(item.gstNo);
      }
    }
    setDuplicateAdharNos(duplicateAadhaarNos);
    setDuplicateMobileNos(duplicateMobileNo);
    setDuplicatePanNos(duplicatePanNo);

    if (
      duplicateAadhaarNos.length > 0 ||
      duplicateMobileNo.length > 0 ||
      duplicatePanNo.length > 0
    ) {
      setLandLordDuplicateError(true);
    } else {
      setLandLordDuplicateError(false);
    }
  }, [preData.landlord]);

  async function get_remarks_by_aggrement_code(code) {
    try {
      const remarks = await get_remarks_by_code(code);

      if (remarks.data.success) {
        let remark = remarks.data.remarks.map(
          ({ step_name, remarks }) => `${step_name} : ${remarks}`
        );
        return remark;
      }
      return;
    } catch (error) {}
  }

  const getData = async (id) => {
    try {
      const agreement = await get_agreement_id(id);
      if (agreement.data.success) {
        get_recovery_data(agreement.data.agreement[agreement.data.ids[0]].id);
        setAgreement(agreement.data.agreement);
        const remark = await get_remarks_by_aggrement_code(
          agreement.data.agreement[agreement.data.ids[0]].code
        );
        setAllRemarks(remark);
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  };

  async function get_recovery_data(id) {
    try {
      const recovery = await get_data_recovery(id);
      if (recovery.status === 200) {
        setRecovery(recovery.data.data[0]);
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong!!",
        })
      );
    }
  }

  useEffect(() => {
    getData(id);
  }, []);

  async function fetchData() {
    try {
      let response = await getDetails(id);

      if (response.status === 200) {
        let {
          id,
          code,
          pincode,
          state,
          address,
          location,
          city,
          lockInYear,
          monthlyRent,
          noticePeriod,
          yearlyIncrement,
          deposit,
          gst_certificate,
          draft_agreement,
          electricity_bill,
          poa,
          maintaince_bill,
          cheque,
          tax_receipt,
          noc,
          tenure,
          year1,
          year2,
          year3,
          year4,
          year5,
          year6,
          year7,
          year8,
          year9,
          year10,
          area,
          landlord,
          remark,
          assets,
          property_pic,
          type,
          status,
          buh_id,
          manager_id,
          srm_id,
          rent_start_date,
          final_agreement_date,
          property_pic_name,
          final_agreement,
        } = response.data.agreement;
        if (landlord.length > 0) {
          for (const item of landlord) {
            oldAdhaarData.push(item.aadharNo);
          }
        }

        setBuh_ID(response.data.agreement.buh_id);
        setManager_ID(response.data.agreement.manager_id);
        setSRM_ID(response.data.agreement.srm_id);
        setOP_ID(response.data.agreement.op_id);
        setFinance_ID(response.data.agreement.finance_id);
        if (response.status === 200) {
          getUserList("SR.MANAGER", buh_id).then(
            (response) => {
              setSrManagerList(response.data);
            },
            (error) => {
              console.info(error);
              setSrManagerList([]);
            }
          );
          getUserList("MANAGER", srm_id).then(
            (response) => {
              setManagerList(response.data);
            },
            (error) => {
              console.info(error);
              setManagerList([]);
            }
          );
        }

        let rent = monthlyRent;
        if (yearlyIncrement === "Percentage") {
          setYearValue({
            year1: 0,
            year2: year2 && Math.round(((year2 - year1) / year1) * 100),
            year3: year3 && Math.round(((year3 - year2) / year2) * 100),
            year4: year4 && Math.round(((year4 - year3) / year3) * 100),
            year5: year5 && Math.round(((year5 - year4) / year4) * 100),
            year6: year6 && Math.round(((year6 - year5) / year5) * 100),
            year7: year7 && Math.round(((year7 - year6) / year6) * 100),
            year8: year8 && Math.round(((year8 - year7) / year7) * 100),
            year9: year9 && Math.round(((year9 - year8) / year8) * 100),
            year10: year10 && Math.round(((year10 - year9) / year9) * 100),
          });
        } else {
          setYearValue({
            year1: 0,
            year2: year2 && year2 - year1,
            year3: year3 && year3 - year2,
            year4: year4 && year4 - year3,
            year5: year5 && year5 - year4,
            year6: year6 && year6 - year5,
            year7: year7 && year7 - year6,
            year8: year8 && year8 - year7,
            year9: year9 && year9 - year8,
            year10: year10 && year10 - year9,
          });
        }
        let temp = {};
        if (landlord.length > 0) {
          landlord.map((object, i) => {
            preData[`aadhar_card${i}`] = object.aadhar_card;
            preData[`cheque${i}`] = object.cheque;
            preData[`pan_card${i}`] = object.pan_card;
            preData[`gst${i}`] = object.gst;
            preData[`gst_name${i}`] =
              object.gst != null ? object.gst.split("/").pop() : "";
            preData[`cheque_name${i}`] =
              object.cheque != null ? object.cheque.split("/").pop() : "";
            preData[`aadhar_card_name${i}`] =
              object.aadhar_card != null
                ? object.aadhar_card.split("/").pop()
                : "";
            preData[`pan_card_name${i}`] =
              object.pan_card != null ? object.pan_card.split("/").pop() : "";
          });
        }
        preData[`final_agreement_name`] =
          final_agreement != null ? final_agreement.split("/").pop() : "";
        preData[`draft_agreement_name`] =
          draft_agreement != null ? draft_agreement.split("/").pop() : "";
        preData[`electricity_bill_name`] =
          electricity_bill != null ? electricity_bill.split("/").pop() : "";
        preData[`poa_name`] = poa != null ? poa.split("/").pop() : "";
        preData[`maintaince_bill_name`] =
          maintaince_bill != null ? maintaince_bill.split("/").pop() : "";
        preData[`tax_receipt_name`] =
          tax_receipt != null ? tax_receipt.split("/").pop() : "";
        preData[`property_pic_name`] =
          property_pic != null
            ? property_pic.split("/").pop()
            : property_pic_name;

        setPreData({
          ...preData,
          id,
          area,
          code,
          pincode,
          state,
          address,
          location,
          city,
          lockInYear,
          monthlyRent,
          noticePeriod,
          yearlyIncrement,
          deposit,
          gst_certificate,
          draft_agreement,
          electricity_bill,
          poa,
          maintaince_bill,
          cheque,
          tax_receipt,
          noc,
          tenure,
          landlord,
          remark,
          assets: assets === '""' ? "" : assets,
          property_pic,
          type,
          status,
          buh_id,
          manager_id,
          srm_id,
          rent_start_date,
          final_agreement_date,
          op_id,
          fin_id: finance_id,
          final_agreement,
        });

        setFormError({
          id: undefined,
          code: undefined,
          pincode: undefined,
          state: undefined,
          address: undefined,
          location: undefined,
          city: undefined,
          lockInYear: undefined,
          monthlyRent: undefined,
          noticePeriod: undefined,
          yearlyIncrement: undefined,
          deposit: undefined,
          gst_certificate: undefined,
          draft_agreement: undefined,
          electricity_bill: undefined,
          poa: undefined,
          maintaince_bill: undefined,
          cheque: undefined,
          tax_receipt: undefined,
          noc: undefined,
          tenure: undefined,
          landlord: landlord.map((row) => ({})),
          remark: undefined,
          area: undefined,
          property_pic: undefined,
        });
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  const [docExpand, setDocExpand] = useState(0);
  const [i, setIndex] = useState(0);
  const [data, setData] = useState({
    landlord: [...landloard],
    code: "",
    lockInYear: "",
    noticePeriod: "",
    deposit: "",
    monthlyRent: "",
    yearlyIncrement: "",
    tenure: "",
  });
  const [openBack, setOpenBack] = useState(false);
  const [openHold, setOpenHold] = useState(false);
  const handleBack = () => {
    setOpenBack(true);
  };

  const handleCloseBack = () => {
    setOpenBack(false);
  };
  useEffect(() => {
    setPreData((old) => ({ ...old, landlord: [...landloard] }));
    setFormError((old) => ({ ...old, landlord: [...landloard] }));
  }, [landloard]);

  const [landblord, setLandblord] = useState([1]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [increment, setIncrement] = useState({
    year1: "",
    year2: "",
    year3: "",
    year4: "",
    year5: "",
    year6: "",
    year7: "",
    year8: "",
    year9: "",
    year10: "",
  });

  const [yearValue, setYearValue] = useState({
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  });

  async function handleChangeCommonFile(e, i) {
    const FD = new FormData();
    FD.append("folderName", preData.code);
    FD.append("photo", e.target.files[0]);
    let response = await uploadDoc(FD);
    if (response.status === 200) {
      setFormError((old) => ({
        ...old,
        [e.target.name + i]: "",
        [e.target.name]: "",
      }));

      setPreData((old) => ({
        ...old,
        [e.target.name + "_name"]: e.target.files[0].name,
        [e.target.name]: response.data.link,
        landlord: old.landlord.map((row, index) => {
          if (i === index) {
            row[e.target.name] = response.data.link;
            row[e.target.name + "_name"] = e.target.files[0].name;
            return row;
          } else return row;
        }),
        [e.target.name + i]: e.target.files[0].name,
      }));

      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: response.data.message,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message || "Something went wrong !!!",
        })
      );
    }
  }
  async function handleChangeFile(e) {
    const FD = new FormData();
    FD.append("folderName", preData.code);
    FD.append("photo", e.target.files[0]);
    let response = await uploadDoc(FD);

    if (response.status === 200) {
      setFormError((old) => ({
        ...old,
        [e.target.name + "_name"]: "",
        [e.target.name]: "",
      }));

      setPreData((old) => ({
        ...old,
        [e.target.name]: response.data.link,
        [e.target.name + "_name"]: e.target.files[0].name,
      }));
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: response.data.message,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message || "Something went wrong !!!",
        })
      );
    }
  }

  function handleChange(e, i) {
    let error = { state: false, message: null };

    switch (e.target.name) {
      case "leeseName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "pincode":
        if (!e.target.value.match(/^.{0,6}$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "aadharNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        else if (e.target.value.length < 12 && e.target.value.length > 0)
          error = { ...error, message: "Aadhaar number must be of 12 digit." };
        else error = { ...error, message: null };
        break;
      case "mobileNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "alternateMobile":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "bankName":
        if (e.target.value === "Not Found")
          error = { state: true, message: "Value must be Correct" };
        break;
      case "benificiaryName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "accountNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length > 17 && e.target.value.length > 0)
          error = { ...error, message: "Account can be of 17 digit only." };
        else error = { ...error, message: null };
        break;

      case "email":
        if (!e.target.value.match(/^[a-zA-Z0-9@._]*$/))
          error = { state: true, message: null };
        else if (
          !e.target.value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "Email address should be like example@gmail.com.",
          };
        else error = { ...error, message: null };
        break;
      case "panNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };
        // pattern match
        else if (
          !e.target.value.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "PAN number should be like NOOPS8327K",
          };
        else error = { ...error, message: null };
        break;
      case "gstNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };
        // pattern match
        if (!checksum(e.target.value) && e.target.value.length > 0)
          error = {
            ...error,
            message: "GST number should be like 27AAPFU0939F1ZV.",
          };
        else error = { ...error, message: null };
        break;
      case "ifscCode":
        e.target.value = e.target.value.toUpperCase();

        if (!e.target.value.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };

        break;
      default:
        break;
    }
    if (e.target.name === "ifscCode") {
      getBankDetails(e.target.value, i);
    }
    if (!error.state) {
      if (preData.landlord[i]) {
        setPreData((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: e.target.value,
              };
            }
            return row;
          }),
        }));
      } else {
        setPreData((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: e.target.value,
            },
          ],
        }));
      }
    }
  }

  async function handleCommonChange(e, i) {
    console.log(e.target.value, "common change");
    var error = { state: false };
    switch (e.target.name) {
      case "location":
        if (!e.target.value.match(/^[^@#$%^&*~<>'!?)(}{:_+=\";`%]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "address":
        if (!e.target.value.match(/^[^@#$%^&*<>'~!?)(}{:_+=\";`%]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "pincode":
        if (e.target.value !== 0 && e.target.value.length <= 6) {
          if (!e.target.value.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      case "lockInYear":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "noticePeriod":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "rental_amount":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "assets":
        if (!e.target.value.match(/^[^@#$%^&*<>'}{)(!?~:_+=";`%]*$/))
          error = { state: true, message: "Value must be correct" };
        break;
      case "area":
        if (e.target.value.length <= 5) {
          if (!e.target.value.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      case "tenure":
        if (e.target.value > 0) {
          if (!e.target.value.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          break;
        }
        break;
      case "monthlyRent":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "deposit":
        if (!e.target.value.match(/^[0-9]*$/)) error = { state: true };
        break;
      case "buh_id":
        const srmanagers = await getUserList("SR.MANAGER", e.target.value);
        setSrManagerList(srmanagers.data);
        setManagerList([]);
        setPreData((old) => ({ ...old, srm_id: 0, manager_id: 0 }));
        setFormError((old) => ({ ...old, [e.target.name]: "" }));
        break;
      case "srm_id":
        const managers = await getUserList("MANAGER", e.target.value);
        setManagerList(managers.data);
        setFormError((old) => ({ ...old, [e.target.name]: "" }));
        break;
      case "manager_id":
        setFormError((old) => ({ ...old, [e.target.name]: "" }));
        break;
      default:
        break;
    }
    if (!error.state) {
      setPreData((old) => ({ ...old, [e.target.name]: e.target.value }));
      setFormError((old) => ({ ...old, [e.target.name]: "" }));
    }
  }

  console.log({ preData });

  async function getBankDetails(data, i) {
    try {
      let res = await getBankName(data);

      if (res.status === 200) {
        setPreData((old) => ({
          ...old,
          landlord: old.landlord.map((row, index) => {
            if (index === i) {
              return {
                ...row,
                bankName: res.data.BANK,
                branchName: res.data.BRANCH,
              };
            } else return row;
          }),
        }));
      }
    } catch (err) {
      setPreData((old) => ({
        ...old,
        landlord: old.landlord.map((row, index) => {
          if (index === i) {
            return { ...row, bankName: "Not Found", branchName: "" };
          } else return row;
        }),
      }));
    }
  }

  const handleConfirm = () => {
    if (
      remark.length <= 0 &&
      preData.type === "Migrated" &&
      (preData.status === "Sent To Operations" ||
        preData.status === "Sent To Finance Team")
    ) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required.",
        })
      );
    } else {
      setOpen(false);

      const {
        id,
        code,
        area,
        lockInYear,
        monthlyRent,
        noticePeriod,
        yearlyIncrement,
        deposit,
        gst_certificate,
        draft_agreement,
        electricity_bill,
        poa,
        maintaince_bill,
        tax_receipt,
        noc,
        tenure,
        pincode,
        state,
        address,
        location,
        city,
        assets,
        final_agreement_date,
        property_pic,
        rent_start_date,
        buh_id,
        srm_id,
        manager_id,
        type,
        final_agreement,
      } = preData;

      let { landlord } = preData;
      landlord.length > 0 &&
        landlord.map((obj) => {
          if (!obj.email) {
            return (obj["email"] = "dummy@gmail.com");
          }
        });
      APICall(
        {
          modify_date: new Date(),
          assets,
          pincode,
          state,
          address,
          location,
          city,
          area,
          id,
          code,
          lockInYear,
          monthlyRent,
          noticePeriod,
          yearlyIncrement: tenure <= 12 ? "" : yearlyIncrement,
          deposit,
          gst_certificate,
          draft_agreement: type == "Migrated" ? "" : draft_agreement,
          final_agreement: type == "Migrated" ? final_agreement : "",
          electricity_bill,
          property_pic,
          poa,
          maintaince_bill,
          tax_receipt,
          noc,
          tenure,
          ...increment,
          landlord,
          status:
            preData.type === "Migrated" && preData.status === "Draft"
              ? "Sent To Operations"
              : preData.type === "Migrated" &&
                preData.status === "Sent To Finance Team"
              ? "Approved"
              : preData.type === "Migrated" &&
                preData.status === "Sent To Operations"
              ? "Sent To Finance Team"
              : preData.status === "Sent Back From Sr Manager Termination" ||
                preData.status === "Sent Back From BUH Termination" ||
                preData.status === "Sent Back From Operations Termination" ||
                preData.status === "Sent Back From Finance Team Termination"
              ? "Terminated By Manager"
              : (op_id === 0 && buh_id !== 0) ||
                preData.status === "Sent Back From Operations"
              ? "Sent To Operations"
              : "Sent To Sr Manager",

          remark: remark,
          op_id:
            auth.isAuth && auth.role.includes("Operations") ? auth.id : op_id,
          buh_id,
          srm_id,
          manager_id,
          finance_id:
            auth.role.includes("Finance") && auth.isAuth ? auth.id : null,
          final_agreement_date,
          rent_start_date,
          percentage1: tenure <= 12 ? "" : yearValue.year1,
          percentage2: tenure <= 12 ? "" : yearValue.year2,
          percentage3: tenure <= 12 ? "" : yearValue.year3,
          percentage4: tenure <= 12 ? "" : yearValue.year4,
          percentage5: tenure <= 12 ? "" : yearValue.year5,
          percentage6: tenure <= 12 ? "" : yearValue.year6,
          percentage7: tenure <= 12 ? "" : yearValue.year7,
          percentage8: tenure <= 12 ? "" : yearValue.year8,
          percentage9: tenure <= 12 ? "" : yearValue.year9,
          percentage10: tenure <= 12 ? "" : yearValue.year10,
          event: true,
        },
        landlord
      );
    }
  };
  const [expand, setExpand] = useState(0);
  const APICall = async (values, landlordData) => {
    if (preData.type === "Migrated" && preData.status !== "Draft") {
      const subType = "agreement";
      const payloadData = {
        status: "Sent To Finance Team",
        op_id: auth.id,
        additionalRemark: {
          remarks: remark,
          step_name: auth.name,
          user_id:
            auth.name.includes("Finance") || auth.name.includes("Operations")
              ? auth.id
              : "",
          agreement_code: preData.code,
          agreement_id: preData.id,
        },
      };
      const response = auth.name.includes("Finance")
        ? ApprovedByFinance(payloadData, id, auth.role, subType)
        : await send_to_bhu(payloadData, id, auth.role, subType);
      if (response) {
        const agreement = editAgreement(values);
        const result1 = await create_landlord_id(preData.id, auth.id);

        if (result1.status) {
          dispatch(
            setAlert({
              open: true,
              variant: "success",
              message:
                values.status === "Draft"
                  ? "Draft saved successfully"
                  : "Agrement Edited & Submited Successfully",
            })
          );
          navigate(-1);
        }
      }
    } else if (preData.type === "Migrated" && preData.status === "Draft") {
      const agreement = await editAgreement(values);
      const result1 = await create_landlord_id(preData.id, auth.id);
      // const result1 = await create_landlord_id(preData.id, auth.id);

      if (agreement.status) {
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message:
              values.status === "Draft"
                ? "Draft saved successfully"
                : "Agrement Edited & Submited Successfully",
          })
        );
        navigate(-1);
      }
    } else {
      const agreement = await editAgreement(values);
      const result1 = await create_landlord_id(preData.id, auth.id);
      if (preData.status === "Sent Back From Operations") {
        const notification = await notification_handler(
          "AG7M",
          preData.id,
          preData.code,
          auth.id
        );
      } else if (
        preData.status === "Draft" ||
        preData.status === "Sent Back From Finance Team" ||
        preData.status === "Sent Back From BUH" ||
        preData.status === "Sent Back From Sr Manager"
      ) {
      }
      if (agreement.status === 200) {
        preData.status !== "Sent Back From Operations" &&
          values?.event === true &&
          (await notification_handler(
            "AG1",
            preData.id,
            preData.code,
            auth.id
          ));
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message:
              values.status === "Draft"
                ? "Draft saved successfully"
                : "Agrement Edited & Submited Successfully",
          })
        );
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      setOpen(true);
    }
  }, [formError]);

  // form validation
  function validate(data) {
    if (preData.type === "Migrated") {
      return true;
    } else {
      let field = [
        "draft_agreement",
        "electricity_bill",
        "tax_receipt",
        "property_pic",
      ];
      preData.landlord.length > 1 && field.push("noc");
      if (preData.landlord.length > 0) {
        preData.landlord.map((row, i) => {
          if (row.gstNo) {
            field.push(`${`gst${i}`}`.replace(" ", ""));
          }
          field.push(`${`aadhar_card${i}`}`.replace(" ", ""));
          field.push(`${`cheque${i}`}`.replace(" ", ""));
          field.push(`${`pan_card${i}`}`.replace(" ", ""));
        });
      }

      let finalCheck = field.map((row) => {
        if (!preData[row]) {
          setFormError((old) => ({ ...old, [row]: "Document required." }));
          return true;
        } else {
          setFormError((old) => ({ ...old, [row]: "" }));

          return false;
        }
      });
      if (!finalCheck.includes(true)) {
        return true;
      } else return false;
    }
  }
  function validateFields(data) {
    let field =
      preData.tenure > 12
        ? [
            "lockInYear",
            "noticePeriod",
            "deposit",
            "monthlyRent",
            "tenure",
            "state",
            "city",
            "address",
            "pincode",
            "location",
            "area",
            "assets",
            "yearlyIncrement",
          ]
        : [
            "lockInYear",
            "noticePeriod",
            "deposit",
            "monthlyRent",
            "tenure",
            "state",
            "city",
            "address",
            "pincode",
            "location",
            "area",
            "assets",
          ];

    let migrationFields = [
      "deposit",
      "monthlyRent",
      "tenure",
      "buh_id",
      "srm_id",
      "manager_id",
      "final_agreement_date",
      "rent_start_date",
      "final_agreement",
    ];
    let dataError = [];
    if (
      preData.type !== "Migrated" &&
      data.landlord &&
      data.landlord.length > 0
    ) {
      dataError = data.landlord.map((row, i) => ({
        aadharNo: data.landlord[i].aadharNo ? false : "Field is required.",
        panNo: data.landlord[i].panNo ? false : "Field is required.",
        mobileNo: data.landlord[i].mobileNo ? false : "Field is required.",
        email: data.landlord[i].email ? false : "Field is required.",
        ifscCode: data.landlord[i].ifscCode
          ? data.landlord[i].ifscCode.length === 11
            ? false
            : "Incorrect IFSC"
          : "Field is required.",
        bankName: data.landlord[i].bankName ? false : "Field is required.",
        accountNo: data.landlord[i].accountNo ? false : "Field is required.",
        benificiaryName: data.landlord[i].benificiaryName
          ? false
          : "Field is required.",
      }));
    }

    let finalCheck = (
      preData.type === "Migrated" ? migrationFields : field
    ).map((row) => {
      if (!preData[row] || preData[row] === "") {
        setFormError((old) => ({
          ...old,
          [row]: "Field required.",
          landlord: preData.type === "Migrated" ? [] : dataError,
        }));
        return true;
      } else {
        setFormError((old) => ({
          ...old,
          [row]: "",
          landlord: preData.type === "Migrated" ? [] : dataError,
        }));
        return false;
      }
    });

    dataError.map((row, i) => {
      finalCheck.push(Object.values(row).includes("Incorrect IFSC"));
      finalCheck.push(Object.values(row).includes("Field is required."));
    });

    if (!finalCheck.includes(true)) {
      return true;
    } else return false;
  }
  const [open, setOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validData = validate(preData);
    console.log(validData, "validData");
    const validFields = validateFields(preData);
    if (Number(preData.noticePeriod) > Number(preData.tenure)) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Notice Period should be less than tenure",
        })
      );
    } else if (Number(preData.lockInYear) > Number(preData.tenure)) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Lock in month should be less than tenure",
        })
      );
    } else if (landLordDuplicateError) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Duplicate Landlord details",
        })
      );
    } else {
      setPreData((old) => ({ ...old, ...increment }));
      if (validData && validFields) {
        if (
          remark.length <= 0 &&
          preData.type === "Migrated" &&
          (preData.status === "Sent To Operations" ||
            preData.status === "Sent To Finance Team")
        ) {
          dispatch(
            setAlert({
              variant: "error",
              open: true,
              message: "Remark Required.",
            })
          );
        } else {
          setIsSubmit(true);
          setOpen(true);
        }
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Please Fill/Upload Mandatory Fields",
          })
        );
      }
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // async function handleStateSearch(e, i) {
  //   if (e.target.value.length === 6) {
  //     let response = await getLocation(e.target.value);
  //     if (response.data[0].PostOffice) {
  //       let address = response.data[0].PostOffice[0];
  //       setFormError((old) => ({
  //         ...old,
  //         pincode: "",
  //       }));
  //       return setPreData((old) => ({
  //         ...old,
  //         state: address.State,
  //         city: address.District,
  //         wrongPin: false,
  //       }));
  //     } else {
  //       setFormError((old) => ({
  //         ...old,
  //         city: "",
  //         state: "",
  //         pincode: "Invalid pin code.",
  //       }));
  //       return setPreData((old) => ({
  //         ...old,
  //         state: "",
  //         city: "",
  //         wrongPin: true,
  //       }));
  //     }
  //   } else {
  //     setFormError((old) => ({
  //       ...old,
  //       city: "",
  //       state: "",
  //       pincode: "",
  //     }));
  //     return setPreData((old) => ({
  //       ...old,
  //       state: "",
  //       city: "",
  //       wrongPin: false,
  //     }));
  //   }
  // }
  const getStateData = async (e) => {
    try {
      let fallbackResponse = await getState(e.target.value);
      console.log({ fallbackResponse });
      if (fallbackResponse?.data?.success) {
        let fallbackData = fallbackResponse.data;
        setApiErrorStatus(false);
        setFormError((old) => ({
          ...old,
        }));
        return setPreData((old) => ({
          ...old,
          state: fallbackData.state,
          city: fallbackData.city,
          wrongPin: false,
        }));
      } else {
        setApiErrorStatus(true);
        throw new Error("Both APIs failed");
      }
    } catch {
      console.log("Error in second API call");
      setApiErrorStatus(true);
      setFormError((old) => ({
        ...old,
        pincode: "Invalid Pincode",
      }));
    }
  };

  async function handleStateSearch(e) {
    if (e.target.value.length === 6) {
      try {
        let response = await getLocation(e.target.value);
        if (
          response?.data[0].Status === "Success" &&
          response?.data[0].PostOffice
        ) {
          console.log("HITTTTT33333333");
          let address = response.data[0].PostOffice[0];
          setFormError((old) => ({
            ...old,
          }));
          console.log("address", address);
          return setPreData((old) => ({
            ...old,
            state: address.State,
            city: address.District,
            wrongPin: false,
          }));
        } else {
          setFormError((old) => ({
            ...old,
            city: "",
            state: "",
            pincode: "Invalid pin code.",
          }));

          // let fallbackResponse = await getState(e.target.value);
          // console.log({ fallbackResponse });
          // if (fallbackResponse?.data?.success) {
          //   let fallbackData = fallbackResponse.data;
          //   setApiErrorStatus(false);
          //   setFormError((old) => ({
          //     ...old,
          //   }));
          //   return setPreData((old) => ({
          //     ...old,
          //     state: fallbackData.state,
          //     city: fallbackData.city,
          //     wrongPin: false,
          //   }));
          // } else {
          //   setApiErrorStatus(true);
          //   throw new Error("Both APIs failed");
          // }
        }
      } catch (error) {
        console.error(error);
        getStateData(e);

        // setFormError((old) => ({
        //   ...old,
        //   city: "",
        //   state: "",
        //   pincode: "Error retrieving data.",
        // }));
        return setPreData((old) => ({
          ...old,
          state: "",
          city: "",
          wrongPin: false,
        }));
      }
    } else {
      setFormError((old) => ({
        ...old,
        city: "",
        state: "",
        pincode: "",
      }));
      return setPreData((old) => ({
        ...old,
        state: "",
        city: "",
        wrongPin: false,
      }));
    }
  }

  function checksum(g) {
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
      g
    );
    if (regTest) {
    }
    return regTest;
  }

  function handleOnBlur(e, i) {
    let error = { state: false, message: null };

    switch (e.target.name) {
      case "leeseName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "pincode":
        const pinCodeRegex = /^[1-9][0-9]{5}$/;
        if (!e.target.value.match(pinCodeRegex)) {
          error = {
            state: true,
            message: "Please enter a valid pin code.",
          };
        } else if (data.wrongPin) {
          error = {
            state: true,
            message: "Invalid pin code.",
          };
        } else {
          error = {
            state: false,
            message: null,
          };
        }
        break;
      case "aadharNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 12 && e.target.value.length > 0)
          error = { ...error, message: "Aadhaar number must be of 12 digit." };
        else error = { ...error, message: null };
        break;
      case "mobileNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "alternateMobile":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "bankName":
        if (!e.target.value === "Not Found")
          error = { state: true, message: "Value must be Correct" };
        break;
      case "benificiaryName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "accountNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length > 17 && e.target.value.length > 0)
          error = { ...error, message: "Account can be of 17 digit only." };
        else error = { ...error, message: null };
        break;

      case "email":
        if (
          !e.target.value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "Email should be like example@gmail.com.",
          };
        else error = { ...error, message: null };
        break;
      case "panNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };
        else if (
          !e.target.value.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "PAN number should be like NOOPS8327K",
          };
        else error = { ...error, message: null };
        break;

      case "gstNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^.{0,15}$/))
          error = { state: true, message: null };
        if (!checksum(e.target.value) && e.target.value.length > 0)
          error = { ...error, message: "GST should be like 18AABCU9603R1ZM." };
        else error = { ...error, message: null };
        break;
      case "ifscCode":
        if (
          !e.target.value.match(/^[A-Z]{4}0[A-Z0-9]{6}$/) &&
          e.target.value !== ""
        ) {
          error = { ...error, message: "Incorrect IFSC" };
          setIsError(true);
        } else {
          error = { ...error, message: null };
          setIsError(false);
        }
        break;
      default:
        break;
    }

    if (error.message) {
      if (formError.landlord[i]) {
        setFormError((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: error.message,
              };
            }
            return row;
          }),
        }));
      } else {
        setFormError((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: error.message,
            },
          ],
        }));
      }
    } else {
      if (formError.landlord[i]) {
        setFormError((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: "",
              };
            }
            return row;
          }),
        }));
      } else {
        setFormError((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: "",
            },
          ],
        }));
      }
    }
  }

  async function getBankeDetails(data, i) {
    let res = await getBankName(data);
    if (res) {
      setPreData((old) => ({
        ...old,
        landlord: old.landlord.map((row, index) => {
          if (index === i) {
            return { ...row, bankName: res.data.BANK };
          } else return row;
        }),
      }));
    }
  }

  function handleHold() {
    const {
      id,
      area,
      code,
      lockInYear,
      monthlyRent,
      noticePeriod,
      yearlyIncrement,
      deposit,
      gst_certificate,
      draft_agreement,
      electricity_bill,
      poa,
      maintaince_bill,
      tax_receipt,
      noc,
      tenure,
      pincode,
      state,
      address,
      location,
      city,
      landlord,
      assets,
      property_pic,
      buh_id,
      srm_id,
      manager_id,
      migrate_id,
      final_agreement_date,
      rent_start_date,
      final_agreement,
      type,
    } = preData;

    // if (validate(preData) && validateFields(preData)) {
    APICall(
      {
        modify_date: new Date(),
        pincode,
        state,
        address,
        area,
        location,
        city,
        id,
        code,
        lockInYear,
        monthlyRent,
        noticePeriod,
        yearlyIncrement: tenure <= 12 ? "" : yearlyIncrement,
        deposit,
        gst_certificate,
        draft_agreement: type == "Migrated" ? "" : draft_agreement,
        final_agreement: type == "Migrated" ? final_agreement : "",
        electricity_bill,
        poa,
        maintaince_bill,
        tax_receipt,
        noc,
        tenure,
        ...increment,
        landlord,
        status: "Draft",
        remark: "",
        assets,
        property_pic,
        type: preData.type,
        buh_id,
        srm_id,
        manager_id,
        migrate_id,
        final_agreement_date,
        rent_start_date,
        final_agreement,
        percentage1: tenure <= 12 ? "" : yearValue.year1,
        percentage2: tenure <= 12 ? "" : yearValue.year2,
        percentage3: tenure <= 12 ? "" : yearValue.year3,
        percentage4: tenure <= 12 ? "" : yearValue.year4,
        percentage5: tenure <= 12 ? "" : yearValue.year5,
        percentage6: tenure <= 12 ? "" : yearValue.year6,
        percentage7: tenure <= 12 ? "" : yearValue.year7,
        percentage8: tenure <= 12 ? "" : yearValue.year8,
        percentage9: tenure <= 12 ? "" : yearValue.year9,
        percentage10: tenure <= 12 ? "" : yearValue.year10,
        event: false,
      },
      landlord
    );
  }

  function Docview(href, name) {
    return (
      <ImageView open={true} handleClose={() => {}} href={href} name={name} />
    );
  }

  console.log({ apiErrorStatus, error: formError.city });
  console.log(apiErrorStatus ? false : formError.city);
  console.log(preData.wrongPin);

  return (
    <>
      <PermissionAlert
        handleClose={handleCancel}
        handleConfirm={handleConfirm}
        open={open}
        message={"Please check agreement carefully before submission."}
      />
      <Stack sx={{ flexWrap: "nowrap", flexDirection: "row" }}>
        <HamburgerManager />
        <Box className="backButton">
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleBack}
            size={"large"}
          >
            <ArrowCircleLeftIcon
              sx={{ fontSize: "3rem" }}
              color="#FFFFF !important"
            />
          </IconButton>
          <Dialog open={openBack} onClose={handleCloseBack}>
            <DialogTitle>Confirmation</DialogTitle>
            <Box p={1}>
              <p>Are you sure you want to go back?</p>
              <Box display="flex" justifyContent="space-between" p={1}>
                <IconButton
                  style={{ fontSize: "16px" }}
                  onClick={() => navigate(-1)}
                  color="secondary"
                >
                  Cancel
                </IconButton>
                <IconButton
                  style={{ fontSize: "16px" }}
                  onClick={handleHold}
                  color="primary"
                  disabled={
                    preData.type === "Migrated" &&
                    (preData.status === "Sent To Operations" ||
                      preData.status === "Sent To Finance Team")
                      ? true
                      : false
                  }
                >
                  Save As Draft
                </IconButton>
              </Box>
            </Box>
          </Dialog>
        </Box>

        {preData && preData?.landlord?.length > 0 && (
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              item
              xs={12}
              sx={{ justifyContent: "space-between", display: "flex" }}
            >
              <MyHeader>
                RMS{" "}
                <span className="small-heading">
                  (Rental Management System)
                </span>
              </MyHeader>
              <Typography mt="15px" mr="15px" fontWeight="600">
                Welcome {auth.name}
              </Typography>
            </Grid>
            <Divider />
            <Grid
              container
              sx={{
                px: 1,
                justifyContent: "space-between",
                my: 1,
                alignItems: "center",
              }}
            >
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  color="black"
                  fontSize="20px"
                  fontWeight="600"
                  alignSelf="center"
                  lineHeight="30px"
                  sx={{
                    "@media(max-width:600px)": {
                      fontSize: "17px",
                      lineHeight: "25px",
                    },
                  }}
                >
                  {auth.name.toLowerCase().includes("migration")
                    ? "Edit Migrated Agreement"
                    : "Edit Agreement"}
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item xs={12} md={10}>
                {/* agreement form start here */}
                <Box
                  component="form"
                  sx={{
                    py: 5,
                    backgroundColor: "white",
                    mx: 3,
                    borderRadius: "15px",
                    maxWidth: "1050px",
                    "@media(max-width:900px)": { mx: 0 },
                  }}
                >
                  {/* Basic details start here */}
                  <Typography
                    variant="body1"
                    color="var(--main-color)"
                    fontSize="25px"
                    lineHeight="28px"
                    fontWeight="600"
                    my="20px"
                  >
                    Property Details
                  </Typography>

                  <Grid container sx={{ px: 3 }} spacing={isSmall ? 2 : 4}>
                    <TextFieldWrapper
                      label="Code"
                      placeHolder=""
                      disabled={true}
                      backgroundColor="rgba(3, 193, 243, 0.2);"
                      value={preData.code}
                      name="code"
                    />

                    <TextFieldWrapper
                      label="Pincode"
                      placeHolder="Pincode"
                      backgroundColor="rgba(3, 193, 243, 0.2);"
                      value={preData.pincode}
                      required={preData.type === "Migrated" ? false : true}
                      maxLength={6}
                      name="pincode"
                      onChange={(e) => {
                        if (e.target.value.length <= 6) {
                          handleCommonChange(e);
                          handleStateSearch(e);
                          const pincodeValue =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1);
                          const pincodeRegex = /^[0-9]*$/;

                          if (
                            pincodeValue.length === 6 &&
                            !pincodeRegex.test(pincodeValue) &&
                            data.wrongPin
                          ) {
                            setFormError((prevFormError) => ({
                              ...prevFormError,
                              pincode: "Invalid pin code.",
                            }));
                          } else {
                            setFormError((prevFormError) => ({
                              ...prevFormError,
                              pincode: null,
                            }));
                          }
                        }
                      }}
                      onBlur={(e) => {
                        if (e.target.value.length < 6) {
                          setFormError((prevFormError) => ({
                            ...prevFormError,
                            pincode: "Pin code must be 6 digits long.",
                          }));
                        } else if (data.wrongPin) {
                          setFormError((prevFormError) => ({
                            ...prevFormError,
                            pincode: "Invalid pin code.",
                          }));
                        } else {
                          // setFormError((prevFormError) => ({
                          //   ...prevFormError,
                          //   pincode: null,
                          // }));
                        }
                      }}
                      error={
                        preData.wrongPin
                          ? "Invalid pin code."
                          : formError.pincode
                      }
                      disabled={preData.type === "Renewed" ? true : false}
                    />

                    <TextFieldWrapper
                      label="State"
                      // disabled={
                      //   apiErrorStatus
                      //     ? false
                      //     : !formError?.state
                      //     ? true
                      //     : false
                      // }
                      disabled={true}
                      name="state"
                      required={preData.type === "Migrated" ? false : true}
                      value={preData.state || ""}
                      onChange={(e) => {
                        console.log(e.target.value, "testttttttttt");
                        setPreData((prev) => ({
                          ...prev,
                          state: e.target.value,
                        }));
                      }}
                      error={formError.state}
                    />
                    <TextFieldWrapper
                      label="City"
                      required={preData.type === "Migrated" ? false : true}
                      // disabled={
                      //   apiErrorStatus ? false : !formError?.city ? true : false
                      // }
                      disabled={true}
                      error={formError.city}
                      fullWidth
                      name="city"
                      value={preData.city || ""}
                      onChange={(e) => {
                        console.log(e);
                        setPreData((prev) => ({
                          ...prev,
                          city: e.target.value,
                        }));
                      }}
                    />

                    <TextFieldWrapper
                      label="Location"
                      placeHolder="Enter Location"
                      name="location"
                      required={preData.type === "Migrated" ? false : true}
                      error={formError.location}
                      value={preData.location || ""}
                      onChange={handleCommonChange}
                      index={i}
                      disabled={preData.type === "Renewed" ? true : false}
                    />
                    <TextFieldWrapper
                      label="Area"
                      placeHolder="Area in sq. ft"
                      name="area"
                      maxLength={5}
                      notationVal="sq. ft"
                      textAlignRight={"textAlignRight"}
                      error={
                        preData?.areaInvalid
                          ? "Value must be greater than zero ! "
                          : formError.area
                      }
                      required={preData.type === "Migrated" ? false : true}
                      value={preData.area}
                      onChange={(e) => {
                        handleCommonChange(e);
                        const areaValue = e.target.value;

                        if (areaValue === 0) {
                          setFormError((prevFormError) => ({
                            ...prevFormError,
                            area: "Value must be greater than zero !",
                          }));
                          setPreData((previousdata) => ({
                            ...previousdata,
                            areaInvalid: true,
                          }));
                        } else {
                          setFormError((prevFormError) => ({
                            ...prevFormError,
                            area: null,
                          }));
                          setPreData((previousdata) => ({
                            ...previousdata,
                            areaInvalid: false,
                          }));
                        }
                      }}
                      index={i}
                    />

                    <TextFieldWrapper
                      label="Address"
                      placeHolder="Enter Address"
                      error={formError.address}
                      required={preData.type === "Migrated" ? false : true}
                      name="address"
                      value={preData.address}
                      onChange={handleCommonChange}
                      index={i}
                      disabled={preData.type === "Renewed" ? true : false}
                    />
                    <TextFieldWrapper
                      label="Deposit Amount"
                      required={true}
                      placeHolder="Enter deposit Amount"
                      name="deposit"
                      textAlignRight={"textAlignRight"}
                      error={
                        preData.depositInvalid
                          ? "Value must be greater than zero !"
                          : formError.deposit
                      }
                      value={preData.deposit}
                      maxLength={8}
                      onChange={handleCommonChange}
                      disabled={
                        preData.type === "Migrated"
                          ? false
                          : buh_id !== 0
                          ? true
                          : false
                      }
                    />
                    <TextFieldWrapper
                      label="Monthly Rental"
                      placeHolder="Enter Rental"
                      required={true}
                      name="monthlyRent"
                      maxLength={8}
                      textAlignRight={"textAlignRight"}
                      disabled={
                        preData.type === "Migrated"
                          ? false
                          : buh_id !== 0
                          ? true
                          : false
                      }
                      error={formError.monthlyRent}
                      value={preData.monthlyRent}
                      onChange={(e) => {
                        handleCommonChange(e);
                        const rentalValue = e.target.value;
                        if (rentalValue === 0) {
                          setFormError((prevFormError) => ({
                            ...prevFormError,
                            monthlyRent: "Value must be greater than zero !",
                          }));
                          setPreData((previousdata) => ({
                            ...previousdata,
                            monthlyRentalInvalid: true,
                          }));
                        } else {
                          setFormError((prevFormError) => ({
                            ...prevFormError,
                            monthlyRent: null,
                          }));
                          setPreData((previousdata) => ({
                            ...previousdata,
                            monthlyRentalInvalid: false,
                          }));
                        }
                      }}
                    />

                    <TextFieldWrapper
                      label="Agreement Tenure"
                      placeHolder="Tenure In Months"
                      name="tenure"
                      notationVal="Month's"
                      textAlignRight={"textAlignRight"}
                      error={
                        preData.tenureInvalid
                          ? "Value must be greater than zero !"
                          : formError.tenure
                      }
                      required={true}
                      value={preData.tenure || ""}
                      onChange={(e) => {
                        if (e.target.value <= 120) {
                          handleCommonChange(e);
                          const rentalValue = e.target.value;

                          if (rentalValue === 0) {
                            setFormError((prevFormError) => ({
                              ...prevFormError,
                              tenure: "Value must be greater than zero !",
                            }));
                            setPreData((previousdata) => ({
                              ...previousdata,
                              tenureInvalid: true,
                            }));
                          } else {
                            setFormError((prevFormError) => ({
                              ...prevFormError,
                              tenure: null,
                            }));
                            setPreData((previousdata) => ({
                              ...previousdata,
                              tenureInvalid: false,
                            }));
                          }
                        }
                      }}
                      index={i}
                      maxLength={3}
                      style={{
                        WebkitAppearance: "textfield",
                        MozAppearance: "textfield",
                        appearance: "textfield",
                      }}
                      type="text"
                      onKeyPress={(e) => {
                        const charCode = e.which ? e.which : e.keyCode;
                        if (charCode < 48 || charCode > 57) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        const pastedText =
                          e.clipboardData.getData("text/plain");
                        if (!/^\d*$/.test(pastedText)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <TextFieldWrapper
                      label="Lock In Months"
                      placeHolder="Enter Lock in Months"
                      name="lockInYear"
                      maxLength={2}
                      required={preData.type === "Migrated" ? false : true}
                      type="text"
                      className="no-spinner"
                      value={preData.lockInYear}
                      error={
                        Number(preData.lockInYear) > Number(preData.tenure)
                          ? "Lock in months should be less than tenure"
                          : formError.lockInYear
                      }
                      onChange={(e) => handleCommonChange(e)}
                      style={{
                        MozAppearance: "textfield",
                        appearance: "textfield",
                      }}
                      onInput={(e) => {
                        const inputValue = e.target.value;
                        e.target.value = inputValue.replace(/[^0-9]/g, "");
                      }}
                    />
                    <TextFieldWrapper
                      label="Notice Period In Months"
                      placeHolder="Enter Notice Period"
                      required={preData.type === "Migrated" ? false : true}
                      maxLength={2}
                      name="noticePeriod"
                      type="text"
                      onKeyPress={(e) => {
                        const charCode = e.which ? e.which : e.keyCode;
                        if (charCode < 48 || charCode > 57) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        const pastedText =
                          e.clipboardData.getData("text/plain");
                        if (!/^\d*$/.test(pastedText)) {
                          e.preventDefault();
                        }
                      }}
                      value={preData.noticePeriod}
                      error={
                        Number(preData.noticePeriod) > Number(preData.tenure)
                          ? "Notice Period should be less than tenure"
                          : formError.noticePeriod
                      }
                      style={{
                        MozAppearance: "textfield",
                        appearance: "textfield",
                      }}
                      onChange={(e) => handleCommonChange(e)}
                      onInput={(e) => {
                        const inputValue = e.target.value;
                        e.target.value = inputValue.replace(/[^0-9]/g, "");
                      }}
                    />

                    {preData.tenure === "" ? null : preData.tenure <=
                      12 ? null : (
                      <SelectComponent
                        label={"Yearly Increment *"}
                        required={true}
                        name="yearlyIncrement"
                        options={["Percentage", "Value"]}
                        value={preData.yearlyIncrement}
                        error={formError.yearlyIncrement}
                        onChange={(e) => {
                          handleCommonChange(e);
                          setYearlyIncreentChanged(true);
                        }}
                        disabled={
                          preData.type === "Migrated" ||
                          preData.type === "New" ||
                          preData.type === "Renew"
                            ? false
                            : buh_id !== 0
                            ? true
                            : false
                        }
                      />
                    )}

                    {preData.type === "Migrated" && (
                      <>
                        {" "}
                        <SelectUser
                          label={"Business Unit Head *"}
                          required={true}
                          name="buh_id"
                          options={BUHUsers}
                          value={preData.buh_id}
                          onChange={handleCommonChange}
                          error={formError.buh_id}
                        />
                        <SelectUser
                          label={"Senior Manager *"}
                          required={true}
                          name="srm_id"
                          value={preData.srm_id}
                          options={SrManagerList}
                          onChange={handleCommonChange}
                          error={formError.srm_id}
                        />
                        <SelectUser
                          label={"Manager *"}
                          required={true}
                          name="manager_id"
                          value={preData.manager_id}
                          options={ManagerList}
                          onChange={handleCommonChange}
                          error={formError.manager_id}
                        />
                      </>
                    )}
                  </Grid>

                  {/* basic details end here */}

                  {/* Increment Yearly */}
                  <YearlyIncrement
                    yearlyIncreentChanged={yearlyIncreentChanged}
                    yearValue={yearValue}
                    setYearValue={setYearValue}
                    tenure={preData.tenure || ""}
                    value={preData.yearlyIncrement || ""}
                    rent={preData.monthlyRent || ""}
                    increment={increment}
                    setIncrement={setIncrement}
                    monthlyRent={preData.monthlyRent || ""}
                    disabled={
                      preData.type === "Migrated" ||
                      preData.type === "New" ||
                      preData.type === "Renew"
                        ? false
                        : buh_id !== 0
                        ? true
                        : false
                    }
                  />

                  {/* landlord Details start here*/}
                  <Typography
                    variant="body1"
                    color="var(--main-color)"
                    fontSize="25px"
                    lineHeight="28px"
                    fontWeight="600"
                    my="20px"
                  >
                    Landlord Details
                  </Typography>

                  {preData.landlord.length > 0 &&
                    preData.landlord.map((_, i) => (
                      <>
                        {preData.landlord.length > 0 && (
                          <Box
                            mb={2}
                            size="small"
                            fullWidth
                            variant="outlined"
                            component={Button}
                            onClick={() => setExpand(expand === i ? -1 : i)}
                            sx={{
                              color: "black",
                              justifyContent: "space-between",
                              backgroundColor: "#b0d6f773",
                            }}
                          >
                            <Typography color={"var( --main-color)"}>
                              {" "}
                              {preData.landlord.length > 0
                                ? preData.landlord[i].leeseName
                                : ""}{" "}
                              Personal Details
                            </Typography>
                            <IconButton
                              onClick={() => setExpand(expand === i ? -1 : i)}
                            >
                              {expand === i ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </Box>
                        )}
                        <Collapse
                          in={expand === i}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Grid
                            container
                            sx={{ px: 3, mb: "25px" }}
                            spacing={isSmall ? 2 : 4}
                          >
                            <TextFieldWrapper
                              label="Name of Lessor"
                              placeHolder="Enter Name of Lessor"
                              required={
                                preData.type === "Migrated" ? false : true
                              }
                              disabled={false}
                              onBlur={(e) => handleOnBlur(e, i)}
                              name="name"
                              value={preData.landlord[i].name}
                              onChange={(e) => handleChange(e, i)}
                            />
                            <TextFieldWrapper
                              label="Aadhaar Number"
                              placeHolder="Enter Aadhaar No."
                              required={
                                preData.type === "Migrated" ? false : true
                              }
                              onBlur={(e) => handleOnBlur(e, i)}
                              name="aadharNo"
                              maxLength={12}
                              value={preData.landlord[i].aadharNo}
                              onChange={(e) => handleChange(e, i)}
                              index={i}
                              error={
                                duplicateAadhaarNoss.length > 0 &&
                                preData.landlord[i].aadharNo &&
                                preData.landlord[i].aadharNo.length === 12 &&
                                duplicateAadhaarNoss.includes(
                                  preData.landlord[i].aadharNo
                                )
                                  ? "Duplicate Aadhar"
                                  : formError.landlord[i] &&
                                    formError.landlord[i].aadharNo
                                  ? formError.landlord[i].aadharNo
                                  : ""
                              }
                              disabled={
                                preData.type === "Renewed" ? true : false
                              }
                            />
                            <TextFieldWrapper
                              label="PAN Number"
                              required={
                                preData.type === "Migrated" ? false : true
                              }
                              placeHolder="Enter PAN No."
                              onBlur={(e) => handleOnBlur(e, i)}
                              error={
                                duplicatePanrNoss.length > 0 &&
                                preData.landlord[i].panNo &&
                                preData.landlord[i].panNo.length === 10 &&
                                duplicatePanrNoss.includes(
                                  preData.landlord[i].panNo
                                )
                                  ? "Duplicate Pan"
                                  : formError.landlord[i] &&
                                    formError.landlord[i].panNo
                                  ? formError.landlord[i].panNo
                                  : ""
                              }
                              name="panNo"
                              maxLength={10}
                              value={preData.landlord[i].panNo}
                              onChange={(e) => handleChange(e, i)}
                              index={i}
                              disabled={
                                preData.type === "Renewed" ? true : false
                              }
                            />

                            <TextFieldWrapper
                              label="Mobile Number"
                              placeHolder="Enter Mobile No."
                              required={
                                preData.type === "Migrated" ? false : true
                              }
                              onBlur={(e) => handleOnBlur(e, i)}
                              name="mobileNo"
                              maxLength={10}
                              error={
                                duplicateMobileNoss.length > 0 &&
                                preData.landlord[i].mobileNo &&
                                preData.landlord[i].mobileNo.length === 10 &&
                                duplicateMobileNoss.includes(
                                  preData.landlord[i].mobileNo
                                )
                                  ? "Duplicate Mobile no"
                                  : formError.landlord[i] &&
                                    formError.landlord[i].mobileNo
                                  ? formError.landlord[i].mobileNo
                                  : ""
                              }
                              value={preData.landlord[i].mobileNo}
                              onChange={(e) => handleChange(e, i)}
                              index={i}
                            />
                            <TextFieldWrapper
                              label="Alternate Number"
                              placeHolder="Enter Alternate No."
                              name="alternateMobile"
                              onBlur={(e) => handleOnBlur(e, i)}
                              maxLength={10}
                              value={preData.landlord[i].alternateMobile}
                              onChange={(e) => handleChange(e, i)}
                              index={i}
                            />

                            <TextFieldWrapper
                              label="Email"
                              placeHolder="Enter Email"
                              onBlur={(e) => handleOnBlur(e, i)}
                              required={
                                preData.type === "Migrated" ? false : true
                              }
                              error={
                                formError.landlord[i] &&
                                formError.landlord[i].email
                                  ? formError.landlord[i].email
                                  : ""
                              }
                              name="email"
                              value={preData.landlord[i].email}
                              onChange={(e) => handleChange(e, i)}
                              index={i}
                            />
                            <TextFieldWrapper
                              label="GST Number"
                              placeHolder="Enter GST No."
                              onBlur={(e) => handleOnBlur(e, i)}
                              error={
                                formError.landlord[i] &&
                                formError.landlord[i].gstNo
                                  ? formError.landlord[i].gstNo
                                  : ""
                              }
                              name="gstNo"
                              maxLength={15}
                              value={preData.landlord[i].gstNo}
                              onChange={(e) => handleChange(e, i)}
                            />
                            <TextFieldWrapper
                              required={
                                preData.type === "Migrated" ? false : true
                              }
                              label="Bank IFSC"
                              placeHolder="Enter IFSC Code"
                              onBlur={(e) => handleOnBlur(e, i)}
                              name="ifscCode"
                              error={
                                formError.landlord[i] &&
                                formError.landlord[i].ifscCode
                                  ? formError.landlord[i].ifscCode
                                  : ""
                              }
                              value={preData.landlord[i].ifscCode}
                              onChange={(e) => handleChange(e, i)}
                              maxLength={11}
                            />

                            <TextFieldWrapper
                              label="Bank Name"
                              placeHolder="Enter Bank Name"
                              name="bankName"
                              onBlur={(e) => handleOnBlur(e, i)}
                              partLabel={
                                preData.landlord[i] &&
                                preData.landlord[i].branchName
                                  ? preData.landlord[i].branchName
                                  : ""
                              }
                              error={
                                preData.landlord[i] &&
                                preData.landlord[i].bankName === "Not Found"
                                  ? "Bank Name not found."
                                  : ""
                              }
                              required={
                                preData.type === "Migrated" ? false : true
                              }
                              disabled={true}
                              value={preData.landlord[i].bankName}
                              onChange={(e) => handleChange(e, i)}
                            />

                            <TextFieldWrapper
                              required={
                                preData.type === "Migrated" ? false : true
                              }
                              label="Beneficiary Name"
                              onBlur={(e) => handleOnBlur(e, i)}
                              error={
                                formError.landlord[i] &&
                                formError.landlord[i].benificiaryName
                                  ? formError.landlord[i].benificiaryName
                                  : ""
                              }
                              placeHolder="Enter Beneficiary Name"
                              name="benificiaryName"
                              value={preData.landlord[i].benificiaryName}
                              onChange={(e) => handleChange(e, i)}
                              maxLength={50}
                            />
                            <TextFieldWrapper
                              label="Bank A/c Number "
                              required={
                                preData.type === "Migrated" ? false : true
                              }
                              placeHolder="Enter Account No."
                              name="accountNo"
                              maxLength={17}
                              error={
                                formError.landlord[i] &&
                                formError.landlord[i].accountNo
                                  ? formError.landlord[i].accountNo
                                  : ""
                              }
                              value={preData.landlord[i].accountNo}
                              onChange={(e) => handleChange(e, i)}
                              onBlur={(e) => handleOnBlur(e, i)}
                            />
                          </Grid>
                        </Collapse>
                      </>
                    ))}

                  {/* Bank Details ends here*/}

                  {/* Document */}
                  <Typography
                    variant="body1"
                    color="var(--main-color)"
                    fontSize="25px"
                    lineHeight="28px"
                    fontWeight="600"
                    my="20px"
                  >
                    {preData.type === "Migrated"
                      ? "Upload Document"
                      : "Upload Document *"}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="var(--main-color)"
                    fontSize="13px"
                    lineHeight="5px"
                    fontWeight="400"
                    my="20px"
                  >
                    ( Upload file in png, jpeg, jpg, pdf format & maximum file
                    size limit less than 20 MB )
                  </Typography>

                  {preData.landlord.length > 0 &&
                    formError.landlord.length > 0 &&
                    preData.landlord.map((_, i) => (
                      <>
                        {preData.landlord.length > 0 && (
                          <Box
                            mb={2}
                            size="small"
                            fullWidth
                            variant="outlined"
                            component={Button}
                            onClick={() =>
                              setDocExpand(docExpand === i ? -1 : i)
                            }
                            sx={{
                              color: "black",
                              justifyContent: "space-between",
                              backgroundColor: "#b0d6f773",
                            }}
                          >
                            <Typography color={"var( --main-color)"}>
                              {" "}
                              {preData.landlord.length > 0
                                ? preData.landlord[i].leeseName
                                : ""}{" "}
                              {preData.type === "Migrated"
                                ? "Upload Document"
                                : "Upload Document *"}
                            </Typography>
                            <IconButton
                              onClick={() =>
                                setDocExpand(docExpand === i ? -1 : i)
                              }
                            >
                              {docExpand === i ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </Box>
                        )}
                        <Collapse
                          in={docExpand === i}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Grid
                            container
                            spacing={isSmall ? 2 : 4}
                            sx={{ px: 1, justifyContent: "", mb: 3 }}
                          >
                            <Grid item xs={6}>
                              <DocumentUpload
                                uploaded={
                                  preData[`aadhar_card${i}`] ||
                                  preData.landlord[i]["aadhar_card"]
                                    ? true
                                    : false
                                }
                                label="Upload Aadhaar Card"
                                placeHolder="Upload Aadhaar Card"
                                handleChange={(e) =>
                                  handleChangeCommonFile(e, i)
                                }
                                name={"aadhar_card"}
                                fileName={
                                  preData.landlord[i].aadhar_card
                                    ? preData.landlord[i].aadhar_card
                                        .split("/")
                                        .pop()
                                    : ""
                                }
                                href={preData.landlord[i].aadhar_card}
                                error={
                                  preData.type === "Migrated"
                                    ? false
                                    : formError[`aadhar_card${i}`]
                                }
                                disabled={
                                  preData.type === "Renewed" ? true : false
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DocumentUpload
                                label="Upload PAN Card"
                                uploaded={
                                  preData[`pan_card${i}`] ||
                                  preData.landlord[i]["pan_card"]
                                    ? true
                                    : false
                                }
                                name={"pan_card"}
                                fileName={
                                  preData.landlord[i].pan_card != null
                                    ? preData.landlord[i].pan_card
                                        .split("/")
                                        .pop()
                                    : ""
                                }
                                placeHolder={"Upload PAN Card"}
                                handleChange={(e) =>
                                  handleChangeCommonFile(e, i)
                                }
                                href={preData.landlord[i].pan_card}
                                disabled={
                                  preData.type === "Renewed" ? true : false
                                }
                                error={
                                  preData.type === "Migrated"
                                    ? false
                                    : formError[`pan_card${i}`]
                                }
                              />
                            </Grid>

                            {preData.landlord[i].gstNo && (
                              <Grid item xs={6}>
                                <DocumentUpload
                                  uploaded={
                                    preData[`gst${i}`] ||
                                    preData.landlord[i]["gst"]
                                      ? true
                                      : false
                                  }
                                  label="Upload GST Certificate"
                                  placeHolder="Upload GST Certificate"
                                  handleChange={(e) =>
                                    handleChangeCommonFile(e, i)
                                  }
                                  name={"gst"}
                                  fileName={
                                    preData.landlord[i].gst != null
                                      ? preData.landlord[i].gst.split("/").pop()
                                      : ""
                                  }
                                  href={preData.landlord[i].gst}
                                  error={
                                    preData.type === "Migrated"
                                      ? false
                                      : formError[`gst${i}`]
                                  }
                                />
                              </Grid>
                            )}

                            <Grid item xs={6}>
                              <DocumentUpload
                                uploaded={
                                  preData[`cheque${i}`] ||
                                  preData.landlord[i]["cheque"]
                                    ? true
                                    : false
                                }
                                error={
                                  preData.type === "Migrated"
                                    ? false
                                    : formError[`cheque${i}`]
                                }
                                label="Upload Cancel Cheque"
                                name={"cheque"}
                                fileName={
                                  preData.landlord[i].cheque != null
                                    ? preData.landlord[i].cheque
                                        .split("/")
                                        .pop()
                                    : ""
                                }
                                placeHolder="Upload Cancel Cheque"
                                handleChange={(e) =>
                                  handleChangeCommonFile(e, i)
                                }
                                href={preData.landlord[i].cheque}
                              />
                            </Grid>
                          </Grid>
                        </Collapse>
                      </>
                    ))}

                  {landblord.length > 0 ? (
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="25px"
                      lineHeight="28px"
                      fontWeight="600"
                      my="20px"
                    >
                      Upload Document
                    </Typography>
                  ) : (
                    ""
                  )}
                  <Typography
                    variant="body1"
                    color="var(--main-color)"
                    fontSize="13px"
                    lineHeight="5px"
                    fontWeight="400"
                    my="20px"
                  >
                    ( Upload file in png, jpeg, jpg, pdf format & maximum file
                    size limit less than 20 MB )
                  </Typography>

                  <Grid
                    container
                    spacing={isSmall ? 2 : 4}
                    sx={{ px: 1, justifyContent: "" }}
                  >
                    <Grid item xs={6}>
                      <DocumentUpload
                        label={
                          preData.type === "Migrated"
                            ? "Upload Final Agreement *"
                            : "Upload Draft Agreement *"
                        }
                        uploaded={
                          (preData.draft_agreement && true) ||
                          preData?.final_agreement
                        }
                        error={
                          preData.type === "Migrated"
                            ? formError?.final_agreement
                            : formError.draft_agreement
                        }
                        placeHolder={
                          preData.type === "Migrated"
                            ? "Upload Final Agreement"
                            : "Upload Draft Agreement"
                        }
                        fileName={
                          preData.type === "Migrated"
                            ? preData?.final_agreement_name
                            : preData?.draft_agreement_name
                        }
                        handleChange={handleChangeFile}
                        name={
                          preData.type === "Migrated"
                            ? "final_agreement"
                            : "draft_agreement"
                        }
                        href={
                          preData.type === "Migrated"
                            ? preData?.final_agreement
                            : preData?.draft_agreement
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DocumentUpload
                        label={`Upload Electricity Bill ${
                          preData.type === "Migrated" ? "" : "*"
                        }`}
                        uploaded={preData.electricity_bill && true}
                        error={
                          preData.type === "Migrated"
                            ? false
                            : formError.electricity_bill
                        }
                        placeHolder={"Upload Electricity Bill"}
                        handleChange={handleChangeFile}
                        fileName={preData.electricity_bill_name}
                        name={"electricity_bill"}
                        href={preData.electricity_bill}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DocumentUpload
                        label="Upload POA"
                        placeHolder="Upload POA"
                        error={
                          preData.type === "Migrated" ? false : formError.poa
                        }
                        uploaded={preData.poa && true}
                        fileName={preData.poa_name}
                        handleChange={handleChangeFile}
                        name={"poa"}
                        href={preData.poa}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DocumentUpload
                        label="Upload Maintenance Bill"
                        uploaded={preData.maintaince_bill && true}
                        placeHolder={"Upload Maintaince Bill"}
                        error={
                          preData.type === "Migrated"
                            ? false
                            : formError.maintaince_bill
                        }
                        handleChange={handleChangeFile}
                        fileName={preData.maintaince_bill_name}
                        name={"maintaince_bill"}
                        href={preData.maintaince_bill}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <DocumentUpload
                        label={`Upload Property Tax Receipt  ${
                          preData.type === "Migrated" ? "" : "*"
                        } `}
                        uploaded={preData.tax_receipt && true}
                        placeHolder={"Upload Property Tax Receipt"}
                        handleChange={handleChangeFile}
                        fileName={preData.tax_receipt_name}
                        error={
                          preData.type === "Migrated"
                            ? false
                            : formError.tax_receipt
                        }
                        name={"tax_receipt"}
                        href={preData.tax_receipt}
                      />
                    </Grid>
                    {preData.landlord.length > 1 && (
                      <Grid item xs={6}>
                        <DocumentUpload
                          uploaded={preData.noc && true}
                          label="Upload NOC (If Mutiple Oweners) *"
                          error={formError.noc}
                          placeHolder="NOC"
                          fileName={
                            preData.noc_name
                              ? preData.noc_name
                              : preData.noc != null
                              ? preData.noc.split("/").pop()
                              : ""
                          }
                          handleChange={handleChangeFile}
                          name={"noc"}
                          href={preData.noc}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <DocumentUpload
                        label={`Upload Property Picture  ${
                          preData.type === "Migrated" ? "" : "*"
                        }`}
                        uploaded={preData.property_pic && true}
                        placeHolder={"Upload Property Picture"}
                        handleChange={handleChangeFile}
                        fileName={preData.property_pic_name}
                        error={formError.property_pic}
                        name={"property_pic"}
                        href={preData.property_pic}
                      />
                    </Grid>

                    {preData.type === "Migrated" && (
                      <>
                        {" "}
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <FormLabel>
                              <Typography variant="body1" sx={labelStyle}>
                                Agreement Date *
                              </Typography>
                            </FormLabel>
                            <input
                              type="date"
                              name="final_agreement_date"
                              value={preData.final_agreement_date}
                              className="DatePicker"
                              onChange={(e) => handleCommonChange(e)}
                            />
                            <Typography variant="caption" sx={{ color: "red" }}>
                              {formError.final_agreement_date}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <FormLabel>
                              <Typography variant="body1" sx={labelStyle}>
                                Monthly Rent Start Date *
                              </Typography>
                            </FormLabel>
                            <input
                              type="date"
                              name="rent_start_date"
                              value={preData?.rent_start_date}
                              disabled={
                                preData?.final_agreement_date === "" && true
                              }
                              className="DatePicker"
                              onChange={(e) => {
                                handleCommonChange(e);
                              }}
                            />
                            <Typography variant="caption" sx={{ color: "red" }}>
                              {formError.rent_start_date}
                            </Typography>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={10}
                    sx={{ mt: 5 }}
                    className={"textFieldWrapper"}
                  >
                    <Grid item xs={8}>
                      <TextField
                        type="text"
                        multiline
                        rows={3}
                        fullWidth
                        variant="outlined"
                        label="Landlord Assets "
                        placeholder="Landlord Assets "
                        value={preData.assets}
                        name={"assets"}
                        onChange={handleCommonChange}
                        error={formError.assets ? true : false}
                      />
                    </Grid>
                    <Typography sx={{ color: "red" }} variant="caption">
                      {formError.assets}
                    </Typography>
                  </Grid>
                  {/* Document upload section end here */}

                  {allRemarks?.length > 0 && (
                    <>
                      <Grid
                        item
                        container
                        sx={{ alignItems: "baseline", mt: 5 }}
                        xs={10}
                      >
                        <DataFieldStyle field={"Remark"} />
                        {allRemarks.map((remark, index) => (
                          <div
                            style={{
                              width: "100%",
                              padding: "10px 10px 10px 0",
                            }}
                            key={index}
                          >
                            {remark}
                          </div>
                        ))}
                      </Grid>
                    </>
                  )}

                  {/* Button Start from here */}
                  {preData.type === "Migrated" &&
                    (preData.status === "Sent To Operations" ||
                      preData.status === "Sent To Finance Team") && (
                      <Grid
                        item
                        xs={10}
                        sx={{ mt: 5, gap: "2rem" }}
                        className={"textFieldWrapper"}
                      >
                        <Grid item xs={8}>
                          <TextField
                            type="text"
                            name={"remark"}
                            multiline
                            rows={3}
                            fullWidth
                            variant="outlined"
                            label={
                              preData.type === "migrated"
                                ? "Remark"
                                : "Remark *"
                            }
                            placeholder={
                              preData.type === "migrated"
                                ? "Remark"
                                : "Remark *"
                            }
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    )}

                  <Grid
                    container
                    sx={{ justifyContent: "center", mt: 2 }}
                    spacing={4}
                  >
                    <Grid item md={4} xs={6}>
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        type="submit"
                        color="primary"
                        sx={{
                          height: "60px",
                          width: "100%",
                          borderRadius: "20px",
                          fontSize: "16px",
                          color: "#FFFFFF",
                          lineHeight: "32px",
                          textTransform: "capitalize",
                          "@media(max-width:900px)": {
                            fontSize: "11px",
                            lineHeight: "12px",
                            height: "40px",
                          },
                        }}
                      >
                        {preData.type === "Migrated" &&
                        preData.status === "Draft"
                          ? "Send To Operations"
                          : preData.type === "Migrated" &&
                            preData.status === "Sent To Finance Team"
                          ? "Approve"
                          : preData.type === "Migrated" &&
                            preData.status === "Sent To Operations"
                          ? "Send To Finance"
                          : preData.status ===
                              "Sent Back From Sr Manager Termination" ||
                            preData.status ===
                              "Sent Back From Operations Termination" ||
                            preData.status ===
                              "Sent Back From Finance Team Termination"
                          ? "Sent To Sr Manager Termination"
                          : (op_id === 0 && buh_id !== 0) ||
                            preData.status === "Sent Back From Operations"
                          ? "Send To Operations"
                          : "Send To Sr Manager"}
                      </Button>
                    </Grid>

                    {((preData.type === "New" && preData.status === "Draft") ||
                      (preData.type === "Migrated" &&
                        preData.status === "Draft") ||
                      (preData.type === "Migrated" &&
                        preData.status !== "Sent To Operations") ||
                      (preData.type === "Migrated" &&
                        preData.status !== "Sent To Finance Team")) && (
                      <Grid item md={4} xs={6}>
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{
                            height: "60px",
                            width: "100%",
                            borderRadius: "20px",
                            fontSize: "16px",
                            lineHeight: "32px",
                            textTransform: "capitalize",
                            "@media(max-width:900px)": {
                              fontSize: "10px",
                              lineHeight: "20px",
                              height: "40px",
                            },
                          }}
                          onClick={handleHold}
                          disabled={
                            preData.type === "Migrated" &&
                            (preData.status === "Sent To Operations" ||
                              preData.status === "Sent To Finance Team")
                              ? true
                              : false
                          }
                        >
                          Save As Draft
                        </Button>
                      </Grid>
                    )}
                  </Grid>

                  {/* Button Ends Here */}
                </Box>

                {/* agreemenet from end here */}
              </Grid>
            </Grid>
          </Box>
        )}
      </Stack>
    </>
  );
}

export default EditAgreement;
