/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  Box,
  Button,
  Grid,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  TextField,
  IconButton,
  FormControl,
  Collapse,
  Divider,
  Checkbox,
} from "@mui/material";

import {
  DocumentUpload,
  MyHeader,
  SelectComponent,
  TextFieldWrapper,
} from "../StyledComponent";
import {
  updateMonthlyRentStatus,
  add_agreement,
  add_landlord,
  uploadDoc,
  getBankName,
  getLocation,
  getDetails_renewal,
  send_to_bhu,
  addRenewalDesposit,
  add_invoice,
  get_remarks_by_code,
  get_old_agreement,
  notification_handler,
  create_landlord_id,
} from "../../Services/Services";
import { useDispatch, useSelector } from "react-redux";
import { setAlert, setRefreshBox } from "../../store/action/action";
import PermissionAlert from "../Manager/Alert";
import { useParams, useNavigate } from "react-router-dom";
import { ImageView } from "../StyleComponents/Rental";
import HamburgerManager from "../Manager/HamburgerManager";
import UploadInvoice from "../MonthalyPayment/UploadInvoiceMonthly";
import YearlyIncrement from "../Manager/IncrementType";

function EditAgreement({ history }) {
  const navigate = useNavigate();
  const { landloard, auth } = useSelector((state) => state);
  const role = auth.role;
  const dispatch = useDispatch();
  const [agreement, setAgreement] = useState([]);
  const [unpaidAmount, setUnpaidAmount] = useState();
  const [previousDeposit, setPreviousDeposit] = useState();
  const [oldMonthlyValue, setOldMonthlyValue] = useState();
  const [selectedUnpaidMonths, setSelectedUnpaidMonths] = useState([]);
  const [selectedUnpaidIds, setSelectedUnpaidIds] = useState([]);
  const [monthlyId, setMonthlyId] = useState(0);
  const [rowDataForUpload, setRowDataForUpload] = useState();
  const [invoiceEdited, setInvoiceEdited] = useState(false);
  const [selectID, setSelectID] = useState(0);
  const [code, setCode] = useState(0);
  const [ids, setIds] = useState([]);
  const [allRemarks, setAllRemarks] = useState("");
  const [openUpload, setUploadOpen] = useState(false);
  const { id } = useParams();
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceNo: "",
    invoiceDate: "",
    rentAmount: "",
    gstAmount: "",
    totalAmount: "",
    invoice: "",
    invoice_file_name: "",
    manager_id: auth.id,
    gst: "",
    monthlyId: monthlyId,
    user_id: auth.id,
    agreement_id: agreement?.[ids[0]]?.agreement_id,
    code: agreement?.[ids[0]]?.code,
  });
  const [recovery, setRecovery] = useState({
    agreement_id: id,
    remainingMonth: 0,
    depositedAmount: 0,
    adjustmentAmount: 0,
    expenses: 0,
    otherAdjustments: 0,
    totalAdjustmentAmount: 0,
    balanceDeposit: 0,
    monthlyRent: 0,
  });
  const [renewal, setRenewal] = useState({
    deposited: 0,
    unpaid_amount: 0,
    balance_amount: 0,
    new_deposit: 0,
    receivable: 0,
    status: "Pending",
  });
  const [unPaidMonthsAmount, setUnpaidMonthsAmount] = useState(0);
  const [upaid, setUnpaid] = useState([]);
  const [yearlyIncreentChanged, setYearlyIncreentChanged] = useState(false);
  const [preData, setPreData] = useState({
    landlord: [],
    area: "",
    code: "",
    lockInYear: "",
    address: "",
    pincode: "",
    state: "",
    city: "",
    locaiton: "",
    noticePeriod: "",
    deposit: "",
    monthlyRent: "",
    yearlyIncrement: "",
    status: "",
    gst_certificate: "",
    draft_agreement: "",
    electricity_bill: "",
    poa: "",
    maintaince_bill: "",
    cheque: "",
    tax_receipt: "",
    noc: "",
    remark: "",
    precentage: "",
    manager_id: "",
    assets: "",
    alternateMobile: "",
    wrongPin: false,
    buh_id: null,
    op_id: null,
    srm_id: null,
    finance_id: null,
  });
  const [agreementData, setAgreementData] = useState({
    id: id,
    assets: preData.assets,
    file: preData.file,
    renewal_remark: preData.renewal_remark,
    finalLockedIn: preData.finalLockedIn,
  });
  useEffect(() => {
    let unpaid_amount = selectedUnpaidMonths.reduce(
      (sum, row) =>
        (sum += parseInt(
          row.gstNo !== null && row.gstNo.length > 0
            ? Number(row.rent_amount) + Number((row.rent_amount / 100) * 18)
            : row.rent_amount
        )),
      0
    );

    setUnpaidAmount(unpaid_amount);
    setRenewal((old) => ({
      deposited: parseInt(preData.deposit),
      balance_amount: preData.deposit - previousDeposit,
      receivable: Math.round(
        preData.deposit - previousDeposit + +unpaid_amount
      ),
    }));
    setUnpaidMonthsAmount(unpaid_amount);
  }, [
    unpaidAmount,
    preData.deposit,
    selectedUnpaidMonths.length,
    renewal.balance_amount,
    renewal.receivable,
    selectedUnpaidIds,
    recovery.unpaidMonthsAmount,
  ]);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  async function get_remarks_by_aggrement_code(code, id) {
    try {
      const remarks = await get_remarks_by_code(code, "renewal", id);

      if (remarks.data.success) {
        let remark = remarks.data.remarks.map(
          ({ step_name, remarks }) => `${step_name} : ${remarks}`
        );
        return remark;
      }
      return;
    } catch (error) {}
  }

  async function fetchData() {
    try {
      let response = await getDetails_renewal(id);
      if (response.status === 200) {
        let {
          id,
          code,
          pincode,
          state,
          address,
          location,
          city,
          lockInYear,
          monthlyRent,
          noticePeriod,
          yearlyIncrement,
          deposit,
          gst_certificate,
          draft_agreement,
          electricity_bill,
          poa,
          maintaince_bill,
          cheque,
          tax_receipt,
          noc,
          tenure,
          year1,
          year2,
          year3,
          year4,
          year5,
          year6,
          year7,
          year8,
          year9,
          year10,
          area,
          landlord,
          remark,
          manager_id,
          branchName,
          assets,
          property_pic,
          alternateMobile,
          status,
          property_pic_name,
          buh_id,
          op_id,
          srm_id,
          finance_id,
        } = response.data.agreement;
        get_old_data(code);
        const remarks = await get_remarks_by_aggrement_code(code, id);
        setAllRemarks(remarks);
        if (response.data.listUnpaidRow.length > 0) {
          setUnpaid(response.data.listUnpaidRow);
          if (
            status === "Sent Back From Sr Manager Renewal" ||
            status === "Sent Back From Finance Team Renewal" ||
            status === "Sent Back From Operations Renewal"
          ) {
            response.data.listUnpaidRow.map((object) => {
              if (object.status === "Hold") {
                selectedUnpaidMonths.push(object);
                selectedUnpaidIds.push(object.id);
              }
            });
          } else {
            setSelectedUnpaidMonths(response.data.listUnpaidRow);
            response.data.listUnpaidRow.map((object) => {
              selectedUnpaidIds.push(object.id);
            });
          }
        }
        if (response.data.listHoldRow.length > 0) {
          setUnpaid(response.data.listHoldRow);
          setSelectedUnpaidMonths(response.data.listHoldRow);
        }
        setRenewal((old) => ({
          deposited: parseInt(deposit),
          unpaid_amount: unpaidAmount,
          balance_amount: parseInt(deposit) - preData.deposit,
          new_deposit: parseInt(deposit),
          receivable: parseInt(deposit) - unpaidAmount + 0,
        }));

        let rent = monthlyRent;
        if (yearlyIncrement === "Percentage") {
          setYearValue({
            year1: 0,
            year2: year2 && parseInt(((year2 - year1) / year1) * 100),
            year3: year3 && parseInt(((year3 - year2) / year2) * 100),
            year4: year4 && parseInt(((year4 - year3) / year3) * 100),
            year5: year5 && parseInt(((year5 - year4) / year4) * 100),
            year6: year6 && Math.round(((year6 - year5) / year5) * 100),
            year7: year7 && Math.round(((year7 - year6) / year6) * 100),
            year8: year8 && Math.round(((year8 - year7) / year7) * 100),
            year9: year9 && Math.round(((year9 - year8) / year8) * 100),
            year10: year10 && Math.round(((year10 - year9) / year9) * 100),
          });
        } else {
          setYearValue({
            year1: 0,
            year2: year2 && year2 - year1,
            year3: year3 && year3 - year2,
            year4: year4 && year4 - year3,
            year5: year5 && year5 - year4,
            year6: year6 && year6 - year5,
            year7: year7 && year7 - year6,
            year8: year8 && year8 - year7,
            year9: year9 && year9 - year8,
            year10: year10 && year10 - year9,
          });
        }

        if (landlord.length > 0) {
          landlord.map((object, i) => {
            preData[`aadhar_card${i}`] = object.aadhar_card;
            preData[`cheque${i}`] = object.cheque;
            preData[`pan_card${i}`] = object.pan_card;
            preData[`gst${i}`] = object.gst;
            preData[`gst_name${i}`] =
              object.gst != null ? object.gst.split("/").pop() : "";
            preData[`cheque_name${i}`] =
              object.cheque != null ? object.cheque.split("/").pop() : "";
            preData[`aadhar_card_name${i}`] =
              object.aadhar_card != null
                ? object.aadhar_card.split("/").pop()
                : "";
            preData[`pan_card_name${i}`] =
              object.pan_card != null ? object.pan_card.split("/").pop() : "";
          });
        }
        preData[`draft_agreement_name`] =
          draft_agreement != null ? draft_agreement.split("/").pop() : "";
        preData[`electricity_bill_name`] =
          electricity_bill != null ? electricity_bill.split("/").pop() : "";
        preData[`poa_name`] = poa != null ? poa.split("/").pop() : "";
        preData[`maintaince_bill_name`] =
          maintaince_bill != null ? maintaince_bill.split("/").pop() : "";
        preData[`tax_receipt_name`] =
          tax_receipt != null ? tax_receipt.split("/").pop() : "";
        preData[`property_pic_name`] =
          property_pic != null
            ? property_pic.split("/").pop()
            : property_pic_name;

        setPreData({
          ...preData,
          id,
          property_pic,
          area,
          code,
          pincode,
          state,
          address,
          location,
          city,
          lockInYear,
          monthlyRent,
          noticePeriod,
          yearlyIncrement,
          deposit,
          assets: assets === '""' ? "" : assets,
          gst_certificate,
          draft_agreement,
          electricity_bill,
          poa,
          maintaince_bill,
          cheque,
          tax_receipt,
          noc,
          tenure,
          landlord,
          remark,
          manager_id,
          branchName,
          buh_id,
          op_id,
          srm_id,
          finance_id,
          status,
        });
        setFormError({
          id: undefined,
          code: undefined,
          pincode: undefined,
          state: undefined,
          address: undefined,
          location: undefined,
          city: undefined,
          lockInYear: undefined,
          monthlyRent: undefined,
          noticePeriod: undefined,
          yearlyIncrement: undefined,
          deposit: undefined,
          gst_certificate: undefined,
          draft_agreement: undefined,
          electricity_bill: undefined,
          poa: undefined,
          maintaince_bill: undefined,
          cheque: undefined,
          tax_receipt: undefined,
          noc: undefined,
          tenure: undefined,
          landlord: landlord.map((row) => ({})),
          remark: undefined,
          area: undefined,
        });
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
  }, []);
  async function get_old_data(code) {
    try {
      const oldvalue = await get_old_agreement(code);
      oldvalue.status === 200 &&
        setPartLable(Object.values(oldvalue.data.agreement)[0]);
      setOldMonthlyValue(oldvalue?.data?.oldMonthlyRentFinal);
      setPreviousDeposit(Object.values(oldvalue.data.agreement)[0].deposit);
      oldvalue.status === 200 && setOldIds(oldvalue.data.ids);
    } catch (error) {}
  }
  async function uploadInvoiceDetails() {
    try {
      const addInvoice = await add_invoice(selectID, invoiceDetails);

      if (addInvoice.data.success) {
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message: invoiceEdited
              ? "Invoice Details Updated Sucessfully"
              : "Invoice Details Submitted Sucessfully",
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        dispatch(setRefreshBox());
        setUploadOpen(false);
        setInvoiceDetails({
          invoiceNo: "",
          invoiceDate: "",
          rentAmount: "",
          gstAmount: "",
          totalAmount: "",
          invoice: "",
          invoice_file_name: "",
          manager_id: auth.id,
        });
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Some Error Occured Please Try Again Later.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }
  const [docExpand, setDocExpand] = useState(0);
  const [i, setIndex] = useState(0);

  useEffect(() => {
    setPreData((old) => ({ ...old, landlord: [...landloard] }));
    setFormError((old) => ({ ...old, landlord: [...landloard] }));
  }, [landloard]);

  const [landblord, setLandblord] = useState([1]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [increment, setIncrement] = useState({
    year1: "",
    year2: "",
    year3: "",
    year4: "",
    year5: "",
    year6: "",
    year7: "",
    year8: "",
    year9: "",
    year10: "",
  });
  const [yearValue, setYearValue] = useState({
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  });

  const [duplicateAadhaarNoss, setDuplicateAdharNos] = useState([]);
  const [duplicatePanrNoss, setDuplicatePanNos] = useState([]);
  const [duplicateMobileNoss, setDuplicateMobileNos] = useState([]);
  const [landLordDuplicateError, setLandLordDuplicateError] = useState(false);

  useEffect(() => {
    const duplicateAadhaarNos = [];
    const duplicatePanNo = [];
    const duplicateMobileNo = [];
    const duplicateGstNo = [];
    const aadhaarNoSet = new Set();
    const panNoSet = new Set();
    const mobileNoSet = new Set();
    const gstNoSet = new Set();

    for (const item of preData.landlord) {
      if (
        item.aadharNo !== undefined &&
        item.aadharNo !== null &&
        aadhaarNoSet.has(item.aadharNo)
      ) {
        duplicateAadhaarNos.push(item.aadharNo);
      } else {
        aadhaarNoSet.add(item.aadharNo);
      }

      if (
        item.panNo !== undefined &&
        item.panNo !== null &&
        panNoSet.has(item.panNo)
      ) {
        duplicatePanNo.push(item.panNo);
      } else {
        panNoSet.add(item.panNo);
      }

      if (
        item.mobileNo !== undefined &&
        item.mobileNo !== null &&
        mobileNoSet.has(item.mobileNo)
      ) {
        duplicateMobileNo.push(item.mobileNo);
      } else {
        mobileNoSet.add(item.mobileNo);
      }

      if (gstNoSet.has(item.gstNo)) {
        duplicateGstNo.push(item.gstNo);
      } else {
        gstNoSet.add(item.gstNo);
      }
    }
    setDuplicateAdharNos(duplicateAadhaarNos);
    setDuplicateMobileNos(duplicateMobileNo);
    setDuplicatePanNos(duplicatePanNo);

    if (
      duplicateAadhaarNos.length > 0 ||
      duplicateMobileNo.length > 0 ||
      duplicatePanNo.length > 0
    ) {
      setLandLordDuplicateError(true);
    } else {
      setLandLordDuplicateError(false);
    }
  }, [preData.landlord]);

  function handleRenewal(e) {
    let newDP = parseInt(e.target.value || 0);
  }
  async function handleChangeCommonFile(e, i) {
    const FD = new FormData();
    FD.append("folderName", preData.code);
    FD.append("photo", e.target.files[0]);
    let response = await uploadDoc(FD);

    if (response.status === 200) {
      setFormError((old) => ({
        ...old,
        [e.target.name + i]: "",
        [e.target.name]: "",
      }));
      setPreData((old) => ({
        ...old,
        [e.target.name + "_name"]: e.target.files[0].name,
        landlord: old.landlord.map((row, index) => {
          if (parseInt(i) === index) {
            row[e.target.name] = response.data.link;
            return row;
          } else return row;
        }),
        [e.target.name + i]: e.target.files[0].name,
      }));

      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: response.data.message,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message || "Something went wrong !!!",
        })
      );
    }
  }

  async function handleChangeFile(e) {
    const FD = new FormData();

    FD.append("folderName", preData.code);
    FD.append("photo", e.target.files[0]);
    let response = await uploadDoc(FD);
    if (response.status === 200) {
      setFormError((old) => ({
        ...old,
        [e.target.name + "_name"]: "",
        [e.target.name]: "",
      }));

      setPreData((old) => ({
        ...old,
        [e.target.name]: response.data.link,
        [e.target.name + "_name"]: e.target.files[0].name,
      }));
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: response.data.message,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message || "Something went wrong !!!",
        })
      );
    }
  }

  function handleChange(e, i) {
    let error = { state: false, message: null };

    switch (e.target.name) {
      case "leeseName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "pincode":
        if (!e.target.value.match(/^.{0,6}$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "aadharNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 12 && e.target.value.length > 0)
          error = { ...error, message: "Aadhaar number must be of 12 digit." };
        else error = { ...error, message: null };
        break;
      case "mobileNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "alternateMobile":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "bankName":
        if (e.target.value === "Not Found")
          error = { state: true, message: "Value must be Correct" };
        break;
      case "benificiaryName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "accountNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length > 17 && e.target.value.length > 0)
          error = { ...error, message: "Account can be of 17 digit only." };
        else error = { ...error, message: null };
        break;

      case "email":
        if (
          !e.target.value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "Email address should be like example@gmail.com.",
          };
        else error = { ...error, message: null };
        break;
      case "panNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };
        else if (
          !e.target.value.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "PAN number should be like NOOPS8327K",
          };
        else error = { ...error, message: null };
        break;
      case "gstNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^.{0,15}$/))
          error = { state: true, message: null };
        if (!checksum(e.target.value) && e.target.value.length > 0)
          error = {
            ...error,
            message: "GST number should be like 27AAPFU0939F1ZV.",
          };
        else error = { ...error, message: null };
        break;
      case "ifscCode":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^[^@#$%^&*<>'\"/;`%]*$/))
          error = { state: true, message: null };

        break;
      default:
        break;
    }
    if (e.target.name === "ifscCode") {
      getBankDetails(e.target.value, i);
    }
    if (!error.state) {
      setPreData((old) => ({
        ...old,
        landlord: old.landlord.map((row, id) => {
          if (parseInt(i) === id) {
            return {
              ...row,
              [e.target.name]: e.target.value,
            };
          }
          return row;
        }),
      }));
    }
  }

  function handleCommonChange(e, i) {
    var error = { state: false };
    switch (e.target.name) {
      case "location":
        if (!e.target.value.match(/^[^@#$%^&*<>'\"/;`%]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "address":
        if (!e.target.value.match(/^[^@#$%^&*<>'\"/;`%]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "pincode":
        if (e.target.value !== 0 && e.target.value.length <= 6) {
          if (!e.target.value.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      case "lockInYear":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "rental_amount":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "noticePeriod":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        else e.target.value = e.target.value.toLocaleString();
        break;
      case "area":
        if (e.target.value.length <= 5) {
          if (!e.target.value.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      case "monthlyRent":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "deposit":
        if (!e.target.value.match(/^[0-9]*$/)) error = { state: true };
        break;
      default:
        break;
    }

    if (!error.state) {
      setPreData((old) => ({ ...old, [e.target.name]: e.target.value }));
      setFormError((old) => ({ ...old, [e.target.name]: "" }));
    }
  }

  async function getBankDetails(data, i) {
    try {
      let res = await getBankName(data);

      if (res.status === 200) {
        setPreData((old) => ({
          ...old,
          landlord: old.landlord.map((row, index) => {
            if (index === i) {
              return {
                ...row,
                bankName: res.data.BANK,
                branchName: res.data.BRANCH,
              };
            } else return row;
          }),
        }));
      }
    } catch (err) {
      setPreData((old) => ({
        ...old,
        landlord: old.landlord.map((row, index) => {
          if (index === i) {
            return { ...row, bankName: "Not Found", branchName: "" };
          } else return row;
        }),
      }));
    }
  }

  const [oldBranchName, setOldBranchName] = useState("");
  const handleConfirm = () => {
    setOpen(false);

    const {
      id,
      code,
      area,
      lockInYear,
      monthlyRent,
      noticePeriod,
      yearlyIncrement,
      deposit,
      gst_certificate,
      draft_agreement,
      electricity_bill,
      poa,
      maintaince_bill,
      tax_receipt,
      noc,
      tenure,
      pincode,
      state,
      address,
      location,
      precentage,
      city,
      landlord,
      manager_id,
      assets,
      property_pic,
      buh_id,
      op_id,
      srm_id,
      finance_id,
    } = preData;

    APICall(
      {
        assets,
        pincode,
        state,
        address,
        location,
        city,
        area,
        code,
        lockInYear,
        monthlyRent,
        noticePeriod,
        yearlyIncrement: tenure <= 12 ? "" : yearlyIncrement,
        deposit,
        gst_certificate,
        draft_agreement,
        electricity_bill,
        poa,
        maintaince_bill,
        tax_receipt,
        noc,
        property_pic,
        tenure,
        percentage1: tenure <= 12 ? "" : yearValue.year1,
        percentage2: tenure <= 12 ? "" : yearValue.year2,
        percentage3: tenure <= 12 ? "" : yearValue.year3,
        percentage4: tenure <= 12 ? "" : yearValue.year4,
        percentage5: tenure <= 12 ? "" : yearValue.year5,
        percentage6: tenure <= 12 ? "" : yearValue.year6,
        percentage7: tenure <= 12 ? "" : yearValue.year7,
        percentage8: tenure <= 12 ? "" : yearValue.year8,
        percentage9: tenure <= 12 ? "" : yearValue.year9,
        percentage10: tenure <= 12 ? "" : yearValue.year10,
        ...increment,
        manager_id,
        status:
          preData.status === "Sent Back From Operations"
            ? "Sent To Operations"
            : "Sent To Sr Manager",
        remark: "",
        renewal_status:
          preData.status === "Sent Back From Operations"
            ? "Sent To Operations For Renewal"
            : "Sent To Sr Manager For Renewal",
        renewal_remark: preData.renewal_remark,
        type: "Renewed",
        active_flag: "Y",
        buh_id,
        op_id,
        srm_id,
        finance_id,
      },
      landlord
    );
  };

  const [partLabel, setPartLable] = useState({
    landlord: [
      {
        accountNo: "",
        alternateMobile: "",
        area: "",
        bankName: "",
        benificiaryName: "",
        branchName: "",
        cheque: "",
        email: "",
        gst: null,
        gstNo: null,
        ifscCode: "",
      },
    ],
  });

  const [oldIds, setOldIds] = useState([]);
  const [expand, setExpand] = useState(0);
  const selectedUnpaidMonthsFunc = (e, data) => {
    if (
      selectedUnpaidMonths.length > 0 &&
      selectedUnpaidIds.includes(data.id)
    ) {
      const filtered = selectedUnpaidMonths.filter(
        (item) => item.id !== data.id
      );
      setSelectedUnpaidMonths(filtered);
    } else {
      selectedUnpaidMonths.push(data);
    }
    if (selectedUnpaidIds.includes(data.id)) {
      setSelectedUnpaidIds(selectedUnpaidIds.filter((i) => i !== data.id));
    } else {
      selectedUnpaidIds.push(data.id);
    }
    let unpaidMonthsAmount = selectedUnpaidMonths.reduce(
      (sum, row) => (sum += parseInt(row.rent_amount)),
      0
    );
    let unpaid_amount = selectedUnpaidMonths.reduce(
      (sum, row) =>
        (sum += Math.round(
          row.gstNo !== null && row.gstNo.length > 0
            ? Number(row.rent_amount) + Number((row.rent_amount / 100) * 18)
            : row.rent_amount
        )),
      0
    );
    setUnpaidAmount(unpaid_amount);

    setRecovery((old) => ({
      ...old,
      unpaidMonthsAmount: unpaidMonthsAmount,
    }));
  };

  async function APICall(values, landlordData) {
    const oldAgreementId = landlordData[0].agreement_id;
    const agreement = await add_agreement(values);

    if (agreement.data.success) {
      const agreement_id = agreement.data.agreement[0];

      landlordData = landlordData.map((row) => {
        row.agreement_id = agreement_id;
        return row;
      });

      const result = await add_landlord(landlordData);
      const result1 = await create_landlord_id(agreement_id, auth.id);
      let data = {
        deposited: renewal.deposited,
        balance_amount: renewal.balance_amount,
        receivable: renewal.receivable,
      };

      const renew_deposit = await addRenewalDesposit({
        ...renewal,
        agreement_id,
      });

      if (result) {
        const response = await send_to_bhu(
          {
            renewal_status:
              (preData.op_id === 0 || preData.op_id === null) &&
              preData.buh_id !== 0
                ? "Sent To Operations For Renewal"
                : "Sent To Sr Manager For Renewal",
            renewal_remark: preData.renewal_remark,
            selectedUnpaidIds: selectedUnpaidIds,
            additionalRemark: {
              remarks: preData.renewal_remark,
              step_name: auth.name,
              user_id: auth.id,
              agreement_code: values.code,
              agreement_id: agreement.data.agreement[0],
            },
          },
          agreement.data.agreement[0],
          role,
          "renewal"
        );

        if (response.data.success) {
          const monthlyRentStatus = await updateMonthlyRentStatus(
            {
              status: "Hold",
              check_status: "Paid",
            },
            oldAgreementId
          );

          if (monthlyRentStatus.data.success) {
            navigate(-1);
            dispatch(
              setAlert({
                open: true,
                variant: "success",
                message: "Agreement Submitted.",
              })
            );
          }
        }
      }
      preData.status === "Sent Back From Operations"
        ? await notification_handler("RN11", preData.id, preData.code, auth.id)
        : await notification_handler("RN9", preData.id, preData.code, auth.id);
    }
  }

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      setOpen(true);
    }
  }, [formError]);

  function validate(data) {
    let field = [
      "draft_agreement",
      "electricity_bill",
      "tax_receipt",
      "property_pic",
    ];
    preData.landlord.length > 1 && field.push("noc");

    if (preData.landlord.length > 0) {
      preData.landlord.map((row, i) => {
        if (row.gstNo) {
          field.push(`${`gst${i}`}`.replace(" ", ""));
        }
        field.push(`${`aadhar_card${i}`}`.replace(" ", ""));
        field.push(`${`cheque${i}`}`.replace(" ", ""));
        field.push(`${`pan_card${i}`}`.replace(" ", ""));
      });
    }

    let unPaidMonthsError = [];
    unPaidMonthsError = selectedUnpaidMonths.map((object, i) => ({
      invoice_number:
        selectedUnpaidMonths[i]?.invoice_number == null &&
        selectedUnpaidMonths[i]?.gstNo !== ""
          ? "Please Upload Invoice"
          : false,
    }));

    let finalCheck = field.map((row) => {
      if (!preData[row]) {
        setFormError((old) => ({
          ...old,
          [row]: "Document required.",
          unPaidMonthsError: unPaidMonthsError,
        }));
        return true;
      } else {
        setFormError((old) => ({
          ...old,
          [row]: "",
          unPaidMonthsError: unPaidMonthsError,
        }));

        return false;
      }
    });
    unPaidMonthsError.map((row, i) => {
      finalCheck.push(Object.values(row).includes("Please Upload Invoice"));
    });
    if (!finalCheck.includes(true)) {
      return true;
    } else return false;
  }
  function validateFields(data) {
    let field = [
      "lockInYear",
      "noticePeriod",
      "deposit",
      "monthlyRent",
      "tenure",
      "state",
      "city",
      "address",
      "pincode",
      "location",
      "area",
      "assets",
      "renewal_remark",
    ];

    let dataError = [];
    if (data.landlord && data.landlord.length > 0) {
      dataError = data.landlord.map((row, i) => ({
        aadharNo: data.landlord[i].aadharNo ? false : "Field is required.",
        panNo: data.landlord[i].panNo ? false : "Field is required.",
        mobileNo: data.landlord[i].mobileNo ? false : "Field is required.",
        email: data.landlord[i].email ? false : "Field is required.",
        ifscCode: data.landlord[i].ifscCode
          ? data.landlord[i].ifscCode.length === 11
            ? false
            : "Incorrect IFSC"
          : "Field is required.",
        bankName: data.landlord[i].bankName ? false : "Field is required.",
        accountNo: data.landlord[i].accountNo ? false : "Field is required.",
        benificiaryName: data.landlord[i].benificiaryName
          ? false
          : "Field is required.",
      }));
    }

    let finalCheck = field.map((row) => {
      if (!data[row] || data[row] === "") {
        setFormError((old) => ({
          ...old,
          [row]: "Field required.",
          landlord: dataError,
        }));
        return true;
      } else {
        setFormError((old) => ({ ...old, [row]: "", landlord: dataError }));
        return false;
      }
    });

    dataError.map((row, i) => {
      finalCheck.push(Object.values(row).includes("Incorrect IFSC"));
      finalCheck.push(Object.values(row).includes("Field is required."));
    });

    if (!finalCheck.includes(true)) {
      return true;
    } else return false;
  }

  const [open, setOpen] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    let validateData = validate(preData);
    let validateFieldsData = validateFields(preData);
    setPreData((old) => ({ ...old, ...increment }));

    if (Number(preData.noticePeriod) > Number(preData.tenure)) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Notice Period should be less than tenure",
        })
      );
    } else if (Number(preData.lockInYear) > Number(preData.tenure)) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Lock in month should be less than tenure",
        })
      );
    } else if (landLordDuplicateError) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Duplicate Landlord details",
        })
      );
    } else if (validateData && validateFieldsData) {
      if (
        !preData.InvalidDeposit &&
        !preData.InvalidMonthlyValue &&
        !preData.InvalidTenure
      ) {
        if (renewal.receivable < 0) {
          dispatch(
            setAlert({
              open: true,
              variant: "error",
              message: `Please Contact To Finance Team as Balance Deposit Payable is ${renewal.receivable}.`,
            })
          );
        } else {
          setIsSubmit(true);
          setOpen(true);
        }
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Please Enter Values Greater than Zero",
          })
        );
      }
    } else {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Please Fill/Upload Mandatory Fields",
        })
      );
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  async function handleStateSearch(e, i) {
    if (e.target.value.length === 6) {
      let response = await getLocation(e.target.value);
      if (response.data[0].PostOffice) {
        let address = response.data[0].PostOffice[0];
        setFormError((old) => ({
          ...old,
          pincode: "",
        }));
        return setPreData((old) => ({
          ...old,
          state: address.State,
          city: address.District,
          wrongPin: false,
        }));
      } else {
        setFormError((old) => ({
          ...old,
          city: "",
          state: "",
          pincode: "Invalid pin code.",
        }));
        return setPreData((old) => ({
          ...old,
          state: "",
          city: "",
          wrongPin: true,
        }));
      }
    } else {
      setFormError((old) => ({
        ...old,
        city: "",
        state: "",
        pincode: "",
      }));
      return setPreData((old) => ({
        ...old,
        state: "",
        city: "",
        wrongPin: false,
      }));
    }
  }
  function checksum(g) {
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
      g
    );
    if (regTest) {
    }
    return regTest;
  }

  function handleOnBlur(e, i) {
    let error = { state: false, message: null };
    switch (e.target.name) {
      case "leeseName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;

      case "pincode":
        if (!e.target.value.match(/^.{0,6}$/))
          error = { state: true, message: "Value must be Correct" };
        if (e.target.value.length < 6 && e.target.value.length > 0)
          error = { ...error, message: "Pincode number must be of 6 digit." };
        else error = { ...error, message: null };
        break;

      case "aadharNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 12 && e.target.value.length > 0)
          error = { ...error, message: "Aadhaar number must be of 12 digit." };
        else error = { ...error, message: null };
        break;

      case "mobileNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;

      case "alternateMobile":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;

      case "bankName":
        if (!e.target.value === "Not Found")
          error = { state: true, message: "Value must be Correct" };
        break;

      case "benificiaryName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;

      case "accountNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length > 17 && e.target.value.length > 0)
          error = { ...error, message: "Account can be of 17 digit only." };
        else error = { ...error, message: null };
        break;

      case "email":
        if (
          !e.target.value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "Email should be like example@gmail.com.",
          };
        else error = { ...error, message: null };
        break;

      case "panNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };
        else if (
          !e.target.value.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "PAN number should be like NOOPS8327K",
          };
        else error = { ...error, message: null };
        break;

      case "gstNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^.{0,15}$/))
          error = { state: true, message: null };
        if (!checksum(e.target.value) && e.target.value.length > 0)
          error = { ...error, message: "GST should be like 18AABCU9603R1ZM." };
        else error = { ...error, message: null };
        break;

      case "ifscCode":
        if (
          !e.target.value.match(/^[A-Z]{4}0[A-Z0-9]{6}$/) &&
          e.target.value !== ""
        ) {
          error = { ...error, message: "Incorrect IFSC" };
        } else {
          error = { ...error, message: null };
        }
        break;
      default:
        break;
    }
    if (error.message) {
      if (formError.landlord[i]) {
        setFormError((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: error.message,
              };
            }
            return row;
          }),
        }));
      } else {
        setFormError((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: error.message,
            },
          ],
        }));
      }
    } else {
      if (formError.landlord[i]) {
        setFormError((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: "",
              };
            }
            return row;
          }),
        }));
      } else {
        setFormError((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: "",
            },
          ],
        }));
      }
    }
  }
  function handleHold() {
    const {
      id,
      area,
      code,
      lockInYear,
      monthlyRent,
      noticePeriod,
      yearlyIncrement,
      deposit,
      gst_certificate,
      draft_agreement,
      electricity_bill,
      poa,
      maintaince_bill,
      tax_receipt,
      noc,
      tenure,
      pincode,
      state,
      address,
      location,
      city,
      landlord,
      property_pic,
    } = preData;

    if (validate(preData) && validateFields(preData)) {
      APICall(
        {
          pincode,
          state,
          address,
          area,
          location,
          city,
          code,
          lockInYear,
          monthlyRent,
          noticePeriod,
          yearlyIncrement: tenure <= 12 ? "" : yearlyIncrement,
          deposit,
          gst_certificate,
          draft_agreement,
          electricity_bill,
          poa,
          maintaince_bill,
          tax_receipt,
          property_pic,
          noc,
          tenure,
          percentage1: tenure <= 12 ? "" : yearValue.year1,
          percentage2: tenure <= 12 ? "" : yearValue.year2,
          percentage3: tenure <= 12 ? "" : yearValue.year3,
          percentage4: tenure <= 12 ? "" : yearValue.year4,
          percentage5: tenure <= 12 ? "" : yearValue.year5,
          percentage6: tenure <= 12 ? "" : yearValue.year6,
          percentage7: tenure <= 12 ? "" : yearValue.year7,
          percentage8: tenure <= 12 ? "" : yearValue.year8,
          percentage9: tenure <= 12 ? "" : yearValue.year9,
          percentage10: tenure <= 12 ? "" : yearValue.year10,
          ...increment,
          status: "Draft",
          remark: "",
          type: "Renewed",
          renewal_status: "",
          active_flag: "Y",
        },
        landlord
      );
    }
  }

  function Docview(href, name) {
    return (
      <ImageView open={true} handleClose={() => {}} href={href} name={name} />
    );
  }

  return (
    <>
      {preData.landlord.length > 0 && (
        <>
          <PermissionAlert
            handleClose={handleCancel}
            handleConfirm={handleConfirm}
            open={open}
            message={"Please check agreement carefully before submission."}
          />
          <Stack sx={{ flexWrap: "nowrap", flexDirection: "row" }}>
            <HamburgerManager />
            <Box className="backButton">
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                size={"large"}
              >
                <ArrowCircleLeftIcon
                  sx={{ fontSize: "3rem" }}
                  color="#FFFFF !important"
                />
              </IconButton>
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Grid
                item
                xs={12}
                sx={{ justifyContent: "space-between", display: "flex" }}
              >
                <MyHeader>
                  RMS{" "}
                  <span className="small-heading">
                    (Rental Management System)
                  </span>
                </MyHeader>
                <Typography mt="15px" mr="15px" fontWeight="600">
                  Welcome {auth.name}
                </Typography>
              </Grid>
              <Divider />
              <Grid
                container
                sx={{
                  px: 1,
                  justifyContent: "space-between",
                  my: 1,
                  alignItems: "center",
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    color="black"
                    fontSize="20px"
                    fontWeight="600"
                    alignSelf="center"
                    lineHeight="30px"
                    sx={{
                      "@media(max-width:600px)": {
                        fontSize: "17px",
                        lineHeight: "25px",
                      },
                    }}
                  >
                    {"Renewal Adjustment Form"}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />

              <Grid container sx={{ justifyContent: "center" }}>
                <Grid item xs={12} md={10}>
                  <Box
                    component="form"
                    sx={{
                      py: 5,
                      backgroundColor: "white",
                      mx: 3,
                      borderRadius: "15px",
                      maxWidth: "1050px",
                      "@media(max-width:900px)": { mx: 0 },
                    }}
                  >
                    {/* Basic details start here */}
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="25px"
                      lineHeight="28px"
                      fontWeight="600"
                      my="10px"
                    >
                      Property Details
                    </Typography>

                    <Grid
                      container
                      sx={{ px: 3, mb: 2 }}
                      spacing={isSmall ? 4 : 4}
                      rowSpacing={5}
                    >
                      <TextFieldWrapper
                        label="Code"
                        placeHolder=""
                        disabled={true}
                        backgroundColor="rgba(3, 193, 243, 0.2);"
                        value={preData.code}
                        name="code"
                      />

                      <TextFieldWrapper
                        label="Pincode"
                        placeHolder="Pincode"
                        backgroundColor="rgba(3, 193, 243, 0.2);"
                        value={preData.pincode}
                        required={true}
                        maxLength={6}
                        name="pincode"
                        onChange={(e) => {
                          if (e.target.value.length <= 6) {
                            handleCommonChange(e);
                            handleStateSearch(e);
                            const pincodeValue =
                              e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1);
                            const pincodeRegex = /^[0-9]*$/;

                            if (
                              pincodeValue.length === 6 &&
                              !pincodeRegex.test(pincodeValue) &&
                              preData.wrongPin
                            ) {
                              setFormError((prevFormError) => ({
                                ...prevFormError,
                                pincode: "Invalid pin code.",
                              }));
                            } else {
                              setFormError((prevFormError) => ({
                                ...prevFormError,
                                pincode: null,
                              }));
                            }
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value.length < 6) {
                            setFormError((prevFormError) => ({
                              ...prevFormError,
                              pincode: "Pin code must be 6 digits long.",
                            }));
                          } else if (preData.wrongPin) {
                            setFormError((prevFormError) => ({
                              ...prevFormError,
                              pincode: "Invalid pin code.",
                            }));
                          } else {
                            setFormError((prevFormError) => ({
                              ...prevFormError,
                              pincode: null,
                            }));
                          }
                        }}
                        error={formError.pincode}
                      />

                      <TextFieldWrapper
                        label="State"
                        disabled={true}
                        name="state"
                        required={true}
                        maxLength={6}
                        value={preData.state || ""}
                        error={formError.state}
                      />
                      <Grid
                        item
                        md={4}
                        xs={6}
                        sx={{
                          mb: "0px !important",
                          "@media(max-width:900px)": { my: 1 },
                        }}
                      >
                        <FormControl fullWidth className="textFieldWrapper">
                          <TextField
                            label="City"
                            required={true}
                            disabled={true}
                            error={formError.city}
                            fullWidth
                            name="city"
                            value={preData.city || ""}
                          />
                        </FormControl>
                      </Grid>

                      <TextFieldWrapper
                        label="Location"
                        placeHolder="Enter Location"
                        name="location"
                        error={formError.location}
                        value={preData.location || ""}
                        onChange={handleCommonChange}
                        index={i}
                      />

                      <TextFieldWrapper
                        label="Area"
                        placeHolder="Area in sq. ft"
                        name="area"
                        maxLength={5}
                        notationVal="sq. ft"
                        textAlignRight={"textAlignRight"}
                        error={formError.area}
                        required={true}
                        value={preData.area}
                        partLabel={
                          partLabel && partLabel?.area
                            ? "Old Area:" + partLabel.area + " sq. ft"
                            : ""
                        }
                        onChange={handleCommonChange}
                      />

                      <TextFieldWrapper
                        label="Address"
                        placeHolder="Enter Address"
                        error={formError.address}
                        required={true}
                        name="address"
                        value={preData.address}
                        onChange={handleCommonChange}
                        index={i}
                      />
                      <TextFieldWrapper
                        label="Deposit Amount"
                        placeHolder="Enter deposit Amount"
                        name="deposit"
                        required={true}
                        textAlignRight={"textAlignRight"}
                        error={
                          preData.InvalidDeposit
                            ? "Value must be greater than zero !"
                            : formError.deposit
                        }
                        value={preData.deposit}
                        partLabel={
                          partLabel?.deposit
                            ? "Old Deposit Amount :" + partLabel.deposit
                            : ""
                        }
                        onChange={(e) => {
                          handleCommonChange(e);
                          if (e.target.value === 0) {
                            setFormError((prevFormError) => ({
                              ...prevFormError,
                              deposit: "Value must be greater than zero !",
                            }));
                            setPreData((previousdata) => ({
                              ...previousdata,
                              InvalidDeposit: true,
                            }));
                          } else {
                            setFormError((prevFormError) => ({
                              ...prevFormError,
                              deposit: null,
                            }));
                            setPreData((previousdata) => ({
                              ...previousdata,
                              InvalidDeposit: false,
                            }));
                          }
                        }}
                        disabled={
                          preData.buh_id != null &&
                          preData.status === "Sent Back From Operations"
                        }
                      />
                      <TextFieldWrapper
                        label="Monthly Rental"
                        placeHolder="Enter Rental"
                        required={true}
                        name="monthlyRent"
                        textAlignRight={"textAlignRight"}
                        error={
                          preData.InvalidMonthlyValue
                            ? "Value must be greater than zero !"
                            : formError.monthlyRent
                        }
                        value={preData.monthlyRent}
                        partLabel={
                          oldMonthlyValue
                            ? "Old Monthly Rent:" + oldMonthlyValue
                            : ""
                        }
                        onChange={(e) => {
                          handleCommonChange(e);
                          if (e.target.value === 0) {
                            setFormError((prevFormError) => ({
                              ...prevFormError,
                              monthlyRent: "Value must be greater than zero !",
                            }));
                            setPreData((previousdata) => ({
                              ...previousdata,
                              InvalidMonthlyValue: true,
                            }));
                          } else {
                            setFormError((prevFormError) => ({
                              ...prevFormError,
                              monthlyRent: null,
                            }));
                            setPreData((previousdata) => ({
                              ...previousdata,
                              InvalidMonthlyValue: false,
                            }));
                          }
                        }}
                        disabled={
                          preData.buh_id != null &&
                          preData.status === "Sent Back From Operations"
                        }
                      />
                      <TextFieldWrapper
                        label="Agreement Tenure"
                        placeHolder="Tenure In Months"
                        name="tenure"
                        notationVal="Month's"
                        textAlignRight={"textAlignRight"}
                        error={
                          preData.InvalidTenure
                            ? "Value must be greater than zero !"
                            : formError.tenure
                        }
                        required={true}
                        value={preData.tenure || ""}
                        onChange={(e) => {
                          if (e.target.value <= 120) {
                            handleCommonChange(e);
                            if (e.target.value === 0) {
                              setFormError((prevFormError) => ({
                                ...prevFormError,
                                tenure: "Value must be greater than zero !",
                              }));
                              setPreData((previousdata) => ({
                                ...previousdata,
                                InvalidTenure: true,
                              }));
                            } else {
                              setFormError((prevFormError) => ({
                                ...prevFormError,
                                tenure: null,
                              }));
                              setPreData((previousdata) => ({
                                ...previousdata,
                                InvalidTenure: false,
                              }));
                            }
                          }
                        }}
                        index={i}
                        maxLength={3}
                        partLabel={
                          partLabel?.tenure
                            ? "Old Tenure:" + partLabel.tenure
                            : ""
                        }
                      />
                      <TextFieldWrapper
                        label="Lock In Months"
                        placeHolder="Enter Lock in Months"
                        name="lockInYear"
                        maxLength={2}
                        value={preData.lockInYear}
                        partLabel={
                          partLabel?.lockInYear
                            ? "Old Lock In Month :" +
                              partLabel.lockInYear +
                              " (Month)"
                            : ""
                        }
                        error={
                          Number(preData.lockInYear) > Number(preData.tenure)
                            ? "Lock in months should be less than tenure"
                            : formError.lockInYear
                        }
                        onChange={handleCommonChange}
                      />
                      <TextFieldWrapper
                        label="Notice Period In Months"
                        placeHolder="Enter Notice Period"
                        error={
                          Number(preData.noticePeriod) > Number(preData.tenure)
                            ? "Notice Period should be less than tenure"
                            : formError.noticePeriod
                        }
                        name="noticePeriod"
                        maxLength={2}
                        value={preData.noticePeriod}
                        partLabel={
                          partLabel?.noticePeriod
                            ? "Old Notice Period :" + partLabel.noticePeriod
                            : ""
                        }
                        onChange={handleCommonChange}
                      />
                      {preData.tenure === "" ? null : preData.tenure <=
                        12 ? null : (
                        <SelectComponent
                          label={"Yearly Increment"}
                          required={true}
                          name="yearlyIncrement"
                          partLabel={
                            partLabel?.yearlyIncrement != null
                              ? "Old Yearly Increment" +
                                partLabel.yearlyIncrement
                              : ""
                          }
                          options={["Percentage", "Value"]}
                          value={preData.yearlyIncrement}
                          onChange={(e) => {
                            handleCommonChange(e);
                            setYearlyIncreentChanged(true);
                          }}
                        />
                      )}
                    </Grid>

                    {/* basic details end here */}

                    {/* Increment Yearly */}
                    {preData.tenure === "" ? null : preData.tenure <=
                      12 ? null : (
                      <YearlyIncrement
                        yearlyIncreentChanged={yearlyIncreentChanged}
                        yearValue={yearValue}
                        setYearValue={setYearValue}
                        tenure={preData.tenure || ""}
                        value={preData.yearlyIncrement || ""}
                        rent={preData.monthlyRent || ""}
                        increment={increment}
                        setIncrement={setIncrement}
                        monthlyRent={preData.monthlyRent || ""}
                        partLabel={partLabel}
                      />
                    )}

                    {/* landlord Details start here*/}
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="25px"
                      lineHeight="28px"
                      fontWeight="600"
                      my="20px"
                      mt="35px !important"
                    >
                      Landlord Details
                    </Typography>

                    {preData.landlord.length > 0 &&
                      preData.landlord.map((_, i) => (
                        <>
                          {preData.landlord.length > 0 && (
                            <Box
                              mb={2}
                              size="small"
                              fullWidth
                              variant="outlined"
                              component={Button}
                              onClick={() => setExpand(expand === i ? -1 : i)}
                              sx={{
                                color: "black",
                                justifyContent: "space-between",
                                backgroundColor: "#b0d6f773",
                              }}
                            >
                              <Typography color={"var( --main-color)"}>
                                {" "}
                                {preData.landlord.length > 0
                                  ? preData.landlord[i].leeseName
                                  : ""}{" "}
                                Personal Details
                              </Typography>
                              <IconButton
                                onClick={() => setExpand(expand === i ? -1 : i)}
                              >
                                {expand === i ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </Box>
                          )}
                          <Collapse
                            in={expand === i}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Grid
                              container
                              sx={{ px: 3, mb: "25px" }}
                              spacing={isSmall ? 2 : 4}
                              rowSpacing={5}
                            >
                              <TextFieldWrapper
                                label="Name Of Lessor"
                                placeHolder="Enter Name Of Lessor"
                                required={true}
                                disabled={true}
                                onBlur={(e) => handleOnBlur(e, i)}
                                name="name"
                                value={preData.landlord[i].name}
                                onChange={(e) => handleChange(e, i)}
                              />
                              <TextFieldWrapper
                                label="Aadhar Number"
                                placeHolder="Enter Aadhar No."
                                required={true}
                                onBlur={(e) => handleOnBlur(e, i)}
                                name="aadharNo"
                                maxLength={12}
                                value={preData.landlord[i].aadharNo}
                                onChange={(e) => handleChange(e, i)}
                                index={i}
                                error={
                                  duplicateAadhaarNoss.length > 0 &&
                                  preData.landlord[i].aadharNo &&
                                  preData.landlord[i].aadharNo.length === 12 &&
                                  duplicateAadhaarNoss.includes(
                                    preData.landlord[i].aadharNo
                                  )
                                    ? "Duplicate Aadhar"
                                    : formError.landlord[i] &&
                                      formError.landlord[i].aadharNo
                                    ? formError.landlord[i].aadharNo
                                    : ""
                                }
                              />
                              <TextFieldWrapper
                                label="PAN Number"
                                placeHolder="Enter PAN No."
                                onBlur={(e) => handleOnBlur(e, i)}
                                error={
                                  duplicatePanrNoss.length > 0 &&
                                  preData.landlord[i].panNo &&
                                  preData.landlord[i].panNo.length === 10 &&
                                  duplicatePanrNoss.includes(
                                    preData.landlord[i].panNo
                                  )
                                    ? "Duplicate Pan"
                                    : formError.landlord[i] &&
                                      formError.landlord[i].panNo
                                    ? formError.landlord[i].panNo
                                    : ""
                                }
                                name="panNo"
                                maxLength={10}
                                value={preData.landlord[i].panNo}
                                onChange={(e) => handleChange(e, i)}
                                index={i}
                              />

                              <TextFieldWrapper
                                label="Mobile Number"
                                placeHolder="Enter Mobile No."
                                required={true}
                                onBlur={(e) => handleOnBlur(e, i)}
                                name="mobileNo"
                                maxLength={10}
                                partLabel={
                                  partLabel?.mobileNo?.[i]
                                    ? "Old Mobile Number: " +
                                      partLabel.mobileNo[i]
                                    : ""
                                }
                                error={
                                  duplicateMobileNoss.length > 0 &&
                                  preData.landlord[i].mobileNo &&
                                  preData.landlord[i].mobileNo.length === 10 &&
                                  duplicateMobileNoss.includes(
                                    preData.landlord[i].mobileNo
                                  )
                                    ? "Duplicate Mobile no"
                                    : formError.landlord[i] &&
                                      formError.landlord[i].mobileNo
                                    ? formError.landlord[i].mobileNo
                                    : formError?.landlord?.[i]?.mobileNo
                                }
                                value={preData.landlord[i].mobileNo}
                                onChange={(e) => handleChange(e, i)}
                                index={i}
                              />
                              <TextFieldWrapper
                                label="Alternate Number"
                                placeHolder="Enter Alternate No."
                                name="alternateMobile"
                                onBlur={(e) => handleOnBlur(e, i)}
                                maxLength={10}
                                partLabel={
                                  partLabel?.alternateMobile?.[i]
                                    ? "Old Alternate Number: " +
                                      partLabel?.alternateMobile?.[i]
                                    : ""
                                }
                                value={preData.landlord[i].alternateMobile}
                                onChange={(e) => handleChange(e, i)}
                                index={i}
                              />

                              <TextFieldWrapper
                                label="Email"
                                placeHolder="Enter Email"
                                onBlur={(e) => handleOnBlur(e, i)}
                                required={true}
                                error={
                                  formError.landlord[i] &&
                                  formError.landlord[i].email
                                    ? formError.landlord[i].email
                                    : ""
                                }
                                name="email"
                                partLabel={
                                  partLabel?.email?.[i]
                                    ? "Old Email: " + partLabel?.email?.[i]
                                    : ""
                                }
                                value={preData.landlord[i].email}
                                onChange={(e) => handleChange(e, i)}
                                index={i}
                              />
                              <TextFieldWrapper
                                label="GST Number"
                                placeHolder="Enter GST No."
                                onBlur={(e) => handleOnBlur(e, i)}
                                error={
                                  formError.landlord[i] &&
                                  formError.landlord[i].gstNo
                                    ? formError.landlord[i].gstNo
                                    : ""
                                }
                                name="gstNo"
                                maxLength={15}
                                partLabel={
                                  partLabel?.gstNo?.[i]
                                    ? "Old GST Number :" + partLabel?.gstNo?.[i]
                                    : ""
                                }
                                value={preData.landlord[i].gstNo}
                                onChange={(e) => handleChange(e, i)}
                              />
                              <TextFieldWrapper
                                required={true}
                                label="Bank IFSC"
                                placeHolder="Enter IFSC Code"
                                onBlur={(e) => handleOnBlur(e, i)}
                                name="ifscCode"
                                error={
                                  formError.landlord[i] &&
                                  formError.landlord[i].ifscCode
                                    ? formError.landlord[i].ifscCode
                                    : ""
                                }
                                partLabel={
                                  partLabel?.ifscCode?.[i]
                                    ? "Old Bank IFSC :" +
                                      partLabel?.ifscCode?.[i]
                                    : ""
                                }
                                value={preData.landlord[i].ifscCode}
                                onChange={(e) => handleChange(e, i)}
                                maxLength={11}
                              />
                              {/* Dipali old branch name  */}
                              <TextFieldWrapper
                                label="Bank Name"
                                placeHolder="Bank Name"
                                name="bankBranch"
                                onBlur={(e) => handleOnBlur(e, i)}
                                partLabel={
                                  <>
                                    Branch:{""}
                                    {partLabel?.branchName?.[i]}
                                    <br />
                                    Old Branch Name:{" "}
                                    {partLabel?.branchName?.[i]}
                                    <br />
                                    {preData.landlord[i].ifscCode !==
                                    partLabel?.ifscCode?.[i]
                                      ? `New Branch Name: ${preData.landlord[i].branchName}`
                                      : ""}
                                  </>
                                }
                                error={
                                  formError.landlord[i] &&
                                  formError.landlord[i].bankBranch
                                    ? formError.landlord[i].bankBranch
                                    : ""
                                }
                                required={true}
                                disabled={true}
                                value={preData.landlord[i].bankName}
                                onChange={(e) => handleChange(e, i)}
                              />

                              {/* Dipali old branch name end */}
                              <TextFieldWrapper
                                required={true}
                                maxLength={50}
                                label="Beneficiary Name"
                                onBlur={(e) => handleOnBlur(e, i)}
                                error={
                                  formError.landlord[i] &&
                                  formError.landlord[i].benificiaryName
                                    ? formError.landlord[i].benificiaryName
                                    : ""
                                }
                                placeHolder="Enter Beneficiary Name"
                                name="benificiaryName"
                                value={preData.landlord[i].benificiaryName}
                                onChange={(e) => handleChange(e, i)}
                                partLabel={
                                  partLabel?.benificiaryName?.[i]
                                    ? "Old Beneficiary Name:" +
                                      partLabel?.benificiaryName?.[i]
                                    : ""
                                }
                              />
                              <TextFieldWrapper
                                label="Bank A/C Number "
                                required={true}
                                placeHolder="Enter Account No."
                                name="accountNo"
                                error={
                                  formError.landlord[i] &&
                                  formError.landlord[i].accountNo
                                    ? formError.landlord[i].accountNo
                                    : ""
                                }
                                value={preData.landlord[i].accountNo}
                                onChange={(e) => handleChange(e, i)}
                                partLabel={
                                  partLabel?.accountNo?.[i]
                                    ? "Old Bank A/C No:" +
                                      partLabel?.accountNo?.[i]
                                    : ""
                                }
                                maxLength={17}
                              />
                            </Grid>
                          </Collapse>
                        </>
                      ))}

                    {/* Bank Details ends here*/}

                    {/* Document upload section start here */}

                    {/* Document */}
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="25px"
                      lineHeight="28px"
                      fontWeight="600"
                      my="20px"
                    >
                      Upload Documents *
                    </Typography>
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="13px"
                      lineHeight="5px"
                      fontWeight="400"
                      my="20px"
                    >
                      ( Upload file in png, jpeg, jpg, pdf format & maximum file
                      size limit less than 20 MB )
                    </Typography>

                    {preData.landlord.length > 0 &&
                      formError.landlord.length > 0 &&
                      preData.landlord.map((_, i) => (
                        <>
                          {preData.landlord.length > 0 && (
                            <Box
                              mb={2}
                              size="small"
                              fullWidth
                              variant="outlined"
                              component={Button}
                              onClick={() =>
                                setDocExpand(docExpand === i ? -1 : i)
                              }
                              sx={{
                                color: "black",
                                justifyContent: "space-between",
                                backgroundColor: "#b0d6f773",
                              }}
                            >
                              <Typography color={"var( --main-color)"}>
                                {" "}
                                {preData.landlord.length > 0
                                  ? preData.landlord[i].leeseName
                                  : ""}{" "}
                                Upload Documents *
                              </Typography>

                              <IconButton
                                onClick={() =>
                                  setDocExpand(docExpand === i ? -1 : i)
                                }
                              >
                                {docExpand === i ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </Box>
                          )}
                          <Collapse
                            in={docExpand === i}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Grid
                              container
                              spacing={isSmall ? 2 : 4}
                              sx={{ px: 1, justifyContent: "", mb: 3 }}
                            >
                              <Grid item xs={6}>
                                <DocumentUpload
                                  uploaded={
                                    preData.landlord[i].aadhar_card
                                      ? true
                                      : false
                                  }
                                  label="Upload Aadhaar Card"
                                  placeHolder="Upload Aadhaar Card"
                                  handleChange={(e) =>
                                    handleChangeCommonFile(e, i)
                                  }
                                  error={formError[`aadhar_card${i}`]}
                                  name={"aadhar_card"}
                                  fileName={
                                    preData.landlord[i].aadhar_card
                                      ? preData.landlord[i].aadhar_card
                                          .split("/")
                                          .pop()
                                      : ""
                                  }
                                  href={preData.landlord[i].aadhar_card}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <DocumentUpload
                                  label="Upload PAN Card"
                                  uploaded={
                                    preData[`pan_card${i}`] ||
                                    preData.landlord[i]["pan_card"]
                                      ? true
                                      : false
                                  }
                                  name={"pan_card"}
                                  error={formError[`pan_card${i}`]}
                                  fileName={
                                    preData.landlord[i].pan_card != null
                                      ? preData.landlord[i].pan_card
                                          .split("/")
                                          .pop()
                                      : ""
                                  }
                                  placeHolder={"Upload PAN Card"}
                                  handleChange={(e) =>
                                    handleChangeCommonFile(e, i)
                                  }
                                  href={preData.landlord[i].pan_card}
                                />
                              </Grid>

                              {((preData.landlord[i].gstNo != null &&
                                partLabel?.gstNo?.[i] ===
                                  preData.landlord[i].gstNo &&
                                partLabel.gstNo[i] !== "") ||
                                (preData.landlord[i].gstNo !== "" &&
                                  preData.landlord[i].gstNo !== null)) && (
                                <Grid item xs={6}>
                                  <DocumentUpload
                                    uploaded={
                                      preData[`gst${i}`] ||
                                      preData.landlord[i]["gst"]
                                        ? true
                                        : false
                                    }
                                    label="Upload GST Certificate"
                                    placeHolder="Upload GST Certificate"
                                    handleChange={(e) =>
                                      handleChangeCommonFile(e, i)
                                    }
                                    name={"gst"}
                                    error={formError[`gst${i}`]}
                                    fileName={
                                      preData.landlord[i].gst != null
                                        ? preData.landlord[i].gst
                                            .split("/")
                                            .pop()
                                        : ""
                                    }
                                    href={
                                      partLabel?.gst?.[i] ||
                                      preData.landlord[i].gst
                                    }
                                  />
                                </Grid>
                              )}

                              {((partLabel?.ifscCode?.[i] ===
                                preData.landlord[i].ifscCode &&
                                partLabel.ifscCode[i] !== "") ||
                                preData.landlord[i].ifscCode !== "") && (
                                <Grid item xs={6}>
                                  <DocumentUpload
                                    uploaded={
                                      preData[`cheque${i}`] ||
                                      preData.landlord[i]["cheque"]
                                        ? true
                                        : false
                                    }
                                    error={formError[`cheque${i}`]}
                                    label="Upload Cancel Cheque"
                                    name={"cheque"}
                                    fileName={
                                      preData.landlord[i].cheque != null
                                        ? preData.landlord[i].cheque
                                            .split("/")
                                            .pop()
                                        : ""
                                    }
                                    placeHolder="Upload Cancel Cheque"
                                    handleChange={(e) =>
                                      handleChangeCommonFile(e, i)
                                    }
                                    href={
                                      partLabel?.cheque?.[i] ||
                                      preData.landlord[i].cheque
                                    }
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Collapse>
                        </>
                      ))}

                    {landblord.length > 0 ? (
                      <>
                        <Typography
                          variant="body1"
                          color="var(--main-color)"
                          fontSize="25px"
                          lineHeight="28px"
                          fontWeight="600"
                          my="20px"
                        >
                          Upload Documents
                        </Typography>
                        <Typography
                          variant="body1"
                          color="var(--main-color)"
                          fontSize="13px"
                          lineHeight="5px"
                          fontWeight="400"
                          my="20px"
                        >
                          ( Upload file in png, jpeg, jpg, pdf format & maximum
                          file size limit less than 20 MB )
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}

                    <Grid
                      container
                      spacing={isSmall ? 2 : 4}
                      sx={{ px: 1, justifyContent: "" }}
                    >
                      <Grid item xs={6}>
                        <DocumentUpload
                          label="Upload Draft Agreement *"
                          uploaded={preData.draft_agreement && true}
                          error={formError.draft_agreement}
                          placeHolder="Upload Draft Agreement"
                          fileName={preData.draft_agreement_name}
                          handleChange={handleChangeFile}
                          name={"draft_agreement"}
                          href={preData.draft_agreement}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DocumentUpload
                          label="Upload Electricity Bill *"
                          uploaded={preData.electricity_bill && true}
                          error={formError.electricity_bill}
                          placeHolder={"Upload Electricity Bill"}
                          handleChange={handleChangeFile}
                          fileName={preData.electricity_bill_name}
                          name={"electricity_bill"}
                          href={preData.electricity_bill}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DocumentUpload
                          label="Upload POA"
                          placeHolder="Upload POA"
                          error={formError.poa}
                          uploaded={preData.poa && true}
                          fileName={preData.poa_name}
                          handleChange={handleChangeFile}
                          name={"poa"}
                          href={preData.poa}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DocumentUpload
                          label="Upload Maintenance Bill"
                          uploaded={preData.maintaince_bill && true}
                          placeHolder={"Upload Maintaince Bill"}
                          error={formError.maintaince_bill}
                          handleChange={handleChangeFile}
                          fileName={preData.maintaince_bill_name}
                          name={"maintaince_bill"}
                          href={preData.maintaince_bill}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DocumentUpload
                          label="Upload Property Tax Receipt *"
                          uploaded={preData.tax_receipt && true}
                          placeHolder={"Upload Property Tax Receipt"}
                          handleChange={handleChangeFile}
                          fileName={preData.tax_receipt_name}
                          error={formError.tax_receipt}
                          name={"tax_receipt"}
                          href={preData.tax_receipt}
                        />
                      </Grid>

                      {preData.landlord && preData.landlord.length > 1 && (
                        <Grid item xs={6}>
                          <DocumentUpload
                            uploaded={preData.noc && true}
                            label="Upload NOC (If Mutiple Oweners) *"
                            error={formError.noc}
                            placeHolder="NOC"
                            fileName={preData.noc_name}
                            handleChange={handleChangeFile}
                            name={"noc"}
                            href={preData.noc}
                          />
                        </Grid>
                      )}

                      <Grid item xs={6}>
                        <DocumentUpload
                          label="Property Picture *"
                          uploaded={preData.property_pic && true}
                          placeHolder={"Upload Property Picture"}
                          handleChange={handleChangeFile}
                          fileName={preData.property_pic_name}
                          error={formError.property_pic}
                          name={"property_pic"}
                          href={preData.property_pic}
                        />
                      </Grid>
                    </Grid>
                    {/* New Renewal Disposite Form */}
                    <Grid
                      container
                      sx={{ mt: "25px", mb: "25px" }}
                      spacing={isSmall ? 2 : 4}
                      component={"form"}
                      onSubmit={handleSubmit}
                      method="post"
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          color="var(--main-color)"
                          fontSize="25px"
                          lineHeight="28px"
                          fontWeight="600"
                        >
                          Renewal Adjustments Form
                        </Typography>
                      </Grid>

                      {/* unpaid section */}
                      <Grid item xs={12}>
                        <Grid
                          coantiner
                          sx={{
                            display: "flex",
                            gap: "2rem",
                            flexDirection: "column",
                          }}
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="h6"
                              color={"primary"}
                              sx={{ fontWeight: 700 }}
                            >
                              Unpaid Months
                            </Typography>
                          </Grid>
                          {upaid.map((row) => (
                            <Grid
                              item
                              xs={12}
                              sx={{ display: "flex", gap: "2rem" }}
                            >
                              <Checkbox
                                onChange={(e) => {
                                  selectedUnpaidMonthsFunc(e, row);
                                }}
                                name={row.id}
                                checked={
                                  selectedUnpaidIds.length > 0
                                    ? selectedUnpaidIds.includes(row.id)
                                    : true
                                }
                                disabled={true}
                              />
                              <TextFieldWrapper
                                label="Landlord Name"
                                disabled={true}
                                value={row.name}
                              />
                              <TextFieldWrapper
                                label={"Rent Month (Unpaid)"}
                                placeHolder="Deposit Amount"
                                disabled={true}
                                value={
                                  month[
                                    new Date(row.rent_date).getUTCMonth()
                                  ] +
                                  "-" +
                                  new Date(row.rent_date).getFullYear()
                                }
                                onChange={(e) => handleChange(e)}
                              />
                              <TextFieldWrapper
                                label="Rent Amount"
                                disabled={true}
                                value={
                                  row.gstNo != null && row.gstNo.length > 0
                                    ? parseInt(
                                        Number(row.rent_amount) +
                                          Number((row.rent_amount / 100) * 18)
                                      )
                                    : parseInt(row.rent_amount)
                                }
                                onChange={(e) => handleChange(e)}
                              />
                              <TextFieldWrapper
                                label="Status"
                                disabled={true}
                                value={row.status}
                                onChange={(e) => handleChange(e)}
                              />
                              {preData.landlord[i]?.gstNo && row.gstNo ? (
                                <>
                                  {row.invoice_number == null && (
                                    <Grid item sx={{ textAlign: "center" }}>
                                      {
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          size="small"
                                          style={{
                                            backgroundColor:
                                              row.invoice_number != null ||
                                              !selectedUnpaidIds.includes(
                                                row.id
                                              )
                                                ? "rgb(103 185 68 / 30%)"
                                                : "rgb(103 185 68 / 89%)",
                                            color: "white",
                                            fontSize: "12px",
                                            textTransform: "capitalize",
                                            minHeight: "37px",
                                            margin: "10px",
                                            minWidth: "100px",
                                          }}
                                          disabled={
                                            row.invoice_number != null ||
                                            !selectedUnpaidIds.includes(row.id)
                                          }
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            setSelectID(row.id);
                                            setUploadOpen(true);
                                            setInvoiceDetails({
                                              ...invoiceDetails,
                                              rentAmount: row.rent_amount,
                                              gstAmount:
                                                row?.gstNo !==
                                                  (null || undefined) &&
                                                row?.gstNo.length > 0
                                                  ? Number(
                                                      parseInt(
                                                        row?.rent_amount * 0.18
                                                      ).toFixed(2)
                                                    )
                                                  : 0,
                                              totalAmount:
                                                Number(row?.rent_amount) +
                                                Number(
                                                  parseInt(
                                                    row?.rent_amount * 0.18
                                                  ).toFixed(2)
                                                ),
                                              user_id: auth.id,
                                              agreement_id:
                                                agreement?.[ids[0]]
                                                  ?.agreement_id[0],
                                              code: agreement?.[ids[0]]?.code,
                                            });
                                            setRowDataForUpload(row);
                                            setMonthlyId(row.id);
                                            setInvoiceEdited(false);
                                          }}
                                        >
                                          <Typography sx={{ fontSize: "14px" }}>
                                            {"Upload"}
                                          </Typography>
                                        </Button>
                                      }
                                      <Typography
                                        variant="caption"
                                        sx={{ color: "red", fontSize: "9px" }}
                                      >
                                        {selectedUnpaidIds.includes(row.id) &&
                                        row.invoice_number == null
                                          ? "Please Upload Invoice"
                                          : ""}
                                      </Typography>
                                    </Grid>
                                  )}
                                  {row.invoice_number != null && (
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        size="small"
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          fontSize: "12px",
                                          textTransform: "capitalize",
                                          minHeight: "37px",
                                          margin: "10px",
                                          minWidth: "100px",
                                        }}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setSelectID(id);
                                          setUploadOpen(true);
                                          setCode(row.code);
                                          setMonthlyId(row.id);
                                          setInvoiceEdited(true);
                                        }}
                                      >
                                        Edit
                                      </Button>
                                      {/* <Typography variant="caption" sx={{ color: "red", fontSize: "11px" }}> */}
                                      {/* {/* {selectedUnpaidIds.includes(row.id) && row.invoice_number == (null) ? "Please Upload Invoice" : ""}  */}
                                      {/* </Typography> */}
                                    </Grid>
                                  )}
                                </>
                              ) : (
                                <Grid item>
                                  <Button
                                    style={{
                                      display: "block",
                                      minHeight: "37px",
                                      margin: "10px",
                                      minWidth: "100px",
                                      background: "transparent",
                                    }}
                                  ></Button>
                                </Grid>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>

                      {/* unpaid section ends */}
                      <Grid
                        container
                        sx={{ px: 3, mb: "25px", marginTop: "5px" }}
                        spacing={4}
                        rowSpacing={5}
                      >
                        <TextFieldWrapper
                          label="Deposit Amount (Paid)"
                          placeHolder="Deposit Amount"
                          name="depositedAmount"
                          disabled={true}
                          value={previousDeposit}
                          onChange={(e) => handleChange(e)}
                        />

                        <TextFieldWrapper
                          label="New Deposit Amount"
                          placeHolder="New Deposit Amount"
                          name="new_deposit"
                          disabled={true}
                          value={preData.deposit}
                          onChange={(e) =>
                            handleRenewal({ target: { value: e.target.value } })
                          }
                        />
                      </Grid>

                      <Grid
                        container
                        sx={{ px: 3, mb: "25px" }}
                        spacing={4}
                        rowSpacing={5}
                      >
                        <TextFieldWrapper
                          label="Selected Unpaid Months Amount"
                          placeHolder="Selected UnPaid Months Amount"
                          name="unPaidMonthsAmount"
                          disabled={true}
                          value={unPaidMonthsAmount}
                        />
                        <TextFieldWrapper
                          label="Balance Deposit Amount"
                          placeHolder="Balance Deposit Amount"
                          name="depositedAmount"
                          disabled={true}
                          value={renewal.balance_amount}
                        />
                      </Grid>

                      <Grid
                        container
                        sx={{ px: 3, mb: "25px" }}
                        spacing={4}
                        rowSpacing={5}
                      >
                        <TextFieldWrapper
                          label={`Balance Deposit Payable`}
                          placeHolder="Balance Deposit Payable"
                          name="depositedAmount"
                          disabled={true}
                          value={renewal.receivable}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      sx={{ mt: 5 }}
                      className={"textFieldWrapper"}
                    >
                      <Grid item xs={8}>
                        <TextField
                          type="text"
                          multiline
                          rows={3}
                          fullWidth
                          variant="outlined"
                          label="Landlord Assets *"
                          helperText={
                            <span style={{ color: "rgb(198, 89, 17)" }}>
                              {"Old Landlord Assets:" + partLabel?.assets}
                            </span>
                          }
                          placeholder="Landlord Assets *"
                          value={preData.assets}
                          name={"assets"}
                          onChange={handleCommonChange}
                          error={formError.assets ? true : false}
                        />
                      </Grid>
                    </Grid>
                    <Typography sx={{ color: "red" }} variant="caption">
                      {formError.assets}
                    </Typography>

                    <Grid item xs={12} mt={2}>
                      <Typography
                        sx={{ fontSize: "1.4rem", fontWeight: 700 }}
                        color="primary"
                      >
                        Renewal Remark
                        <span style={{ color: "inherit", marginLeft: "4px" }}>
                          *
                        </span>
                      </Typography>

                      <TextField
                        type="text"
                        multiline
                        rows={3}
                        fullWidth
                        variant="outlined"
                        placeholder="Renewal Remark*"
                        value={preData.renewal_remark}
                        onChange={(e) =>
                          setPreData((old) => ({
                            ...old,
                            renewal_remark: e.target.value,
                          }))
                        }
                      />
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {formError.renewal_remark}
                      </Typography>
                    </Grid>
                    <UploadInvoice
                      open={openUpload}
                      handleClose={() => setUploadOpen(false)}
                      handleConfirm={uploadInvoiceDetails}
                      value={invoiceDetails}
                      rowDataForUpload={rowDataForUpload}
                      setRowDataForUpload={setRowDataForUpload}
                      setValue={setInvoiceDetails}
                      code={code}
                      monthlyId={monthlyId}
                    />
                    {/* New Renewal Disposite Form ends */}

                    {/* Button Start from here */}
                    <Grid
                      container
                      sx={{ justifyContent: "center", mt: 2 }}
                      spacing={4}
                    >
                      <Grid item md={4} xs={6}>
                        <Button
                          onClick={handleSubmit}
                          variant="contained"
                          type="submit"
                          color="primary"
                          sx={{
                            height: "60px",
                            width: "100%",
                            borderRadius: "20px",
                            fontSize: "16px",
                            color: "#FFFFFF",
                            lineHeight: "32px",
                            textTransform: "capitalize",
                            "@media(max-width:900px)": {
                              fontSize: "11px",
                              lineHeight: "12px",
                              height: "40px",
                            },
                          }}
                        >
                          {preData.status === "Sent Back From Operations"
                            ? " Send To Operations"
                            : "Send To Sr Manager"}
                        </Button>
                      </Grid>
                    </Grid>

                    {/* Button Ends Here */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </>
      )}
    </>
  );
}

export default EditAgreement;
