import React from "react";
import HamburgerMenu from "../HamburgerMenu";

function HamburgerManager() {
  return (
    <HamburgerMenu
      navigateHome={"dashboard"}
      monthlyBtn="true"
      agreements={[
        { text: "In-Process ", navigateTo: "/listing/in-procces-ag" },
        {
          text: "Active / In Active Agreements ",
          navigateTo: "/listing/approved-ag",
        },

        { text: "Renewal / Termination ", navigateTo: "/renewal" },
        { text: "Landlord List", navigateTo: "/list-landlord" },
        { text: "Migrated Agreements ", navigateTo: "/listing/mg-ag" },
      ]}
      monthly={[
        { text: "In-Process", navigateTo: "/monthly-payment/in-process" },
        { text: "Paid", navigateTo: "/monthly-payment/paid" },
      ]}
      misReports={[
        { text: "Rental MIS Reports", navigateTo: "/rental-mis-reports" },
        { text: "Graph Reports", navigateTo: "/graph-reports" },
        { text: "Rental Due Schedule", navigateTo: "/rent-paid-schedule" },
      ]}
    />
  );
}

export default HamburgerManager;
