/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MyHeader,
  TextFieldWrapper,
} from "../StyledComponent";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

import {
  AddUser,
  GetSupervisor,
  GetSupervisorSRM,
  getCityList,
  getStateList,
} from "../../Services/Services";
import AddUserCheckBox from "../StyleComponents/AddUserCheckBox";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../store/action/action";
import HamburgerAdmin from "./HamburgerAdmin";

const initialState = {
  code: "",
  name: "",
  email: "",
  role: [],
  mobile: "",
  supervisor: "",
  state: "",
  city: "",
};

function NewUser() {
  const navigate = useNavigate();
  const [randomPassword, setRandomPassword] = useState("");
  const [error, setError] = useState({ code: "" });

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [isSupervisorDisabled, setIsSupervisorDisabled] = useState(false);
  const dispatch = useDispatch();

  const superVisor = [
    "Admin",
    "Finance",
    "BUH",
    "Operations",
    "Senior_Manager",
    "Manager",
  ];

  const [loading, setLoading] = useState(false);
  // Disable Role CheckBox Base On Condition
  const [disable, setDisable] = useState({});
  const [formData, setFormData] = useState(initialState);
  const [formError, setformError] = useState({ code: "" });
  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    const isSpecialChar =
      (!e.shiftKey && charCode >= 33 && charCode <= 47) ||
      (charCode >= 58 && charCode <= 64) ||
      (charCode >= 91 && charCode <= 96) ||
      (charCode >= 123 && charCode <= 126);

    if (isSpecialChar) {
      e.preventDefault();
    }
  };
  function validate(e) {
    const error = {};
    if (e.target.value < 1 && e.target.name === "code") {
      error.code = "Emp.code Required!";
    } else if (
      e.target.value.length !== 0 &&
      e.target.value.length < 4 &&
      e.target.name === "name"
    ) {
      error.name = "Name must be of 4 character.";
    } else if (
      !e.target.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) &&
      e.target.value.length > 0 &&
      e.target.name === "email"
    ) {
      error.email = "Please Enter Valid Email !!";
    } else if (
      e.target.value.length !== 0 &&
      e.target.value.length < 10 &&
      e.target.name === "mobile"
    ) {
      error.mobile = "Mobile Number Not Valid.";
    }
    setformError(error);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "code" && /[^a-zA-Z0-9]/.test(value)) {
      e.preventDefault();
      return;
    }
    if (name === "code" && value.trim().length < 1) {
      error.code = "Emp.code Required!";
    }
    if (e.target.name === "role") {
      setFormData((old) => ({
        ...old,
        role: old.role.includes(e.target.value)
          ? old.role.filter((row) => row !== e.target.value)
          : [...old.role, e.target.value],
      }));
    } else if (name === "name" && /[^a-zA-Z\s]/.test(value)) {
      e.preventDefault();
      return;
    } else if (e.target.name === "mobile") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
    if (name === "supervisor") {
      setSupervisorSelected(value !== "");
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (Object.keys(formError).length < 1) {
      if (role.length < 1) {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Please Select Role",
          })
        );
      } 
      else if (!isSupervisorDisabled && !supervisorSelected) {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Please Select Supervisor",
          })
        );
      }  else {
        apiCall(formData, randomPassword);
      }
    }
  };

  //distructring elements from values
  const { code, name, email, role, mobile, supervisor, state, city } = formData;

  // state for set supervisor value
  const [supervisorArray, setsupervisorArray] = useState([]);
  const [supervisorSelected, setSupervisorSelected] = useState(false);

  useEffect(() => {
    setsupervisorArray([]); // Reset the supervisorArray to an empty array
    manageRole(role);
    getSupervisor(role);
    setSupervisorSelected(false); // Reset supervisorSelected when roles change
    setFormData((old) => ({ ...old, supervisor: "" })); // Reset supervisor value
  }, [role]);


  // Supervisor value
  async function getSupervisor(role) {
    let superVisor1 = [
      "Finance",
      "Operations",
      "BUH",
      "Senior_Manager",
      "Manager",
      "Role",
    ];

    let finalQuerry = [];
    if (role.includes("Manager")) {
      finalQuerry = ["Senior_Manager"];
    } else if (role.includes("Senior_Manager")) {
      finalQuerry = ["BUH"];
    } else if (role.includes("BUH")) {
      finalQuerry = ["Operations"];
    } else if (role.includes("Operations")) {
      finalQuerry = ["BUH"];
    } else if (role.includes("Finance")) {
      finalQuerry = ["Role"];
    } else if (role.includes("Admin")) {
      finalQuerry = ["BUH"];
    }
    superVisor1 = superVisor1.filter((row) => {
      return finalQuerry.includes(row);
    });

    if (role.includes("Manager")) {
      const supervisor = await GetSupervisor({
        role: superVisor1,
        state,
        city,
      });
      setsupervisorArray(supervisor.data);
    } else if (
      role.includes("Senior_Manager") ||
      role.includes("Operations") ||
      role.includes("Admin") ||
      role.includes("BUH")
    ) {
      const supervisor = await GetSupervisorSRM(superVisor1);
      setsupervisorArray(supervisor.data);
    }
  }

  // Role Check Box Disable Manage
  function manageRole(role) {
    let setVal = {};
    if (
      (role.includes("Manager") || role.includes("Operations")) &&
      role.length > 0
    ) {
      superVisor.map(
        (row) =>
          (setVal = {
            ...setVal,
            [row]: true,
            Admin: false,
            Operations: !role.includes("Operations"),
            Manager: !role.includes("Manager"),
            [role[0] === "Senior Manager" ? "Senior_Manager" : role[0]]: false,
          })
      );
      setDisable(setVal);
    } else if (role.includes("Admin") && role.length === 1) {
      superVisor.map(
        (row) =>
          (setVal = {
            ...setVal,
            [row]: true,
            Admin: false,
            Operations: false,
            Manager: false,
          })
      );
      setDisable(setVal);
    } else if (role.includes("Senior_Manager")) {
      superVisor.map(
        (row) =>
          (setVal = {
            ...setVal,
            [row]: true,
            Senior_Manager: false,
          })
      );
      setDisable(setVal);
    } else if (role.includes("Finance")) {
      superVisor.map(
        (row) =>
          (setVal = {
            ...setVal,
            [row]: true,
            Finance: false,
          })
      );
      setDisable(setVal);
    } else if (role.includes("BUH")) {
      superVisor.map(
        (row) =>
          (setVal = {
            ...setVal,
            [row]: true,
            BUH: false,
          })
      );
      setDisable(setVal);
    } else if (role.length < 2) {
      superVisor.map((row) => (setVal = { ...setVal, [row]: false }));
      setDisable(setVal);
    } else if (role.includes("Admin") && role.length === 2) {
      superVisor.map(
        (row) =>
          (setVal = {
            ...setVal,
            [row]: true,
            Admin: false,
            [role[1] === "Senior Manager" ? "Senior_Manager" : role[1]]: false,
          })
      );
    }
    if (role.includes("Finance") || role.includes("BUH")) {
      setsupervisorArray([]);
      setIsSupervisorDisabled(true);
    } else {
      setIsSupervisorDisabled(false);
      setsupervisorArray(supervisor.data);
    }
    setDisable(setVal);
  }

  const passwordGenerate = () => {
    let length = 6,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      random = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      random += charset.charAt(Math.floor(Math.random() * n));
      setRandomPassword(random);
    }
  };

  useEffect(() => {
    passwordGenerate();
  }, []);

  // For save data in DB
  const apiCall = async (values, randomPassword) => {
    values = { ...values, password: randomPassword };
    setLoading(true);
    const result = await AddUser(values);

    if (result.status === 201) {
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: result.data.message,
        })
      );
      navigate("/userManagement/all-user");
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Email has already register",
        })
      );
    }
    setLoading(false);
  };

  // funciton for fetching state list
  async function handleStateSearch(e, i) {
    let response = await getStateList(e.target.value);

    if (response.status === 200) {
      setStateList(response.data);
    } else setStateList([]);
  }

  useEffect(() => {
    handleCitySearch();
  }, [state]);

  // funciton for fetching state list
  async function handleCitySearch() {
    let search = stateList.filter((row) => row.name === state && row.id);
    if (search.length > 0) {
      let response = await getCityList(search[0].id);

      if (response.status === 200) {
        setCityList(response.data);
      } else setCityList([]);
    }
  }
  const { auth } = useSelector((s) => s);

  const disabledDropdownStyle = {
    opacity: 0.3,
    cursor: "not-allowed",
  };

  return (
    <>
      <Stack sx={{ flexWrap: "nowrap", flexDirection: "row" }}>
        <HamburgerAdmin />

        <Box className="backButton">
          <IconButton
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
            size={"large"}
          >
            <ArrowCircleLeftIcon
              sx={{ fontSize: "3rem" }}
              color="#FFFFF !important"
            />
          </IconButton>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <>
            <Grid container sx={{ justifyContent: "center", mt: 2 }}>
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "space-between", display: "flex" }}
                >
                   <MyHeader>RMS <span className="small-heading">(Rental Management System)</span></MyHeader>

                  <Typography mt="15px" mr="15px" fontWeight="600">
                    Welcome {auth.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
          <Divider />
          <Grid
            container
            sx={{
              px: 1,
              justifyContent: "space-between",
              my: 1,
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <Typography
                variant="body1"
                color="black"
                fontSize="20px"
                fontWeight="600"
                alignSelf="center"
                lineHeight="30px"
                sx={{
                  "@media(max-width:600px)": {
                    fontSize: "17px",
                    lineHeight: "25px",
                  },
                }}
              >
                {"New User"}
              </Typography>
            </Grid>
          </Grid>
          <Divider />

          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item>
              <Box
                component="form"
                sx={{
                  py: 5,
                  backgroundColor: "white",
                  mx: 3,
                  borderRadius: "15px",
                  maxWidth: "1050px",
                }}
                onSubmit={handleSubmit}
              >
                <Grid container sx={{ p: 3 }} spacing={4}>
                  {/* Dipali */}
                  <TextFieldWrapper
                    label="Emp.Code"
                    placeHolder="Employee Code"
                    name="code"
                    required={true}
                    value={code}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    error={formError.code}
                    onBlur={validate}
                    type={Number}
                    maxLength={10}
                  />

                  <TextFieldWrapper
                    label="Full Name"
                    placeHolder="Full Name"
                    value={name}
                    name="name"
                    onBlur={(e) => validate(e)}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    required={true}
                    error={formError.name}
                    type={Text}

                    //onBlur={validate}
                  />
                  <TextFieldWrapper
                    label="Email"
                    placeHolder="Email"
                    value={email}
                    name="email"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => validate(e)}
                    required={true}
                    type={"email"}
                    error={formError.email}
                  />
                  <TextFieldWrapper
                    label="Mobile Number"
                    placeHolder="Mobile Number"
                    value={mobile}
                    name="mobile"
                    required={true}
                    error={formError.mobile}
                    maxLength={10}
                    onBlur={(e) => validate(e)}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <Grid
                    item
                    md={4}
                    xs={6}
                    sx={{
                      mb: "0px !important",
                      "@media(max-width:900px)": { my: 1 },
                    }}
                  >
                    <FormControl fullWidth className="textFieldWrapper">
                      <Autocomplete
                        freeSolo
                        fullWidth
                        id="free-solo-2-demo"
                        disableClearable
                        onChange={(e, val) => {
                          setFormData((old) => ({ ...old, state: val }));
                        }}
                        options={stateList.map((option) => option.name)}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            name="state"
                            value={state}
                            {...params}
                            label="State"
                            type={Text}
                            required
                            onBlur={(e) => validate(e)}
                            onChange={(e) => {
                              handleStateSearch(e);
                            }}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={6}
                    sx={{
                      mb: "0px !important",
                      "@media(max-width:900px)": { my: 1 },
                    }}
                  >
                    <FormControl fullWidth className="textFieldWrapper">
                      <TextField
                        label="City"
                        placeHolder="Enter City"
                        select
                        fullWidth
                        name="city"
                        // required={true}
                        value={city || ""}
                        onChange={handleChange}
                        required
                      >
                        {cityList &&
                          cityList.map((item) => {
                            return (
                              <MenuItem value={item.city}>{item.city}</MenuItem>
                            );
                          })}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <AddUserCheckBox
                    handleChange={handleChange}
                    disable={disable}
                    setDisable={setDisable}
                    required={true}
                    error={formError.name}
                    //onBlur={validate}
                  />
                  <Grid
                    item
                    md={4}
                    xs={6}
                    sx={{
                      mb: "0px !important",
                      "@media(max-width:900px)": { my: 1 },
                    }}
                  >
                    <FormControl fullWidth className={`textFieldWrapper ${isSupervisorDisabled ? "disabled-dropdown" : ""}`}
                      sx={isSupervisorDisabled ? { ...disabledDropdownStyle } : {}}>
                      <TextField
                        label="Supervisor *"
                        select
                        fullWidth
                        name={"supervisor"}
                        value={supervisor}
                        onChange={handleChange}
                        disabled={isSupervisorDisabled}
                        error={!supervisorSelected && !isSupervisorDisabled}
                      >
                        {supervisorArray?.map((item, i) => {
                          return (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container sx={{ justifyContent: "space-evenly", mt: 3 }}>
                  <Grid item sm={3.0}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        height: "65px",
                        width: "100%",
                        borderRadius: "9px",
                        fontSize: "16px",
                        color: "#FFFFFF",
                        lineHeight: "32px",
                        textTransform: "capitalize",
                      }}
                      type="submit"
                      disabled={!!loading}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );
}

export default NewUser;
