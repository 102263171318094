/* eslint-disable no-unused-vars */
import { Button, Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  sendMonthyPaymentForwordNew,
  notification_handler,
} from "../../../Services/Services";
import { setAlert, setRefreshBox } from "../../../store/action/action";

import Remark from "../../RentalPortal/Remark";

export default function ListingTable({ rows, props }) {
  const { auth, refresh } = useSelector((s) => s);
  const { type } = useParams();
  const role = auth.role;

  const { id } = useParams();

  const subType = "monthly";

  const [ids, setIds] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [remarkOpen, setRemarkOpen] = useState(false);

  const [remarkMSG, setRemarkMSG] = useState("");

  const [agreementData, setAgreementData] = useState([]);
  const [agreementIds, setAgreementIds] = useState([]);

  const renderDetailsButton = (e) => {
    const id = e.id;

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{
          backgroundColor: "rgb(103 185 68 / 89%)",
          color: "white",
          fontSize: "12px",
          textTransform: "capitalize",
        }}
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/srm-monthly-view/${id}`);
        }}
      >
        View
      </Button>
    );
  };

  async function sendToOperations() {
    for (const data of agreementData) {
      const send = await sendMonthyPaymentForwordNew(
        data.id,
        auth.role,
        "monthly",
        {
          status: "Sent To Operations",
          srm_id: auth.id,
          remark: remarkMSG,
          additionalRemark: {
            remarks: remarkMSG,
            step_name: auth.name,
            user_id: auth.id,
            agreement_code: data.code,
            agreement_id: data.id,
          },
        },
        data.id,
        role,
        subType
      );

      if (send.data.success) {
        await notification_handler(
          "MR7",
          data?.agreement_id,
          data?.code,
          auth.id,
          false,
          data?.id,
          data?.landlord_name
        );
        setIds([]);
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message: "Payment Details Sent To Operations.",
          })
        );
        setRemarkOpen(false);
        dispatch(setRefreshBox());
        //  navigate(-1)
      } else if (send.data.success === false) {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: send.data.message,
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Something Went Wrong Please Try Again Later.",
          })
        );
      }
    }
    setAgreementData([]);
  }
  const handleSwitch = (e, data) => {
    agreementData.push(data);
    if (agreementIds.includes(data.id)) {
      setAgreementIds(agreementIds.filter((i) => i !== data.id));
    } else {
      agreementIds.push(data.id);
    }

    let idVar = Number(e.target.name);
    if (ids.includes(idVar)) {
      setIds(ids.filter((i) => i !== idVar));
    } else {
      setIds([...ids, idVar]);
    }
  };

  const columns = [
    {
      field: "checkbox",
      disableColumnMenu: true,
      sortable: false,
      width: 20,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.formattedValue === "Sent To Sr Manager" ? (
            <Checkbox
              onChange={(e) => handleSwitch(e, params.row)}
              name={params.id}
              checked={!!agreementIds.includes(params.id)}
            />
          ) : (
            <Checkbox disabled={true} />
          )}
        </>
      ),
    },

    {
      field: "code",
      headerName: "Code",
      minWidth: 100,
      flex: 1,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "rent_date",
      headerName: "Rent Month",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "name",
      headerName: "Landlord Name",
      minWidth: 150,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 150,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "gst",
      headerName: "GST Number",
      minWidth: 180,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "manager",
      headerName: "Manager Name",
      minWidth: 130,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "total_month_rent",
      headerName: "Month Rent",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "percentage",
      headerName: "Percentage Share",
      minWidth: 150,
      headerAlign: "center",
    },
    {
      field: "rent_amount",
      headerName: "Payable Rent",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "gst_fee",
      headerName: "GST",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "total_rent",
      headerName: "Total Rent Payable",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      minWidth: 200,
      flex: 1,
    },
    // {d
    //   field: "utr",
    //   headerName: "UTR Number",
    //   minWidth: 100,
    //   headerAlign: "center",
    //   flex: 1hhh

    // },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      flex: 1,
      renderCell: renderDetailsButton,
      minWidth: 150,
    },
  ];
  const approvedColumns = [
    {
      field: "code",
      headerName: "Code",
      minWidth: 100,
      flex: 1,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "rent_date",
      headerName: "Rent Month",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "name",
      headerName: "Landlord Name",
      minWidth: 150,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 150,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "gst",
      headerName: "GST Number",
      minWidth: 180,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "manager",
      headerName: "Manager Name",
      minWidth: 130,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "total_month_rent",
      headerName: "Month Rent",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "percentage",
      headerName: "Percentage Share",
      minWidth: 150,
      headerAlign: "center",
    },
    {
      field: "rent_amount",
      headerName: "Payable Rent",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "gst_fee",
      headerName: "GST",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "total_rent",
      headerName: "Total Rent Payable",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      flex: 1,
      renderCell: renderDetailsButton,
      minWidth: 150,
    },
  ];
  return (
    <>
      {ids.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", m: 1, mx: 3 }}
            onClick={() => setRemarkOpen(true)}
          >
            Send To Operations
          </Button>
        </Box>
      )}
      <Remark
        remark={remarkMSG}
        setIds={setIds}
        setRemark={setRemarkMSG}
        handleSend={sendToOperations}
        open={remarkOpen}
        handleClose={() => {
          setRemarkOpen(false);
          setRemarkMSG("");
        }}
      />

      <Box
        sx={{
          height: "430px",
          px: 2,
          "& .dataGridHeader": {
            color: "#CACACA",
            textAlign: "left",
          },
          "& .green": {
            backgroundColor: "#E7FFE9",
            color: "#41CF2A",
          },
          "& .yellow": {
            backgroundColor: "#FEFFC8",
            color: "#C5C05B",
          },
          "& .red": {
            backgroundColor: "#FFEBE7",
            color: "#CF482A",
          },
          "& .statusCell": {
            // maxWidth:"180px !important",
            // minWidth:"92px !important",
            maxHeight: "30px !important",
            minHeight: "25px !important",
            alignSelf: "center",
            mx: "1",
            textAlign: "center !important",
            borderRadius: "10px !important",
          },
          "& .allCell": {
            justifyContent: "center !important",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={type === "paid" ? approvedColumns : columns}
          pageSize={6}
          rowsPerPageOptions={[6]}
          // checkboxSelection
          sx={{ color: "black !important", minWidth: "50px" }}
          getCellClassName={(parms) => {
            let cellClass = [];
            if (parms.field === "status" && parms.row.status === "Paid") {
              cellClass.push("green statusCell");
            } else if (
              parms.field === "status" &&
              (parms.row.status === "Sent To Sr Manager" ||
                parms.row.status === "Sent To BUH" ||
                parms.row.status === "Sent To Operations" ||
                parms.row.status === "Sent To Finance" ||
                parms.row.status === "Approved By Finance" ||
                parms.row.status === "Draft")
            ) {
              cellClass.push("yellow statusCell");
            } else if (
              parms.field === "status" &&
              (parms.row.status === "Sent Back From Sr Manager" ||
                parms.row.status === "Sent Back From BUH" ||
                parms.row.status === "Sent Back From Operations" ||
                parms.row.status === "Sent Back From Finance" ||
                parms.row.status === "Terminated")
            ) {
              cellClass.push("red statusCell");
            }
            cellClass.push("allCell");

            return cellClass;
          }}
          // onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
        ></DataGrid>
      </Box>
    </>
  );
}
