/* eslint-disable no-unused-vars */
import { Button, Checkbox, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  create_monthly_receipt_id,
  getPaymentModifyDate,
  notification_handler,
  sendMonthyPaymentForwordNew,
  sendMonthyPaymentForwordNew2,
} from "../../../Services/Services";
import { setAlert, setRefreshBox } from "../../../store/action/action";
import DialogBoxSBM from "../../RentalPortal/DialogBoxSBM";
import Remark from "../../RentalPortal/Remark";

export default function ListingTable({ rows }) {
  const { type } = useParams();
  const [utr, setUtr] = useState({ utr: "", paymentDate: "" });
  const [ids, setIds] = useState([]);
  const navigate = useNavigate();

  const [open, setopen] = useState({ open: false });

  const { auth } = useSelector((s) => s);

  const role = auth.role;

  const subType = "monthly";

  const [agreementData, setAgreementData] = useState([]);
  const [agreementIds, setAgreementIds] = useState([]);

  const dispatch = useDispatch();

  const [modifyDate, setModifyDate] = useState("");

  async function getUpdateDate(id) {
    try {
      const response = await getPaymentModifyDate(id);
      if (response.status === 200) {
        setModifyDate(response.data.modify_date);
        setopen({ open: true, id: id });
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }

  const renderDetailsButton = (e) => {
    const id = e.id;

    return (
      <Grid container justifyContent={"center"}>
        {/* <DialogBoxSBM
            open={open.open}
            handleClose={() => setopen({...open,open : false})}
            handleConfirm={handleConfirm}
            value={utr}
            setValue={setUtr}
          /> */}
        <Grid item xs={6} spacing={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              backgroundColor: "rgb(103 185 68 / 89%)",
              color: "white",
              fontSize: "12px",
              textTransform: "capitalize",
            }}
            onClick={(e) => {
              e.stopPropagation(); // don't select this row after clicking
              navigate(`/finance-monthly-view/${id}`);
            }}
          >
            View
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderActionButton = (e) => {
    const id = e.id;

    return (
      <Grid container justifyContent={"center"}>
        {e.row.status === "Approved By Finance" && (
          <Grid item xs={6} spacing={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{
                backgroundColor: "rgb(103 185 68 / 89%)",
                color: "white",
                fontSize: "12px",
                textTransform: "capitalize",
                // width:"100%"
              }}
              onClick={(e) => {
                e.stopPropagation(); // don't select this row after clicking
                // navigate(`/finance-monthly-view/${id}`);
                getUpdateDate(id);
              }}
            >
              UTR Number
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };
  const handleSwitch = (e, data) => {
    agreementData.push(data);
    if (agreementIds.includes(data.id)) {
      setAgreementIds(agreementIds.filter((i) => i !== data.id));
    } else {
      agreementIds.push(data.id);
    }

    let idVar = Number(e.target.name);
    if (ids.includes(idVar)) {
      setIds(ids.filter((i) => i !== idVar));
    } else {
      setIds([...ids, idVar]);
    }
  };

  const approvedColumns = [
    {
      field: "code",
      headerName: "Code",
      minWidth: 100,
      flex: 1,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "month_of_rent",
      headerName: "Rent Month",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Landlord Name",
      minWidth: 170,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 150,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "gst",
      headerName: "GST",
      minWidth: 100,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "manager",
      headerName: "Manager",
      minWidth: 100,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "srm_name",
      headerName: "Sr Manager",
      minWidth: 100,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "Operations",
      minWidth: 100,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "total_month_rent",
      headerName: "Month Rent",
      headerAlign: "center",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "percentage",
      headerName: "Percentage Share",
      minWidth: 150,
      headerAlign: "center",
    },
    {
      field: "rent_amount",
      headerName: "Payable Rent",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "gst_fee",
      headerName: "GST",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "total_rent",
      headerName: "Total Rent Payable",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      minWidth: 200,
      flex: 1,
    },
    // {
    //   field: "utr",
    //   headerName: "UTR Number",
    //   minWidth: 100,
    //   headerAlign: "center",
    //   flex: 1,
    // },
    {
      field: "view",
      headerName: "View",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      renderCell: renderDetailsButton,
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   headerAlign: "center",
    //   flex: 1,
    //   minWidth: 200,
    //   renderCell: renderActionButton,
    // },
  ];
  const columns = [
    {
      field: "checkbox",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 20,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.formattedValue === "Sent To Finance" ? (
            <Checkbox
              onChange={(e) => handleSwitch(e, params.row)}
              name={params.id}
              checked={!!agreementIds.includes(params.id)}
            />
          ) : (
            <Checkbox disabled={true} />
          )}
        </>
      ),
    },

    {
      field: "code",
      headerName: "Code",
      minWidth: 100,
      flex: 1,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "month_of_rent",
      headerName: "Rent Month",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Landlord Name",
      minWidth: 170,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 150,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "gst",
      headerName: "GST",
      minWidth: 100,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "manager",
      headerName: "Manager",
      minWidth: 100,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "srm_name",
      headerName: "Sr Manager",
      minWidth: 100,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "Operations",
      minWidth: 100,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "total_month_rent",
      headerName: "Month Rent",
      headerAlign: "center",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "percentage",
      headerName: "Percentage Share",
      minWidth: 150,
      headerAlign: "center",
    },
    {
      field: "rent_amount",
      headerName: "Payable Rent",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "gst_fee",
      headerName: "GST",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "total_rent",
      headerName: "Total Rent Payable",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      minWidth: 200,
      flex: 1,
    },
    // {
    //   field: "utr",
    //   headerName: "UTR Number",
    //   minWidth: 100,
    //   headerAlign: "center",
    //   flex: 1,
    // },
    {
      field: "view",
      headerName: "View",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      renderCell: renderDetailsButton,
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   headerAlign: "center",
    //   flex: 1,
    //   minWidth: 200,
    //   renderCell: renderActionButton,
    // },
  ];

  const [remarkOpen, setRemarkOpen] = useState(false);

  const [remarkMSG, setRemarkMSG] = useState("");

  async function handleApprove() {
    agreementData.map(async (data) => {
      const send = await sendMonthyPaymentForwordNew(
        data.id,
        auth.role,
        "monthly",
        {
          status: "Approved By Finance",
          finance_id: auth.id,
          additionalRemark: {
            remarks: remarkMSG,
            step_name: auth.name,
            user_id: auth.id,
            agreement_code: data.code,
            agreement_id: data.id,
            modified_date: new Date(),
          },
        },
        data.id,
        role,
        subType
      );
      if (send.data.success) {
        await notification_handler(
          "MR17",
          data?.agreement_id,
          data?.code,
          auth.id,
          false,
          data?.id,
          data?.landlord_name
        );
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message: "Approved",
          })
        );
        setIds([]);
        dispatch(setRefreshBox());
        //  navigate(-1)
        setRemarkOpen(false);
      } else if (send.data.success === false) {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: send.data.message,
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Something Went Wrong Please Try Again Later.",
          })
        );
      }
    });
  }

  const handleConfirm = async (e) => {
    const response = await sendMonthyPaymentForwordNew2(
      open.id,
      auth.role,
      "monthly",
      {
        status: "Paid",
        utr_number: utr.utr,
        payment_date: utr.paymentDate,
        update_at: new Date(),
        additionalRemark: null,
      }
    );
    const rowData = rows.find((data) => data.id === open.id);

    if (response.data.success) {
      const addmontlyid = await create_monthly_receipt_id(open.id);
      console.log({ open });
      await notification_handler(
        "MR19",
        rowData.agreement_id,
        rowData.code,
        auth.id,
        false,
        rowData.monthly_id,
        rowData.landlord_name
      );
      setUtr({ utr: "", paymentDate: "" });
      setopen({ open: false });

      dispatch(
        setAlert({
          variant: "success",
          open: true,
          message: "UTR Number Added.",
        })
      );
      dispatch(setRefreshBox());
    } else if (response.data.success === false) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message,
        })
      );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Something went wrong! Please again later.",
        })
      );
    }
  };

  return (
    <>
      {ids.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", m: 1, mx: 3 }}
            onClick={() => setRemarkOpen(true)}
          >
            Approve
          </Button>
        </Box>
      )}
      <Remark
        remark={remarkMSG}
        setRemark={setRemarkMSG}
        handleSend={handleApprove}
        open={remarkOpen}
        handleClose={() => setRemarkOpen(false)}
      />

      <DialogBoxSBM
        open={open.open}
        handleClose={() => setopen({ ...open, open: false })}
        handleConfirm={handleConfirm}
        value={utr}
        setValue={setUtr}
        modifyDate={modifyDate}
      />

      <Box
        sx={{
          height: "430px",
          px: 2,
          "& .dataGridHeader": {
            color: "#CACACA",
            textAlign: "left",
          },
          "& .green": {
            backgroundColor: "#E7FFE9",
            color: "#41CF2A",
          },
          "& .yellow": {
            backgroundColor: "#FEFFC8",
            color: "#C5C05B",
          },
          "& .red": {
            backgroundColor: "#FFEBE7",
            color: "#CF482A",
          },
          "& .statusCell": {
            // maxWidth:"180px !important",
            // minWidth:"92px !important",
            maxHeight: "30px !important",
            minHeight: "25px !important",
            alignSelf: "center",
            mx: "1",
            textAlign: "center !important",
            borderRadius: "10px !important",
          },
          "& .allCell": {
            justifyContent: "center !important",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={type === "paid" ? approvedColumns : columns}
          pageSize={6}
          rowsPerPageOptions={[6]}
          // checkboxSelection
          sx={{ color: "black !important", minWidth: "50px" }}
          getCellClassName={(parms) => {
            let cellClass = [];
            if (parms.field === "status" && parms.row.status === "Paid") {
              cellClass.push("green statusCell");
            } else if (
              parms.field === "status" &&
              (parms.row.status === "Sent To Sr Manager" ||
                parms.row.status === "Sent To BUH" ||
                parms.row.status === "Sent To Operations" ||
                parms.row.status === "Sent To Finance" ||
                parms.row.status === "Draft" ||
                parms.row.status === "Approved By Finance")
            ) {
              cellClass.push("yellow statusCell");
            } else if (
              parms.field === "status" &&
              (parms.row.status === "Sent Back From Sr Manager" ||
                parms.row.status === "Sent Back From BUH" ||
                parms.row.status === "Sent Back From Operations" ||
                parms.row.status === "Sent Back From Finance")
            ) {
              cellClass.push("red statusCell");
            }
            cellClass.push("allCell");

            return cellClass;
          }}
          // onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
        ></DataGrid>
      </Box>
    </>
  );
}
