/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataFieldStyle,
  DocumentView,
  YearField,
} from "../StyleComponents/Rental";
import { MyHeader, TextFieldWrapper } from "../StyledComponent";
import { useEffect, useState } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  get_agreement_id,
  send_to_bhu,
  get_remarks_by_code,
  get_old_agreement,
  send_back_to_manager,
  notification_handler,
} from "../../Services/Services";
import { setAlert } from "../../store/action/action";
import { useDispatch, useSelector } from "react-redux";
import HamburgerManager from "../Manager/HamburgerManager";
import UploadInvoice from "../MonthalyPayment/UploadInvoiceMonthly";

const Heading = ({ heading }) => {
  return (
    <Grid item xs={11} sx={{ mt: 6, mb: 2 }}>
      <Typography
        variant="body1"
        fontSize={"20px"}
        color={"primary"}
        fontWeight={"600"}
        sx={{ textDecoration: "underline" }}
      >
        {heading}
      </Typography>
    </Grid>
  );
};

function RenewalViewPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { auth } = useSelector((s) => s);
  const login_manager_id = auth.id;
  const role = auth.role;

  const [agreement, setAgreement] = useState({});
  const [previousDeposit, setPreviousDeposit] = useState();
  const [unPaidMonthsAmount, setUnpaidMonthsAmount] = useState(0);
  const [ids, setIds] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectID, setSelectID] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [renewalAdjustment, setRenewalAdjustment] = useState({});
  const [upaid, setUnpaid] = useState([]);
  const [finalLockedInData, setFinalLockedInData] = useState([]);
  const [i, setIndex] = useState(0);
  const dispatch = useDispatch();

  const [allRemarks, setAllRemarks] = useState("");

  const [remark, setRemark] = useState("");
  const [recovery, setRecovery] = useState({});
  const [unpaidAmount, setUnpaidAmount] = useState();
  const [monthlyId, setMonthlyId] = useState(0);
  const [code, setCode] = useState(0);
  const [oldIds, setOldIds] = useState([]);
  const [partLabel, setPartLable] = useState({
    landlord: [
      {
        accountNo: "",
        alternateMobile: "",
        area: "",
        bankName: "",
        benificiaryName: "",
        branchName: "",
        cheque: "",
        email: "",
        gst: null,
        gstNo: null,
        ifscCode: "",
      },
    ],
  });

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const subType = "renewal";

  async function get_old_data(code) {
    try {
      const oldvalue = await get_old_agreement(code);
      oldvalue.status === 200 &&
        setPartLable(Object.values(oldvalue.data.agreement)[0]);
      oldvalue.status === 200 && setOldIds(oldvalue.data.ids);
    } catch (error) {}
  }

  const getData = async (id) => {
    const agreement = await get_agreement_id(id);
    if (agreement.status === 200) {
      setPreviousDeposit(agreement.data.agreement.deposit);
      setAgreement(agreement.data.agreement);
      setIds(agreement.data.ids);
      get_old_data(agreement.data.agreement[agreement.data.ids[0]].code);
      if (agreement.data.recoveryResult.length > 0) {
        setRecovery(agreement.data.recoveryResult[0]);
      }
      if (agreement.data.renewalDepositeResult.length > 0) {
        setRenewalAdjustment(agreement.data.renewalDepositeResult[0]);
      }
      if (agreement.data.listHoldRow.length > 0) {
        setUnpaid(agreement.data.listHoldRow);
        let unpaid_amount = agreement.data.listHoldRow.reduce(
          (sum, row) =>
            (sum += Math.round(
              row.gstNo !== null && row.gstNo.length > 0
                ? Number(row.rent_amount) + Number((row.rent_amount / 100) * 18)
                : row.rent_amount
            )),
          0
        );

        setUnpaidMonthsAmount(unpaid_amount);
      }

      if (agreement.data.listUnpaidRow.length > 0)
        setUnpaid(agreement.data.listUnpaidRow);
      if (agreement.data.finalLockedIn.length > 0) {
        setFinalLockedInData(agreement.data.finalLockedIn);
      }
      get_remarks_by_aggrement_code(
        agreement.data.agreement[agreement.data.ids[0]].code
      );
    }
  };

  async function get_remarks_by_aggrement_code(code) {
    try {
      const remarks = await get_remarks_by_code(code, subType, id);

      if (remarks.data.success) {
        let remark = remarks.data.remarks.map(
          ({ step_name, remarks }) => `${step_name} : ${remarks}`
        );
        setAllRemarks(remark);
      }
    } catch (error) {}
  }

  useEffect(() => {
    getData(id);
  }, []);

  async function handleSendBack() {
    if (remark.length <= 0) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required !.",
        })
      );
    } else {
      const agreement_id = agreement[ids[0]].id;
      const agreement_code = agreement[ids[0]].code;

      const response = await send_back_to_manager(
        {
          status:
            agreement[ids[0]].status === "Terminated By Manager"
              ? "Sent Back From Sr Manager Termination"
              : "Sent Back From Sr Manager",
          remark: remark,
          additionalRemark: {
            remarks: remark,
            step_name: auth.name,
            user_id: login_manager_id,
            agreement_code: agreement[ids[0]].code,
            agreement_id: agreement[ids[0]].id,
          },
        },
        id,
        role,
        subType
      );

      if (response.data.success) {
        agreement[ids[0]].type === "New" ||
        agreement[ids[0]].type === "Migrated"
          ? await notification_handler(
              "AG3",
              agreement_id,
              agreement_code,
              auth.id
            )
          : agreement[ids[0]].type === "Renewed" &&
            (await notification_handler(
              "RN13",
              agreement_id,
              agreement_code,
              auth.id
            ));
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Send back From Sr Manager",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    }
  }

  const handleSubmitRenewal = async (e) => {
    if (remark.length <= 0) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required !.",
        })
      );
    } else {
      const agreement_id = agreement[ids[0]].id;
      const agreement_code = agreement[ids[0]].code;

      const response = await send_to_bhu(
        {
          status:
            agreement[ids[0]].buh_id === 0 || agreement[ids[0]].buh_id === null
              ? "Sent To BUH"
              : "Sent To Operations",
          srm_id: login_manager_id,
          remark: remark,
          additionalRemark: {
            remarks: remark,
            step_name: auth.name,
            user_id: login_manager_id,
            agreement_code: agreement[ids[0]].code,
            agreement_id: agreement[ids[0]].id,
          },
        },
        id,
        role,
        subType
      );

      if (response.data.success) {
        agreement[ids[0]].type === "New" ||
        agreement[ids[0]].type === "Migrated"
          ? await notification_handler(
              "AG5",
              agreement_id,
              agreement_code,
              auth.id
            )
          : agreement[ids[0]].type === "Renewed" &&
            (agreement[ids[0]].buh_id === 0 ||
              agreement[ids[0]].buh_id === null)
          ? await notification_handler(
              "RN15",
              agreement_id,
              agreement_code,
              auth.id
            )
          : await notification_handler(
              "RN15SM",
              agreement_id,
              agreement_code,
              auth.id
            );
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message:
              agreement[ids[0]].op_id === 0 || agreement[ids[0]].op_id === null
                ? "Agreement Sent To BUH"
                : "Agreement Sent To Operations ",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    }
  };

  const handleSubmit = async (e) => {
    const response = await send_to_bhu(
      {
        renewal_status: "Sent To Sr Manager For Renewal",
        srm_id: auth.id,
        remark: remark,
        additionalRemark: {
          remarks: remark,
          step_name: auth.name,
          user_id: auth.id,
          agreement_code: agreement[ids[0]].code,
          agreement_id: agreement[ids[0]].id,
          modified_date: new Date(),
        },
      },
      agreement.agreement_id,
      auth.role,
      subType
    );
    if (response.data.success) {
      dispatch(
        setAlert({
          variant: "success",
          open: true,
          message: "Agreement Sent To Sr Manager For Renewal",
        })
      );
      navigate(-1);
    } else {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Something went wrong! Please again later.",
        })
      );
    }
  };

  function getIncrement(rent, value, type) {
    let incrementType;
    rent = Number(rent);
    value = Number(value);
    if (type === "Percentage") {
      incrementType = `${Math.round(((value - rent) / rent) * 100)}%`;
    } else if (type === "Value") {
      incrementType = value - rent;
    }
    return incrementType;
  }

  return (
    <>
      {ids && ids.length > 0 && (
        <Stack sx={{ flexDirection: "row", mb: 4 }}>
          {/* <a id="button"></a> */}

          <HamburgerManager />
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              item
              xs={12}
              sx={{ justifyContent: "space-between", display: "flex" }}
            >
              <MyHeader>
                RMS{" "}
                <span className="small-heading">
                  (Rental Management System)
                </span>
              </MyHeader>
              <Typography mt="15px" mr="15px" fontWeight="600">
                Welcome {auth.name}
              </Typography>
            </Grid>
            <Divider />
            <Grid
              container
              sx={{
                px: 1,
                justifyContent: "space-between",
                my: 1,
                alignItems: "center",
              }}
            >
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  color="black"
                  fontSize="20px"
                  fontWeight="600"
                  alignSelf="center"
                  lineHeight="30px"
                  sx={{
                    "@media(max-width:600px)": {
                      fontSize: "17px",
                      lineHeight: "25px",
                    },
                  }}
                >
                  View Agreement
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Box className="backButton">
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}
                size={"large"}
              >
                <ArrowCircleLeftIcon
                  sx={{ fontSize: "3rem" }}
                  color="#FFFFF !important"
                />
              </IconButton>
            </Box>

            <Grid container sx={{ justifyContent: "center", mt: 3 }}>
              {/* Basic Details */}
              <Grid item md={10}>
                <Heading heading={`Property Details`} />
                {agreement[ids[0]].final_agreement_date != null && (
                  <Grid container>
                    <>
                      <Grid container>
                        <DataFieldStyle
                          field={"Final Agreement"}
                          href={agreement[ids[0]].final_agreement}
                          name={"Final Agreement"}
                          bold={true}
                          cursor={true}
                        />
                        <DataFieldStyle
                          field={"Final Agreement Date"}
                          value={agreement[ids[0]].final_agreement_date}
                        />
                        <DataFieldStyle
                          field={"Monthly Rent Start Date"}
                          value={agreement[ids[0]].rent_start_date}
                        />
                      </Grid>
                    </>
                  </Grid>
                )}
                <Grid container sx={{ mt: 2 }}>
                  <DataFieldStyle
                    field={"code"}
                    value={agreement[ids[0]].code}
                  />

                  <DataFieldStyle
                    field={"state"}
                    value={agreement[ids[0]].state}
                  />
                  <DataFieldStyle
                    field={"city"}
                    value={agreement[ids[0]].city}
                  />
                  <DataFieldStyle
                    field={"location"}
                    value={agreement[ids[0]].location}
                  />
                  <DataFieldStyle
                    field={"pincode"}
                    value={agreement[ids[0]].pincode}
                  />
                  <DataFieldStyle
                    field={"address"}
                    value={agreement[ids[0]].address}
                  />
                  <DataFieldStyle
                    field={"area"}
                    value={agreement[ids[0]].area + " sq. ft"}
                    partLabel={
                      partLabel?.area
                        ? `Old Value: ${partLabel.area}sq. ft`
                        : ""
                    }
                  />
                  <DataFieldStyle
                    field={"lock in Months"}
                    value={agreement[ids[0]].lockInYear}
                    partLabel={
                      partLabel?.lockInYear
                        ? `Old Value: ${partLabel.lockInYear}`
                        : ""
                    }
                  />
                  <DataFieldStyle
                    field={"notice period"}
                    value={agreement[ids[0]].noticePeriod}
                    partLabel={
                      partLabel?.noticePeriod
                        ? `Old Value: ${partLabel.noticePeriod}`
                        : ""
                    }
                  />
                  <DataFieldStyle
                    field={"deposit amount"}
                    value={agreement[ids[0]].deposit}
                    partLabel={
                      partLabel?.deposit
                        ? `Old Value: ${partLabel.deposit}`
                        : ""
                    }
                  />
                  <DataFieldStyle
                    field={"monthly rental"}
                    value={agreement[ids[0]].monthlyRent}
                    partLabel={
                      partLabel?.monthlyRent
                        ? `Old Value: ${partLabel.monthlyRent}`
                        : ""
                    }
                  />
                  <DataFieldStyle
                    field={"tenure"}
                    value={agreement[ids[0]].tenure}
                    partLabel={
                      partLabel?.tenure ? `Old Value: ${partLabel.tenure}` : ""
                    }
                  />
                  {agreement[ids[0]].tenure > 12 && (
                    <>
                      <Grid container spacing={2} sx={{ mt: 4 }}>
                        <Grid item xs={12} container>
                          <DataFieldStyle
                            field={"yearly Increment"}
                            value={agreement[ids[0]].yearlyIncrement}
                          />
                        </Grid>
                        <YearField
                          year={"Year 1"}
                          incrementType={agreement[ids[0]].yearlyIncrement}
                          Increment={0}
                          amount={Math.round(agreement[ids[0]].year1)}
                        />
                        <YearField
                          year={"Year 2"}
                          incrementType={agreement[ids[0]].yearlyIncrement}
                          amount={Math.round(agreement[ids[0]].year2)}
                          Increment={getIncrement(
                            agreement[ids[0]].year1,
                            agreement[ids[0]].year2,
                            agreement[ids[0]].yearlyIncrement
                          )}
                        />
                        {agreement[ids[0]].tenure > 24 && (
                          <YearField
                            year={"Year 3"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year3)}
                            Increment={getIncrement(
                              agreement[ids[0]].year2,
                              agreement[ids[0]].year3,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 36 && (
                          <YearField
                            year={"Year 4"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year4)}
                            Increment={getIncrement(
                              agreement[ids[0]].year3,
                              agreement[ids[0]].year4,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 48 && (
                          <YearField
                            year={"Year 5"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year5)}
                            Increment={getIncrement(
                              agreement[ids[0]].year4,
                              agreement[ids[0]].year5,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 60 && (
                          <YearField
                            year={"Year 6"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year6)}
                            Increment={getIncrement(
                              agreement[ids[0]].year5,
                              agreement[ids[0]].year6,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 72 && (
                          <YearField
                            year={"Year 7"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year7)}
                            Increment={getIncrement(
                              agreement[ids[0]].year6,
                              agreement[ids[0]].year7,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 84 && (
                          <YearField
                            year={"Year 8"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year8)}
                            Increment={getIncrement(
                              agreement[ids[0]].year7,
                              agreement[ids[0]].year8,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 96 && (
                          <YearField
                            year={"Year 9"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year9)}
                            Increment={getIncrement(
                              agreement[ids[0]].year8,
                              agreement[ids[0]].year9,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 108 && (
                          <YearField
                            year={"Year 10"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year10)}
                            Increment={getIncrement(
                              agreement[ids[0]].year9,
                              agreement[ids[0]].year10,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                      </Grid>
                    </>
                  )}

                  {Array.from(
                    { length: agreement[ids[0]].leeseName.length },
                    (row, id) => (
                      <>
                        <Grid container sx={{ mt: 3 }}>
                          <Heading
                            heading={`Landlord ${id + 1} Personal Details`}
                          />
                          <DataFieldStyle
                            field={"name of lessee"}
                            value={agreement[ids[0]].name[id]}
                          />
                          <DataFieldStyle
                            field={"aadhaar number"}
                            value={agreement[ids[0]].aadharNo[id]}
                            href={agreement[ids[0]].aadhar_card[id]}
                            name={"AadharCard"}
                            cursor={true}
                          />
                          <DataFieldStyle
                            field={"PAN number"}
                            value={agreement[ids[0]].panNo[id]}
                            href={agreement[ids[0]].pan_card[id]}
                            name={"pan_certicate"}
                            cursor={true}
                          />
                          {agreement[ids[0]].gstNo[id] != null && (
                            <DataFieldStyle
                              field={"GST number"}
                              value={agreement[ids[0]].gstNo[id]}
                              href={agreement[ids[0]].gst[id]}
                              name={"gst"}
                              cursor={true}
                            />
                          )}
                          <DataFieldStyle
                            field={"mobile number"}
                            value={agreement[ids[0]].mobileNo[id]}
                            partLabel={
                              partLabel?.mobileNo?.[id]
                                ? `Old Value: ${partLabel.mobileNo[id]}`
                                : ""
                            }
                          />

                          {agreement.alternateMobile != null && (
                            <DataFieldStyle
                              field={"alternate mobile"}
                              value={agreement[ids[0]].alternateMobile[id]}
                            />
                          )}

                          <DataFieldStyle
                            field={"email"}
                            value={agreement[ids[0]].email[id]}
                            partLabel={
                              partLabel?.email?.[id]
                                ? `Old Value: ${partLabel.email[id]}`
                                : ""
                            }
                          />
                          <DataFieldStyle
                            field={"Percentage Share"}
                            value={`${agreement[ids[0]].percentage[id]}%`}
                          />
                        </Grid>
                        {agreement[ids[0]].utr_number && (
                          <Grid
                            container
                            sx={{ alignItems: "baseline", mt: 1 }}
                          >
                            {agreement.utr_number != null && (
                              <DataFieldStyle
                                field={"Deposit UTR Number"}
                                value={agreement[ids[0]].utr_number[id]}
                              />
                            )}
                            {agreement.payment_date != null && (
                              <DataFieldStyle
                                field={"Deposit Payment Date"}
                                value={agreement[ids[0]].payment_date[id]}
                              />
                            )}
                          </Grid>
                        )}
                      </>
                    )
                  )}
                </Grid>
              </Grid>

              {/* Bank Details start here */}
              {/* <Heading heading={"Bank Details"} /> */}

              <Grid item md={10}>
                <Grid container>
                  {Array.from(
                    { length: agreement[ids[0]].leeseName.length },
                    (row, id) => (
                      <Grid container>
                        <Heading heading={`Landlord ${id + 1} Bank Details`} />
                        <DataFieldStyle
                          field={"bank name"}
                          partLabel={
                            <>
                              Old Bank: {partLabel?.bankName?.[id]} <br />
                              Old Branch: {partLabel?.branchName?.[id]}
                              <br />
                              {agreement?.[ids[0]]?.ifscCode[id] !==
                              partLabel?.ifscCode?.[id]
                                ? `New Branch Name: ${
                                    agreement[ids[0]].branchName[id]
                                  }`
                                : ""}
                            </>
                          }
                          value={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>{agreement[ids[0]].bankName[id]}</span>
                              <span>{agreement[ids[0]].branchName[id]}</span>
                            </div>
                          }
                        />

                        <DataFieldStyle
                          field={"beneficiary name"}
                          value={agreement[ids[0]].benificiaryName[id]}
                        />
                        <DataFieldStyle
                          field={"bank A/C number"}
                          value={agreement[ids[0]].accountNo[id]}
                          href={agreement[ids[0]].cheque[id]}
                          name={"cheque"}
                          cursor={true}
                          partLabel={
                            partLabel?.accountNo?.[id]
                              ? `Old Value:${partLabel?.accountNo?.[id]}`
                              : ""
                          }
                        />
                        <DataFieldStyle
                          field={"Bank IFSC"}
                          value={agreement[ids[0]].ifscCode[id]}
                          partLabel={
                            partLabel?.ifscCode?.[id]
                              ? `Old Value:${partLabel?.ifscCode?.[id]}`
                              : ""
                          }
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>

              {/* Bank Details Ends here */}

              {/* Document Section start here */}

              <Grid item md={10}>
                <Grid container spacing={4} sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <Heading heading={"Document View/Download"} />
                  </Grid>
                  <DocumentView
                    title={"draft agreement"}
                    img={agreement[ids[0]].draft_agreement}
                  />
                  <DocumentView
                    title={"electricity bill"}
                    img={agreement[ids[0]].electricity_bill}
                  />
                  <DocumentView
                    title={"Maintenance bill"}
                    img={agreement[ids[0]].maintaince_bill}
                  />
                  <DocumentView title={"POA"} img={agreement[ids[0]].poa} />
                  <DocumentView
                    title={"Property tax receipt"}
                    img={agreement[ids[0]].tax_receipt}
                  />
                  {agreement[ids[0]].leeseName.length > 1 && (
                    <DocumentView
                      title={"NOC (if multiple owner)"}
                      img={agreement[ids[0]].noc}
                    />
                  )}
                  <DocumentView
                    title={"Property Picture"}
                    img={agreement[ids[0]].property_pic}
                  />
                </Grid>
              </Grid>

              {/* document section ends here */}
              {Object.keys(recovery).length > 0 && (
                <>
                  <Grid item xs={10}>
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="25px"
                      lineHeight="20px"
                      fontWeight="600"
                      marginTop="35px"
                    >
                      Deposit Adjustments Details
                    </Typography>
                  </Grid>
                  {upaid.length > 0 && (
                    <Grid mt={1} mb={1} item container xs={10}>
                      <Grid
                        coantiner
                        sx={{
                          display: "flex",
                          gap: "2rem",
                          flexDirection: "column",
                        }}
                      >
                        <Grid item>
                          <Typography
                            color={"primary"}
                            fontWeight="600"
                            marginTop="15px"
                          >
                            Unpaid Months
                          </Typography>
                        </Grid>
                        {upaid.map((row) => (
                          <Grid item sx={{ display: "flex" }}>
                            <TextFieldWrapper
                              marginRight={true}
                              label={"Name"}
                              disabled={true}
                              value={row.name}
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label={"Rent Month (Unpaid)"}
                              placeHolder="Deposit Amount"
                              disabled={true}
                              value={
                                month[
                                  new Date(row.rent_date).getUTCMonth() + 1
                                ] +
                                "-" +
                                new Date(row.rent_date).getFullYear()
                              }
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label="Rent Amount"
                              disabled={true}
                              value={row.rent_amount}
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label="Status"
                              disabled={true}
                              value={row.status}
                            />
                            {agreement[ids[0]].gstNo && row.gstNo ? (
                              <>
                                {row.invoice_number != null && (
                                  <Grid item sx={{ textAlign: "center" }}>
                                    {
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        style={{
                                          backgroundColor:
                                            "rgb(103 185 68 / 89%)",
                                          color: "white",
                                          fontSize: "12px",
                                          textTransform: "capitalize",
                                          minHeight: "37px",
                                          margin: "10px",
                                          minWidth: "100px",
                                        }}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setSelectID(row.id);
                                          setOpen(true);
                                          setInvoiceDetails({
                                            ...invoiceDetails,
                                            rentAmount: row.rent_amount,
                                            gstAmount:
                                              row?.gstNo !==
                                                (null || undefined) &&
                                              row?.gstNo.length > 0
                                                ? Number(
                                                    parseInt(
                                                      row?.rent_amount * 0.18
                                                    ).toFixed(2)
                                                  )
                                                : 0,
                                            totalAmount:
                                              Number(row?.rent_amount) +
                                              Number(
                                                parseInt(
                                                  row?.rent_amount * 0.18
                                                ).toFixed(2)
                                              ),
                                            user_id: auth.id,
                                            agreement_id:
                                              agreement?.[ids[0]]
                                                ?.agreement_id[0],
                                            code: agreement?.[ids[0]]?.code,
                                          });
                                          setMonthlyId(row.id);
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "14px" }}>
                                          {"View"}
                                        </Typography>
                                      </Button>
                                    }
                                  </Grid>
                                )}
                              </>
                            ) : (
                              <Grid item>
                                <Button
                                  style={{
                                    display: "block",
                                    minHeight: "37px",
                                    margin: "10px",
                                    minWidth: "100px",
                                    background: "transparent",
                                  }}
                                ></Button>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  {finalLockedInData.length > 0 && (
                    <Grid mt={1} mb={1} item xs={10}>
                      <Grid
                        coantiner
                        sx={{
                          display: "flex",
                          gap: "2rem",
                          flexDirection: "column",
                        }}
                      >
                        <Grid item>
                          <Typography
                            color={"primary"}
                            fontWeight="600"
                            marginTop="15px"
                          >
                            Lock In Months
                          </Typography>
                        </Grid>
                        {finalLockedInData.map((row) => (
                          <>
                            <Grid item sx={{ display: "flex" }}>
                              <TextFieldWrapper
                                marginRight={true}
                                label={"Name"}
                                disabled={true}
                                value={row.name}
                              />
                              <TextFieldWrapper
                                marginRight={true}
                                label={"Rent Month (Locked In Month)"}
                                placeHolder="Deposit Amount"
                                disabled={true}
                                value={
                                  month[new Date(row.rent_date).getUTCMonth()] +
                                  "-" +
                                  new Date(row.rent_date).getFullYear()
                                }
                              />
                              <TextFieldWrapper
                                marginRight={true}
                                label="Rent Amount"
                                disabled={true}
                                value={row.rent_amount}
                              />
                              <TextFieldWrapper
                                marginRight={true}
                                label="Status"
                                disabled={true}
                                value={row.status}
                              />
                              {agreement[ids[0]].gstNo && row.gstNo ? (
                                <>
                                  <Grid item sx={{ textAlign: "center" }}>
                                    {row.invoice_number != null && (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        style={{
                                          backgroundColor:
                                            "rgb(103 185 68 / 89%)",
                                          color: "white",
                                          fontSize: "12px",
                                          textTransform: "capitalize",
                                          minHeight: "37px",
                                          margin: "10px",
                                          minWidth: "100px",
                                        }}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setSelectID(row.id);
                                          setOpen(true);
                                          setInvoiceDetails({
                                            ...invoiceDetails,
                                            rentAmount: row.rent_amount,
                                            gstAmount:
                                              row?.gstNo !==
                                                (null || undefined) &&
                                              row?.gstNo.length > 0
                                                ? Number(
                                                    parseInt(
                                                      row?.rent_amount * 0.18
                                                    ).toFixed(2)
                                                  )
                                                : 0,
                                            totalAmount:
                                              Number(row?.rent_amount) +
                                              Number(
                                                parseInt(
                                                  row?.rent_amount * 0.18
                                                ).toFixed(2)
                                              ),
                                            user_id: auth.id,
                                            agreement_id:
                                              agreement?.[ids[0]]
                                                ?.agreement_id[0],
                                            code: agreement?.[ids[0]]?.code,
                                          });
                                          setMonthlyId(row.id);
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "14px" }}>
                                          {"View"}
                                        </Typography>
                                      </Button>
                                    )}
                                  </Grid>
                                </>
                              ) : (
                                <Grid item>
                                  <Button
                                    style={{
                                      display: "block",
                                      minHeight: "37px",
                                      margin: "10px",
                                      minWidth: "100px",
                                      background: "transparent",
                                    }}
                                  ></Button>
                                </Grid>
                              )}
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    sx={{ mt: "25px", mb: "25px" }}
                    spacing={3}
                    item
                    xs={10}
                  >
                    <Grid item xs={10} container>
                      <TextFieldWrapper
                        label="Deposit Amount (Paid)"
                        placeHolder="Deposit Amount"
                        name="depositedAmount"
                        disabled={true}
                        value={recovery.depositedAmount}
                      />
                    </Grid>
                    <Grid item xs={10} container spacing={3}>
                      <Grid item xs={12}>
                        <Typography
                          color={"var( --main-color)"}
                          fontWeight="600"
                          marginTop="15px"
                        >
                          {"Adjustment Amount"}
                        </Typography>
                      </Grid>
                      <TextFieldWrapper
                        label="Number of Months"
                        placeHolder="Number of Months"
                        name="remainingMonth"
                        disabled={true}
                        value={recovery.remainingMonth}
                      />

                      <TextFieldWrapper
                        label="Adjustment Amount"
                        placeHolder="Adjustment Amount"
                        name="adjustmentAmount"
                        disabled={true}
                        value={recovery.adjustmentAmount}
                      />
                      <TextFieldWrapper
                        label="Remark"
                        placeHolder="Remark"
                        disabled={true}
                        name="adjustmentAmountRemark"
                        value={
                          recovery.adjustmentAmountRemark === "'\"\"'"
                            ? ""
                            : recovery.adjustmentAmountRemark
                        }
                      />
                    </Grid>

                    <Grid item xs={10} container spacing={2}>
                      <TextFieldWrapper
                        label="Monthly adjusted rental amount"
                        placeHolder="Monthly adjusted rental amount"
                        name="monthlyAdjustedRentalAmount"
                        value={recovery.monthlyAdjustedRentalAmount}
                        disabled={true}
                      />
                    </Grid>

                    <Grid item xs={10} container spacing={2}>
                      <TextFieldWrapper
                        label="Adjust Towards Expenses"
                        placeHolder="Adjustment Amount"
                        disabled={true}
                        name="expenses"
                        value={recovery.expenses}
                      />
                      <TextFieldWrapper
                        label="Remark"
                        placeHolder="Remark"
                        disabled={true}
                        name="expansesRemark"
                        value={
                          recovery.expansesRemark === '""'
                            ? ""
                            : recovery.expansesRemark
                        }
                      />
                    </Grid>

                    <Grid item xs={10} container spacing={2}>
                      <TextFieldWrapper
                        label="Other Adjustments"
                        placeHolder="Other Adjustments"
                        disabled={true}
                        name="otherAdjustments"
                        value={recovery.otherAdjustments}
                      />
                      <TextFieldWrapper
                        label="Remark"
                        place1646
                        Holder="Remark"
                        disabled={true}
                        name="otherRemark"
                        value={
                          recovery.otherRemark === '""'
                            ? ""
                            : recovery.otherRemark
                        }
                      />
                    </Grid>
                    <Grid item xs={10} container spacing={2}>
                      <TextFieldWrapper
                        label="Total Adjustment Amount"
                        placeHolder="Adjustment Amount"
                        disabled={true}
                        name="totalAdjustmentAmount"
                        value={recovery.totalAdjustmentAmount}
                      />
                      <TextFieldWrapper
                        label={"Balance Deposit Receivable"}
                        placeHolder="Balance Deposit"
                        name="balanceDeposit"
                        disabled={true}
                        value={recovery.balanceDeposit}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={10}>
                    <Grid container>
                      <DocumentView
                        title={"Adjustment Document"}
                        img={agreement[ids[0]].file}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {Object.keys(renewalAdjustment).length > 0 && (
                <>
                  <Grid item xs={10} mt={3}>
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="25px"
                      lineHeight="28px"
                      fontWeight="600"
                    >
                      Renewal Adjustments Form
                    </Typography>
                  </Grid>
                  <Grid item xs={10} mt={3}>
                    <Grid
                      coantiner
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        flexDirection: "column",
                      }}
                    >
                      <Grid item xs={10}>
                        <Typography
                          variant="h6"
                          color={"primary"}
                          sx={{ fontWeight: 700 }}
                        >
                          Unpaid Months
                        </Typography>
                      </Grid>

                      {upaid.map((row) => (
                        <Grid item sx={{ display: "flex" }}>
                          <TextFieldWrapper
                            marginRight={true}
                            label={"Name"}
                            disabled={true}
                            value={row.name}
                          />
                          <TextFieldWrapper
                            marginRight={true}
                            label={"Rent Month (Unpaid)"}
                            placeHolder="Deposit Amount"
                            disabled={true}
                            value={
                              month[new Date(row.rent_date).getUTCMonth()] +
                              "-" +
                              new Date(row.rent_date).getFullYear()
                            }
                          />
                          <TextFieldWrapper
                            marginRight={true}
                            label="Rent Amount"
                            disabled={true}
                            value={
                              row.gstNo !== null && row.gstNo.length > 0
                                ? Math.round(
                                    Number(row.rent_amount) +
                                      Number((row.rent_amount / 100) * 18)
                                  )
                                : row.rent_amount
                            }
                          />
                          <TextFieldWrapper
                            marginRight={true}
                            label="Status"
                            disabled={true}
                            value={row.status}
                          />
                          {row.gstNo ? (
                            <>
                              {row.invoice_number != null && (
                                <Grid item sx={{ textAlign: "center" }}>
                                  {
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      style={{
                                        backgroundColor:
                                          "rgb(103 185 68 / 89%)",
                                        color: "white",
                                        fontSize: "12px",
                                        textTransform: "capitalize",
                                        minHeight: "37px",
                                        margin: "10px",
                                        minWidth: "100px",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setSelectID(row.id);
                                        setOpen(true);
                                        setInvoiceDetails({
                                          ...invoiceDetails,
                                          rentAmount: row.rent_amount,
                                          gstAmount:
                                            row?.gstNo != null &&
                                            row?.gstNo.length > 0
                                              ? Number(
                                                  parseInt(
                                                    row?.rent_amount * 0.18
                                                  ).toFixed(2)
                                                )
                                              : 0,
                                          totalAmount:
                                            Number(row?.rent_amount) +
                                            Number(
                                              parseInt(
                                                row?.rent_amount * 0.18
                                              ).toFixed(2)
                                            ),
                                          user_id: auth.id,
                                          agreement_id:
                                            agreement?.[ids[0]]
                                              ?.agreement_id[0],
                                          code: agreement?.[ids[0]]?.code,
                                        });
                                        setMonthlyId(row.id);
                                      }}
                                    >
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {"View"}
                                      </Typography>
                                    </Button>
                                  }
                                </Grid>
                              )}
                            </>
                          ) : (
                            <Grid item>
                              <Button
                                style={{
                                  display: "block",
                                  minHeight: "37px",
                                  margin: "10px",
                                  minWidth: "100px",
                                  background: "transparent",
                                }}
                              ></Button>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      marginTop: "5px",
                      display: "flex",
                      gap: "2rem",
                      flexDirection: "row",
                    }}
                    rowSpacing={5}
                    xs={10}
                  >
                    <TextFieldWrapper
                      label="Deposit Amount (Paid)"
                      placeHolder="Deposit Amount"
                      name="depositedAmount"
                      disabled={true}
                      value={partLabel.deposit}
                    />
                    <TextFieldWrapper
                      label="New Deposit Amount"
                      placeHolder="New Deposit Amount"
                      name="new_deposit"
                      disabled={true}
                      value={agreement[ids[0]].deposit}
                    />
                  </Grid>
                  <Grid
                    container
                    sx={{
                      marginTop: "5px",
                      display: "flex",
                      gap: "2rem",
                      flexDirection: "row",
                    }}
                    rowSpacing={5}
                    xs={10}
                  >
                    <TextFieldWrapper
                      label="Selected Un Paid Months Amount"
                      placeHolder="Selected UnPaid Months Amount"
                      name="unPaidMonthsAmount"
                      disabled={true}
                      value={unPaidMonthsAmount}
                    />
                    <TextFieldWrapper
                      label="Balance Deposit Amount"
                      placeHolder="Balance Deposit Amount"
                      name="depositedAmount"
                      disabled={true}
                      value={renewalAdjustment.balance_amount}
                    />
                  </Grid>

                  <Grid
                    container
                    sx={{
                      marginTop: "5px",
                      display: "flex",
                      gap: "2rem",
                      flexDirection: "row",
                    }}
                    rowSpacing={5}
                    xs={10}
                  >
                    <TextFieldWrapper
                      label={`Balance Deposit Payable`}
                      placeHolder="Balance Deposit Payable"
                      name="depositedAmount"
                      disabled={true}
                      value={renewalAdjustment.receivable}
                    />
                  </Grid>
                </>
              )}

              <Grid item container xs={10} sx={{ mt: 2 }}>
                <DataFieldStyle
                  field={"Landlord Assets"}
                  value={agreement[ids[0]].assets}
                  partLabel={
                    partLabel?.assets ? `Old Value:${partLabel?.assets}` : ""
                  }
                />
              </Grid>

              {allRemarks &&
                allRemarks.length > 0 &&
                agreement[ids[0]].renewal_status !==
                  "Sent Back For Renewal Rectification" &&
                agreement[ids[0]].renewal_status !==
                  "Request Approved for Renewal" && (
                  <>
                    <Grid
                      item
                      container
                      sx={{ alignItems: "baseline", mt: 2 }}
                      xs={10}
                    >
                      <DataFieldStyle
                        field={
                          agreement[ids[0]].type === "Terminate"
                            ? "Termination Remark"
                            : "Renewal Remark"
                        }
                      />
                      {allRemarks.map((remark, index) => (
                        <div
                          style={{
                            width: "100%",
                            padding: "10px 10px 10px 0",
                          }}
                          key={index}
                        >
                          {remark}
                        </div>
                      ))}
                    </Grid>
                  </>
                )}
              {agreement[ids[0]].terminate_status ===
                "Sent Back For Termination Rectification" &&
                agreement[ids[0]].terminate_status !==
                  "Request Approved for Termination" &&
                agreement[ids[0]].renewal_status !==
                  "Request Approved for Renewal" && (
                  <>
                    <Grid
                      item
                      container
                      sx={{ alignItems: "baseline", mt: 2 }}
                      xs={10}
                    >
                      <DataFieldStyle field={"Termination Remark"} />
                      <div
                        style={{
                          width: "100%",
                          padding: "10px 10px 10px 0",
                        }}
                      >
                        Sr Manager : {agreement[ids[0]].termination_remark}
                      </div>
                    </Grid>
                  </>
                )}
              {agreement[ids[0]].renewal_status ===
                "Sent Back For Renewal Rectification" &&
                agreement[ids[0]].renewal_status !==
                  "Request Approved for Renewal" &&
                agreement[ids[0]].terminate_status !==
                  "Request Approved for Termination" && (
                  <>
                    <Grid
                      item
                      container
                      sx={{ alignItems: "baseline", mt: 2 }}
                      xs={10}
                    >
                      <DataFieldStyle field={"Renewal Remark"} />
                      <div
                        style={{
                          width: "100%",
                          padding: "10px 10px 10px 0",
                        }}
                      >
                        Sr Manager : {agreement[ids[0]].renewal_remark}
                      </div>
                    </Grid>
                  </>
                )}
              <UploadInvoice
                open={open}
                view={true}
                handleClose={() => setOpen(false)}
                value={invoiceDetails}
                setValue={setInvoiceDetails}
                code={code}
                monthlyId={monthlyId}
              />
              {/* Buttons start here*/}

              {agreement[ids[0]].status === "Sent To Sr Manager" &&
                role.includes("Senior_Manager") && (
                  <>
                    <Grid
                      item
                      xs={10}
                      sx={{ mt: 5, gap: "2rem" }}
                      className={"textFieldWrapper"}
                    >
                      <Grid item xs={8}>
                        <TextField
                          type="text"
                          multiline
                          rows={3}
                          fullWidth
                          variant="outlined"
                          label="Remark *"
                          placeholder="Remark *"
                          value={remark}
                          onChange={(e) => setRemark(e.target.value)}
                        />
                      </Grid>
                    </Grid>

                    <Grid item md={8} sx={{ mt: 4, mb: 2 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ justifyContent: "center" }}
                      >
                        <Grid item md={6} xs={11}>
                          <Button
                            variant="contained"
                            sx={{
                              height: "55px",
                              borderRadius: "12px",
                              backgroundColor: "primary",
                              width: "100%",
                              color: "#FFFFFF",
                              textTransform: "capitalize",
                              fontSize: "18px",
                              lineHeight: "20px",
                            }}
                            onClick={handleSubmitRenewal}
                          >
                            {agreement[ids[0]].buh_id === 0 ||
                            agreement[ids[0]].buh_id === null
                              ? " Send to BUH"
                              : " Send To Operations"}
                          </Button>
                        </Grid>
                        <Grid item md={6} xs={11}>
                          <Button
                            variant="outlined"
                            sx={{
                              height: "55px",
                              borderRadius: "12px",
                              width: "100%",
                              textTransform: "capitalize",
                              fontSize: "18px",
                            }}
                            onClick={handleSendBack}
                          >
                            Send Back To Manager
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              {agreement[ids[0]].renewal_status ===
                "Sent Back For Renewal Rectification" &&
                agreement[ids[0]].status !== "Terminated By Manager" &&
                agreement[ids[0]].terminate_status !==
                  "Sent Back For Termination Rectification" && (
                  <Grid item md={8} sx={{ mt: 4, mb: 2 }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ justifyContent: "center" }}
                    >
                      <Grid item md={8} xs={11}></Grid>
                    </Grid>
                  </Grid>
                )}

              {/* buttons end here */}
            </Grid>
          </Box>
        </Stack>
      )}
    </>
  );
}

export default RenewalViewPage;
