/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const labelStyle = {
  fontSize: "20px",
  lineHeight: "30px",
  color: "var(--main-color)",
  fontWeight: "600",
  "@media(max-width:900px)": { fontSize: "10px" },
};

const fieldStyle = {
  border: "1px solid var(--main-color)",
  borderRadius: "20px",
  p: 1,
  px: 2,
  input: { cursor: "pointer" },
  color: "rgba(16, 99, 173, 0.47)",
  "@media(max-width:900px)": { height: "46px", p: 1 },
};

function DialogBoxSBM({
  open,
  handleClose,
  handleConfirm,
  value,
  setValue,
  modifyDate,
}) {
  const [formError, setFormError] = useState({ utr: "", paymentDate: "" });

  function onChange(e) {
    if (e.target.name === "utr") {
      const regex = /^[a-zA-Z0-9]+$/;
      if (e.target.value === "" || regex.test(e.target.value)) {
        setValue({
          ...value,
          [e.target.name]: e.target.value,
        });
      }
      setFormError({
        ...formError,
        [e.target.name]: "",
      });
    }
    if (e.target.name === "paymentDate") {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
      setFormError({
        ...formError,
        [e.target.name]: "",
      });
    }
  }

  const currentDate = moment();
  const futureDate = moment(currentDate).add(60, "days");
  const pastDate = moment(currentDate).subtract(60, "days");

  const disablePastDate = () => {
    const today = new Date(modifyDate);
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  function validate(e) {
    let error = {};
    if (value.utr === "") {
      error.utr = "Please Enter UTR Number.";
      setFormError(error);
      return false;
    } else if (value.paymentDate === "") {
      error.paymentDate = "Please Select Payment Date.";
      setFormError(error);
      return false;
    }
    handleConfirm();
  }

  function handleSubmit(e) {
    e.preventDefault();
    validate(e);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 18 },
        }}
      >
        <Box sx={{ pt: 5, px: 2, pb: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" sx={labelStyle}>
                    UTR Number
                  </Typography>
                </FormLabel>
                <TextField
                  type="text"
                  variant="standard"
                  onChange={(e) => onChange(e)}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      color: "#000",
                      fontSize: "15px",
                    },
                  }}
                  inputProps={{
                    maxLength: 22,
                  }}
                  value={value.utr}
                  fullWidth
                  name="utr"
                  sx={fieldStyle}
                  placeholder="UTR Number"
                  id="standard-basic"
                  autoFocus
                />
                <Typography variant="body1" color="red" mt={1}>
                  {formError.utr}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel>
                  <Typography variant="body1" sx={labelStyle}>
                    Payment Date
                  </Typography>
                </FormLabel>
                <Tooltip title="Calender" placement="right">
                  <input
                    type="date"
                    name="paymentDate"
                    value={value.paymentDate}
                    max={futureDate.format("YYYY-MM-DD")}
                    min={pastDate.format("YYYY-MM-DD")}
                    className="DatePicker"
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => onChange(e)}
                    onFocus={(e) => e.currentTarget.blur()}
                  />
                </Tooltip>
                <Typography variant="body1" color="red" mt={1}>
                  {formError.paymentDate}
                </Typography>
              </FormControl>
            </Grid>
          </Grid>

          <DialogActions sx={{ mt: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{
                    height: 45,
                    borderRadius: "15px",
                    textTransform: "capitalize",
                  }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={
                    value == null ||
                    (value.utr == undefined &&
                      value.paymentDate == undefined) ||
                    (value.utr == "" && value.paymentDate == "")
                  }
                  sx={{
                    height: 45,
                    color: "#FFFFFF",
                    borderRadius: "15px",
                    textTransform: "capitalize",
                  }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default DialogBoxSBM;
