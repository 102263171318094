/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Divider,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  MyHeader,
  TextFieldWrapper,
} from "../StyledComponent";
import AdminCheckBox from "../StyleComponents/AdminCheckBox";
import {
  EditUserInfo,
  GetSupervisor,
  GetSupervisorSRM,
  getCityList,
  getStateList,
  get_user,
} from "../../Services/Services";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../store/action/action";
import HamburgerAdmin from "./HamburgerAdmin";
const initialState = {
  code: "123456",
  name: "",
  email: "",
  role: [],
  mobile: "",
  supervisor: "",
  state: "",
  city: "",
};

function EditUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formVal, setFormVal] = useState(initialState);
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [disable, setDisable] = useState({
    manager: false,
    srManager: false,
    admin: false,
    BUH: false,
    finance: false,
  });

  const superVisor = [
    "Admin",
    "Finance",
    "BUH",
    "Operations",
    "Senior_Manager",
    "Manager",
  ];

  const [supervisorArray, setsupervisorArray] = useState([]);
  const [isSupervisorDisabled, setIsSupervisorDisabled] = useState(false);
  const [formError, setformError] = useState({});

  const [supervisorSelected, setSupervisorSelected] = useState(false);

  function validate(e) {
    const error = {};
    if (e.target.value < 1 && e.target.name === "code") {
      error.code = "Emp.code Required!";
    } else if (
      e.target.value.length !== 0 &&
      e.target.value.length < 4 &&
      e.target.name === "name"
    ) {
      error.name = "Name must be of 4 character.";
    } else if (
      e.target.value.length !== 0 &&
      e.target.value.length < 10 &&
      e.target.name === "mobile"
    ) {
      error.mobile = "Mobile Number Not Valid.";
      // } else if (
      //   !e.target.value.match(
      //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9]{2,}))$/
      //   ) &&
      //   e.target.value.length > 0 &&
      //   e.target.name === "email"
      // ) {
      //   error.email = "Please Enter Valid Email !!";
      // }
      // } else if (
      //   !e.target.value.match(
      //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      //   ) &&
      //   e.target.value.length > 0 &&
      //   e.target.name === "email"
      // ) {
      //   error.email = "Please Enter Valid Email !!";
      // }
    } else if (
      !e.target.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) &&
      e.target.value.length > 0 &&
      e.target.name === "email"
    ) {
      error.email = "Please Enter Valid Email !!";
    }
    setformError((old) => ({ ...old, [e.target.name]: error[e.target.name] }));
  }

  async function getSupervisor(role) {
    let superVisor1 = [
      "Finance",
      "Operations",
      "BUH",
      "Senior_Manager",
      "Manager",
      "Role",
    ];

    let finalQuerry = [];
    if (role.includes("Manager")) {
      finalQuerry = ["Senior_Manager"];
    } else if (role.includes("Senior_Manager")) {
      finalQuerry = ["BUH"];
    } else if (role.includes("BUH")) {
      finalQuerry = ["Operations"];
    } else if (role.includes("Operations")) {
      finalQuerry = ["BUH"];
    } else if (role.includes("Admin")) {
      finalQuerry = ["BUH"];
    } else if (role.includes("Finance")) {
      finalQuerry = ["Role"];
    }
    superVisor1 = superVisor1.filter((row) => {
      return finalQuerry.includes(row);
    });

    if (role.includes("Manager")) {
      const supervisor = await GetSupervisor({
        role: superVisor1,
        state,
        city,
      });
      setsupervisorArray(supervisor.data);
    } else if (
      role.includes("Senior_Manager") ||
      role.includes("Operations") ||
      role.includes("BUH") ||
      role.includes("Admin")
    ) {
      const supervisor = await GetSupervisorSRM(superVisor1);
      setsupervisorArray(supervisor.data);
    }
  }

  // Role Check Box Disable Manage
  function manageRole(role) {
    let setVal = {};
    if (
      (role.includes("Manager") ||role.includes("Operations")) && role.length > 0)
     {
      superVisor.map(
        (row) =>
          (setVal = {
            ...setVal,
            [row]: true,
            Admin: false,
            Operations:!role.includes("Operations"),
            Manager:!role.includes("Manager"),
            [role[0] === "Senior Manager" ? "Senior_Manager" : role[0]]: false,
          })
      );
      setDisable(setVal);
    } else if (role.includes("Admin") && role.length === 1) {
      superVisor.map(
        (row) =>
          (setVal = {
            ...setVal,
            [row]: true,
            Admin: false,
            Operations: false,
            Manager: false,
          })
      );
      setDisable(setVal);
    } else if (role.includes("Senior_Manager")) {
      superVisor.map(
        (row) =>
          (setVal = {
            ...setVal,
            [row]: true,
            Senior_Manager: false,
          })
      );
      setDisable(setVal);
    } else if (role.includes("Finance")) {
      superVisor.map(
        (row) =>
          (setVal = {
            ...setVal,
            [row]: true,
            Finance: false,
          })
      );
      setDisable(setVal);
    } else if (role.includes("BUH")) {
      superVisor.map(
        (row) =>
          (setVal = {
            ...setVal,
            [row]: true,
            BUH: false,
          })
      );
      setDisable(setVal);
    } else if (role.length < 2) {
      superVisor.map((row) => (setVal = { ...setVal, [row]: false }));
      setDisable(setVal);
    } else if (role.includes("Admin") && role.length === 2) {
      superVisor.map(
        (row) =>
          (setVal = {
            ...setVal,
            [row]: true,
            Admin: false,
            [role[1] === "Senior Manager" ? "Senior_Manager" : role[1]]: false,
          })
      );
    }
    if (role.includes("Finance") || role.includes("BUH")) {
      setsupervisorArray([]);
      setIsSupervisorDisabled(true);
    } else {
      setIsSupervisorDisabled(false);
      setsupervisorArray(supervisor.data);
    }
    setDisable(setVal);
  }

  const { name, email, role, mobile, code, supervisor, state, city } = formVal;

  useEffect(() => {
    manageRole(role);
    getSupervisor(role);
  }, [role]);

  const getData = async (id) => {
    const data = await get_user(id);

    const { name, code, email, role, supervisor, mobile, state, city, designation, location } =
      data.data[0];

    setFormVal({
      ...formVal,
      name,
      code,
      email,
      role: JSON.parse(role),
      supervisor,
      mobile,
      state,
      city,
      location : location != null ? location : "No Location",
      designation : designation != null ? designation : "No Designation"
    });
  };

  async function handleSubmit(e) {
    try {    
        e.preventDefault();
        let hasError = false;
  
        if (role.length < 1 ) {
          dispatch(
            setAlert({
              open: true,
              type: "error",
              message: "Please Select Role",
            })
          );
          hasError = true;
        }

        if (!supervisor && !isSupervisorDisabled && !supervisorSelected && !hasError) {
          dispatch(
            setAlert({
              open: true,
              variant: "error",
              message: "Please Select Supervisor",
            })
          );
          hasError = true;
        }
        
      // Email validation using a regular expression
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formVal.email)) {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Please enter a valid email address",
          })
        );
        hasError = true;
      }

      if (!hasError) {
        const response = await EditUserInfo(id, {
          ...formVal,
          modify: new Date().toISOString().slice(0, 10),
        });

        if (response.status === 200) {
          dispatch(
            setAlert({
              open: true,
              variant: "success",
              message: response.data.message,
            })
          );
          navigate("/userManagement/all-user");
        } else {
          dispatch(
            setAlert({
              open: true,
              variant: "error",
              message: response.data.message,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong! Please Try Again Later.",
        })
        );
      }
    }

    const handleChange = (e) => {
      if (e.target.type === "checkbox") {
        const { value, checked } = e.target;
    
        if (checked) {
          setFormVal({
            ...formVal,
            role: [...role, value],
            supervisor: "", // Reset supervisor value when a checkbox is checked
          });
        } else {
          setFormVal({
            ...formVal,
            role: role.filter((e) => e !== value),
            supervisor: "", // Reset supervisor value when a checkbox is unchecked
          });
        }
      } else {
        setFormVal({
          ...formVal,
          [e.target.name]: e.target.value,
        });
        if (e.target.name === "supervisor") {
          setSupervisorSelected(!!e.target.value);
        }
      }
    };

  useEffect(() => {
    getData(id);
  }, []);

  // funciton for fetching state list
  async function handleStateSearch(e, i) {
    let response = await getStateList(e.target.value);

    if (response.status === 200) {
      setStateList(response.data);
    } else setStateList([]);
  }

  useEffect(() => {
    handleCitySearch();
  }, [state]);

  // funciton for fetching state list
  async function handleCitySearch() {
    let search = stateList.filter((row) => row.name === state && row.id);

    let response = await getCityList(search[0]?.id);

    if (response.status === 200) {
      setCityList(response.data);
    } else setCityList([]);
  }

  const { auth } = useSelector((s) => s);

  const disabledDropdownStyle = {
    opacity: 0.3,
    cursor: "not-allowed",
  };

  return (
    <>
      <Stack sx={{ flexWrap: "nowrap", flexDirection: "row" }}>
        <HamburgerAdmin />

        <Box sx={{ flexGrow: 1 }}>
          <>
            <Grid container sx={{ justifyContent: "center", mt: 2 }}>
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "space-between", display: "flex" }}
                >
                  <MyHeader>
                    RMS{" "}
                    <span className="small-heading">
                      (Rental Management System)
                    </span>
                  </MyHeader>
                  <Typography mt="15px" mr="15px" fontWeight="600">
                    Welcome {auth.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
          <Divider />
          <Grid
            container
            sx={{
              px: 1,
              justifyContent: "space-between",
              my: 1,
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <Typography
                variant="body1"
                color="black"
                fontSize="20px"
                fontWeight="600"
                alignSelf="center"
                lineHeight="30px"
                sx={{
                  "@media(max-width:600px)": {
                    fontSize: "17px",
                    lineHeight: "25px",
                  },
                }}
              >
                {"Edit-User Management"}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item>
              <Box
                component="form"
                sx={{
                  py: 5,
                  backgroundColor: "white",
                  mx: 3,
                  borderRadius: "15px",
                  maxWidth: "1050px",
                }}
                onSubmit={handleSubmit}
              >
                <Grid container sx={{ p: 3 }} spacing={4}>
                  <TextFieldWrapper
                    label="Emp.Code"
                    placeHolder=""
                    value={code}
                    onChange={handleChange}
                    required={true}
                    error={formError.code}
                    onBlur={(e) => validate(e)}
                    maxLength={10}
                  />
                  <TextFieldWrapper
                    label="Full Name"
                    placeHolder="Full Name"
                    value={name}
                    name="name"
                    required={true}
                    error={formError.name}
                    onBlur={(e) => validate(e)}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <TextFieldWrapper
                    label="Email"
                    placeHolder="Email"
                    value={email}
                    name="email"
                    required={true}
                    onBlur={(e) => validate(e)}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    error={formError.email}
                  />

                  <TextFieldWrapper
                    label="Mobile Number"
                    placeHolder="Mobile Number"
                    value={mobile}
                    name="mobile"
                    maxLength={10}
                    required={true}
                    error={formError.mobile}
                    onBlur={(e) => validate(e)}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  {/* <TextFieldWrapper
                    label="Location"
                    placeHolder="Location"
                    value={location}
                    name="Location"
                  />
                  <TextFieldWrapper
                    label="Designation"
                    placeHolder="Designation"
                    value={designation}
                    name="Designation"
                  /> */}
                  <Grid
                    item
                    md={4}
                    xs={6}
                    sx={{
                      mb: "0px !important",
                      "@media(max-width:900px)": { my: 1 },
                    }}
                  >
                    <FormControl fullWidth className="textFieldWrapper">
                      <Autocomplete
                        freeSolo
                        fullWidth
                        id="free-solo-2-demo"
                        disableClearable
                        value={state || formVal.state}
                        onChange={(e, val) => {
                          setFormVal((old) => ({ ...old, state: val }));
                        }}
                        options={stateList.map((option) => option.name)}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            name="state"
                            value={state || formVal.state}
                            {...params}
                            label="State"
                            required
                            onKeyPress={(e) => {
                              const charCode =
                                e.charCode || e.keyCode || e.which;
                              if (
                                (charCode >= 65 && charCode <= 90) || // A-Z
                                (charCode >= 97 && charCode <= 122) || // a-z
                                charCode === 32 // Space
                              ) {
                                return;
                              }
                              e.preventDefault();
                            }}
                            onChange={(e) => {
                              setFormVal((prevFormVal) => ({
                                ...prevFormVal,
                                [e.target.name]: e.target.value,
                              }));
                              handleStateSearch(e);
                            }}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    md={4}
                    xs={6}
                    sx={{
                      mb: "0px !important",
                      "@media(max-width:900px)": { my: 1 },
                    }}
                  >
                    <FormControl fullWidth className="textFieldWrapper">
                      <TextField
                        label="City"
                        placeHolder="Enter City"
                        select
                        fullWidth
                        name="city"
                        required
                        // required={true}
                        value={formVal.city || city}
                        onChange={handleChange}
                      >
                        {cityList &&
                          cityList?.map((item) => {
                            return (
                              <MenuItem value={item?.city}>{item?.city}</MenuItem>
                            );
                          })}
                        <MenuItem value={formVal.city}>{formVal.city}</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={6}
                    sx={{
                      mb: "0px !important",
                      "@media(max-width:900px)": { my: 1 },
                    }}
                  >
                    <FormControl
                      fullWidth
                      className={`textFieldWrapper ${
                        isSupervisorDisabled ? "disabled-dropdown" : ""
                      }`}
                      sx={
                        isSupervisorDisabled ? { ...disabledDropdownStyle } : {}
                      }
                    >
                      <TextField
                        label="Supervisor *"
                        select
                        fullWidth
                        name={"supervisor"}
                        value={supervisor}
                        onChange={handleChange}
                        disabled={isSupervisorDisabled}
                        error={!supervisorSelected && !isSupervisorDisabled}
                      >
                        {supervisorArray?.map((item, i) => {
                          return (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <AdminCheckBox
                    handleChange={handleChange}
                    disable={disable}
                    value={role}
                  />
                </Grid>
                <Grid container sx={{ justifyContent: "space-evenly", mt: 3 }}>
                  <Grid item sm={3.0}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        height: "65px",
                        width: "100%",
                        borderRadius: "9px",
                        fontSize: "16px",
                        color: "#FFFFFF",
                        lineHeight: "32px",
                        textTransform: "capitalize",
                      }}
                      type="submit"
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );
}

export default EditUser;
