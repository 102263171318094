/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DataFieldStyle, YearField } from "../StyleComponents/Rental";
import { DocumentUpload, MyHeader, TextFieldWrapper } from "../StyledComponent";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  get_agreement_code,
  get_remarks_by_code,
} from "../../Services/Services";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import HamburgerManager from "../Manager/HamburgerManager";
import moment from "moment";

const Heading = ({ heading }) => {
  return (
    <Grid item xs={11} sx={{ mt: 6, mb: 2 }}>
      <Typography
        variant="body1"
        fontSize={"20px"}
        color={"primary"}
        fontWeight={"400"}
        sx={{ textDecoration: "underline" }}
      >
        {heading}
      </Typography>
    </Grid>
  );
};

function ViewPage() {
  const { auth } = useSelector((s) => s);
  const { id } = useParams();
  const navigate = useNavigate();
  const subType = "monthly";
  const [agreement, setAgreement] = useState({});
  const [ids, setIds] = useState([]);
  const [allRemarks, setAllRemarks] = useState("");
  const getData = async (id) => {
    const agreement = await get_agreement_code(id);
    if (agreement.status === 200) {
      setAgreement(agreement.data.agreement);
      setIds(agreement.data.ids);
      get_remarks_by_aggrement_code(
        agreement.data.agreement[agreement.data.ids[0]].code
      );
    }
  };

  async function get_remarks_by_aggrement_code(code) {
    try {
      const remarks = await get_remarks_by_code(code, subType, id);

      if (remarks.data.success) {
        let remark = remarks.data.remarks.map(
          ({ step_name, remarks }) => `${step_name} : ${remarks}`
        );
        setAllRemarks(remark);
      }
    } catch (error) {}
  }

  useEffect(() => {
    getData(id);
  }, []);

  function getIncrement(rent, value, type) {
    let incrementType;
    rent = Number(rent);
    value = Number(value);
    if (type === "Percentage") {
      incrementType = `${parseInt(((value - rent) / rent) * 100)}%`;
    } else if (type === "Value") {
      incrementType = value - rent;
    }
    return incrementType;
  }
  return (
    <>
      {ids.length > 0 && (
        <Stack sx={{ flexDirection: "row", mb: 4 }}>
          <HamburgerManager />

          <Box className="backButton">
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              size={"large"}
            >
              <ArrowCircleLeftIcon
                sx={{ fontSize: "3rem" }}
                color="#FFFFF !important"
              />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container sx={{ justifyContent: "center", mt: 2 }}>
              <Grid
                item
                xs={12}
                sx={{ justifyContent: "space-between", display: "flex" }}
              >
                <MyHeader>
                  RMS{" "}
                  <span className="small-heading">
                    (Rental Management System)
                  </span>
                </MyHeader>
                <Typography mt="15px" mr="15px" fontWeight="600">
                  Welcome {auth.name}
                </Typography>
              </Grid>
              <Divider />
              <Grid
                container
                sx={{
                  px: 1,
                  justifyContent: "space-between",
                  my: 1,
                  alignItems: "center",
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    color="black"
                    fontSize="20px"
                    fontWeight="600"
                    alignSelf="center"
                    lineHeight="30px"
                    sx={{
                      "@media(max-width:600px)": {
                        fontSize: "17px",
                        lineHeight: "25px",
                      },
                    }}
                  >
                    {agreement.type === "Migrated"
                      ? "View Migrated Agreement"
                      : "View Agreement"}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />

              {/* Basic Details */}
              <Grid item md={10} sx={{ mt: 2 }}>
                <Heading heading={`Property Details`} />
                <Grid container sx={{ mt: 4 }}>
                  <DataFieldStyle
                    field={"Rent Month"}
                    value={moment(agreement[ids[0]].rent_date).format(
                      "MMMM YY"
                    )}
                  />
                  ,
                </Grid>
                {agreement[ids[0]].payment_status === "Paid" && (
                  <Grid container>
                    <DataFieldStyle
                      field={"Rent Amount"}
                      value={agreement[ids[0]].rent_amount}
                    />
                    <DataFieldStyle
                      field={"GST Amount"}
                      value={agreement[ids[0]].gst_amount}
                    />
                    <DataFieldStyle
                      field={"Total Amount"}
                      value={parseInt(
                        parseInt(agreement[ids[0]].rent_amount) +
                          parseInt(agreement[ids[0]].gst_amount)
                      ).toFixed(2)}
                    />
                  </Grid>
                )}

                <Grid container sx={{ mt: 4 }}>
                  <DataFieldStyle
                    field={"Final Agreement"}
                    href={agreement[ids[0]].final_agreement}
                    name={"Final Agreement"}
                    bold={true}
                    cursor={true}
                  />
                  <DataFieldStyle
                    field={"Final Agreement Date"}
                    value={agreement[ids[0]].final_agreement_date}
                  />
                  <DataFieldStyle
                    field={"Monthly Rent Start Date"}
                    value={agreement[ids[0]].rent_start_date}
                  />
                </Grid>
                <Grid container sx={{ mt: 1 }}>
                  {agreement.utr_number != null && (
                    <DataFieldStyle
                      field={"Rent UTR Number"}
                      value={agreement[ids[0]].utr_deposit}
                    />
                  )}
                  {agreement.payment_date != null && (
                    <DataFieldStyle
                      field={"Rent Payment Date"}
                      value={agreement[ids[0]].rent_start_date}
                    />
                  )}
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <DataFieldStyle
                    field={"code"}
                    value={agreement[ids[0]].code}
                  />

                  <DataFieldStyle
                    field={"state"}
                    value={agreement[ids[0]].state}
                  />
                  <DataFieldStyle
                    field={"city"}
                    value={agreement[ids[0]].city}
                  />
                  <DataFieldStyle
                    field={"location"}
                    value={agreement[ids[0]].location}
                  />

                  <DataFieldStyle
                    field={"pincode"}
                    value={agreement[ids[0]].pincode}
                  />
                  <DataFieldStyle
                    field={"address"}
                    value={agreement[ids[0]].address}
                  />
                  <DataFieldStyle
                    field={"area"}
                    value={agreement[ids[0]].area + " sq. ft"}
                  />
                  <DataFieldStyle
                    field={"lock in month"}
                    value={agreement[ids[0]].lockInYear}
                  />
                  <DataFieldStyle
                    field={"notice period in month"}
                    value={agreement[ids[0]].noticePeriod}
                  />
                  <DataFieldStyle
                    field={"deposit"}
                    value={agreement[ids[0]].deposit}
                  />
                  <DataFieldStyle
                    field={"monthly rental"}
                    value={agreement[ids[0]].monthlyRent}
                  />
                  <DataFieldStyle
                    field={"tenure"}
                    value={agreement[ids[0]].tenure}
                  />
                  {agreement[ids[0]].tenure > 12 && (
                    <>
                      <Grid container spacing={1} sx={{ mt: 6 }}>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                          <DataFieldStyle
                            field={"yearly Increment"}
                            value={agreement[ids[0]].yearlyIncrement}
                          />
                        </Grid>
                        <YearField
                          year={"Year 1"}
                          incrementType={agreement[ids[0]].yearlyIncrement}
                          Increment={0}
                          amount={agreement[ids[0]].year1}
                        />
                        <YearField
                          year={"Year 2"}
                          incrementType={agreement[ids[0]].yearlyIncrement}
                          amount={agreement[ids[0]].year2}
                          Increment={getIncrement(
                            agreement[ids[0]].year1,
                            agreement[ids[0]].year2,
                            agreement[ids[0]].yearlyIncrement
                          )}
                        />
                        {agreement[ids[0]].tenure > 24 && (
                          <YearField
                            year={"Year 3"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={agreement[ids[0]].year3}
                            Increment={getIncrement(
                              agreement[ids[0]].year2,
                              agreement[ids[0]].year3,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 36 && (
                          <YearField
                            year={"Year 4"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={agreement[ids[0]].year4}
                            Increment={getIncrement(
                              agreement[ids[0]].year3,
                              agreement[ids[0]].year4,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 48 && (
                          <YearField
                            year={"Year 5"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year5)}
                            Increment={getIncrement(
                              agreement[ids[0]].year4,
                              agreement[ids[0]].year5,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 60 && (
                          <YearField
                            year={"Year 6"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year6)}
                            Increment={getIncrement(
                              agreement[ids[0]].year5,
                              agreement[ids[0]].year6,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 72 && (
                          <YearField
                            year={"Year 7"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year7)}
                            Increment={getIncrement(
                              agreement[ids[0]].year6,
                              agreement[ids[0]].year7,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 84 && (
                          <YearField
                            year={"Year 8"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year8)}
                            Increment={getIncrement(
                              agreement[ids[0]].year7,
                              agreement[ids[0]].year8,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 96 && (
                          <YearField
                            year={"Year 9"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year9)}
                            Increment={getIncrement(
                              agreement[ids[0]].year8,
                              agreement[ids[0]].year9,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                        {agreement[ids[0]].tenure > 108 && (
                          <YearField
                            year={"Year 10"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year10)}
                            Increment={getIncrement(
                              agreement[ids[0]].year9,
                              agreement[ids[0]].year10,
                              agreement[ids[0]].yearlyIncrement
                            )}
                          />
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid item md={10}>
                  {agreement[ids[0]].status === "Paid" && (
                    <Grid container sx={{ mb: 2, mt: 4 }}></Grid>
                  )}

                  {agreement[ids[0]].status === "Paid" &&
                    agreement[ids[0]].utr_number !== null && (
                      <Grid container sx={{ mb: 2 }}>
                        <DataFieldStyle
                          field={"Rent UTR Number"}
                          value={agreement[ids[0]].utr_number}
                        />
                        <DataFieldStyle
                          field={"Rent Payment Date"}
                          value={moment(agreement[ids[0]].payment_date).format(
                            "DD/MM/YYYY"
                          )}
                        />
                      </Grid>
                    )}

                  <Grid container spacing={4} sx={{ mt: 4 }}>
                    <TextFieldWrapper
                      required={true}
                      label="Rental Month"
                      placeHolder="Rent Date"
                      value={moment(agreement[ids[0]].rent_date).format(
                        "DD/MM/YYYY"
                      )}
                      disabled={true}
                    />

                    <TextFieldWrapper
                      required={true}
                      label="Rental Amount"
                      placeHolder="Enter Rent Amount"
                      value={parseInt(
                        agreement[ids[0]].rent_amount
                      ).toLocaleString()}
                      disabled={true}
                      name="rent_amount"
                    />
                    {agreement[ids[0]].rent_gst.length > 0 && (
                      <TextFieldWrapper
                        required={true}
                        label="GST Amount"
                        placeHolder="Enter GST AMount"
                        value={parseInt(
                          agreement[ids[0]].gst_amount
                        ).toLocaleString()}
                        disabled={true}
                        name="gst_amount"
                      />
                    )}
                    <TextFieldWrapper
                      required={true}
                      label="Payable Amount"
                      placeHolder="Enter Total Amount"
                      value={parseInt(
                        parseInt(agreement[ids[0]].rent_amount) +
                          parseInt(agreement[ids[0]].gst_amount)
                      ).toLocaleString()}
                      disabled={true}
                    />

                    {agreement[ids[0]].rent_gst.length > 0 &&
                      agreement[ids[0]].invoice_number !== '""' &&
                      agreement[ids[0]].invoice_number !== null &&
                      agreement[ids[0]].invoice_date !== '""' &&
                      agreement[ids[0]].invoice_date !== null && (
                        <>
                          <TextFieldWrapper
                            required={true}
                            label="Invoice Number"
                            placeHolder="Enter Invoice Number"
                            value={agreement[ids[0]].invoice_number}
                            disabled={true}
                            name="invoice_no"
                          />
                          <TextFieldWrapper
                            required={true}
                            label="Invoice Date"
                            placeHolder="Invoice Date"
                            value={moment(
                              agreement[ids[0]].invoice_date
                            ).format("DD/MM/YYYY")}
                            disabled={true}
                          />
                        </>
                      )}

                    {agreement[ids[0]].rent_gst.length > 0 &&
                      agreement[ids[0]].invoice !== null && (
                        <Grid item xs={12} container>
                          <Grid item xs={4}>
                            <DocumentUpload
                              label="Invoice"
                              disabled={true}
                              placeHolder="Invoice"
                              name={"invoice"}
                              href={agreement[ids[0]].invoice}
                            />
                          </Grid>
                        </Grid>
                      )}

                    {agreement[ids[0]].status === "Paid" &&
                      agreement[ids[0]].utr_number === null && (
                        <Grid item xs={10}>
                          <Typography
                            variant="body1"
                            height="30px"
                            color="primary"
                          >
                            Note: Monthly Payment Adjust In Deposit Adjustment
                          </Typography>
                        </Grid>
                      )}
                    {allRemarks && allRemarks.length > 0 && (
                      <>
                        <Grid
                          item
                          container
                          sx={{ alignItems: "baseline", mt: 5 }}
                          xs={10}
                        >
                          <DataFieldStyle field={"Remark"} />
                          {allRemarks.map((remark, index) => (
                            <div
                              style={{
                                width: "100%",
                                padding: "10px 10px 10px 0",
                              }}
                              key={index}
                            >
                              {remark}
                            </div>
                          ))}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      )}
    </>
  );
}

export default ViewPage;
