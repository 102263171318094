/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ListingComponent from "../StyleComponents/ListingComponent";
import {
  get_finance_agreements,
  get_search_finance_agreements,
  get_finance_agreements_inProcess,
  get_finance_agreements_approved,
} from "../../Services/Services";
import { useSelector } from "react-redux";
import FinanceTable from "./FinanceDataTable";
import FinanceHamburger from "./FinanceHamburger";
import moment from "moment";

const options = ["New Agreement", "Monthly Payment", "Rental"];

function FinanceListing() {
  const { auth, refresh } = useSelector((state) => state);
  const { type } = useParams();

  const finance_ID = auth.id;
  const [rows, setRows] = useState([]);

  const [data, setData] = useState([]);
  const [searchValue, setsearchValue] = useState("");

  const getData = async (id) => {
    const response = await get_finance_agreements(id);
    if (response.status === 200) {
      setData(response.data.agreement);
    }
  };

  async function getInprocessAg(id) {
    try {
      const response = await get_finance_agreements_inProcess(id);
      if (response.status === 200) {
        setData(response.data.agreement);
      }
    } catch (error) {}
  }

  async function getMigratedAg(id) {
    try {
      const response = await get_finance_agreements_inProcess(id, "Migrated");
      if (response.status === 200) {
        setData(response.data.agreement);
      }
    } catch (error) {}
  }

  async function getPendingAg(id) {
    try {
      const response = await get_finance_agreements_inProcess(id, "Pending");
      if (response.status === 200) {
        setData(response.data.agreement);
      }
    } catch (error) {}
  }

  //approved agreements
  async function get_approved_agreements(id) {
    try {
      const response = await get_finance_agreements_approved(id);
      if (response.status === 200) {
        setData(response.data.agreement);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (type === "total-ag") {
      getData(finance_ID);
    } else if (type === "in-procces-ag") {
      getInprocessAg(finance_ID);
    } else if (type === "approved-ag") {
      get_approved_agreements(finance_ID);
    } else if (type === "mg-ag") {
      getMigratedAg(finance_ID);
    } else if (type === "pendingList") {
      getPendingAg(finance_ID);
    }
  }, [type, refresh]);

  useEffect(() => {
    data &&
      data.length > 0 &&
      setRows(
        data.map((item) => {
          return {
            checkbox: item.status,
            id: item.landlords,
            i: item.id,
            status: item.status,
            code: item.code,
            name: item.name,
            location: item.location,
            address: item.address,
            rentalAmount: parseFloat(
              (Number(item.monthlyRent) / 100) * Number(item.percentage)
            ).toFixed(0),
            // deposit:parseFloat( Number(item.deposit)/100* Number(item.percentage)).toFixed(0),
            // deposit:(item.old_deposit && item.new_amount) ? (item.old_deposit - item.new_amount) * (item.percentage/100) : "---",
            deposit: item.deposit,
            state: item.state,

            manager_name: item.manager_name,
            srm_name: item.srm_name,
            buh: item.buh,
            op_name: item.op_name,
            city: item.city,
            initiateDate: moment(item.time).format("DD-MM-YYYY"),
            type: item.type ? item.type : "New",
            utr_number: item.utr_number,
            modify_date: item.modify_date,
            // payable_deposit: (item.old_deposit && item.new_amount) ? (item.old_deposit - item.new_amount) * (item.percentage/100) : "---",
            payable_deposit:
              item.type === "Renewed"
                ? Math.round(item.deposit_payable)
                : parseInt(
                    (Number(item.deposit) / 100) * Number(item.percentage)
                  ),
          };
        })
      );
  }, [data]);

  let filteredRows =
    searchValue.length > 0
      ? rows.filter(
          (object) =>
            JSON.stringify(object)
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > 0
        )
      : rows;

  //search
  async function SearchAPi(id, searchValue, type) {
    const search = await get_search_finance_agreements(id, searchValue, type);
    setData(Object.values(search.data.agreement));
  }

  function handleSerachChange(e) {
    setsearchValue(e.target.value);
  }

  const navigate = useNavigate();

  return (
    <>
      {/* {data.success && ( */}
      <Stack sx={{ flexWrap: "wap", flexDirection: "row" }}>
        <FinanceHamburger />
        <ListingComponent
          title1={
            <header>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </header>
          }
          title={
            type === "in-procces-ag"
              ? "In Process"
              : type === "mg-ag"
              ? "Migrated Agreement"
              : type === "approved-ag"
              ? "Active / In Active Agreements"
              : "Rental Agreement"
          }
          buttonText="Upload"
          options={options}
          value={"New Agreement"}
          setRows={setRows}
          Table={FinanceTable}
          rows={filteredRows}
          dropDown={false}
          searchValue={searchValue}
          setsearchValue={setsearchValue}
          handleSerachChange={handleSerachChange}
        />
      </Stack>
      {/* )} */}
    </>
  );
}

export default FinanceListing;
