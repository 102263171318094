import { Box, Grid, Stack } from "@mui/material";
import React, { useState } from "react";

//icons hero section
import Logo from "../../assest/pic/Dashboard/logo1 2.png";
import Dashboard from "../../assest/pic/Dashboard/chart.png";

import "../../assest/CSS/hamburgerMenu.css";
import { useNavigate } from "react-router-dom";
import { Vector1, Vector2, Vector3, VectorLogout, VectorUser } from "../Vector";
import { NavExpand, NavItem } from "../StyleComponents/HamburgerStyled";
import { setAlert } from "../../store/action/action";
import { useDispatch } from "react-redux";

function AdminHamburgerMenu() {
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  function logout() {
    try {
      localStorage.clear();
      dispatch({ type: "LOGOUT" });
      navigate("/");
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: "You have been successfully logged out.",
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong !",
        })
      );
    }
  }
  return (
    <>
      {/* hambergur menu  */}
      <Grid
        sx={{ ml: "10px" }}
        className={expand ? "HeroSectionMenu" : ""}
        onBlur={() => setExpand(false)}
        // onMou
      >
        <Stack sx={{ flexDirection: "column" }} spacing={2}>
          <Box
            sx={{
              background: `url(${Logo})`,
              height: "65px",
              width: "89px",
              backgroundSize: "cover",
            }}
          />
          <Box sx={{ display: "grid", placeItems: "center", width: "89px" }}>
            <Box
              sx={{
                background: `url(${Dashboard})`,
                backgroundSize: "cover",
                height: "45px",
                width: "45px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/userDashboard")}
              title={"Dashboard"}
            />
          </Box>

          {!expand ? (
            <>
              <NavItem
                title={"User Management"}
                Vector={VectorUser}
                onClick={() => navigate(`/userManagement/all-user`)}
              />
              <NavItem
                title={"Rental MIS Report"}
                Vector={Vector2}
                onClick={() => navigate("/rental-mis-reports")}
              />
              <NavItem
                title={"graph Reports"}
                Vector={Vector3}
                onClick={() => navigate("/graph-reports")}
              />
              {/* <NavItem
                title={"Export Reports"}
                Vector={Vector3}
                onClick={() => navigate("/export-reports")}
              /> */}
              <NavItem
                title={"Rental Due Schedule"}
                Vector={Vector1}
                onClick={() => navigate("/rent-paid-schedule")}
              />
              {/* write code here for adding new menu */}
              <NavItem
                title={"Logout"}
                Vector={VectorLogout}
                onClick={logout}
              />
            </>
          ) : (
            <Stack container spacing={2}>
              {/* onclick */}
              <NavExpand
                msg="Users"
                Vector={VectorUser}
                NavItem={NavItem}
                onClick={() => navigate(`/super-admin-listing`)}
              />
              <NavExpand
                msg="LogOut"
                // navigateTO=""
                Vector={VectorLogout}
                onClick={logout}
              />
            </Stack>
          )}
        </Stack>
      </Grid>
    </>
  );
}

export default AdminHamburgerMenu;
