/* eslint-disable no-unused-vars */
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ListingComponent from "../../StyleComponents/ListingComponent";
import ListingTable from "./ListingTable";
import {
  get_monthlt_rent_finance,
  get_monthlt_rent_finance_paid,
  get_search_monthly_rent_finance,
  get_search_monthly_rent_finance_paid,
} from "../../../Services/Services";
import { useSelector } from "react-redux";
import FinanceHamburger from "../FinanceHamburger";
import moment from "moment";
import { useParams } from "react-router-dom";

function FinanceMonthlyPayement() {
  const { type } = useParams();
  const { auth, refresh } = useSelector((s) => s);

  const [agIDS, setAgIds] = useState([]);
  const [rentData, setRent] = useState({});

  //search
  const [searchValue, setsearchValue] = useState("");

  async function fetchData(id) {
    try {
      const data = await get_monthlt_rent_finance(id);
      if (data.data.success) {
        setAgIds(data.data.ids);
        setRent(data.data.agreement);
      } else {
        // TODO document
      }
    } catch (error) {}
  }

  async function fetchDataPaid(id) {
    try {
      const data = await get_monthlt_rent_finance_paid(id);
      if (data.data.success) {
        setAgIds(data.data.ids);
        setRent(data.data.agreement);
      } else {
        // TODO document why this block is empty
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (type === "in-process") {
      fetchData(auth.id);
    } else if (type === "paid") {
      fetchDataPaid(auth.id);
    }
  }, [auth.id, refresh, type]);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const rows = agIDS.map((row) => {
    return {
      id: rentData[row].id,
      agreement_id: rentData[row].agreement_id,
      landlord_name: rentData[row].landlord_name,
      monthly_id: rentData[row].id,
      code: rentData[row].code,
      checkbox: rentData[row].status,
      status: rentData[row].status,
      utr: rentData[row].utr_no,
      // srmanager:rentData[row].manager,
      name: rentData[row].landlord_name,
      location: rentData[row].location,
      gst: rentData[row].gst ? rentData[row].gst : "---",
      percentage: rentData[row].share,
      month_of_rent:
        month[new Date(rentData[row].rent_date).getUTCMonth()] +
        " " +
        new Date(rentData[row].rent_date).getFullYear(),
        rent_date: moment(rentData[row].rent_date).format("MMMM YY"),
      total_month_rent: rentData[row].monthly_rent,
      rent_amount: parseInt(rentData[row].rent_amount),
      manager: rentData[row].manager_name,
      operations: rentData[row].op_name,
      srm_name: rentData[row].srm_name,
      gst_fee: rentData[row].gst
        ? parseInt((Number(rentData[row].rent_amount) / 100) * 18)
        : 0,
      total_rent: rentData[row].gst
        ? parseInt(
            (Number(rentData[row].rent_amount) / 100) * 18 +
              Number(rentData[row].rent_amount)
          )
        : parseInt(rentData[row].rent_amount),
    };
  });
  const filteredRow =
    searchValue.length === 0
      ? rows
      : rows.filter(
          (item) =>
            JSON.stringify(item)
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > 0
        );
  async function SearchAPi(id, searchValue) {
    const search = await get_search_monthly_rent_finance(id, searchValue);
    setAgIds(search.data.ids);
    setRent(search.data.agreement);
  }

  async function Search_Paid(id, searchValue) {
    const search = await get_search_monthly_rent_finance_paid(id, searchValue);
    setAgIds(search.data.ids);
    setRent(search.data.agreement);
  }

  function handleSerachChange(e) {
    setsearchValue(e.target.value);
  }

  return (
    <>
      <Stack sx={{ flexWrap: "wap", flexDirection: "row" }}>
        <FinanceHamburger />
        <ListingComponent
         title1={<header>RMS <span className="small-heading">(Rental Management System)</span></header>}
          title={
            type === "in-process"
              ? "Monthly Payment - In Process"
              : type === "paid"
              ? "Monthly Payment-Paid/Terminated"
              : "Monthly Payment"
          }
          buttonText="Upload"
          value={"New Agreement"}
          Table={ListingTable}
          rows={filteredRow}
          dropDown={false}
          searchValue={searchValue}
          handleSerachChange={handleSerachChange}
          setsearchValue={setsearchValue}
        />
      </Stack>
    </>
  );
}

export default FinanceMonthlyPayement;
