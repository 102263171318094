/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../assest/CSS/hamburgerMenu.css";
import { DashboardItem, MyHeader } from "../StyledComponent";
import { getMetaData } from "../../Services/Services";
import { useSelector } from "react-redux";
import HamburgerManager from "./HamburgerManager";

function Dashboard() {
  const navigate = useNavigate();
  const [meta, setMeta] = useState({
    totalAgreement: 0,
    Pending: 0,
    Approved: 0,
    Renewal: 0,
  });

  const { auth } = useSelector((s) => s);

  async function getMetaDatas(id) {
    const metaData = await getMetaData(id);
    setMeta(metaData.data);
  }
  useEffect(() => {
    getMetaDatas(auth.id);
  }, []);
  const data = [
    {
      service: "Total Agreements",
      value: meta.totalAgreement,
      onClick: () => navigate("/listing/total-ag"),
    },
    {
      service: "Pending Approvals",
      value: meta.Pending,
      onClick: () => navigate("/pendinglist"),
    },
    {
      service: "Active/In Active Agreements",
      value: meta.Approved,
      onClick: () => navigate("/listing/approved-ag"),
    },
  ];
  return (
    <>
      <Stack sx={{ flexWrap: "noWrap", flexDirection: "row" }}>
        <HamburgerManager />
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "space-between", display: "flex" }}
          >
            <MyHeader>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </MyHeader>
            <Typography mt="15px" mr="15px" fontWeight="600">
              Welcome {auth.name}
            </Typography>
          </Grid>
          <Grid item xs={10} sx={{ mt: 4 }}>
            <Grid container spacing={4}>
              {data.map((item, index) => {
                return (
                  <DashboardItem
                    service={item.service}
                    value={item.value}
                    key={index}
                    onClick={item.onClick}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

export default Dashboard;
