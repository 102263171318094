import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  MyHeader,
  SelectComponent,
  TextFieldWrapper,
} from "../StyledComponent";
import { excelDownload, uploadDoc, uploadExcel } from "../../Services/Services";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "../../store/action/action";
import FinanceHam from "../FinancePanel/FinanceHamburger";
import ManagerHam from "../Manager/HamburgerManager";
import SrMHam from "../SrManager/SRMHAmburger";
import OPHam from "../Operations/OperationsHamburger";
import AdminHamburgerMenu from "./AdminHamburgerMenu";

import BackButton from "../utility/BackButton";
import SuperAdminHamburgerMenu from "./SuperAdminHamburgerMenu";

const ExportReports = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reports, setReports] = useState("");
  console.log(reports, "reports");
  const [financialYear, setFinancialYear] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  // const [data, setData] = useState({});
  const { auth } = useSelector((s) => s);

  const { role, id } = auth;

  const labelStyle = {
    fontSize: "20px",
    lineHeight: "30px",
    color: "var(--main-color)",
    fontWeight: "600",
    "@media(max-width:900px)": { fontSize: "10px" },
  };

  function getFinancialYearDates(financialYear) {
    const yearData = financialYear.split("-");
    const startDate = `${yearData[0]}-04-01`;
    const endDate = `20${yearData[1]}-03-31`;
    return {
      financialStartDate: startDate,
      financialEndDate: endDate,
    };
  }
  async function handleChangeFile(e) {
    const FD = new FormData();
    const file = e.target.files;

    FD.append("file", e.target.files[0]);
    const response = await uploadExcel(FD, reports);

    if (response.status === 200) {
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: response.data.message,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message || "Something went wrong !!!",
        })
      );
    }
  }
  function onChange(e) {
    if (e.target.name === "financialYear") {
      const regex = /^\d{4}-(\d{2})?$/;
      const newFinancialYear = e.target.value;

      setFinancialYear(newFinancialYear);

      if (newFinancialYear === "" || regex.test(newFinancialYear)) {
        if (newFinancialYear !== "") {
          const { financialStartDate, financialEndDate } =
            getFinancialYearDates(newFinancialYear);

          if (moment(financialStartDate).isAfter(financialEndDate)) {
            setError("Start date should be earlier than end date");
          } else {
            setError(false);
            setStartDate(financialStartDate);
            setEndDate(financialEndDate);
          }
        } else {
          setError(false);
        }
      } else {
        setError(true); // Invalid format
      }
    } else if (e.target.name === "reports") {
      setReports(e.target.value);
    } else if (e.target.name === "start_date") {
      setStartDate(e.target.value);
    } else if (e.target.name === "end_date") {
      setEndDate(e.target.value);
    }
  }
  return (
    <>
      <Box sx={{ position: "absolute", right: "1%", top: "2%" }}>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          {" "}
          Welcome {auth.name}
        </Typography>
      </Box>
      <Stack sx={{ flexWrap: "nowrap", flexDirection: "row" }}>
        {role.includes("Super Admin") && <SuperAdminHamburgerMenu />}
        {role.includes("Admin") && !role.includes("Operations") && (
          <AdminHamburgerMenu />
        )}

        {role.includes("Finance") && <FinanceHam />}
        {role.includes("Manager") && <ManagerHam />}
        {role.includes("Senior_Manager") && <SrMHam />}
        {role.includes("Operations") && <OPHam />}

        <BackButton />

        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            sx={{ flexDirection: "column", justifyContent: "center" }}
          >
            <Grid xs={12}>
              <MyHeader>
                RMS{" "}
                <span className="small-heading">
                  (Rental Management System)
                </span>
              </MyHeader>
              <Divider />
              <Grid
                container
                sx={{
                  px: 1,
                  justifyContent: "space-between",
                  my: 1,
                  alignItems: "center",
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    color="black"
                    fontSize="20px"
                    fontWeight="600"
                    alignSelf="center"
                    lineHeight="30px"
                    sx={{
                      "@media(max-width:600px)": {
                        fontSize: "17px",
                        lineHeight: "25px",
                      },
                    }}
                  >
                    UTR Bulk Upload
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ mt: 4 }}>
              <SelectComponent
                label={"UTR Bulk Upload Reports"}
                required={true}
                name="reports"
                options={[
                  "Pending UTR - Monthly Rentals",
                  "Pending UTR - Deposit payment",
                ]}
                value={reports}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <br />
              <TextFieldWrapper
                label="Financial Year e.g 2022-23"
                placeHolder="Enter financial year e.g 2022-23"
                name="financialYear"
                maxLength={7}
                value={financialYear}
                onChange={(e) => onChange(e)}
                error={
                  error &&
                  "The value does not match the financial year pattern."
                }
              />
              <Grid item md={10} sx={{ display: "flex", mt: 4 }}>
                {/* <FormControl> */}
                <FormControl fullWidth sx={{ mr: 4 }}>
                  <FormLabel>
                    <Typography variant="body1" sx={labelStyle}>
                      Start Date *
                    </Typography>
                  </FormLabel>
                  <input
                    type="date"
                    name="start_date"
                    value={startDate}
                    className="DatePicker"
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => onChange(e)}
                    onFocus={(e) => e.currentTarget.blur()}
                    disabled={financialYear}
                  />
                  <Typography
                    variant="caption"
                    sx={{ color: "red" }}
                  ></Typography>
                </FormControl>
                {/* <FormControl> */}
                <FormControl fullWidth>
                  <FormLabel>
                    <Typography variant="body1" sx={labelStyle}>
                      End Date *
                    </Typography>
                  </FormLabel>
                  <input
                    type="date"
                    name="end_date"
                    value={endDate}
                    className="DatePicker"
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => onChange(e)}
                    onFocus={(e) => e.currentTarget.blur()}
                    disabled={financialYear}
                  />
                  <Typography
                    variant="caption"
                    sx={{ color: "red" }}
                  ></Typography>
                </FormControl>
              </Grid>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                sx={{
                  m: 5,
                  height: 45,
                  width: 200,
                  color: "#FFFFFF",
                  borderRadius: "15px",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  excelDownload(reports, id, role, startDate, endDate);
                }}
                disabled={!(reports && startDate && endDate)}
              >
                Download
              </Button>

              <Button
                variant="contained"
                sx={{
                  border: "1px solid #C8C8C8",
                  borderRadius: "20px",
                  m: 5,
                  height: 45,
                  width: 200,
                  borderRadius: "15px",
                  p: 2,
                  color: "white",
                  textTransform: "capitalize",
                }}
                disabled={!reports}
                component="label"
              >
                {"Upload"}

                <input
                  hidden
                  accept=".xlsx"
                  name={reports}
                  multiple
                  type="file"
                  onChange={handleChangeFile}
                />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );
};

export default ExportReports;
