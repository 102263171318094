/* eslint-disable no-unused-vars */
import { Box, Button, Checkbox, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  add_invoice_monthly,
  get_rent_data_ID,
  notification_handler,
  sendMonthyPaymentForword,
  sendMonthyPaymentForwordNew,
} from "../../Services/Services";
import { useDispatch, useSelector } from "react-redux";
import { setAlert, setRefreshBox } from "../../store/action/action";
import UploadInvoice from "./UploadInvoiceMonthly";

function DataTable({ rows }) {
  const { type } = useParams();

  const [open, setOpen] = useState(false);
  const [monthlyId, setMonthlyId] = useState(0);

  const [loading, setLoading] = useState(false);

  const { auth, refresh } = useSelector((state) => state);

  const role = auth.role;

  const subType = "monthly";
  const [status, setStatus] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceNo: "",
    invoiceDate: "",
    rentAmount: "",
    gstAmount: "",
    totalAmount: "",
    invoice: "",
    invoice_file_name: "",
    manager_id: auth.id,
    gst: "",
    monthlyId: monthlyId,
    status: "",
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [selectID, setSelectID] = useState(0);
  const [code, setCode] = useState(0);
  const [notificationData, setNotificationData] = useState({});
  async function getData(id) {
    try {
      const response = await get_rent_data_ID(id);
      if (response.data.succes) {
        setInvoiceDetails({
          ...invoiceDetails,
          gst: response.data.data[0].gst ? response.data.data[0].gst : "---",
          rentAmount: response.data.data[0].rent_amount,
          gstAmount: response.data.data[0].gst
            ? (response.data.data[0].rent_amount / 100) * 18
            : 0,
          invoiceNo:
            response.data.data[0].invoice_number !== '""'
              ? response.data.data[0].invoice_number
              : "",
          invoiceDate:
            response.data.data[0].invoice_date !== '""'
              ? response.data.data[0].invoice_date
              : "",
          invoice:
            response.data.data[0].invoice !== '""'
              ? response.data.data[0].invoice
              : "",
          agreement_id: response.data.data[0].agreement_id,
          code: response.data.data[0].code,
          landlord_name: response.data.data[0].landlord_name,
          user_id: auth.id,
          status: response.data.data[0].status,
        });
        setNotificationData({
          agreement_id: response.data.data[0]?.agreement_id,
          code: response.data.data[0]?.code,
          landlord_name: response.data.data[0]?.landlord_name,
          monthlyId: response.data.data[0]?.id,
          user_id: auth.id,
        });
        setStatus(response.data.data[0].status);
      } else {
        dispatch(
          setAlert({
            open: true,
            message: "something Went Wrong Please Try Again Later.",
            variant: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          message: "something Went Wrong Please Try Again Later.",
          variant: "error",
        })
      );
    }
  }

  async function handleSendSRM(id, obj) {
    try {
      const send = await sendMonthyPaymentForwordNew(
        id,
        role,
        "monthly",
        {
          status: "Sent To Sr Manager",
          manager_id: auth.id,
          additionalRemark: null,
        },
        id,
        role,
        subType
      );
      console.log(send, "res");
      if (send.status == 200) {
        if (send.data.success) {
          await notification_handler(
            obj?.event_id,
            obj?.agreement_id,
            obj?.code,
            auth.id,
            false,
            obj?.monthly_id,
            obj?.landlord_name
          );
          dispatch(
            setAlert({
              open: true,
              variant: "success",
              message: "Payment Details Sent To Sr Manager.",
            })
          );
          dispatch(setRefreshBox());
          //  navigate(-1)
        } else if (send.data.success === false) {
          dispatch(
            setAlert({
              open: true,
              variant: "error",
              message: send.data.message,
            })
          );
          dispatch(setRefreshBox());
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        } else {
          dispatch(
            setAlert({
              open: true,
              variant: "error",
              message: send.data.message,
            })
          );
        }
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Something Went Wrong Please Try Again Later.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }

  const gstUploadButton = (e) => {
    const id = e.id;

    const code = e.row.code;
    const rowData = e.row;

    return (
      <>
        <Grid container spacing={5} sx={{ justifyContent: "center" }}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{
                backgroundColor: "rgb(103 185 68 / 89%)",
                color: "white",
                fontSize: "12px",
                textTransform: "capitalize",
                // width:"100%"
              }}
              onClick={async (e) => {
                e.stopPropagation(); // don't select this row after clicking

                navigate(`/monthly-payment-view/${id}`);
              }}
            >
              View
            </Button>
          </Grid>
          {(e.row.status === "Sent Back From Sr Manager" ||
            e.row.status === "Sent Back From Operations" ||
            e.row.status === "Sent Back From Finance") && (
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "#97999c",
                  color: "white",
                  fontSize: "12px",
                  textTransform: "capitalize",
                  // width:"100%"
                }}
                onClick={(event) => {
                  event.stopPropagation(); // don't select this row after clicking
                  // navigate(`/monthly-payment-edit/${id}`);
                  getData(id);
                  setSelectID(id);
                  setOpen(true);
                  setCode(e.row.code);
                  setMonthlyId(e.row.id);
                }}
              >
                Edit
              </Button>
            </Grid>
          )}
          {(e.row.status === "Draft" || e.row.status === "Pending") &&
            e.row.gst !== "---" && (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    backgroundColor: "rgb(103 185 68 / 89%)",
                    color: "white",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    // width:"100%"
                  }}
                  onClick={(event) => {
                    event.stopPropagation(); // don't select this row after clicking
                    // navigate(`/BHUapproval/${id}`);
                    getData(id);
                    setSelectID(id);
                    setOpen(true);
                    setCode(e.row.code);
                    setMonthlyId(e.row.id);
                  }}
                >
                  Upload
                </Button>
              </Grid>
            )}
          {(e.row.status === "Draft" || e.row.status === "Pending") &&
            e.row.gst === "---" && (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    backgroundColor: "rgb(103 185 68 / 89%)",
                    color: "white",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    // width:"100%"
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    handleSendSRM(id, {
                      event_id: "MR5",
                      agreement_id: rowData?.agreement_id,
                      code: rowData?.code,
                      monthly_id: rowData?.id,
                      landlord_name: rowData?.landlord_name,
                    });
                  }}
                >
                  Approve
                </Button>
              </Grid>
            )}
        </Grid>
      </>
    );
  };

  const [ids, setIds] = useState([]);

  //handle Checkbox
  const handleSwitch = (e) => {
    let idVar = Number(e.target.name);
    const data = rows.find((data) => data.id === idVar);
    setNotificationData((raw) => data);
    if (ids.includes(idVar)) {
      setIds(ids.filter((i) => i !== idVar));
    } else {
      setIds([...ids, idVar]);
    }
  };
  const approvedColumns = [
    {
      field: "code",
      headerName: "Code",
      flex: 1,
      minWidth: 100,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "rent_date",
      headerName: "Rent Month",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      // maxHeight:150
    },
    {
      field: "landlord_name",
      headerName: "Landlord Name",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      // maxWidth:200
    },
    {
      field: "location",
      headerName: "Location",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      // maxWidth:200
    },
    {
      field: "gst",
      headerName: "GST Number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      // maxWidth:200
    },
    {
      field: "monthly_rent",
      headerName: "Monthly Rent",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      // maxWidth:200
    },
    {
      field: "share",
      headerName: "Percentage Share",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      // maxWidth:200
    },
    {
      field: "rent_amount",
      headerName: "Payable Rent",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "gst_fee",
      headerName: "GST",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "total_rent",
      headerName: "Total Rent Payable",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      flex: 1,
      // maxWidth:500,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      flex: 1,
      minWidth: 200,
      // maxWidth:250,
      renderCell: gstUploadButton,
    },
  ];
  const columns = [
    {
      field: "checkbox",
      disableColumnMenu: true,
      sortable: false,
      width: 20,
      type: "number",
      headerClassName: "dataGridHeader",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.formattedValue === "" && params.row.status === "Pending" ? (
            <Checkbox
              onChange={handleSwitch}
              name={params.id}
              checked={ids.includes(params.id)}
            />
          ) : (
            <Checkbox disabled={true} />
          )}
        </>
      ),
    },

    {
      field: "code",
      headerName: "Code",
      flex: 1,
      minWidth: 100,
      type: "number",
      headerAlign: "center",
    },
    {
      field: "rent_date",
      headerName: "Rent Month",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      // maxHeight:150
    },
    {
      field: "landlord_name",
      headerName: "Landlord Name",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      // maxWidth:200
    },
    {
      field: "location",
      headerName: "Location",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      // maxWidth:200
    },
    {
      field: "gst",
      headerName: "GST Number",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      // maxWidth:200
    },
    {
      field: "monthly_rent",
      headerName: "Monthly Rent",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      // maxWidth:200
    },
    {
      field: "share",
      headerName: "Percentage Share",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      // maxWidth:200
    },
    {
      field: "rent_amount",
      headerName: "Payable Rent",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "gst_fee",
      headerName: "GST",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "total_rent",
      headerName: "Total Rent Payable",
      headerAlign: "center",
      flex: 1,
      minWidth: 100,
      //  maxWidth:200
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      flex: 1,
      // maxWidth:500,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      flex: 1,
      minWidth: 200,
      // maxWidth:250,
      renderCell: gstUploadButton,
    },
  ];

  //upload invoice details
  async function uploadInvoiceDetails() {
    try {
      let temp =
        status === "Sent Back From Operations"
          ? "Sent To Operations"
          : "Sent To Sr Manager";
      invoiceDetails["status"] = temp;
      const addInvoice = await add_invoice_monthly(selectID, invoiceDetails);

      if (addInvoice.data.success) {
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message:
              status === "Sent Back From Operations"
                ? "Invoice Details Submited & Sent To Operations"
                : "Invoice Details Submited & Sent To Sr Manager",
          })
        );
        // dispatch(setRefreshBox());
        setInvoiceDetails({
          invoiceNo: "",
          invoiceDate: "",
          rentAmount: "",
          gstAmount: "",
          totalAmount: "",
          invoice: "",
          invoice_file_name: "",
          manager_id: auth.id,
        });
        dispatch(setRefreshBox());
        setOpen(false);
      } else if (addInvoice.data.success === false) {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: addInvoice.data.message,
          })
        );
        dispatch(setRefreshBox());
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Some Error Occured Please Try Again Later.",
          })
        );
      }
      // window.location.reload();
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }

  function handleHold() {
    ids.map(async (id) => {
      const send = await sendMonthyPaymentForword(id, {
        status: "Draft",
        manager_id: auth.id,
        additionalRemark: null,
      });

      if (send.data.success) {
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message: "Payment Details Set On Hold.",
          })
        );
        dispatch(setRefreshBox());
        //  navigate(-1)
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Something Went Wrong Please Try Again Later.",
          })
        );
      }
    });
  }

  async function SendToSrm() {
    for (const id of ids) {
      try {
        // setTimeout(async () => {
        const send = await sendMonthyPaymentForwordNew(
          id,
          auth.role,
          "monthly",
          {
            status: "Sent To Sr Manager",
            manager_id: auth.id,
            additionalRemark: null,
          },
          id,
          role,
          subType
        );
        if (send.data.success) {
          await notification_handler(
            "MR5",
            notificationData?.agreement_id,
            notificationData?.code,
            auth.id,
            false,
            notificationData?.id,
            notificationData?.landlord_name
          );
          dispatch(
            setAlert({
              open: true,
              variant: "success",
              message: "Payment Details Sent To Sr Manager.",
            })
          );
          dispatch(setRefreshBox());
          setIds([]);
          //  navigate(-1)
        } else if (send.data.success === false) {
          dispatch(
            setAlert({
              open: true,
              variant: "error",
              message: send.data.message,
            })
          );
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        } else {
          dispatch(
            setAlert({
              open: true,
              variant: "error",
              message: "Something Went Wrong Please Try Again Later.",
            })
          );
        }
        // }, 500);
      } catch (error) {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Something Went Wrong Please Try Again Later.",
          })
        );
      }
    }
    setIds([]);
  }

  // handleSendSRM(id)

  return (
    <>
      {ids.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", m: 1, mx: 1 }}
            onClick={() => {
              SendToSrm();
            }}
          >
            Approve
          </Button>
        </Box>
      )}

      <UploadInvoice
        open={open} /** */
        // setOpen={setOpen}
        handleClose={() => setOpen(false)}
        handleConfirm={uploadInvoiceDetails}
        value={invoiceDetails}
        notificationData={notificationData}
        setValue={setInvoiceDetails}
        code={code}
        monthlyId={monthlyId}
        type={"MonthlyRent"}
      />

      <Box
        sx={{
          height: "430px",
          px: 2,
          overflowX: "scroll",
          "& .dataGridHeader": {
            color: "#CACACA",
            textAlign: "left",
          },
          "& .green": {
            backgroundColor: "#E7FFE9",
            color: "#41CF2A",
          },
          "& .yellow": {
            backgroundColor: "#FEFFC8",
            color: "#C5C05B",
          },
          "& .red": {
            backgroundColor: "#FFEBE7",
            color: "#CF482A",
          },
          "& .statusCell": {
            maxHeight: "30px !important",
            minHeight: "25px !important",
            textAlign: "center !important",
            borderRadius: "10px !important",
            my: 1,
          },
          "& .allCell": {
            justifyContent: "center !important",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={type === "paid" ? approvedColumns : columns}
          pageSize={6}
          rowsPerPageOptions={[6]}
          loading={loading}
          sx={{ color: "black !important", minWidth: "50px", overflow: "auto" }}
          getCellClassName={(parms) => {
            let cellClass = [];
            if (parms.field === "status" && parms.row.status === "Paid") {
              cellClass.push("green statusCell");
            } else if (
              parms.field === "status" &&
              (parms.row.status === "Pending" ||
                parms.row.status === "Draft" ||
                parms.row.status === "Sent To Sr Manager" ||
                parms.row.status === "Sent To Operations" ||
                parms.row.status === "Sent To Finance" ||
                parms.row.status === "Approved By Finance")
            ) {
              cellClass.push("yellow statusCell");
            } else if (
              parms.field === "status" &&
              (parms.row.status === "Sent Back From Sr Manager" ||
                parms.row.status === "Hold" ||
                parms.row.status === "Sent Back From BUH" ||
                parms.row.status === "Sent Back From Operations" ||
                parms.row.status === "Sent Back From Finance" ||
                parms.row.status === "Terminated")
            ) {
              cellClass.push("red statusCell");
            }
            cellClass.push("allCell");

            return cellClass;
          }}
        ></DataGrid>
      </Box>
    </>
  );
}

export default DataTable;
