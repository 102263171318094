import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { meta } from "../../Services/Services";
import { DashboardItem, MyHeader } from "../StyledComponent";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminHamburgerMenu from "../SuperAdmin/AdminHamburgerMenu";

function UserDashboard() {
  const [metaData, setMeta] = useState({});
  const { auth } = useSelector((s) => s);

  useEffect(() => {
    getMetaData(auth.id);
  }, [auth.id]);

  async function getMetaData() {
    let response = await meta();

    if (response) {
      setMeta(response.data);
    }
  }

  const navigate = useNavigate();

  return (
    <>
      <Stack sx={{ flexWrap: "nowrap", flexDirection: "row" }}>
        <AdminHamburgerMenu />

        <Box sx={{ flexGrow: 1 }}>
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid
              xs={12}
              sx={{ justifyContent: "space-between", display: "flex" }}
            >
              <MyHeader>
                RMS{" "}
                <span className="small-heading">
                  (Rental Management System)
                </span>
              </MyHeader>
              <Typography mt="15px" mr="15px" fontWeight="600">
                Welcome {auth.name}
              </Typography>
            </Grid>
            <Grid item md={10} sx={{ mt: 4 }}>
              <Grid container spacing={4}>
                <DashboardItem
                  service="Total Operation"
                  value={metaData.Operations}
                  onClick={() => navigate("/userManagement/Operation")}
                />
                <DashboardItem
                  service="Total BUH"
                  value={metaData.BHU}
                  onClick={() => navigate("/userManagement/BUH")}
                />
                <DashboardItem
                  service="Total Finance"
                  value={metaData.Finance}
                  onClick={() => navigate("/userManagement/Finance")}
                />
                <DashboardItem
                  service="Total Sr Manager"
                  value={metaData.Senior_Manager}
                  onClick={() => navigate("/userManagement/Senior_Manager")}
                />
                <DashboardItem
                  service="Total Manager"
                  value={metaData.Manager}
                  onClick={() => navigate("/userManagement/Manager")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );
}

export default UserDashboard;
