/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataFieldStyle,
  DocumentView,
  YearField,
} from "../StyleComponents/Rental";
import { MyHeader, SelectUser, TextFieldWrapper } from "../StyledComponent";
import { useEffect, useState } from "react";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  get_agreement_id,
  get_data_recovery,
  get_old_agreement,
  get_renewal_recovery_data,
  send_back_to_manager,
  send_to_bhu,
  get_remarks_by_code,
  getUserList,
  notification_handler,
} from "../../Services/Services";
import { setAlert } from "../../store/action/action";
import { useDispatch, useSelector } from "react-redux";
import OperationsHamburger from "./OperationsHamburger";
import UploadInvoice from "../MonthalyPayment/UploadInvoiceMonthly";

const Heading = ({ heading }) => {
  return (
    <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
      <Typography
        variant="body1"
        fontSize={"20px"}
        color={"primary"}
        fontWeight={"600"}
        sx={{ textDecoration: "underline" }}
      >
        {heading}
      </Typography>
    </Grid>
  );
};
function ApprovalRequest() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { auth } = useSelector((s) => s);
  const login_manager_id = auth.id;
  const role = auth.role;

  const [agreement, setAgreement] = useState({});
  const [ids, setIds] = useState([]);
  const [allRemarks, setAllRemarks] = useState("");
  const [oldIds, setOldIds] = useState([]);
  const [SrManagerList, setSrManagerList] = useState([]);
  const [BUHUsers, setBUHUsers] = useState([]);
  const [ManagerList, setManagerList] = useState([]);
  const [renewalAdjustment, setRenewalAdjustment] = useState({});
  const [unPaidMonthsAmount, setUnpaidMonthsAmount] = useState(0);
  const [upaid, setUnpaid] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectID, setSelectID] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [monthlyId, setMonthlyId] = useState(0);
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const subType = "agreement";

  const [partLabel, setPartLabel] = useState({
    landlord: [
      {
        accountNo: "",
        alternateMobile: "",
        area: "",
        bankName: "",
        benificiaryName: "",
        branchName: "",
        cheque: "",
        email: "",
        gst: null,
        gstNo: null,
        ifscCode: "",
      },
    ],
  });
  const [renewalRecovery, setRenewalRecovery] = useState({});
  const dispatch = useDispatch();
  async function get_old_data(code) {
    try {
      const oldvalue = await get_old_agreement(code);
      oldvalue.status === 200 && setPartLabel(oldvalue.data.agreement);
      oldvalue.status === 200 && setOldIds(oldvalue.data.ids);
    } catch (error) {}
  }

  async function get_renewal_recovery(code) {
    try {
      const renewalRecovery = await get_renewal_recovery_data(code);
      renewalRecovery.status === 200 &&
        setRenewalRecovery(renewalRecovery.data.data);
    } catch (error) {}
  }

  async function get_remarks_by_aggrement_code(code) {
    try {
      const remarks = await get_remarks_by_code(code, subType, id);
      if (remarks.data.success) {
        let remark = remarks.data.remarks.map(
          ({ step_name, remarks }) => `${step_name} : ${remarks}`
        );
        setAllRemarks(remark);
      }
    } catch (error) {}
  }

  async function getData(id) {
    try {
      const agreement = await get_agreement_id(id);
      if (agreement.data.success) {
        if (agreement.data.renewalDepositeResult.length > 0) {
          setRenewalAdjustment(agreement.data.renewalDepositeResult[0]);
        }
        if (agreement.data.listHoldRow.length > 0) {
          setUnpaid(agreement.data.listHoldRow);
          let unpaid_amount = agreement.data.listHoldRow.reduce(
            (sum, row) =>
              (sum += Math.round(
                row.gstNo !== null && row.gstNo.length > 0
                  ? Number(row.rent_amount) +
                      Number((row.rent_amount / 100) * 18)
                  : row.rent_amount
              )),
            0
          );

          setUnpaidMonthsAmount(unpaid_amount);
        }
        getUserList("BUH", null).then(
          (response) => {
            setBUHUsers(response.data);
          },
          (error) => {
            console.info(error);
            setBUHUsers([]);
          }
        );
        getUserList(
          "SR.MANAGER",
          agreement.data.agreement[agreement.data.ids[0]].buh_id
        ).then(
          (response) => {
            setSrManagerList(response.data);
          },
          (error) => {
            console.info(error);
            setSrManagerList([]);
          }
        );
        getUserList(
          "MANAGER",
          agreement.data.agreement[agreement.data.ids[0]].srm_id
        ).then(
          (response) => {
            setManagerList(response.data);
          },
          (error) => {
            console.info(error);
            setManagerList([]);
          }
        );
        get_recovery_data(agreement.data.agreement[agreement.data.ids[0]].id);
        if (
          agreement.data.agreement[agreement.data.ids[0]].type === "Renewed"
        ) {
          get_old_data(agreement.data.agreement[agreement.data.ids[0]].code);
          get_renewal_recovery(id);
        }
        get_remarks_by_aggrement_code(
          agreement.data.agreement[agreement.data.ids[0]].code
        );
        setAgreement(agreement.data.agreement);
        setIds(agreement.data.ids);
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Something Went Wrong Please Try Again Later.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }

  useEffect(() => {
    getData(id);
  }, []);

  const handleSubmit = async (e) => {
    if (remark.length <= 0) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required !.",
        })
      );
    } else {
      const agreement_id = agreement[ids[0]].id;
      const agreement_code = agreement[ids[0]].code;

      const response = await send_to_bhu(
        {
          status: "Sent To Finance Team",
          op_id: login_manager_id,
          additionalRemark: {
            remarks: remark,
            step_name: auth.name,
            user_id: login_manager_id,
            agreement_code: agreement[ids[0]].code,
            agreement_id: agreement[ids[0]].id,
          },
        },
        id,
        role,
        subType
      );
      if (response.data.success) {
        const notification = await notification_handler(
          "AG11",
          agreement_id,
          agreement_code,
          auth.id
        );
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Agreement Sent To Finance Team.",
          })
        );
        navigate(-1);
      } else if (response.status === 201) {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: response.data.message,
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    }
  };

  const [remark, setRemark] = useState("");

  const [recovery, setRecovery] = useState({});

  async function get_recovery_data(id) {
    try {
      const recovery = await get_data_recovery(id);
      if (recovery.status === 200) {
        setRecovery(recovery.data.data[0]);
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong!!",
        })
      );
    }
  }

  //termination
  async function handleTerminate() {
    if (remark.length <= 0) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required !.",
        })
      );
    } else {
      const response = await send_to_bhu(
        {
          status: "Terminated By Operations",
          op_id: login_manager_id,
          additionalRemark: {
            remarks: remark,
            step_name: auth.name,
            user_id: login_manager_id,
            agreement_code: agreement[ids[0]].code,
            agreement_id: agreement[ids[0]].id,
          },
        },
        id,
        role,
        "terminate"
      );
      if (response.data.success) {
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Agreement Sent to Finance For Termination.",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    }
  }

  async function handleSendBack() {
    if (remark.length <= 0) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Remark Required !.",
        })
      );
    } else {
      const agreement_id = agreement[ids[0]].id;
      const agreement_code = agreement[ids[0]].code;

      const response = await send_back_to_manager(
        {
          status:
            agreement[ids[0]].status === "Terminated By Sr Manager"
              ? "Sent Back From Operations Termination"
              : "Sent Back From Operations",
          remark: remark,
          additionalRemark: {
            remarks: remark,
            step_name: auth.name,
            user_id: login_manager_id,
            agreement_code: agreement[ids[0]].code,
            agreement_id: agreement[ids[0]].id,
          },
        },
        id,
        role,
        subType
      );

      if (response.data.success) {
        const notification = await notification_handler(
          "AG13",
          agreement_id,
          agreement_code,
          auth.id
        );
        dispatch(
          setAlert({
            variant: "success",
            open: true,
            message: "Send back For Rectification",
          })
        );
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong! Please again later.",
          })
        );
      }
    }
  }

  function getIncrement(rent, value, type) {
    let incrementType;
    rent = Number(rent);
    value = Number(value);
    if (type === "Percentage") {
      incrementType = `${Math.round(((value - rent) / rent) * 100)}%`;
    } else if (type === "Value") {
      incrementType = value - rent;
    }
    return incrementType;
  }

  return (
    <>
      {ids &&
        ids.length > 0 &&
        (agreement[ids[0]].type === "Renewed" ? oldIds.length > 0 : true) && (
          <Stack sx={{ flexDirection: "row", mb: 4 }}>
            <OperationsHamburger />

            <Box sx={{ flexGrow: 1 }}>
              <Grid
                item
                xs={12}
                sx={{ justifyContent: "space-between", display: "flex" }}
              >
                <MyHeader>
                  RMS{" "}
                  <span className="small-heading">
                    (Rental Management System)
                  </span>
                </MyHeader>
                <Typography mt="15px" mr="15px" fontWeight="600">
                  Welcome {auth.name}
                </Typography>
              </Grid>
              <Divider />
              <Grid
                container
                sx={{
                  px: 1,
                  justifyContent: "space-between",
                  my: 1,
                  alignItems: "center",
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    color="black"
                    fontSize="20px"
                    fontWeight="600"
                    alignSelf="center"
                    lineHeight="30px"
                    sx={{
                      "@media(max-width:600px)": {
                        fontSize: "17px",
                        lineHeight: "25px",
                      },
                    }}
                  >
                    {agreement[ids[0]].type === "Migrated"
                      ? "View Migrated Agreement"
                      : "View Agreement"}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Box className="backButton">
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => navigate(-1)}
                  size={"large"}
                >
                  <ArrowCircleLeftIcon
                    sx={{ fontSize: "3rem" }}
                    color="#FFFFF !important"
                  />
                </IconButton>
              </Box>

              <Grid container sx={{ justifyContent: "center", mt: 3 }}>
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "flex-end", display: "flex" }}
                ></Grid>
                {/* Basic Details */}
                <Grid item md={10}>
                  <Heading heading={`Property Details`} />
                  {agreement[ids[0]].status === "Deposited" && (
                    <>
                      <Grid container>
                        <DataFieldStyle
                          field={"Final Agreement"}
                          href={agreement[ids[0]].final_agreement}
                          name={"Final Agreement"}
                          cursor={true}
                        />
                        <DataFieldStyle
                          field={"Final Agreement Date"}
                          value={agreement[ids[0]].final_agreement_date}
                        />
                        <DataFieldStyle
                          field={"Monthly Rent Start Date"}
                          value={agreement[ids[0]].rent_start_date}
                        />
                      </Grid>
                    </>
                  )}

                  {agreement[ids[0]].site_visit_date !== null && (
                    <>
                      <Grid container sx={{ alignItems: "baseline" }}>
                        <DataFieldStyle
                          field={"Site Visit date"}
                          value={agreement[ids[0]].site_visit_date}
                        />
                        <DataFieldStyle
                          field={"Site Visit Remark"}
                          value={agreement[ids[0]].site_visit_remark}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid container sx={{ mt: 1 }}>
                    <DataFieldStyle
                      field={"code"}
                      value={agreement[ids[0]].code}
                    />

                    <DataFieldStyle
                      field={"state"}
                      value={agreement[ids[0]].state}
                    />

                    <DataFieldStyle
                      field={"city"}
                      value={agreement[ids[0]].city}
                    />
                    <DataFieldStyle
                      field={"location"}
                      value={agreement[ids[0]].location}
                    />

                    <DataFieldStyle
                      field={"pincode"}
                      value={agreement[ids[0]].pincode}
                    />
                    <DataFieldStyle
                      field={"address"}
                      value={agreement[ids[0]].address}
                    />

                    <DataFieldStyle
                      field={"area"}
                      value={agreement[ids[0]].area + " sq. ft"}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        "Old Value: " + partLabel[oldIds[0]].area
                      }
                    />
                    <DataFieldStyle
                      field={"lock in Month"}
                      value={agreement[ids[0]].lockInYear}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        "Old Value: " + partLabel[oldIds[0]].lockInYear
                      }
                    />
                    <DataFieldStyle
                      field={"notice period in month"}
                      value={agreement[ids[0]].noticePeriod}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        "Old Value: " + partLabel[oldIds[0]].noticePeriod
                      }
                    />
                    <DataFieldStyle
                      field={"deposit"}
                      value={agreement[ids[0]].deposit}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        "Old Value: " + partLabel[oldIds[0]].deposit
                      }
                    />
                    <DataFieldStyle
                      field={"monthly rental"}
                      value={agreement[ids[0]].monthlyRent}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        "Old Value: " + partLabel[oldIds[0]].monthlyRent
                      }
                    />
                    <DataFieldStyle
                      field={"tenure"}
                      value={agreement[ids[0]].tenure}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        "Old Value: " + partLabel[oldIds[0]].tenure
                      }
                    />
                    {agreement[ids[0]].type === "Migrated" && (
                      <>
                        <SelectUser
                          label={"BUH"}
                          required={true}
                          value={agreement[ids[0]].buh_id}
                          options={BUHUsers}
                          disabled={true}
                          view={true}
                        />
                        <SelectUser
                          label={"Senior Manager"}
                          required={true}
                          value={agreement[ids[0]].srm_id}
                          options={SrManagerList}
                          disabled={true}
                          view={true}
                        />
                        <SelectUser
                          label={"Manager"}
                          required={true}
                          value={agreement[ids[0]].manager_id}
                          options={ManagerList}
                          disabled={true}
                          view={true}
                        />
                      </>
                    )}
                    {agreement[ids[0]].tenure > 12 && (
                      <>
                        <Grid container spacing={1} sx={{ mt: 6 }}>
                          <Grid item xs={12} container>
                            <DataFieldStyle
                              field={"yearly Increment"}
                              value={agreement[ids[0]].yearlyIncrement}
                            />
                          </Grid>
                          <YearField
                            year={"Year 1"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            Increment={0}
                            amount={agreement[ids[0]].year1}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              "Old Value: " + partLabel[oldIds[0]].year1
                            }
                          />
                          <YearField
                            year={"Year 2"}
                            incrementType={agreement[ids[0]].yearlyIncrement}
                            amount={Math.round(agreement[ids[0]].year2)}
                            Increment={getIncrement(
                              agreement[ids[0]].year1,
                              agreement[ids[0]].year2,
                              agreement[ids[0]].yearlyIncrement
                            )}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              getIncrement(
                                partLabel[oldIds[0]].year1,
                                partLabel[oldIds[0]].year2,
                                partLabel[oldIds[0]].yearlyIncrement
                              )
                            }
                          />
                          {agreement[ids[0]].tenure > 24 && (
                            <YearField
                              year={"Year 3"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year3)}
                              Increment={getIncrement(
                                agreement[ids[0]].year2,
                                agreement[ids[0]].year3,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year2,
                                  partLabel[oldIds[0]].year3,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 36 && (
                            <YearField
                              year={"Year 4"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year4)}
                              Increment={getIncrement(
                                agreement[ids[0]].year3,
                                agreement[ids[0]].year4,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year3,
                                  partLabel[oldIds[0]].year4,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 48 && (
                            <YearField
                              year={"Year 5"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year5)}
                              Increment={getIncrement(
                                agreement[ids[0]].year4,
                                agreement[ids[0]].year5,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year4,
                                  partLabel[oldIds[0]].year5,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 60 && (
                            <YearField
                              year={"Year 6"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year6)}
                              Increment={getIncrement(
                                agreement[ids[0]].year5,
                                agreement[ids[0]].year6,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year5,
                                  partLabel[oldIds[0]].year6,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 72 && (
                            <YearField
                              year={"Year 7"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year7)}
                              Increment={getIncrement(
                                agreement[ids[0]].year6,
                                agreement[ids[0]].year7,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year6,
                                  partLabel[oldIds[0]].year7,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 84 && (
                            <YearField
                              year={"Year 8"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year8)}
                              Increment={getIncrement(
                                agreement[ids[0]].year7,
                                agreement[ids[0]].year8,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year7,
                                  partLabel[oldIds[0]].year8,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 96 && (
                            <YearField
                              year={"Year 9"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year9)}
                              Increment={getIncrement(
                                agreement[ids[0]].year8,
                                agreement[ids[0]].year9,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year8,
                                  partLabel[oldIds[0]].year9,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                          {agreement[ids[0]].tenure > 108 && (
                            <YearField
                              year={"Year 10"}
                              incrementType={agreement[ids[0]].yearlyIncrement}
                              amount={Math.round(agreement[ids[0]].year10)}
                              Increment={getIncrement(
                                agreement[ids[0]].year9,
                                agreement[ids[0]].year10,
                                agreement[ids[0]].yearlyIncrement
                              )}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                getIncrement(
                                  partLabel[oldIds[0]].year9,
                                  partLabel[oldIds[0]].year10,
                                  partLabel[oldIds[0]].yearlyIncrement
                                )
                              }
                            />
                          )}
                        </Grid>
                      </>
                    )}

                    {Array.from(
                      { length: agreement[ids[0]].leeseName.length },
                      (row, id) => (
                        <>
                          <Grid container sx={{ mt: 3 }}>
                            <Heading
                              heading={`Landlord ${id + 1} Personal Details`}
                            />
                            <DataFieldStyle
                              field={"Name of Lessor"}
                              value={agreement[ids[0]].name[id]}
                            />
                            <DataFieldStyle
                              field={"aadhaar number"}
                              value={agreement[ids[0]].aadharNo[id]}
                              href={agreement[ids[0]].aadhar_card[id]}
                              name={"AadharCard"}
                              cursor={true}
                            />
                            <DataFieldStyle
                              field={"PAN number"}
                              value={agreement[ids[0]].panNo[id]}
                              href={agreement[ids[0]].pan_card[id]}
                              name={"pan_certicate"}
                              cursor={true}
                            />
                            {agreement[ids[0]].gstNo[id] != null && (
                              <DataFieldStyle
                                field={"GST number"}
                                value={agreement[ids[0]].gstNo[id]}
                                href={agreement[ids[0]].gst[id]}
                                name={"gst"}
                                cursor={true}
                                partLabel={
                                  agreement[ids[0]].type === "Renewed" &&
                                  "Old Value: " + partLabel[oldIds[0]].gstNo[id]
                                }
                              />
                            )}

                            <DataFieldStyle
                              field={"mobile number"}
                              value={agreement[ids[0]].mobileNo[id]}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                "Old Value: " +
                                  partLabel[oldIds[0]].mobileNo[id]
                              }
                            />
                            {agreement.alternateMobile != null && (
                              <DataFieldStyle
                                field={"alternate mobile"}
                                value={agreement.alternateMobile}
                                partLabel={
                                  agreement.type === "Renewed" &&
                                  "Old Value: " +
                                    partLabel[oldIds[0]].alternateMobile
                                }
                              />
                            )}
                            <DataFieldStyle
                              field={"email"}
                              value={agreement[ids[0]].email[id]}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                "Old Value: " + partLabel[oldIds[0]].email[id]
                              }
                            />
                            <DataFieldStyle
                              field={"Percentage Share"}
                              value={`${agreement[ids[0]].percentage[id]}%`}
                              partLabel={
                                agreement[ids[0]].type === "Renewed" &&
                                "Old Value: " +
                                  partLabel[oldIds[0]].percentage[id]
                              }
                            />
                          </Grid>
                          {agreement[ids[0]].utr_number[0] != null && (
                            <Grid
                              container
                              sx={{ alignItems: "baseline", mt: 1 }}
                            >
                              {agreement[ids[0]].utr_number != null && (
                                <DataFieldStyle
                                  field={"Deposit UTR Number"}
                                  value={agreement[ids[0]].utr_number[id]}
                                />
                              )}
                              {agreement[ids[0]].payment_date != null && (
                                <Tooltip title="Calender" placement="right">
                                  <DataFieldStyle
                                    field={"Deposit Payment Date"}
                                    value={agreement[ids[0]].payment_date[id]}
                                  />
                                </Tooltip>
                              )}
                            </Grid>
                          )}
                        </>
                      )
                    )}
                  </Grid>
                </Grid>

                {/* Bank Details start here */}
                {/* */}

                <Grid item md={10}>
                  <Grid container>
                    {Array.from(
                      { length: agreement[ids[0]].leeseName.length },
                      (row, id) => (
                        <Grid container>
                          <Heading
                            heading={`Landlord ${id + 1} Bank Details`}
                          />
                          <DataFieldStyle
                            field={"bank name"}
                            value={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>{agreement[ids[0]].bankName[id]}</span>
                                <span>{agreement[ids[0]].branchName[id]}</span>
                              </div>
                            }
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              `Old Bank: ${partLabel[oldIds[0]].bankName[id]}
                              Old Branch: ${
                                partLabel[oldIds[0]].branchName[id]
                              }`
                            }
                          />
                          <DataFieldStyle
                            field={"beneficiary name"}
                            value={agreement[ids[0]].benificiaryName[id]}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              "Old Value: " +
                                partLabel[oldIds[0]].benificiaryName[id]
                            }
                          />
                          <DataFieldStyle
                            field={"bank A/c number"}
                            value={agreement[ids[0]].accountNo[id]}
                            href={agreement[ids[0]].cheque[id]}
                            name={"cheque"}
                            cursor={true}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              "Old Value: " + partLabel[oldIds[0]].accountNo[id]
                            }
                          />
                          <DataFieldStyle
                            field={"Bank IFSC"}
                            value={agreement[ids[0]].ifscCode[id]}
                            partLabel={
                              agreement[ids[0]].type === "Renewed" &&
                              "Old Value: " + partLabel[oldIds[0]].ifscCode[id]
                            }
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>

                {/* Bank Details Ends here */}

                <Grid item md={10}>
                  <Grid container spacing={4} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                      <Heading heading={"Document View/Download"} />
                    </Grid>
                    <DocumentView
                      title={
                        agreement[ids[0]].type === "Migrated"
                          ? "Final Agreement"
                          : "draft agreement"
                      }
                      img={
                        agreement[ids[0]].type === "Migrated"
                          ? agreement[ids[0]].final_agreement
                          : agreement[ids[0]].draft_agreement
                      }
                      disabled={
                        agreement[ids[0]].type === "Migrated"
                          ? agreement[ids[0]].final_agreement == null
                          : agreement[ids[0]].draft_agreement == null
                      }
                      partLabel={
                        agreement[ids[0]].type === "Migrated"
                          ? agreement[ids[0]].final_agreement
                          : agreement[ids[0]].type === "Renewed" &&
                            partLabel[oldIds[0]].draft_agreement
                      }
                    />
                    <DocumentView
                      title={"electricity bill"}
                      img={agreement[ids[0]].electricity_bill}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].electricity_bill
                      }
                    />
                    <DocumentView
                      title={"maintenance bill"}
                      img={agreement[ids[0]].maintaince_bill}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].maintaince_bill
                      }
                    />
                    <DocumentView
                      title={"POA"}
                      img={agreement[ids[0]].poa}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].poa
                      }
                    />
                    <DocumentView
                      title={"Property tax receipt"}
                      img={agreement[ids[0]].tax_receipt}
                      partLabel={
                        agreement[ids[0]].type === "Renewed" &&
                        partLabel[oldIds[0]].tax_receipt
                      }
                    />
                    {agreement[ids[0]].leeseName.length > 1 && (
                      <DocumentView
                        title={"NOC (if multiple owner)"}
                        img={agreement[ids[0]].noc}
                        partLabel={
                          agreement[ids[0]].type === "Renewed" &&
                          partLabel[oldIds[0]].noc
                        }
                      />
                    )}
                    <DocumentView
                      title={"Property Picture"}
                      img={agreement[ids[0]].property_pic}
                    />
                  </Grid>
                </Grid>
                {agreement[ids[0]].status !== "Deposited" &&
                  agreement[ids[0]].type === "Migrated" && (
                    <Grid item md={10}>
                      <Grid container sx={{ alignItems: "baseline", mt: 2 }}>
                        <DataFieldStyle
                          field={"Final Agreement Date"}
                          value={agreement[ids[0]].final_agreement_date}
                        />
                        <DataFieldStyle
                          field={"Monthly Rent Start Date"}
                          value={agreement[ids[0]].rent_start_date}
                        />
                      </Grid>
                    </Grid>
                  )}
                {Object.keys(renewalAdjustment).length > 0 && (
                  <>
                    <Grid item xs={10} mt={3}>
                      <Typography
                        variant="body1"
                        color="var(--main-color)"
                        fontSize="25px"
                        lineHeight="28px"
                        fontWeight="600"
                        // my="20px"
                      >
                        Renewal Adjustments Form
                      </Typography>
                    </Grid>
                    <Grid item xs={10} mt={3}>
                      <Grid
                        coantiner
                        sx={{
                          display: "flex",
                          gap: "2rem",
                          flexDirection: "column",
                        }}
                      >
                        <Grid item xs={10}>
                          <Typography
                            variant="h6"
                            color={"primary"}
                            sx={{ fontWeight: 700 }}
                          >
                            Unpaid Months
                          </Typography>
                        </Grid>

                        {upaid.map((row) => (
                          <Grid item sx={{ display: "flex" }}>
                            <TextFieldWrapper
                              marginRight={true}
                              label={"Name"}
                              disabled={true}
                              value={row.name}
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label={"Rent Month (Unpaid)"}
                              placeHolder="Deposit Amount"
                              disabled={true}
                              value={
                                month[new Date(row.rent_date).getUTCMonth()] +
                                "-" +
                                new Date(row.rent_date).getFullYear()
                              }
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label="Rent Amount"
                              disabled={true}
                              value={
                                row.gstNo !== null && row.gstNo.length > 0
                                  ? Math.round(
                                      Number(row.rent_amount) +
                                        Number((row.rent_amount / 100) * 18)
                                    )
                                  : row.rent_amount
                              }
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label="Status"
                              disabled={true}
                              value={row.status}
                            />
                            {row.gstNo ? (
                              <>
                                {row.invoice_number != null && (
                                  <Grid item sx={{ textAlign: "center" }}>
                                    {
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        style={{
                                          backgroundColor:
                                            "rgb(103 185 68 / 89%)",
                                          color: "white",
                                          fontSize: "12px",
                                          textTransform: "capitalize",
                                          minHeight: "37px",
                                          margin: "10px",
                                          minWidth: "100px",
                                        }}
                                        // disabled={row.invoice_number != (null)}
                                        onClick={(event) => {
                                          event.stopPropagation(); // don't select this row after clicking
                                          setSelectID(row.id);
                                          setOpen(true);
                                          setInvoiceDetails({
                                            ...invoiceDetails,
                                            rentAmount: row.rent_amount,
                                            gstAmount:
                                              row?.gstNo != null &&
                                              row?.gstNo.length > 0
                                                ? Number(
                                                    parseInt(
                                                      row?.rent_amount * 0.18
                                                    ).toFixed(2)
                                                  )
                                                : 0,
                                            totalAmount:
                                              Number(row?.rent_amount) +
                                              Number(
                                                parseInt(
                                                  row?.rent_amount * 0.18
                                                ).toFixed(2)
                                              ),
                                            user_id: auth.id,
                                            agreement_id:
                                              agreement?.[ids[0]]
                                                ?.agreement_id[0],
                                            code: agreement?.[ids[0]]?.code,
                                          });
                                          setMonthlyId(row.id);
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "14px" }}>
                                          {"View"}
                                        </Typography>
                                      </Button>
                                    }
                                  </Grid>
                                )}
                              </>
                            ) : (
                              <Grid item>
                                <Button
                                  style={{
                                    display: "block",
                                    minHeight: "37px",
                                    margin: "10px",
                                    minWidth: "100px",
                                    background: "transparent",
                                  }}
                                ></Button>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        marginTop: "5px",
                        display: "flex",
                        gap: "2rem",
                        flexDirection: "row",
                      }}
                      rowSpacing={5}
                      xs={10}
                    >
                      <TextFieldWrapper
                        label="Deposit Amount (Paid)"
                        placeHolder="Deposit Amount"
                        name="depositedAmount"
                        disabled={true}
                        value={renewalAdjustment.deposited}
                      />
                      <TextFieldWrapper
                        label="New Deposit Amount"
                        placeHolder="New Deposit Amount"
                        name="new_deposit"
                        disabled={true}
                        value={agreement?.[ids[0]]?.deposit}
                      />
                    </Grid>
                    <Grid
                      container
                      sx={{
                        marginTop: "5px",
                        display: "flex",
                        gap: "2rem",
                        flexDirection: "row",
                      }}
                      rowSpacing={5}
                      xs={10}
                    >
                      <TextFieldWrapper
                        label="Selected Un Paid Months Amount"
                        placeHolder="Selected UnPaid Months Amount"
                        // onBlur={(e) => handleOnBlur(e, i)}
                        // error = {errorObj.leeseName}
                        name="unPaidMonthsAmount"
                        disabled={true}
                        value={unPaidMonthsAmount}
                      />
                      <TextFieldWrapper
                        label="Balance Deposit Amount"
                        placeHolder="Balance Deposit Amount"
                        // onBlur={(e) => handleOnBlur(e, i)}
                        // error = {errorObj.leeseName}
                        name="depositedAmount"
                        disabled={true}
                        value={renewalAdjustment.balance_amount}
                      />
                    </Grid>

                    <Grid
                      container
                      sx={{
                        marginTop: "5px",
                        display: "flex",
                        gap: "2rem",
                        flexDirection: "row",
                      }}
                      rowSpacing={5}
                      xs={10}
                    >
                      <TextFieldWrapper
                        label={`Balance Deposit Payable`}
                        placeHolder="Balance Deposit Payable"
                        // onBlur={(e) => handleOnBlur(e, i)}
                        // error = {errorObj.leeseName}
                        name="depositedAmount"
                        disabled={true}
                        value={renewalAdjustment.receivable}
                        // onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                  </>
                )}

                <Grid item container xs={10} sx={{ mt: 5 }}>
                  <DataFieldStyle
                    field={"Landlord Assets"}
                    value={
                      agreement[ids[0]].assets === '""'
                        ? ""
                        : agreement[ids[0]].assets
                    }
                  />
                </Grid>

                {allRemarks.length > 0 && (
                  <>
                    <Grid
                      item
                      container
                      sx={{ alignItems: "baseline", mt: 5 }}
                      xs={10}
                    >
                      <DataFieldStyle field={"Remark"} />
                      {allRemarks.map((remark, index) => (
                        <div
                          style={{
                            width: "100%",
                            padding: "10px 10px 10px 0",
                          }}
                          key={index}
                        >
                          {remark}
                        </div>
                      ))}
                    </Grid>
                  </>
                )}
                {/* Buttons start here*/}
                {agreement[ids[0]].status === "Terminated By Sr Manager" && (
                  <>
                    {allRemarks && allRemarks.length > 0 && (
                      <>
                        <Grid
                          item
                          container
                          sx={{ alignItems: "baseline", mt: 5 }}
                          xs={10}
                        >
                          <DataFieldStyle field={"Termination Remarks"} />
                          {allRemarks.map((remark, index) => (
                            <div
                              style={{
                                width: "100%",
                                padding: "10px 10px 10px 0",
                              }}
                              key={index}
                            >
                              {remark}
                            </div>
                          ))}
                        </Grid>
                      </>
                    )}
                    {/* document section ends here */}

                    {/* Buttons start here*/}
                    <Grid item xs={10} sx={{ mt: 2 }}>
                      <Grid container sx={{ gap: "2rem" }}>
                        <DataFieldStyle
                          field="Deposit Amount (Paid)"
                          value={recovery.depositedAmount}
                        />
                      </Grid>
                      <Grid container sx={{ gap: "2rem", mt: 2 }}>
                        <DataFieldStyle
                          field="Remaining Months"
                          value={recovery.remainingMonth}
                        />
                        <DataFieldStyle
                          field="Adjustment Amount"
                          value={recovery.adjustmentAmount}
                        />
                        <DataFieldStyle
                          field="Remark"
                          value={recovery.adjustmentAmountRemark}
                        />
                      </Grid>

                      <Grid container sx={{ gap: "2rem", mt: 2 }}>
                        <DataFieldStyle
                          field="Expenses Amount"
                          value={recovery.expenses}
                        />
                        <DataFieldStyle
                          field="Remark"
                          value={recovery.expansesRemark}
                        />
                      </Grid>

                      <Grid item xs={12} container sx={{ gap: "2rem", mt: 2 }}>
                        <DataFieldStyle
                          field="Other Adjustments"
                          value={recovery.otherAdjustments}
                        />
                        <DataFieldStyle
                          field="Remark"
                          value={recovery.otherRemark}
                        />
                      </Grid>
                      <Grid item xs={12} container sx={{ gap: "2rem", mt: 2 }}>
                        <DataFieldStyle
                          field="Total Adjustment Amount "
                          value={recovery.totalAdjustmentAmount}
                        />
                        <DataFieldStyle
                          field="Balance Deposit "
                          value={recovery.balanceDeposit}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DocumentView
                          title={"Termination File"}
                          img={agreement[ids[0]].file}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}

                {/* termonation */}
                {agreement[ids[0]].status === "Terminated By Sr Manager" && (
                  <>
                    <Grid
                      item
                      xs={10}
                      sx={{ mt: 5 }}
                      className={"textFieldWrapper"}
                    >
                      <Grid item xs={8}>
                        <TextField
                          type="text"
                          multiline
                          rows={3}
                          fullWidth
                          variant="outlined"
                          label="Remark *"
                          placeholder="Remark *"
                          value={remark}
                          onChange={(e) => setRemark(e.target.value)}
                        />
                      </Grid>
                    </Grid>

                    <Grid item md={8} sx={{ mt: 4, mb: 2 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ justifyContent: "center" }}
                      >
                        <Grid item md={6} xs={11}>
                          <Button
                            variant="contained"
                            sx={{
                              height: "55px",
                              borderRadius: "12px",
                              backgroundColor: "primary",
                              width: "100%",
                              color: "#FFFFFF",
                              textTransform: "capitalize",
                              fontSize: "18px",
                              lineHeight: "20px",
                            }}
                            onClick={handleTerminate}
                          >
                            Send To Finance
                          </Button>
                        </Grid>
                        <Grid item md={6} xs={11}>
                          <Button
                            variant="outlined"
                            sx={{
                              height: "55px",
                              borderRadius: "12px",
                              width: "100%",
                              textTransform: "capitalize",
                              fontSize: "18px",
                            }}
                            onClick={handleSendBack}
                          >
                            Send Back To Manager
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <UploadInvoice
                  open={open}
                  view={true}
                  handleClose={() => setOpen(false)}
                  value={invoiceDetails}
                  setValue={setInvoiceDetails}
                  monthlyId={monthlyId}
                />

                {/* Buttons start here*/}

                {agreement[ids[0]].status === "Sent To Operations" &&
                  !auth.role?.includes("Super Admin") && (
                    <>
                      <Grid
                        item
                        xs={10}
                        sx={{ mt: 5 }}
                        className={"textFieldWrapper"}
                      >
                        <Grid item xs={8}>
                          <TextField
                            type="text"
                            multiline
                            rows={3}
                            fullWidth
                            variant="outlined"
                            label="Remark *"
                            placeholder="Remark *"
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      {!auth.role?.includes("Super Admin") && (
                        <Grid item md={8} sx={{ mt: 4, mb: 2 }}>
                          <Grid
                            container
                            spacing={1}
                            sx={{ justifyContent: "center" }}
                          >
                            <Grid item md={6} xs={11}>
                              <Button
                                variant="contained"
                                sx={{
                                  height: "55px",
                                  borderRadius: "12px",
                                  backgroundColor: "primary",
                                  width: "100%",
                                  color: "#FFFFFF",
                                  textTransform: "capitalize",
                                  fontSize: "18px",
                                  lineHeight: "20px",
                                }}
                                onClick={handleSubmit}
                              >
                                Send To Finance
                              </Button>
                            </Grid>
                            <Grid item md={6} xs={11}>
                              <Button
                                variant="outlined"
                                sx={{
                                  height: "55px",
                                  borderRadius: "12px",
                                  width: "100%",
                                  textTransform: "capitalize",
                                  fontSize: "18px",
                                }}
                                onClick={handleSendBack}
                                disabled={agreement[ids[0]].type === "Migrated"}
                              >
                                Send Back To Manager
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                {/* buttons end here */}
              </Grid>
            </Box>
          </Stack>
        )}
    </>
  );
}

export default ApprovalRequest;
