/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  Box,
  Button,
  Grid,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  TextField,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  Divider,
} from "@mui/material";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  DocumentUpload,
  MyHeader,
  SelectComponent,
  TextFieldWrapper,
} from "../StyledComponent";
import { useNavigate } from "react-router-dom";
import YearlyIncrement from "./IncrementType";
import DialogBox from "./DialogBox";
import {
  add_agreement,
  add_landlord,
  uploadDoc,
  getBankName,
  getLocation,
  getState,
  create_landlord_id,
  notification_handler,
} from "../../Services/Services";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../store/action/action";
import PermissionAlert from "./Alert";
import HamburgerManager from "./HamburgerManager";

function Agreement({ history }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { landloard, auth } = useSelector((state) => state);
  const [dupliacateStatus, setdupliacateStatus] = useState({
    aadharNo: "",
    gstNo: "",
    mobileNo: "",
    panNo: "",
  });

  const manager_id = auth.id;
  const codeGenerater = () => {
    var length = 6,
      charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      random = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      random += charset.charAt(Math.floor(Math.random() * n));
      setData({ ...data, code: random });
    }
  };
  const [openBack, setOpenBack] = useState(false);
  const [duplicateAadhaarNoss, setDuplicateAdharNos] = useState([]);
  const [duplicatePanrNoss, setDuplicatePanNos] = useState([]);
  const [duplicateMobileNoss, setDuplicateMobileNos] = useState([]);
  const [landLordDuplicateError, setLandLordDuplicateError] = useState(false);
  const [apiErrorStatus, setApiErrorStatus] = useState(false);

  const handleBack = () => {
    setOpenBack(true);
  };

  const handleCloseBack = () => {
    setOpenBack(false);
  };

  useEffect(() => {
    codeGenerater();
  }, []);

  useEffect(() => {}, []);

  const [i, setIndex] = useState(0);
  const [data, setData] = useState({
    landlord: [...landloard],
    code: "",
    lockInYear: "",
    noticePeriod: "",
    monthlyRent: "",
    yearlyIncrement: "",
    tenure: "",
    state: "",
    deposit: "",
    pincode: "",
    location: "",
    city: "",
    area: "",
  });

  data.landlord.map((data) => {});

  let disableCheck =
    dupliacateStatus.aadharNo ||
    dupliacateStatus.panNo ||
    dupliacateStatus.mobileNo
      ? true
      : data.lockInYear &&
        data.noticePeriod &&
        data.monthlyRent &&
        data.yearlyIncrement &&
        data.tenure &&
        data.state &&
        data.deposit &&
        data.pincode &&
        data.location &&
        data.city &&
        data.area &&
        false;

  useEffect(() => {
    setData((old) => ({ ...old, landlord: [...landloard] }));
    setFormError((old) => ({ ...old, landlord: [...landloard] }));
  }, [landloard]);

  useEffect(() => {
    const duplicateAadhaarNos = [];
    const duplicatePanNo = [];
    const duplicateMobileNo = [];
    const duplicateGstNo = [];
    const aadhaarNoSet = new Set();
    const panNoSet = new Set();
    const mobileNoSet = new Set();
    const gstNoSet = new Set();

    for (const item of data.landlord) {
      if (item.aadharNo !== undefined && aadhaarNoSet.has(item.aadharNo)) {
        duplicateAadhaarNos.push(item.aadharNo);
      } else {
        aadhaarNoSet.add(item.aadharNo);
      }

      if (item.panNo !== undefined && panNoSet.has(item.panNo)) {
        duplicatePanNo.push(item.panNo);
      } else {
        panNoSet.add(item.panNo);
      }

      if (item.mobileNo !== undefined && mobileNoSet.has(item.mobileNo)) {
        duplicateMobileNo.push(item.mobileNo);
      } else {
        mobileNoSet.add(item.mobileNo);
      }

      if (gstNoSet.has(item.gstNo)) {
        duplicateGstNo.push(item.gstNo);
      } else {
        gstNoSet.add(item.gstNo);
      }
    }
    setDuplicateAdharNos(duplicateAadhaarNos);
    setDuplicateMobileNos(duplicateMobileNo);
    setDuplicatePanNos(duplicatePanNo);

    if (
      duplicateAadhaarNos.length > 0 ||
      duplicateMobileNo.length > 0 ||
      duplicatePanNo.length > 0
    ) {
      setLandLordDuplicateError(true);
    } else {
      setLandLordDuplicateError(false);
    }
    duplicateAadhaarNos.length > 0
      ? setdupliacateStatus((prev) => {
          return { ...prev, aadharNo: true };
        })
      : setdupliacateStatus((prev) => {
          return { ...prev, aadharNo: false };
        });

    duplicateMobileNo.length > 0
      ? setdupliacateStatus((prev) => {
          return { ...prev, mobileNo: true };
        })
      : setdupliacateStatus((prev) => {
          return { ...prev, mobileNo: false };
        });

    duplicatePanNo.length > 0
      ? setdupliacateStatus((prev) => {
          return { ...prev, panNo: true };
        })
      : setdupliacateStatus((prev) => {
          return { ...prev, panNo: false };
        });
  }, [data.landlord]);

  const [landblord, setLandblord] = useState([1]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [formError, setFormError] = useState({
    landlord: [...landloard],
    code: "",
    lockInYear: "",
    noticePeriod: "",
    deposit: "",
    monthlyRent: "",
    yearlyIncrement: "",
    tenure: "",
    state: "",
    pincode: "",
    location: "",
    city: "",
    area: "",
  });

  const [isSubmit, setIsSubmit] = useState(false);
  const [expand, setExpand] = useState(0);
  const [docExpand, setDocExpand] = useState(0);
  const [yearlyIncreentChanged, setYearlyIncreentChanged] = useState(false);

  const [increment, setIncrement] = useState({
    year1: "",
    year2: "",
    year3: "",
    year4: "",
    year5: "",
    year6: "",
    year7: "",
    year8: "",
    year9: "",
    year10: "",
  });

  const [yearValue, setYearValue] = useState({
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  });

  async function handleChangeFile(e) {
    const FD = new FormData();
    FD.append("folderName", data.code);
    FD.append("photo", e.target.files[0]);
    let response = await uploadDoc(FD);

    if (response.status === 200) {
      setFormError((old) => ({
        ...old,
        [e.target.name + "_name"]: "",
        [e.target.name]: "",
      }));

      setData((old) => ({
        ...old,
        [e.target.name]: response.data.link,
        [e.target.name + "_name"]: e.target.files[0].name,
      }));
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: response.data.message,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message || "Something went wrong !!!",
        })
      );
    }
  }

  function checksum(g) {
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
      g
    );
    if (regTest) {
    }
    return regTest;
  }

  function handleOnBlur(e, i) {
    let error = { state: false, message: null };
    const capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = capitalizedValue;
    switch (e.target.name) {
      case "leeseName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "aadharNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 12 && e.target.value.length > 0)
          error = { ...error, message: "Aadhaar number must be of 12 digit." };
        else error = { ...error, message: null };
        break;
      case "mobileNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0) {
          error = {
            ...error,
            message: "Phone number must be of 10 digits.",
          };
        } else if (e.target.value.match(/^[0-5]/)) {
          error = {
            ...error,
            message: "Incorrect Phone Number",
          };
        } else {
          error = {
            ...error,
            message: null,
          };
        }
        break;

      case "alternateMobile":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "bankName":
        if (!e.target.value === "Not Found")
          error = { ...error, state: true, message: "Value must be Correct" };
        else error = { ...error, message: null };
        break;
      case "benificiaryName":
        if (!capitalizedValue.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "accountNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length > 17 && e.target.value.length > 0)
          error = { ...error, message: "Account can be of 17 digit only." };
        else error = { ...error, message: null };
        break;

      case "email":
        if (
          !e.target.value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "Email should be like example@gmail.com.",
          };
        else error = { ...error, message: null };
        break;
      case "panNo":
        e.target.value = e.target.value.toUpperCase();
        if (
          !e.target.value.match(/^.{0,10}$/) &&
          !e.target.value.match(/^[^@#$%^&*<>'\"/;`%]*$/)
        )
          error = { state: true, message: null };
        if (
          !e.target.value.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "PAN number should be like NOOPS8327K",
          };
        else error = { ...error, message: null };
        break;
      case "gstNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^.{0,15}$/))
          error = { state: true, message: null };
        if (!checksum(e.target.value) && e.target.value.length > 0)
          error = { ...error, message: "GST should be like 18AABCU9603R1ZM." };
        else error = { ...error, message: null };
        break;
      case "ifscCode":
        if (
          !e.target.value.match(/^[A-Z]{4}0[A-Z0-9]{6}$/) &&
          e.target.value !== ""
        )
          error = { ...error, message: "Incorrect IFSC" };
        else error = { ...error, message: null };

        break;
      default:
        break;
    }

    if (error.message) {
      if (formError.landlord[i]) {
        setFormError((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: error.message,
              };
            }
            return row;
          }),
        }));
      } else {
        setFormError((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: error.message,
            },
          ],
        }));
      }
    } else {
      if (formError.landlord[i]) {
        setFormError((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: "",
              };
            }
            return row;
          }),
        }));
      } else {
        setFormError((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: "",
            },
          ],
        }));
      }
    }
  }

  function handleChange(e, i) {
    let error = { state: false, message: null };
    const capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = capitalizedValue;
    switch (e.target.name) {
      case "leeseName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "pincode":
        if (!e.target.value.match(/^.{0,6}$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "aadharNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        else if (e.target.value.length < 12 && e.target.value.length > 0)
          error = { ...error, message: "Aadhaar number must be of 12 digit." };
        else error = { ...error, message: null };
        break;
      case "mobileNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0) {
          error = {
            ...error,
            message: "Phone number must be of 10 digits.",
          };
        } else if (e.target.value.match(/^[0-5]/)) {
          error = {
            ...error,
            message: "Incorrect Phone Number",
          };
        } else {
          error = {
            ...error,
            message: null,
          };
        }
        break;

      case "alternateMobile":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "bankName":
        if (e.target.value === "Not Found")
          error = { ...error, state: true, message: "Value must be Correct" };
        else error = { ...error, message: null };
        break;
      case "benificiaryName":
        if (!capitalizedValue.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "accountNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length > 17 && e.target.value.length > 0)
          error = { ...error, message: "Account can be of 17 digit only." };
        else error = { ...error, message: null };
        break;

      case "email":
        e.target.value = e.target.value.toLowerCase();
        if (!capitalizedValue.match(/^[a-zA-Z0-9@._]*$/))
          error = { state: true, message: null };
        else if (
          !e.target.value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "Email address should be like example@gmail.com.",
          };
        else error = { ...error, message: null };
        break;

      case "panNo":
        e.target.value = e.target.value.toUpperCase();
        if (!capitalizedValue.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };
        else if (
          !e.target.value.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "PAN number should be like NOOPS8327K",
          };
        else error = { ...error, message: null };
        break;
      case "gstNo":
        e.target.value = e.target.value.toUpperCase();
        if (!capitalizedValue.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };
        else if (!checksum(e.target.value) && e.target.value.length > 0)
          error = {
            ...error,
            message: "GST number should be like 27AAPFU0939F1ZV.",
          };
        else error = { ...error, message: null };
        break;
      case "ifscCode":
        e.target.value = e.target.value.toUpperCase();
        if (!capitalizedValue.match(/^[a-zA-Z0-9]*$/))
          error = { state: true, message: null };
        break;
      default:
        break;
    }
    if (e.target.name === "ifscCode") {
      getBankDetails(e.target.value, i);
    }
    if (!error.state) {
      if (data.landlord[i]) {
        setData((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: e.target.value,
              };
            }
            return row;
          }),
        }));
      } else {
        setData((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: e.target.value,
            },
          ],
        }));
      }
    }
  }

  function handleCommonChange(e, i) {
    var error = { state: false };
    const capitalizedValue =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    switch (e.target.name) {
      case "assets":
        if (!e.target.value.match(/^[^@#$%^&*<>'}{)(!?~:_+=";`%]*$/))
          error = { state: true, message: "Value must be correct" };
        break;
      case "location":
        if (!capitalizedValue.match(/^[^@#$%^&*~<>'!?)(}{:_+=\";`%]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "address":
        if (!e.target.value.match(/^[^@#$%^&*<>'!?~)(}{:_+=\";`%]*$/))
          error = { state: true, message: "Value must be correct" };
        break;
      case "pincode":
        if (e.target.value !== 0 && e.target.value.length <= 6) {
          if (!capitalizedValue.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      case "lockInYear":
        if (e.target.value !== 0 && e.target.value.length <= 2) {
          if (!capitalizedValue.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      case "noticePeriod":
        if (e.target.value !== 0 && e.target.value.length <= 2) {
          if (!capitalizedValue.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      case "area":
        if (e.target.value !== 0 && e.target.value.length <= 5) {
          if (!capitalizedValue.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      case "rental_amount":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "monthlyRent":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;

      case "deposit":
        if (!e.target.value.match(/^[0-9]*$/)) error = { state: true };
        break;
      case "tenure":
        if (e.target.value !== 0 && e.target.value.length <= 3) {
          if (!capitalizedValue.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      default:
        break;
    }
    if (!error.state) {
      setData((old) => ({ ...old, [e.target.name]: e.target.value }));
      setFormError((old) => ({ ...old, [e.target.name]: "" }));
    }
  }

  const handleConfirm = (id) => {
    setOpen(false);
    const {
      code,
      lockInYear,
      monthlyRent,
      noticePeriod,
      yearlyIncrement,
      deposit,
      gst_certificate,
      draft_agreement,
      electricity_bill,
      poa,
      maintaince_bill,
      cheque,
      tax_receipt,
      noc,
      tenure,
      year1,
      year2,
      year3,
      year4,
      year5,
      year6,
      year7,
      year8,
      year9,
      year10,
      pincode,
      state,
      address,
      location,
      city,
      area,
      assets,
      property_pic,
    } = data;

    const { landlord } = data;

    APICall(
      {
        modify_date: new Date(),
        property_pic,
        assets,
        area,
        code,
        lockInYear,
        monthlyRent,
        noticePeriod,
        yearlyIncrement: tenure <= 12 ? "" : yearlyIncrement,
        deposit,
        gst_certificate,
        draft_agreement,
        electricity_bill,
        poa,
        maintaince_bill,
        cheque,
        tax_receipt,
        noc,
        tenure,
        year1,
        year2,
        year3,
        year4,
        year5,
        year6,
        year7,
        year8,
        year9,
        year10,
        percentage1: tenure <= 12 ? "" : yearValue.year1,
        percentage2: tenure <= 12 ? "" : yearValue.year2,
        percentage3: tenure <= 12 ? "" : yearValue.year3,
        percentage4: tenure <= 12 ? "" : yearValue.year4,
        percentage5: tenure <= 12 ? "" : yearValue.year5,
        percentage6: tenure <= 12 ? "" : yearValue.year6,
        percentage7: tenure <= 12 ? "" : yearValue.year7,
        percentage8: tenure <= 12 ? "" : yearValue.year8,
        percentage9: tenure <= 12 ? "" : yearValue.year9,
        percentage10: tenure <= 12 ? "" : yearValue.year10,
        pincode,
        state,
        address,
        location,
        city,
        manager_id: id,
        status: "Sent To Sr Manager",
        type: "New",
      },
      landlord
    );
  };

  function handleHold(id) {
    handleHoldApiCall(id, { ...data, ...increment });
  }
  function handleHold1(id) {
    setData({ ...data, ...increment });
    handleHoldApiCall(id, { ...data, ...increment });
  }

  async function handleHoldApiCall(id, data) {
    const {
      area,
      pincode,
      state,
      address,
      location,
      city,
      code,
      lockInYear,
      monthlyRent,
      noticePeriod,
      yearlyIncrement,
      deposit,
      gst_certificate,
      draft_agreement,
      electricity_bill,
      poa,
      maintaince_bill,
      cheque,
      tax_receipt,
      noc,
      tenure,
      year1,
      year2,
      year3,
      year4,
      year5,
      year6,
      year7,
      year8,
      year9,
      year10,
      assets,
      property_pic,
    } = data;

    const { landlord } = data;

    APICall(
      {
        modify_date: new Date(),
        area,
        code,
        lockInYear,
        monthlyRent,
        noticePeriod,
        yearlyIncrement: tenure <= 12 ? "" : yearlyIncrement,
        deposit,
        gst_certificate,
        draft_agreement,
        electricity_bill,
        poa,
        maintaince_bill,
        cheque,
        tax_receipt,
        noc,
        tenure,
        year1,
        year2,
        year3,
        year4,
        year5,
        year6,
        year7,
        year8,
        year9,
        year10,
        percentage1: tenure <= 12 ? "" : yearValue.year1,
        percentage2: tenure <= 12 ? "" : yearValue.year2,
        percentage3: tenure <= 12 ? "" : yearValue.year3,
        percentage4: tenure <= 12 ? "" : yearValue.year4,
        percentage5: tenure <= 12 ? "" : yearValue.year5,
        percentage6: tenure <= 12 ? "" : yearValue.year6,
        percentage7: tenure <= 12 ? "" : yearValue.year7,
        percentage8: tenure <= 12 ? "" : yearValue.year8,
        percentage9: tenure <= 12 ? "" : yearValue.year9,
        percentage10: tenure <= 12 ? "" : yearValue.year10,
        pincode,
        state,
        address,
        location,
        city,
        manager_id: id,
        status: "Draft",
        remark: "",
        assets,
        property_pic,
        type: "New",
      },
      landlord
    );
  }
  async function notificationCall(event_id, id, code) {
    const notification = await notification_handler(
      event_id,
      id,
      code,
      auth.id
    );
  }

  async function APICall(values, landlordData) {
    const agreement = await add_agreement(values);
    if (agreement.data.success) {
      const agreement_id = agreement.data.agreement[0];

      landlordData = landlordData.map((row, index) => {
        let aadhar_card = `${(row.leeseName + "@aadhar_card").replace(
          " ",
          ""
        )}`;
        let pan_card = `${(row.leeseName + "@pan_card").replace(" ", "")}`;
        let gst = `${(row.leeseName + "@gst").replace(" ", "")}`;
        let cheque = `${(row.leeseName + "@cheque").replace(" ", "")}`;

        return {
          ...row,
          name: row.leeseName,
          agreement_id,
          aadhar_card: data[aadhar_card],
          pan_card: data[pan_card],
          gst: data[gst],
          cheque: data[cheque],
        };
      });

      const result = await add_landlord(landlordData);
      const result1 = await create_landlord_id(agreement_id, auth.id);

      if (result) {
        values.status !== "Draft" &&
          notificationCall("AG1", agreement_id, values.code);
        navigate(-1);
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message:
              values.status === "Draft"
                ? "Draft saved successfully"
                : "Agreement Submitted.",
          })
        );
      }
    }
  }

  async function getBankDetails(data, i) {
    try {
      let res = await getBankName(data);
      if (res.status === 200) {
        setData((old) => ({
          ...old,
          landlord: old.landlord.map((row, index) => {
            if (index === i) {
              return {
                ...row,
                bankName: res.data.BANK,
                branchName: res.data.BRANCH,
              };
            } else return row;
          }),
        }));
      }
    } catch (err) {
      setData((old) => ({
        ...old,
        landlord: old.landlord.map((row, index) => {
          if (index === i) {
            return { ...row, bankName: "Not Found", branchName: "" };
          } else return row;
        }),
      }));
    }
  }

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      setOpen(true);
    }
  }, [formError]);

  function validate(data) {
    let field = [
      "draft_agreement",
      "electricity_bill",
      "tax_receipt",
      "property_pic",
    ];

    data.landlord.length > 1 && field.push("noc");

    if (landloard.length > 0) {
      data.landlord.map((row, i) => {
        if (row.gstNo) {
          field.push(
            `${landloard?.[i]?.leeseName + "@gst_name"}`.replace(" ", "")
          );
        }
        field.push(
          `${landloard?.[i]?.leeseName + "@aadhar_card_name"}`.replace(" ", "")
        );
        field.push(
          `${landloard?.[i]?.leeseName + "@cheque_name"}`.replace(" ", "")
        );
        field.push(
          `${landloard?.[i]?.leeseName + "@pan_card_name"}`.replace(" ", "")
        );
      });
    }

    let finalCheck = field.map((row) => {
      if (!data[row]) {
        setFormError((old) => ({ ...old, [row]: "Document required." }));
        return true;
      } else {
        setFormError((old) => ({ ...old, [row]: "" }));

        return false;
      }
    });
    if (!finalCheck.includes(true)) {
      return true;
    } else return false;
  }

  function validateFields(data) {
    let field =
      data.tenure > 12
        ? [
            "lockInYear",
            "noticePeriod",
            "deposit",
            "monthlyRent",
            "tenure",
            "state",
            "city",
            "address",
            "pincode",
            "location",
            "area",
            "assets",
            "yearlyIncrement",
          ]
        : [
            "lockInYear",
            "noticePeriod",
            "deposit",
            "monthlyRent",
            "tenure",
            "state",
            "city",
            "address",
            "pincode",
            "location",
            "area",
            "assets",
          ];

    let dataError = [];
    if (data.landlord && data.landlord.length > 0) {
      dataError = data.landlord.map((row, i) => ({
        aadharNo: data.landlord[i].aadharNo ? false : "Field is required.",
        panNo: data.landlord[i].panNo ? false : "Field is required.",
        mobileNo: data.landlord[i].mobileNo ? false : "Field is required.",
        email: data.landlord[i].email ? false : "Field is required.",
        ifscCode: data.landlord[i].ifscCode
          ? data.landlord[i].ifscCode.length === 11
            ? false
            : "Incorrect IFSC"
          : "Field is required.",
        bankName: data.landlord[i].bankName ? false : "Field is required.",
        accountNo: data.landlord[i].accountNo ? false : "Field is required.",
        benificiaryName: data.landlord[i].benificiaryName
          ? false
          : "Field is required.",
      }));
    }

    let finalCheck = field.map((row) => {
      if (!data[row] || data[row] === "") {
        setFormError((old) => ({
          ...old,
          [row]: "Field required.",
          landlord: dataError,
        }));
        return true;
      } else {
        setFormError((old) => ({ ...old, [row]: "", landlord: dataError }));
        return false;
      }
    });

    dataError.map((row, i) => {
      finalCheck.push(Object.values(row).includes("Incorrect IFSC"));
      finalCheck.push(Object.values(row).includes("Field is required."));
    });

    if (!finalCheck.includes(true)) {
      return true;
    } else return false;
  }

  const [open, setOpen] = useState(false);
  const [remainingFields, setRemainingFields] = useState([]);
  const handleSubmit = (e) => {
    setData((old) => ({ ...old, ...increment }));
    const validData = validate(data);
    const validFields = validateFields(data);
    if (landLordDuplicateError) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Notice Period should be less than tenure",
        })
      );
    } else if (Number(data.lockInYear) > Number(data.tenure)) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Lock in month should be less than tenure",
        })
      );
    } else if (
      validData &&
      validFields &&
      !data?.areaInvalid &&
      !data.monthlyRentalInvalid &&
      !data.depositInvalid &&
      !data.tenureInvalid
    ) {
      setIsSubmit(true);
      setOpen(true);
    } else {
      const remaining = [];

      if (data.pincode === "") {
        remaining.push("pincode");
      }

      if (data.location === "") {
        remaining.push("location");
      }

      if (data.area === "") {
        remaining.push("area");
      }

      if (data.address === "") {
        remaining.push("address");
      }

      if (data.lockInYear === "") {
        remaining.push("lockInYear");
      }

      if (data.noticePeriod === "") {
        remaining.push("noticePeriod");
      }

      if (data.deposit === "") {
        remaining.push("deposit");
      }

      if (data.monthlyRent === "") {
        remaining.push("monthlyRent");
      }

      if (data.tenure === "") {
        remaining.push("tenure");
      }

      if (data.state === "") {
        remaining.push("state");
      }

      if (data.city === "") {
        remaining.push("city");
      }

      if (data.assets === "") {
        remaining.push("data.assets");
      }
      if (
        (data.landlord[i] && !data.landlord[i].mobileNo) ||
        data.landlord[i]?.mobileNo === ""
      ) {
        remaining.push("mobileNo");
      }

      if (
        (data.landlord[i] && !data.landlord[i].benificiaryName) ||
        data.landlord[i]?.benificiaryName === ""
      ) {
        remaining.push("benificiaryName");
      }

      if (
        (data.landlord[i] && !data.landlord[i].accountNo) ||
        data.landlord[i]?.accountNo === ""
      ) {
        remaining.push("accountNo");
      }

      if (
        (data.landlord[i] && !data.landlord[i].email) ||
        data.landlord[i]?.email === ""
      ) {
        remaining.push("email");
      }

      if (
        (data.landlord[i] && !data.landlord[i].panNo) ||
        data.landlord[i]?.panNo === ""
      ) {
        remaining.push("panNo");
      }

      if (
        (data.landlord[i] && !data.landlord[i].ifscCode) ||
        data.landlord[i]?.ifscCode === ""
      ) {
        remaining.push("ifscCode");
      }

      if (data.draft_agreement === "") {
        remaining.push("draft_agreement");
      }

      setRemainingFields(remaining);
      if (remaining.length > 0) {
        const firstRemainingField = remaining[0];
        const fieldElement = document.getElementsByName(firstRemainingField)[0];
        if (fieldElement) {
          fieldElement.focus();
        }
        return;
      }
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Please Fill/Upload Mandatory Fields",
        })
      );
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };

  // async function handleStateSearch(e, i) {
  //   if (e.target.value.length === 6) {
  //     let response = await getLocation(e.target.value);
  //     if (response.data[0].PostOffice) {
  //       let address = response.data[0].PostOffice[0];
  //       setFormError((old) => ({
  //         ...old,
  //         pincode: "",
  //       }));
  //       return setData((old) => ({
  //         ...old,
  //         state: address.State,
  //         city: address.District,
  //         wrongPin: false,
  //       }));
  //     } else {
  //       setFormError((old) => ({
  //         ...old,
  //         city: "",
  //         state: "",
  //         pincode: "Invalid pin code.",
  //       }));
  //       return setData((old) => ({
  //         ...old,
  //         state: "",
  //         city: "",
  //         wrongPin: true,
  //       }));
  //     }
  //   } else {
  //     setFormError((old) => ({
  //       ...old,
  //       city: "",
  //       state: "",
  //       pincode: "",
  //     }));
  //     return setData((old) => ({
  //       ...old,
  //       state: "",
  //       city: "",
  //       wrongPin: false,
  //     }));
  //   }
  // }
  const getStateData = async (e) => {
    try {
      const stateResponse = await getState(e.target.value);
      if (stateResponse?.data?.success) {
        const stateData = stateResponse.data;
        setApiErrorStatus(false);
        setData((old) => ({
          ...old,
          state: stateData.state,
          city: stateData.city,
          wrongPin: false,
        }));
      } else {
        console.error("2 Error in second API call");
        setFormError((old) => ({
          ...old,
          pincode: "Invalid Pincode",
        }));
        setApiErrorStatus(true);
      }
    } catch (error) {
      console.error("3 Error in second API call", error);
      setApiErrorStatus(true);
    }
  };

  async function handleStateSearch(e, i) {
    console.log("handleSearch");
    try {
      if (e.target.value.length === 6) {
        let response = await getLocation(e.target.value);
        console.log("if response", response);
        if (response.status === 200 && response.data[0].PostOffice) {
          let address = response.data[0].PostOffice[0];
          setFormError((old) => ({
            ...old,
            pincode: "",
          }));
          setData((old) => ({
            ...old,
            state: address.State,
            city: address.District,
            wrongPin: false,
          }));
        } else {
          console.log("else response");
          setFormError((old) => ({
            ...old,
            city: "",
            state: "",
            pincode: "Invalid pin code.",
          }));
          setData((old) => ({
            ...old,
            state: "",
            city: "",
            wrongPin: true,
          }));
          return;
        }
      } else {
        setFormError((old) => ({
          ...old,
          city: "",
          state: "",
          pincode: "",
        }));
        setData((old) => ({
          ...old,
          state: "",
          city: "",
          wrongPin: false,
        }));
      }
    } catch (error) {
      getStateData(e);
      console.error("Error in first API call", error);
    }
  }

  return (
    <>
      {/* alert for submit form */}
      <PermissionAlert
        handleClose={handleCancel}
        handleConfirm={() => handleConfirm(manager_id)}
        open={open}
        message={"Please check agreement carefully before submission."}
      />

      {/* dialog box ( popup box ) */}
      <DialogBox value={landblord} setValue={setLandblord} />
      <Stack sx={{ flexWrap: "nowrap", flexDirection: "row" }}>
        <HamburgerManager />
        <Box className="backButton">
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleBack}
            size={"large"}
          >
            <ArrowCircleLeftIcon
              sx={{ fontSize: "3rem" }}
              color="#FFFFF !important"
            />
          </IconButton>
          <Dialog open={openBack} onClose={handleCloseBack}>
            <DialogTitle>Confirmation</DialogTitle>
            <Box p={1}>
              <p>Are you sure you want to go back?</p>
              <Box display="flex" justifyContent="space-between" p={1}>
                <IconButton
                  style={{ fontSize: "16px" }}
                  onClick={() => navigate(-1)}
                  color="secondary"
                >
                  Cancel
                </IconButton>
                <IconButton
                  style={{ fontSize: "16px" }}
                  onClick={() => handleHold(manager_id)}
                  color="primary"
                >
                  Save As Draft
                </IconButton>
              </Box>
            </Box>
          </Dialog>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "space-between", display: "flex" }}
          >
            <MyHeader>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </MyHeader>
            <Typography mt="15px" mr="15px" fontWeight="600">
              Welcome {auth.name}
            </Typography>
          </Grid>
          <Divider />
          <Grid
            container
            sx={{
              px: 1,
              justifyContent: "space-between",
              my: 1,
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <Typography
                variant="body1"
                color="black"
                fontSize="20px"
                fontWeight="600"
                alignSelf="center"
                lineHeight="30px"
                sx={{
                  "@media(max-width:600px)": {
                    fontSize: "17px",
                    lineHeight: "25px",
                  },
                }}
              >
                {"New Agreement"}
              </Typography>
            </Grid>
          </Grid>
          <Divider />

          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={10}>
              {/* agreement form start here */}
              <Box
                component="form"
                sx={{
                  py: 5,
                  backgroundColor: "white",
                  mx: 3,
                  borderRadius: "15px",
                  maxWidth: "1050px",
                  "@media(max-width:900px)": { mx: 0 },
                }}
              >
                {/* Basic details start here */}
                <Typography
                  variant="body1"
                  color="var(--main-color)"
                  fontSize="25px"
                  lineHeight="28px"
                  fontWeight="600"
                  my="20px"
                >
                  Property Details
                </Typography>

                <Grid container spacing={isSmall ? 2 : 4}>
                  <TextFieldWrapper
                    label="Code"
                    disabled={true}
                    placeHolder="code"
                    backgroundColor="rgba(3, 193, 243, 0.2);"
                    value={data.code}
                    name="code"
                  />
                  <TextFieldWrapper
                    label="Pincode"
                    placeHolder="Pincode"
                    backgroundColor="rgba(3, 193, 243, 0.2);"
                    value={data.pincode}
                    required={true}
                    maxLength={6}
                    name="pincode"
                    onChange={(e) => {
                      if (e.target.value.length <= 6) {
                        handleCommonChange(e);
                        handleStateSearch(e);
                        const pincodeValue =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1);
                        const pincodeRegex = /^[0-9]*$/;

                        if (
                          pincodeValue.length === 6 &&
                          !pincodeRegex.test(pincodeValue)
                        ) {
                          setFormError((prevFormError) => ({
                            ...prevFormError,
                            pincode: "Invalid pin code.",
                          }));
                        } else {
                          setFormError((prevFormError) => ({
                            ...prevFormError,
                            pincode: null,
                          }));
                        }
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value.length < 6) {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          pincode: "Pin code must be 6 digits long.",
                        }));
                      } else {
                        // setFormError((prevFormError) => ({
                        //   ...prevFormError,
                        //   // pincode: null,
                        // }));
                      }
                    }}
                    // onBlur={(e) => {
                    //   const pincodeValue = e.target.value;
                    //   const pincodeRegex = /^[0-9]{6}$/;

                    //   if (!pincodeRegex.test(pincodeValue)) {
                    //     setFormError((prevFormError) => ({
                    //       ...prevFormError,
                    //       pincode: "Pin code must be 6 digits long ",
                    //     }));
                    //   }
                    // }}
                    error={formError.pincode}
                  />
                  <TextFieldWrapper
                    label="State"
                    // disabled={
                    //   apiErrorStatus ? false : !formError?.state ? true : false
                    // }
                    disabled={true}
                    name="state"
                    required={true}
                    // maxLength={6}
                    value={data.state || ""}
                    error={formError.state}
                    onChange={handleCommonChange}
                  />

                  <TextFieldWrapper
                    label="City"
                    required={true}
                    // disabled={
                    //   apiErrorStatus ? false : !formError?.city ? true : false
                    // }
                    disabled={true}
                    fullWidth
                    name="city"
                    value={data.city || ""}
                    error={formError.city}
                    onChange={handleCommonChange}
                  />

                  <TextFieldWrapper
                    label="Location"
                    placeHolder="Enter Location"
                    name="location"
                    error={formError.location}
                    required={true}
                    value={data.location}
                    onChange={handleCommonChange}
                    index={i}
                    type="text"
                  />
                  <TextFieldWrapper
                    label="Area"
                    placeHolder="Area in sq. ft"
                    name="area"
                    maxLength={5}
                    notationVal="sq. ft"
                    textAlignRight={"textAlignRight"}
                    error={
                      data?.areaInvalid
                        ? "Value must be greater than zero ! "
                        : formError.area
                    }
                    required={true}
                    value={data.area}
                    onChange={(e) => {
                      handleCommonChange(e);
                      const areaValue = e.target.value;

                      if (areaValue === 0) {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          area: "Value must be greater than zero !",
                        }));
                        setData((previousdata) => ({
                          ...previousdata,
                          areaInvalid: true,
                        }));
                      } else {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          area: null,
                        }));
                        setData((previousdata) => ({
                          ...previousdata,
                          areaInvalid: false,
                        }));
                      }
                    }}
                    index={i}
                  />

                  <TextFieldWrapper
                    label="Address"
                    placeHolder="Enter Address"
                    required={true}
                    name="address"
                    error={formError.address}
                    value={data.address}
                    onChange={handleCommonChange}
                    index={i}
                  />
                  <TextFieldWrapper
                    required={true}
                    label="Deposit Amount"
                    placeHolder="Enter Deposit Amount"
                    name="deposit"
                    error={
                      data.depositInvalid
                        ? "Value must be greater than zero !"
                        : formError.deposit
                    }
                    textAlignRight={"textAlignRight"}
                    value={data.deposit || ""}
                    onChange={(e) => {
                      handleCommonChange(e);
                      const depositValue = e.target.value;

                      if (depositValue === 0) {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          deposit: "Value must be greater than zero !",
                        }));
                        setData((previousdata) => ({
                          ...previousdata,
                          depositInvalid: true,
                        }));
                      } else {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          deposit: null,
                        }));
                        setData((previousdata) => ({
                          ...previousdata,
                          depositInvalid: false,
                        }));
                      }
                    }}
                    maxLength={8}
                  />
                  <TextFieldWrapper
                    label="Monthly Rental"
                    placeHolder="Enter Rental"
                    required={true}
                    error={
                      data.monthlyRentalInvalid
                        ? "Value must be greater than zero !"
                        : formError.monthlyRent
                    }
                    textAlignRight={"textAlignRight"}
                    name="monthlyRent"
                    value={data.monthlyRent}
                    onChange={(e) => {
                      handleCommonChange(e);
                      const rentalValue = e.target.value;

                      if (rentalValue === 0) {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          monthlyRent: "Value must be greater than zero !",
                        }));
                        setData((previousdata) => ({
                          ...previousdata,
                          monthlyRentalInvalid: true,
                        }));
                      } else {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          monthlyRent: null,
                        }));
                        setData((previousdata) => ({
                          ...previousdata,
                          monthlyRentalInvalid: false,
                        }));
                      }
                    }}
                    maxLength={8}
                  />

                  <TextFieldWrapper
                    label="Agreement Tenure"
                    placeHolder="Tenure In Months"
                    name="tenure"
                    notationVal="Month's"
                    textAlignRight="textAlignRight"
                    required={true}
                    value={data.tenure}
                    error={
                      data.tenureInvalid
                        ? "Value must be greater than zero !"
                        : formError.tenure
                    }
                    onChange={(e) => {
                      if (e.target.value.length <= 3 && e.target.value <= 120) {
                        handleCommonChange(e);
                        const rentalValue = e.target.value;

                        if (rentalValue === 0) {
                          setFormError((prevFormError) => ({
                            ...prevFormError,
                            tenure: "Value must be greater than zero !",
                          }));
                          setData((previousdata) => ({
                            ...previousdata,
                            tenureInvalid: true,
                          }));
                        } else {
                          setFormError((prevFormError) => ({
                            ...prevFormError,
                            tenure: null,
                          }));
                          setData((previousdata) => ({
                            ...previousdata,
                            tenureInvalid: false,
                          }));
                        }
                      }
                    }}
                    index={i}
                    maxLength={3}
                    style={{
                      WebkitAppearance: "textfield",
                      MozAppearance: "textfield",
                      appearance: "textfield",
                    }}
                    onPaste={(e) => {
                      const pastedText = e.clipboardData.getData("text/plain");
                      if (!/^\d*$/.test(pastedText)) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={() => {
                      const tenureValue = data.tenure;

                      if (tenureValue === 0) {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          tenure: "Value must be greater than zero !",
                        }));
                      } else {
                        setFormError((prevFormError) => ({
                          ...prevFormError,
                          tenure: null,
                        }));
                      }
                    }}
                  />

                  <TextFieldWrapper
                    label="Lock In Months"
                    placeHolder="Enter Lock in Months"
                    required={true}
                    name="lockInYear"
                    maxLength={2}
                    className="no-spinner"
                    value={data.lockInYear}
                    error={
                      Number(data.lockInYear) > Number(data.tenure)
                        ? "Lock in months should be less than tenure"
                        : formError.lockInYear
                    }
                    onChange={(e) => {
                      if (e.target.value.length <= 2) {
                        handleCommonChange(e);
                      }
                    }}
                    style={{
                      MozAppearance: "textfield",
                      appearance: "textfield",
                    }}
                  />
                  <TextFieldWrapper
                    label="Notice Period In Months"
                    placeHolder="Enter Notice Period"
                    required={true}
                    maxLength={2}
                    name="noticePeriod"
                    onKeyPress={(e) => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (charCode < 48 || charCode > 57) {
                        e.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      const pastedText = e.clipboardData.getData("text/plain");
                      if (!/^\d*$/.test(pastedText)) {
                        e.preventDefault();
                      }
                    }}
                    value={data.noticePeriod}
                    error={
                      Number(data.noticePeriod) > Number(data.tenure)
                        ? "Notice Period should be less than tenure"
                        : formError.noticePeriod
                    }
                    style={{
                      MozAppearance: "textfield",
                      appearance: "textfield",
                    }}
                    onChange={(e) => {
                      if (e.target.value.length <= 2) {
                        handleCommonChange(e);
                      }
                    }}
                  />

                  {data.tenure === "" ? null : data.tenure <= 12 ? null : (
                    <SelectComponent
                      label={"Yearly Increment *"}
                      required={true}
                      name="yearlyIncrement"
                      options={["Percentage", "Value"]}
                      value={data.yearlyIncrement}
                      error={formError.yearlyIncrement}
                      onChange={(e) => {
                        console.log(
                          data.yearlyIncrement,
                          formError.yearlyIncrement,
                          ">>"
                        );
                        handleCommonChange(e);
                        setYearlyIncreentChanged(true);
                      }}
                    />
                  )}
                </Grid>

                {/* basic details end here */}
                <br />
                <YearlyIncrement
                  yearlyIncreentChanged={yearlyIncreentChanged}
                  yearValue={yearValue}
                  setYearValue={setYearValue}
                  tenure={data.tenure}
                  value={data.yearlyIncrement}
                  rent={data.monthlyRent}
                  increment={increment}
                  monthlyRent={data.monthlyRent}
                  setIncrement={setIncrement}
                />

                {/* uncommon fields Details start here*/}
                <Typography
                  variant="body1"
                  color="var(--main-color)"
                  fontSize="25px"
                  lineHeight="28px"
                  fontWeight="600"
                  my="20px"
                >
                  Landlord Details
                </Typography>

                {landblord.map((_, i) => (
                  <>
                    {landloard.length > 0 && (
                      <Box
                        mb={2}
                        size="small"
                        fullWidth
                        variant="outlined"
                        component={Button}
                        onClick={() => setExpand(expand === i ? -1 : i)}
                        sx={{
                          color: "black",
                          justifyContent: "space-between",
                          backgroundColor: "#b0d6f773",
                        }}
                      >
                        <Typography color={"var( --main-color)"}>
                          {" "}
                          {landloard.length > 0
                            ? landloard?.[i]?.leeseName
                            : ""}{" "}
                          Personal Details
                        </Typography>
                        <IconButton
                          onClick={() => setExpand(expand === i ? -1 : i)}
                        >
                          {expand === i ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </Box>
                    )}
                    <Collapse in={expand === i} timeout="auto" unmountOnExit>
                      <Grid
                        container
                        sx={{ px: 3, mb: "25px" }}
                        spacing={isSmall ? 2 : 4}
                      >
                        <Grid item xs={12}></Grid>

                        <TextFieldWrapper
                          label="Name Of Lessor"
                          placeHolder="Enter Name Of Lessor"
                          name="leeseName"
                          required={true}
                          value={
                            data.landlord[i] && data.landlord[i].leeseName
                              ? data.landlord[i].leeseName
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                        />
                        <TextFieldWrapper
                          label="Aadhaar Number"
                          placeHolder="Enter Aadhaar No."
                          required={true}
                          name="aadharNo"
                          maxLength={12}
                          value={
                            data.landlord[i] && data.landlord[i].aadharNo
                              ? data.landlord[i].aadharNo
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                          onBlur={(e) => handleOnBlur(e, i)}
                          index={i}
                          error={
                            duplicateAadhaarNoss.length > 0 &&
                            data.landlord[i].aadharNo &&
                            data.landlord[i].aadharNo.length === 12 &&
                            duplicateAadhaarNoss.includes(
                              data.landlord[i].aadharNo
                            )
                              ? "Duplicate Aadhar"
                              : formError.landlord[i] &&
                                formError.landlord[i].aadharNo
                              ? formError.landlord[i].aadharNo
                              : ""
                          }
                        />
                        <TextFieldWrapper
                          label="PAN Number"
                          placeHolder="Enter PAN No."
                          name="panNo"
                          onBlur={(e) => handleOnBlur(e, i)}
                          required={true}
                          maxLength={10}
                          value={
                            data.landlord[i] && data.landlord[i].panNo
                              ? data.landlord[i].panNo
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                          index={i}
                          error={
                            duplicatePanrNoss.length > 0 &&
                            data.landlord[i].panNo &&
                            data.landlord[i].panNo.length === 10 &&
                            duplicatePanrNoss.includes(data.landlord[i].panNo)
                              ? "Duplicate Pan"
                              : formError.landlord[i] &&
                                formError.landlord[i].panNo
                              ? formError.landlord[i].panNo
                              : ""
                          }
                        />

                        <TextFieldWrapper
                          label="Mobile Number"
                          placeHolder="Enter Mobile No."
                          required={true}
                          name="mobileNo"
                          maxLength={10}
                          onBlur={(e) => handleOnBlur(e, i)}
                          value={
                            data.landlord[i] && data.landlord[i].mobileNo
                              ? data.landlord[i].mobileNo
                              : ""
                          }
                          error={
                            duplicateMobileNoss.length > 0 &&
                            data.landlord[i].mobileNo &&
                            data.landlord[i].mobileNo.length === 10 &&
                            duplicateMobileNoss.includes(
                              data.landlord[i].mobileNo
                            )
                              ? "Duplicate Mobile no"
                              : formError.landlord[i] &&
                                formError.landlord[i].mobileNo
                              ? formError.landlord[i].mobileNo
                              : formError?.landlord?.[i]?.mobileNo
                          }
                          onChange={(e) => handleChange(e, i)}
                          index={i}
                        />
                        <TextFieldWrapper
                          label="Alternate Number"
                          placeHolder="Enter Alternate No."
                          name="alternateMobile"
                          onBlur={(e) => handleOnBlur(e, i)}
                          maxLength={10}
                          value={
                            data.landlord[i] && data.landlord[i].alternateMobile
                              ? data.landlord[i].alternateMobile
                              : ""
                          }
                          error={
                            formError.landlord[i] &&
                            formError.landlord[i].alternateMobile
                              ? formError.landlord[i].alternateMobile
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                          index={i}
                        />

                        <TextFieldWrapper
                          label="Email"
                          placeHolder="Enter Email"
                          required={true}
                          name="email"
                          onBlur={(e) => handleOnBlur(e, i)}
                          value={
                            data.landlord[i] && data.landlord[i].email
                              ? data.landlord[i].email
                              : ""
                          }
                          error={
                            formError.landlord[i] && formError.landlord[i].email
                              ? formError.landlord[i].email
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                          index={i}
                        />

                        <TextFieldWrapper
                          label="GST Number"
                          placeHolder="Enter GST No."
                          name="gstNo"
                          onBlur={(e) => handleOnBlur(e, i)}
                          maxLength={15}
                          value={
                            data.landlord[i] && data.landlord[i]?.gstNo
                              ? data.landlord[i].gstNo
                              : ""
                          }
                          error={
                            formError.landlord[i] && formError.landlord[i].gstNo
                              ? formError.landlord[i].gstNo
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                        />
                        <TextFieldWrapper
                          label="Bank IFSC"
                          placeHolder="Enter IFSC Code"
                          name="ifscCode"
                          maxLength={11}
                          onBlur={(e) => handleOnBlur(e, i)}
                          required={true}
                          value={
                            data.landlord[i] && data.landlord[i].ifscCode
                              ? data.landlord[i].ifscCode
                              : ""
                          }
                          error={
                            formError.landlord[i] &&
                            formError.landlord[i].ifscCode
                              ? formError.landlord[i].ifscCode
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                        />

                        <TextFieldWrapper
                          label="Bank Name"
                          placeHolder="Enter Bank Name"
                          name="bankName"
                          partLabel={
                            data.landlord[i] && data.landlord[i].branchName
                              ? data.landlord[i].branchName
                              : ""
                          }
                          error={
                            data.landlord[i] &&
                            data.landlord[i].bankName === "Not Found"
                              ? "Bank Name not found."
                              : ""
                          }
                          required={true}
                          disabled={true}
                          value={
                            data.landlord[i] && data.landlord[i].bankName
                              ? data.landlord[i].bankName
                              : ""
                          }
                          onChange={(e) => handleOnBlur(e, i)}
                        />

                        <TextFieldWrapper
                          label="Beneficiary Name"
                          placeHolder="Enter Beneficiary Name"
                          name="benificiaryName"
                          maxLength={50}
                          onBlur={(e) => handleOnBlur(e, i)}
                          value={
                            data.landlord[i] && data.landlord[i].benificiaryName
                              ? data.landlord[i].benificiaryName
                              : ""
                          }
                          error={
                            formError.landlord[i] &&
                            formError.landlord[i].benificiaryName
                              ? formError.landlord[i].benificiaryName
                              : ""
                          }
                          required={true}
                          onChange={(e) => handleChange(e, i)}
                        />

                        <TextFieldWrapper
                          label="Bank A/c Number "
                          placeHolder="Enter Account No."
                          name="accountNo"
                          maxLength={17}
                          required={true}
                          onBlur={(e) => handleOnBlur(e, i)}
                          value={
                            data.landlord[i] && data.landlord[i].accountNo
                              ? data.landlord[i].accountNo
                              : ""
                          }
                          error={
                            formError.landlord[i] &&
                            formError.landlord[i].accountNo
                              ? formError.landlord[i].accountNo
                              : ""
                          }
                          onChange={(e) => handleChange(e, i)}
                        />
                      </Grid>
                    </Collapse>
                  </>
                ))}

                {/* Bank Details ends here*/}

                {/* Document upload section start here */}
                <Typography
                  variant="body1"
                  color="var(--main-color)"
                  fontSize="25px"
                  lineHeight="28px"
                  fontWeight="600"
                  my="20px"
                >
                  Upload Documents *
                </Typography>
                <Typography
                  variant="body1"
                  color="var(--main-color)"
                  fontSize="13px"
                  lineHeight="5px"
                  fontWeight="400"
                  my="20px"
                >
                  ( Upload file in png, jpeg, jpg, pdf format & maximum file
                  size limit less than 20 MB )
                </Typography>

                {landblord.map((_, i) => (
                  <>
                    {landloard.length > 0 && (
                      <Box
                        mb={2}
                        size="small"
                        fullWidth
                        variant="outlined"
                        component={Button}
                        onClick={() => setDocExpand(docExpand === i ? -1 : i)}
                        sx={{
                          color: "black",
                          justifyContent: "space-between",
                          backgroundColor: "#b0d6f773",
                        }}
                      >
                        <Typography color={"var( --main-color)"}>
                          {" "}
                          {landloard.length > 0
                            ? landloard?.[i]?.leeseName
                            : ""}{" "}
                          Document Upload
                        </Typography>
                        <IconButton
                          onClick={() => setDocExpand(docExpand === i ? -1 : i)}
                        >
                          {docExpand === i ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </Box>
                    )}

                    <Collapse in={docExpand === i} timeout="auto" unmountOnExit>
                      <Grid
                        container
                        spacing={isSmall ? 2 : 4}
                        sx={{ px: 1, justifyContent: "", mb: 3 }}
                      >
                        <Grid item xs={6}>
                          <DocumentUpload
                            uploaded={
                              landloard[i]
                                ? data[
                                    `${(
                                      landloard?.[i]?.leeseName + "@aadhar_card"
                                    ).replace(" ", "")}`
                                  ]
                                  ? true
                                  : false
                                : false
                            }
                            label="Upload Aadhaar Card"
                            placeHolder="Upload Aadhaar Card"
                            handleChange={handleChangeFile}
                            name={
                              landloard[i]
                                ? `${(
                                    landloard?.[i]?.leeseName + "@aadhar_card"
                                  ).replace(" ", "")}`
                                : "adhar"
                            }
                            fileName={
                              landloard[i]
                                ? data[
                                    `${
                                      landloard?.[i]?.leeseName +
                                      "@aadhar_card_name"
                                    }`.replace(" ", "")
                                  ]
                                : ""
                            }
                            error={
                              landloard[i] &&
                              formError[
                                `${
                                  landloard?.[i]?.leeseName +
                                  "@aadhar_card_name"
                                }`.replace(" ", "")
                              ]
                            }
                            href={
                              landloard[i]
                                ? data[
                                    `${
                                      landloard?.[i]?.leeseName + "@aadhar_card"
                                    }`.replace(" ", "")
                                  ]
                                : ""
                            }
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <DocumentUpload
                            label="Upload PAN Card"
                            uploaded={
                              landloard[i]
                                ? data[
                                    `${(
                                      landloard?.[i]?.leeseName + "@pan_card"
                                    ).replace(" ", "")}`
                                  ]
                                  ? true
                                  : false
                                : false
                            }
                            placeHolder={"Upload PAN Card"}
                            handleChange={handleChangeFile}
                            name={
                              landloard[i]
                                ? `${(
                                    landloard?.[i]?.leeseName + "@pan_card"
                                  ).replace(" ", "")}`
                                : "pan"
                            }
                            fileName={
                              landloard[i]
                                ? data[
                                    `${
                                      landloard?.[i]?.leeseName +
                                      "@pan_card_name"
                                    }`.replace(" ", "")
                                  ]
                                : ""
                            }
                            error={
                              landloard[i] &&
                              formError[
                                `${
                                  landloard?.[i]?.leeseName + "@pan_card_name"
                                }`.replace(" ", "")
                              ]
                            }
                            href={
                              landloard[i]
                                ? data[
                                    `${
                                      landloard?.[i]?.leeseName + "@pan_card"
                                    }`.replace(" ", "")
                                  ]
                                : ""
                            }
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <DocumentUpload
                            label="Upload Cancel Cheque"
                            placeHolder="Upload Cancel Cheque"
                            uploaded={
                              landloard[i]
                                ? data[
                                    `${(
                                      landloard?.[i]?.leeseName + "@cheque"
                                    ).replace(" ", "")}`
                                  ]
                                  ? true
                                  : false
                                : false
                            }
                            fileName={
                              landloard[i]
                                ? data[
                                    `${
                                      landloard?.[i]?.leeseName + "@cheque_name"
                                    }`.replace(" ", "")
                                  ]
                                : ""
                            }
                            error={
                              landloard[i] &&
                              formError[
                                `${
                                  landloard?.[i]?.leeseName + "@cheque_name"
                                }`.replace(" ", "")
                              ]
                            }
                            handleChange={handleChangeFile}
                            name={
                              landloard[i]
                                ? `${(
                                    landloard?.[i]?.leeseName + "@cheque"
                                  ).replace(" ", "")}`
                                : "cheque"
                            }
                            href={
                              landloard[i]
                                ? data[
                                    `${
                                      landloard?.[i]?.leeseName + "@cheque"
                                    }`.replace(" ", "")
                                  ]
                                : ""
                            }
                          />
                        </Grid>

                        {data.landlord[i] ? (
                          data.landlord[i].gstNo ? (
                            <Grid item xs={6}>
                              <DocumentUpload
                                label="Upload GST Certificate"
                                uploaded={
                                  landloard[i]
                                    ? data[
                                        `${(
                                          landloard?.[i]?.leeseName + "@gst"
                                        ).replace(" ", "")}`
                                      ]
                                      ? true
                                      : false
                                    : false
                                }
                                fileName={
                                  landloard[i]
                                    ? data[
                                        `${
                                          landloard?.[i]?.leeseName +
                                          "@gst_name"
                                        }`.replace(" ", "")
                                      ]
                                    : ""
                                }
                                placeHolder="Upload GST Certificate"
                                error={
                                  landloard[i] &&
                                  formError[
                                    `${
                                      landloard?.[i]?.leeseName + "@gst_name"
                                    }`.replace(" ", "")
                                  ]
                                }
                                handleChange={handleChangeFile}
                                name={
                                  landloard[i]
                                    ? `${(
                                        landloard?.[i]?.leeseName + "@gst"
                                      ).replace(" ", "")}`
                                    : "gst"
                                }
                                href={
                                  landloard[i]
                                    ? data[
                                        `${
                                          landloard?.[i]?.leeseName +
                                          "@gst_name"
                                        }`.replace(" ", "")
                                      ]
                                    : ""
                                }
                              />
                            </Grid>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Collapse>
                  </>
                ))}

                {landblord.length > 0 ? (
                  <>
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="25px"
                      lineHeight="28px"
                      fontWeight="600"
                      my="20px"
                    >
                      Upload Documents
                    </Typography>
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="13px"
                      lineHeight="5px"
                      fontWeight="400"
                      my="20px"
                    >
                      ( Upload file in png, jpeg, jpg, pdf format & maximum file
                      size limit less than 20 MB )
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                <Grid
                  container
                  spacing={isSmall ? 2 : 4}
                  sx={{ px: 1, justifyContent: "" }}
                >
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload Draft Agreement *"
                      uploaded={data.draft_agreement && true}
                      href={data.draft_agreement}
                      placeHolder="Upload Draft Agreement"
                      fileName={data.draft_agreement_name}
                      handleChange={handleChangeFile}
                      name={"draft_agreement"}
                      error={formError.draft_agreement}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload Electricity Bill *"
                      uploaded={data.electricity_bill && true}
                      href={data.electricity_bill}
                      placeHolder={"Upload Electricity Bill"}
                      fileName={data.electricity_bill_name}
                      handleChange={handleChangeFile}
                      name={"electricity_bill"}
                      error={formError.electricity_bill}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload POA"
                      placeHolder="Upload POA"
                      uploaded={data.poa && true}
                      href={data.poa}
                      fileName={data.poa_name}
                      handleChange={handleChangeFile}
                      name={"poa"}
                      error={formError.poa}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload Maintenance Bill"
                      uploaded={data.maintaince_bill && true}
                      href={data.maintaince_bill}
                      placeHolder={"Upload Maintenance Bill"}
                      fileName={data.maintaince_bill_name}
                      handleChange={handleChangeFile}
                      name={"maintaince_bill"}
                      error={formError.maintaince_bill}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload Property Tax Receipt *"
                      uploaded={data.tax_receipt && true}
                      href={data.tax_receipt}
                      fileName={data.tax_receipt_name}
                      placeHolder={"Upload Property Tax Receipt"}
                      handleChange={handleChangeFile}
                      name={"tax_receipt"}
                      error={formError.tax_receipt}
                    />
                  </Grid>
                  {data.landlord.length > 1 && (
                    <Grid item xs={6}>
                      <DocumentUpload
                        uploaded={data.noc && true}
                        href={data.noc}
                        label="Upload NOC (If Multiple Owners) *"
                        placeHolder="NOC"
                        fileName={data.noc_name}
                        handleChange={handleChangeFile}
                        name={"noc"}
                        error={formError.noc}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload Property Picture *"
                      uploaded={data.property_pic && true}
                      href={data.property_pic}
                      fileName={data.property_pic_name}
                      placeHolder={"Upload Property Picture"}
                      handleChange={handleChangeFile}
                      name={"property_pic"}
                      error={formError.property_pic}
                    />
                  </Grid>
                </Grid>

                {/* Document upload section end here */}
                <Grid
                  item
                  xs={10}
                  sx={{ mt: 5 }}
                  className={"textFieldWrapper"}
                >
                  <Grid item xs={8}>
                    <TextField
                      type="text"
                      multiline
                      rows={3}
                      fullWidth
                      variant="outlined"
                      label="Landlord Assets *"
                      name="assets"
                      placeholder="Landlord Assets *"
                      value={data.assets || ""}
                      onChange={(e) => {
                        handleCommonChange(e);
                      }}
                    />
                    <Typography sx={{ color: "red" }} variant="caption">
                      {formError.assets}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Button Start from here */}
                <Grid
                  container
                  sx={{ justifyContent: "center", mt: 2 }}
                  spacing={4}
                >
                  <Grid item md={3} xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      color="primary"
                      sx={{
                        height: "60px",
                        width: "100%",
                        borderRadius: "20px",
                        fontSize: "16px",
                        color: "#FFFFFF",
                        lineHeight: "32px",
                        textTransform: "capitalize",
                        "@media(max-width:900px)": {
                          fontSize: "11px",
                          lineHeight: "12px",
                          height: "40px",
                        },
                      }}
                      disabled={disableCheck}
                    >
                      Submit To Sr Manager
                    </Button>
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleHold(manager_id)}
                      sx={{
                        height: "60px",
                        width: "100%",
                        borderRadius: "20px",
                        fontSize: "16px",
                        lineHeight: "32px",
                        textTransform: "capitalize",
                        "@media(max-width:900px)": {
                          fontSize: "10px",
                          lineHeight: "20px",
                          height: "40px",
                        },
                      }}
                    >
                      Save As Draft
                    </Button>
                  </Grid>
                </Grid>

                {/* Button Ends Here */}
              </Box>

              {/* agreemenet from end here */}
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );
}

export default Agreement;
