/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  add_invoice,
  get_agreement_id,
  notification_handler,
  updateMonthlyRent,
} from "../../Services/Services";

import { setRefreshBox } from "../../store/action/action";
import {
  Box,
  Button,
  Grid,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  TextField,
  IconButton,
  FormControl,
  Collapse,
  Checkbox,
  Divider,
} from "@mui/material";
import {
  DocumentUpload,
  MyHeader,
  SelectComponent,
  TextFieldWrapper,
} from "../StyledComponent";
import YearlyIncrement from "./IncrementType";
import {
  uploadDoc,
  getDetails,
  editAgreement,
  getBankName,
  getLocation,
  insertAdjustmentAmount,
  get_remarks_by_code,
} from "../../Services/Services";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../store/action/action";
import PermissionAlert from "./Alert";
import { useParams, useNavigate } from "react-router-dom";
import { ImageView } from "../StyleComponents/Rental";
import { send_to_bhu } from "../../Services/Services";
import HamburgerManager from "./HamburgerManager";
import UploadInvoice from "../MonthalyPayment/UploadInvoice";
import { checkGridRowIdIsValid } from "@mui/x-data-grid";

function EditAgreement({ history }) {
  const navigate = useNavigate();
  const { landloard } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [agreement, setAgreement] = useState([]);
  const { id } = useParams();

  const [buh_id, setBuh_ID] = useState(null);
  const [selectedUnpaidMonths, setSelectedUnpaidMonths] = useState([]);
  const [selectedUnpaidIds, setSelectedUnpaidIds] = useState([]);
  const [selectedLockInMonths, setSelectedLockInMonths] = useState([]);
  const [selectedLockInIds, setSelectedLockInIds] = useState([]);

  const [finance_id, setFinance_ID] = useState(null);
  const [uploadInvoice, setInvoiceUpload] = useState(false);
  const [upaid, setUnpaid] = useState([]);
  const [finalLockedInData, setFinalLockedInData] = useState([]);
  const [open, setOpen] = useState(false);
  const { auth, refresh } = useSelector((state) => state);
  const [allRemarks, setAllRemarks] = useState("");
  const role = auth.role;
  const login_manager_id = auth.id;
  const subType = "terminate";
  const [remark, setRemark] = useState("");
  const [selectID, setSelectID] = useState(0);
  const [code, setCode] = useState(0);
  const [ids, setIds] = useState([]);
  const [monthlyId, setMonthlyId] = useState(0);
  const [rowDataForUpload, setRowDataForUpload] = useState();
  const [invoiceEdited, setInvoiceEdited] = useState(false);

  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceNo: "",
    invoiceDate: "",
    rentAmount: "",
    gstAmount: "",
    totalAmount: "",
    invoice: "",
    invoice_file_name: "",
    manager_id: auth.id,
    gst: "",
    monthlyId: monthlyId,
    user_id: auth.id,
    agreement_id: agreement?.[ids[0]]?.agreement_id,
    code: agreement?.[ids[0]]?.code,
  });

  async function getData(id) {
    const agreement = await get_agreement_id(id);
    if (agreement.status === 200) {
      setAgreement(agreement.data.agreement);
      setIds(agreement.data.ids);
      if (agreement.data.recoveryResult.length > 0) {
        setRecovery(agreement.data.recoveryResult[0]);
      }
      get_remarks_by_aggrement_code(
        agreement.data.agreement[agreement.data.ids[0]].code
      );
    }

    setInvoiceDetails({
      ...invoiceDetails,
      gst: agreement.data.agreement[agreement.data.ids[0]]?.gst
        ? agreement.data.agreement[agreement.data.ids[0]]?.gst
        : "---",
      rentAmount: agreement.data.agreement[agreement.data.ids[0]].rent_amount,
      monthlyId: monthlyId,
      gstAmount: agreement.data.agreement[agreement.data.ids[0]]?.gstAmount
        ? (agreement.data.agreement[agreement.data.ids[0]].rent_amount / 100) *
          18
        : 0,
      invoiceNo:
        agreement.data.agreement[agreement.data.ids[0]].invoice_number !== '""'
          ? agreement.data.agreement[agreement.data.ids[0]].invoice_number
          : "",
      invoiceDate:
        agreement.data.agreement[agreement.data.ids[0]].invoice_date !== '""'
          ? agreement.data.agreement[agreement.data.ids[0]].invoice_date
          : "",
      invoice:
        agreement.data.agreement[agreement.data.ids[0]].invoice !== '""'
          ? agreement.data.agreement[agreement.data.ids[0]].invoice
          : "",
      user_id: auth.id,
      agreement_id: agreement?.[ids[0]]?.agreement_id,
      code: agreement?.[ids[0]]?.code,
    });
  }

  async function get_remarks_by_aggrement_code(code) {
    try {
      const remarks = await get_remarks_by_code(code, subType, id);

      if (remarks.data.success) {
        let remark = remarks.data.remarks.map(
          ({ step_name, remarks }) => `${step_name} : ${remarks}`
        );
        setAllRemarks(remark);
      }
    } catch (error) {}
  }

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [preData, setPreData] = useState({
    assets: "",
    landlord: [],
    area: "",
    code: "",
    lockInYear: "",
    address: "",
    pincode: "",
    state: "",
    city: "",
    locaiton: "",
    noticePeriod: "",
    deposit: "",
    monthlyRent: "",
    yearlyIncrement: "",
    status: "",
    gst_certificate: "",
    draft_agreement: "",
    electricity_bill: "",
    poa: "",
    maintaince_bill: "",
    cheque: "",
    tax_receipt: "",
    noc: "",
    remark: "",
    file: "",
    termination_remark: "",
    type: "",
    finalLockedIn: finalLockedInData ? finalLockedInData : [],
  });

  const selectedUnpaidMonthsFunc = (e, data) => {
    if (
      selectedUnpaidMonths.length > 0 &&
      selectedUnpaidIds.includes(data.id)
    ) {
      const filtered = selectedUnpaidMonths.filter(
        (item) => item.id !== data.id
      );
      setSelectedUnpaidMonths(filtered);
    } else {
      selectedUnpaidMonths.push(data);
    }

    if (selectedUnpaidIds.includes(data.id)) {
      setSelectedUnpaidIds(selectedUnpaidIds.filter((i) => i !== data.id));
    } else {
      selectedUnpaidIds.push(data.id);
    }

    let unpaidMonthsAmount = selectedUnpaidMonths.reduce(
      (sum, row) => (sum += parseInt(row.rent_amount)),
      0
    );

    setRecovery((old) => ({
      ...old,
      unpaidMonthsAmount: unpaidMonthsAmount,
    }));
  };

  const selectedLockinMonthsFunc = (e, data) => {
    if (
      selectedLockInMonths.length > 0 &&
      selectedLockInIds.includes(data.id)
    ) {
      const filtered = selectedLockInMonths.filter(
        (item) => item.id !== data.id
      );
      setSelectedLockInMonths(filtered);
    } else {
      selectedLockInMonths.push(data);
    }

    if (selectedLockInIds.includes(data.id)) {
      setSelectedLockInIds(selectedLockInIds.filter((i) => i !== data.id));
    } else {
      selectedLockInIds.push(data.id);
    }
    let lockedInMonthsAmount = selectedLockInMonths.reduce(
      (sum, row) => (sum += parseInt(row.rent_amount)),
      0
    );

    setRecovery((old) => ({
      ...old,
      lockedInMonthsAmount: lockedInMonthsAmount,
    }));
  };

  const [recovery, setRecovery] = useState({
    agreement_id: id,
    remainingMonth: 0,
    depositedAmount: 0,
    adjustmentAmount: 0,
    expenses: 0,
    otherAdjustments: 0,
    totalAdjustmentAmount: 0,
    balanceDeposit: 0,
    monthlyRent: 0,
    monthlyAdjustedRentalAmount: 0,
    lockedInMonthsAmount: 0,
    unpaidMonthsAmount: 0,
  });

  useEffect(() => {
    if (recovery.adjustmentAmount && !recovery.adjustmentAmountRemark) {
      setFormError((old) => ({
        ...old,
        adjustmentAmountRemark: "Remark Required",
      }));
    } else if (recovery.adjustmentAmount && recovery.adjustmentAmountRemark) {
      setFormError((old) => ({
        ...old,
        adjustmentAmountRemark: "",
      }));
    }

    if (recovery.expenses && !recovery.expansesRemark) {
      setFormError((old) => ({
        ...old,
        expansesRemark: "Remark Required",
      }));
    } else {
      setFormError((old) => ({
        ...old,
        expansesRemark: "",
      }));
    }

    if (recovery.otherAdjustments && !recovery.otherRemark) {
      setFormError((old) => ({
        ...old,
        otherRemark: "Remark Required",
      }));
    } else {
      setFormError((old) => ({
        ...old,
        otherRemark: "",
      }));
    }
    if (recovery.remainingMonth === 0) {
      setFormError((old) => ({
        ...old,
        adjustmentAmountRemark: "",
        expansesRemark: "",
      }));
    }
  }, [
    recovery.adjustmentAmount,
    recovery.expenses,
    recovery.otherAdjustments,
    recovery.adjustmentAmountRemark,
    recovery.expansesRemark,
    recovery.otherRemark,
    recovery.remainingMonth,
  ]);

  const [agreementData, setAgreementData] = useState({
    id: id,
    assets: preData.assets,
    file: preData.file,
    termination_remark: preData.termination_remark,
    finalLockedIn: preData.finalLockedIn,
  });

  useEffect(() => {
    let unpaidMonthsAmount = selectedUnpaidMonths.reduce(
      (sum, row) =>
        (sum += Math.round(
          row.rent_amount +
            (row?.gstNo?.length > 0 ? row.rent_amount * 0.18 : 0)
        )),
      0
    );

    let lockedInMonthsAmount = selectedLockInMonths.reduce(
      (sum, row) => (sum += parseInt(row.rent_amount)),
      0
    );

    setAgreementData((old) => ({
      ...old,
      selectedUnpaidMonths: selectedUnpaidMonths,
      selectedLockInMonths: selectedLockInMonths,
    }));

    setRecovery((old) => ({
      ...old,
      unpaidMonthsAmount: unpaidMonthsAmount,
      lockedInMonthsAmount: lockedInMonthsAmount,
      balanceDeposit:
        parseInt(recovery.depositedAmount) - recovery.totalAdjustmentAmount,
      monthlyAdjustedRentalAmount:
        parseInt(recovery.adjustmentAmount) +
        parseInt(recovery.unpaidMonthsAmount) +
        parseInt(recovery.lockedInMonthsAmount),
      totalAdjustmentAmount:
        recovery.monthlyAdjustedRentalAmount -
        recovery.otherAdjustments +
        recovery.expenses,
    }));
  }, [
    selectedUnpaidMonths,
    selectedLockInMonths,
    recovery.unpaidMonthsAmount,
    recovery.lockedInMonthsAmount,
    recovery.adjustmentAmount,
    recovery.monthlyAdjustedRentalAmount,
    recovery.totalAdjustmentAmount,
    recovery.otherAdjustments,
    recovery.expenses,
    recovery.depositedAmount,
  ]);
  async function fetchData() {
    try {
      let response = await getDetails(id);
      if (response.status === 200) {
        let {
          id,
          code,
          pincode,
          state,
          address,
          location,
          city,
          lockInYear,
          monthlyRent,
          noticePeriod,
          yearlyIncrement,
          deposit,
          gst_certificate,
          draft_agreement,
          electricity_bill,
          poa,
          maintaince_bill,
          cheque,
          tax_receipt,
          noc,
          tenure,
          year1,
          year2,
          year3,
          year4,
          year5,
          year6,
          year7,
          year8,
          year9,
          year10,
          area,
          landlord,
          remark,
          assets,
          file,
          status,
          terminate_status,
          renewal_status,
          termination_remark,
          type,
        } = response.data.agreement;

        setBuh_ID(response.data.bhu_id);
        setFinance_ID(response.data.op_id);

        let unpaid_amount = response.data.listUnpaidRow.reduce(
          (sum, row) => (sum += parseInt(row.rent_amount)),
          0
        );
        if (response.data.listUnpaidRow.length > 0) {
          setUnpaid(response.data.listUnpaidRow);
          if (
            status === "Sent Back From Sr Manager Termination" ||
            status === "Sent Back From Finance Team Termination" ||
            status === "Sent Back From Operations Termination"
          ) {
            response.data.listUnpaidRow.map((object) => {
              if (object.status === "Hold") {
                selectedUnpaidMonths.push(object);
                selectedUnpaidIds.push(object.id);
              }
            });
          } else {
            setSelectedUnpaidMonths(response.data.listUnpaidRow);
            response.data.listUnpaidRow.map((object) => {
              selectedUnpaidIds.push(object.id);
            });
          }
        }
        if (response.data.finalLockedIn.length > 0) {
          setFinalLockedInData(response.data.finalLockedIn);
          if (
            status === "Sent Back From Sr Manager Termination" ||
            status === "Sent Back From Finance Team Termination" ||
            status === "Sent Back From Operations Termination"
          ) {
            response.data.finalLockedIn.map((object) => {
              if (object.status === "Hold") {
                selectedLockInMonths.push(object);
                selectedLockInIds.push(object.id);
              }
            });
          } else {
            setSelectedLockInMonths(response.data.finalLockedIn);
            response.data.finalLockedIn.map((object) => {
              selectedLockInIds.push(object.id);
            });
          }
        }

        let rent = monthlyRent;
        setRecovery((old) => ({
          ...old,
          unpaid_amount: 0,
          depositedAmount: deposit,
          balanceDeposit: deposit,
          monthlyRent: monthlyRent,
        }));

        if (yearlyIncrement === "Percentage") {
          setYearValue({
            year1: 0,
            year2: year2 && Math.round(((year2 - year1) / year1) * 100),
            year3: year3 && Math.round(((year3 - year2) / year2) * 100),
            year4: year4 && Math.round(((year4 - year3) / year3) * 100),
            year5: year5 && Math.round(((year5 - year4) / year4) * 100),
            year6: year6 && Math.round(((year6 - year5) / year5) * 100),
            year7: year7 && Math.round(((year7 - year6) / year6) * 100),
            year8: year8 && Math.round(((year8 - year7) / year7) * 100),
            year9: year9 && Math.round(((year9 - year8) / year8) * 100),
            year10: year10 && Math.round(((year10 - year9) / year9) * 100),
          });
        } else {
          setYearValue({
            year1: 0,
            year2: year2 && year2 - year1,
            year3: year3 && year3 - year2,
            year4: year4 && year4 - year3,
            year5: year5 && year5 - year4,
            year6: year6 && year6 - year5,
            year7: year7 && year7 - year6,
            year8: year8 && year8 - year7,
            year9: year9 && year9 - year8,
            year10: year10 && year10 - year9,
          });
        }

        setPreData({
          id,
          assets,
          area,
          code,
          pincode,
          state,
          address,
          location,
          city,
          lockInYear,
          monthlyRent,
          noticePeriod,
          yearlyIncrement,
          deposit,
          gst_certificate,
          draft_agreement,
          electricity_bill,
          poa,
          maintaince_bill,
          cheque,
          tax_receipt,
          noc,
          tenure,
          landlord,
          remark,
          file,
          status,
          terminate_status,
          renewal_status,
          termination_remark,
          type,
        });
        setAgreementData((old) => ({
          ...old,
          assets: assets,
          file: file,
          file_name: file != null ? file.split("/").pop() : "",
        }));

        setFormError({
          id: undefined,
          code: undefined,
          pincode: undefined,
          state: undefined,
          address: undefined,
          location: undefined,
          city: undefined,
          lockInYear: undefined,
          monthlyRent: undefined,
          noticePeriod: undefined,
          yearlyIncrement: undefined,
          deposit: undefined,
          gst_certificate: undefined,
          draft_agreement: undefined,
          electricity_bill: undefined,
          poa: undefined,
          maintaince_bill: undefined,
          cheque: undefined,
          tax_receipt: undefined,
          noc: undefined,
          tenure: undefined,
          landlord: landlord.map((row) => ({})),
          remark: undefined,
          area: undefined,
        });
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  const [docExpand, setDocExpand] = useState(0);
  const [i, setIndex] = useState(0);
  const [data, setData] = useState({
    landlord: [...landloard],
    code: "",
    lockInYear: "",
    noticePeriod: "",
    deposit: "",
    monthlyRent: "",
    yearlyIncrement: "",
    tenure: "",
  });

  useEffect(() => {
    setPreData((old) => ({ ...old, landlord: [...landloard] }));
    setFormError((old) => ({ ...old, landlord: [...landloard] }));
  }, [landloard]);

  const [landblord, setLandblord] = useState([1]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [increment, setIncrement] = useState({
    year1: "",
    year2: "",
    year3: "",
    year4: "",
    year5: "",
    year6: "",
    year7: "",
    year8: "",
    year9: "",
    year10: "",
  });
  const [yearValue, setYearValue] = useState({
    year1: 0,
    year2: 0,
    year3: 0,
    year4: 0,
    year5: 0,
    year6: 0,
    year7: 0,
    year8: 0,
    year9: 0,
    year10: 0,
  });

  async function handleChangeCommonFile(e, i) {
    const FD = new FormData();

    FD.append("folderName", preData.code);
    FD.append("photo", e.target.files[0]);
    let response = await uploadDoc(FD);

    if (response.status === 200) {
      setPreData((old) => ({
        ...old,
        [e.target.name + "_name"]: e.target.files[0].name,
        landlord: old.landlord.map((row, index) => {
          if (i === index) {
            row[e.target.name] = response.data.link;
            return row;
          } else return row;
        }),
        [e.target.name + i]: e.target.files[0].name,
      }));

      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: response.data.message,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message || "Something went wrong !!!",
        })
      );
    }
  }

  async function handleChangeFile(e) {
    const FD = new FormData();

    FD.append("folderName", preData.code);
    FD.append("photo", e.target.files[0]);
    let response = await uploadDoc(FD);
    if (response.status === 200) {
      setFormError((old) => ({
        ...old,
        [e.target.name]: "",
      }));

      setAgreementData((old) => ({
        ...old,
        [e.target.name + "_name"]: e.target.files[0].name,
        [e.target.name]: response.data.link,
      }));
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: response.data.message,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message || "Something went wrong !!!",
        })
      );
    }
  }

  async function uploadInvoiceDetails() {
    try {
      const addInvoice = await add_invoice(selectID, invoiceDetails);

      if (addInvoice.data.success) {
        dispatch(
          setAlert({
            open: true,
            variant: "success",
            message: invoiceEdited
              ? "Invoice Details Updated Sucessfully"
              : "Invoice Details Submitted Sucessfully",
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        dispatch(setRefreshBox());
        setOpen(false);
        setInvoiceDetails({
          invoiceNo: "",
          invoiceDate: "",
          rentAmount: "",
          gstAmount: "",
          totalAmount: "",
          invoice: "",
          invoice_file_name: "",
          manager_id: auth.id,
        });
      } else {
        dispatch(
          setAlert({
            open: true,
            variant: "error",
            message: "Some Error Occured Please Try Again Later.",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }

  useEffect(() => {
    getData(id);
  }, []);
  function handleRemarkChange(e) {
    setRecovery({
      ...recovery,
      [e.target.name]: e.target.value,
    });
  }
  function handleChange(e, i) {
    let error = { state: false, message: null };

    if (!e.target.value.match(/^[0-9]*$/)) error = { ...error, state: true };

    if (!error.state) {
      let adableFeilds = ["expenses", "otherAdjustments", "adjustmentAmount"];

      let sum = 0;

      Object.keys(recovery).map((row) => {
        if (adableFeilds.includes(row) && row !== e.target.name) {
          sum = sum + parseInt(recovery[row]);
        } else if (adableFeilds.includes(row))
          sum += e.target.value.length > 0 ? parseInt(e.target.value) : 0;
      });
      if (e.target.name === "remainingMonth") {
        let unpaidRent = [];
        selectedUnpaidMonths.length > 0 &&
          selectedUnpaidMonths.map((obj) => {
            unpaidRent.push(obj.rent_amount);
          });
        setRecovery((old) => ({
          ...old,
          [e.target.name]:
            e.target.value.length > 0 ? parseInt(e.target.value) : 0,
          adjustmentAmount:
            e.target.value > 0 ? old.monthlyRent * parseInt(e.target.value) : 0,
          // totalAdjustmentAmount:
          //   recovery.monthlyAdjustedRentalAmount +
          //   recovery.otherAdjustments +
          //   recovery.expenses,
          // balanceDeposit:
          //   parseInt(recovery.depositedAmount) - recovery.totalAdjustmentAmount,
          // monthlyAdjustedRentalAmount:
          //   parseInt(recovery.adjustmentAmount) +
          //   parseInt(recovery.unpaidMonthsAmount) +
          //   parseInt(recovery.lockedInMonthsAmount),
        }));
      } else {
        setRecovery((old) => ({
          ...old,
          [e.target.name]:
            e.target.value.length > 0 ? parseInt(e.target.value) : 0,
          // totalAdjustmentAmount:
          //   recovery.monthlyAdjustedRentalAmount +
          //   recovery.otherAdjustments +
          //   recovery.expenses,
          // balanceDeposit:
          //   parseInt(recovery.depositedAmount) - recovery.totalAdjustmentAmount,
          // monthlyAdjustedRentalAmount:
          //   parseInt(recovery.adjustmentAmount) +
          //   parseInt(recovery.unpaidMonthsAmount) +
          //   parseInt(recovery.lockedInMonthsAmount),
        }));
      }
    }
  }

  function handleCommonChange(e, i) {
    var error = { state: false };
    switch (e.target.name) {
      case "location":
        if (!e.target.value.match(/^[^@#$%^&*<>'\"/;`%]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "address":
        if (!e.target.value.match(/^[^@#$%^&*<>'\"/;`%]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "pincode":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "lockInYear":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "rental_amount":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "noticePeriod":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        else e.target.value = e.target.value.toLocaleString();
        break;
      case "area":
        if (e.target.value.length <= 5) {
          if (!e.target.value.match(/^[0-9]*$/))
            error = { state: true, message: "Value must be Correct" };
          else e.target.value = e.target.value.toLocaleString();
        }
        break;
      case "monthlyRent":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "deposit":
        if (!e.target.value.match(/^[0-9]*$/)) error = { state: true };
        break;
      default:
        break;
    }
    if (!error.state) {
      setPreData((old) => ({ ...old, [e.target.name]: e.target.value }));
      setFormError((old) => ({ ...old, [e.target.name]: "" }));
    }
  }

  async function getBankDetails(data, i) {
    try {
      let res = await getBankName(data);

      if (res.status === 200) {
        setPreData((old) => ({
          ...old,
          landlord: old.landlord.map((row, index) => {
            if (index === i) {
              return {
                ...row,
                bankName: res.data.BANK,
                branchName: res.data.BRANCH,
              };
            } else return row;
          }),
        }));
      }
    } catch (err) {
      setPreData((old) => ({
        ...old,
        landlord: old.landlord.map((row, index) => {
          if (index === i) {
            return { ...row, bankName: "Not Found", branchName: "" };
          } else return row;
        }),
      }));
    }
  }

  const handleConfirm = () => {
    setOpen(false);

    const {
      id,
      code,
      area,
      lockInYear,
      monthlyRent,
      noticePeriod,
      yearlyIncrement,
      deposit,
      gst_certificate,
      draft_agreement,
      electricity_bill,
      poa,
      maintaince_bill,
      tax_receipt,
      noc,
      tenure,
      pincode,
      state,
      address,
      location,
      city,
      landlord,
    } = preData;

    APICall(
      {
        pincode,
        state,
        address,
        location,
        city,
        area,
        id,
        code,
        lockInYear,
        monthlyRent,
        noticePeriod,
        yearlyIncrement,
        deposit,
        gst_certificate,
        draft_agreement,
        electricity_bill,
        poa,
        maintaince_bill,
        tax_receipt,
        noc,
        tenure,
        percentage1: tenure <= 12 ? "" : yearValue.year1,
        percentage2: tenure <= 12 ? "" : yearValue.year2,
        percentage3: tenure <= 12 ? "" : yearValue.year3,
        percentage4: tenure <= 12 ? "" : yearValue.year4,
        percentage5: tenure <= 12 ? "" : yearValue.year5,
        percentage6: tenure <= 12 ? "" : yearValue.year6,
        percentage7: tenure <= 12 ? "" : yearValue.year7,
        percentage8: tenure <= 12 ? "" : yearValue.year8,
        percentage9: tenure <= 12 ? "" : yearValue.year9,
        percentage10: tenure <= 12 ? "" : yearValue.year10,
        ...increment,
        landlord,
        status:
          finance_id === 0 && buh_id !== 0
            ? "Sent To Operations"
            : "Sent To Sr Manager",

        remark: "",
      },
      landlord
    );
  };
  const [expand, setExpand] = useState(0);

  const APICall = async (values, landlordData) => {
    const agreement = await editAgreement(values);

    if (agreement.status === 200) {
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: "Agrement Edited & Submited Successfully",
        })
      );
      navigate(-1);
    }
  };

  useEffect(() => {
    if (Object.keys(formError).length === 0 && isSubmit) {
      setOpen(true);
    }
  }, [formError]);

  function validate(data) {
    let field = ["assets", "termination_remark", "file"];
    let unPaidMonthsError = [];
    unPaidMonthsError = agreementData.selectedUnpaidMonths.map((object, i) => ({
      invoice_number:
        agreementData.selectedUnpaidMonths[i]?.invoice_number == null &&
        agreementData.selectedUnpaidMonths[i]?.gstNo !== ""
          ? "Please Upload Invoice"
          : false,
    }));
    let lockedInMonthsError = [];

    lockedInMonthsError = agreementData.selectedLockInMonths.map(
      (object, i) => ({
        invoice_number:
          agreementData.selectedLockInMonths[i]?.invoice_number == null &&
          agreementData.selectedLockInMonths[i]?.gstNo !== ""
            ? "Please Upload Invoice"
            : false,
      })
    );

    let finalCheck = field.map((row) => {
      if (!agreementData[row] || agreementData[row] === '""') {
        setFormError((old) => ({
          ...old,
          [row]: "Field required.",
          unPaidMonthsError: unPaidMonthsError,
          lockedInMonthsError: lockedInMonthsError,
        }));
        return true;
      } else {
        setFormError((old) => ({
          ...old,
          [row]: "",
          unPaidMonthsError: unPaidMonthsError,
          lockedInMonthsError: lockedInMonthsError,
        }));
        return false;
      }
    });

    unPaidMonthsError.map((row, i) => {
      finalCheck.push(Object.values(row).includes("Please Upload Invoice"));
    });
    lockedInMonthsError.map((row, i) => {
      finalCheck.push(Object.values(row).includes("Please Upload Invoice"));
    });

    if (!finalCheck.includes(true)) {
      return true;
    } else return false;
  }

  function validateFields(data) {
    let field = [
      "lockInYear",
      "noticePeriod",
      "deposit",
      "monthlyRent",
      "tenure",
      "state",
      "city",
      "address",
      "pincode",
      "location",
      "area",
    ];

    let dataError = [];
    if (data.landlord && data.landlord.length > 0) {
      dataError = data.landlord.map((row, i) => ({
        aadharNo: data.landlord[i].aadharNo ? false : "Field is required.",
        panNo: data.landlord[i].panNo ? false : "Field is required.",
        mobileNo: data.landlord[i].mobileNo ? false : "Field is required.",
        email: data.landlord[i].email ? false : "Field is required.",
        ifscCode: data.landlord[i].ifscCode ? false : "Field is required.",
        bankName: data.landlord[i].bankName ? false : "Field is required.",
        accountNo: data.landlord[i].accountNo ? false : "Field is required.",
        benificiaryName: data.landlord[i].benificiaryName
          ? false
          : "Field is required.",
      }));
    }

    let finalCheck = field.map((row) => {
      if (!data[row] || data[row] === "") {
        setFormError((old) => ({
          ...old,
          [row]: "Field required.",
          landlord: dataError,
        }));
        return true;
      } else {
        setFormError((old) => ({ ...old, [row]: "", landlord: dataError }));
        return false;
      }
    });

    dataError.map((row, i) => {
      finalCheck.push(Object.values(row).includes("Field is required."));
    });

    if (!finalCheck.includes(true)) {
      return true;
    } else return false;
  }
  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [operationSubmit, setOperationSubmit] = useState(false);

  async function handleSubmitOperations() {
    let validated = validate();
    if (
      !validated ||
      formError.adjustmentAmountRemark === "Remark Required" ||
      formError.otherRemark === "Remark Required" ||
      formError.expansesRemark === "Remark Required"
    ) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Please Fill/Upload Mandatory Fields",
        })
      );
      return false;
    } else {
      setIsSubmit(true);
      setOpenPermissionModal(true);
      setOperationSubmit(true);
    }
  }

  async function handleOperationSubmit() {
    selectedUnpaidMonths.forEach((object) => {
      if (
        selectedUnpaidIds.includes(object.id) &&
        object.invoice_number == null
      ) {
        setInvoiceUpload(true);
      }
    });
    if (uploadInvoice === false) {
      delete agreementData.file_name;
      let data = {
        adjustmentAmount: recovery.adjustmentAmount,
        agreement_id: recovery.agreement_id,
        balanceDeposit: recovery.balanceDeposit,
        depositedAmount: recovery.depositedAmount,
        expansesRemark: recovery.expansesRemark,
        expenses: recovery.expenses,
        monthlyAdjustedRentalAmount: recovery.monthlyAdjustedRentalAmount,
        monthlyRent: recovery.monthlyRent,
        otherAdjustments: recovery.otherAdjustments,
        otherRemark: recovery.otherRemark,
        remainingMonth: recovery.remainingMonth,
        totalAdjustmentAmount: recovery.totalAdjustmentAmount,
        unpaid_amount: recovery.unpaid_amount,
        adjustment_document: agreementData.file,
        type: agreementData.type,
        adjustmentAmountRemark: recovery.adjustmentAmountRemark,
      };
      let response = await insertAdjustmentAmount(data);
      if (response?.status === 200) {
        const updateOpStatus = await updateMonthlyRent(
          {
            status: "Terminated By Sr Manager",
            terminate_status: "Sent To Operations For Termination",
            ...agreementData,
            selectedUnpaidIds: selectedUnpaidIds,
            selectedLockInMonths: selectedLockInIds,
            termination_remark: agreementData.termination_remark,
            additionalRemark: {
              remarks: agreementData.termination_remark,
              step_name: auth.name,
              user_id: login_manager_id,
              agreement_code: agreement[ids[0]].code,
              agreement_id: agreement[ids[0]].id,
            },
          },
          id,
          "terminate"
        );
        const updateOpAgreement = await send_to_bhu(
          {
            ...agreementData,
            selectedUnpaidIds: selectedUnpaidIds,
            selectedLockInMonths: selectedLockInIds,
            termination_remark: agreementData.termination_remark,
            additionalRemark: {
              remarks: agreementData.termination_remark,
              step_name: auth.name,
              user_id: login_manager_id,
              agreement_code: agreement[ids[0]].code,
              agreement_id: agreement[ids[0]].id,
            },
          },
          id,
          role,
          "terminate"
        );
        ////
        if (updateOpStatus.data.success && updateOpAgreement.data.success) {
          await notification_handler(
            "TR11M",
            agreement[ids[0]].id,
            agreement[ids[0]].code,
            auth.id
          );
          dispatch(
            setAlert({
              variant: "success",
              open: true,
              message: "Agreement Sent to Operations For Termination.",
            })
          );
          navigate(-1);
        } else {
          dispatch(
            setAlert({
              variant: "error",
              open: true,
              message: "Something went wrong! Please again later.",
            })
          );
        }
      }
    } else {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Please Upload invoice",
        })
      );
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let validated = validate();

    if (
      !validated ||
      formError.adjustmentAmountRemark === "Remark Required" ||
      formError.otherRemark === "Remark Required" ||
      formError.expansesRemark === "Remark Required"
    ) {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Please Fill/Upload Mandatory Fields",
        })
      );
      return false;
    } else {
      if (recovery.balanceDeposit < 0) {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: `Please Contact To Finance Team because your balance deposit receivable is ${recovery.balanceDeposit}`,
          })
        );
      } else {
        setIsSubmit(true);
        setOpenPermissionModal(true);
        setOperationSubmit(false);
      }
    }
  }

  async function handleSrManagerSubmit() {
    selectedUnpaidMonths.forEach((object) => {
      if (
        selectedUnpaidIds.includes(object.id) &&
        object.invoice_number == null
      ) {
        setInvoiceUpload(true);
      }
    });
    if (uploadInvoice === false) {
      delete agreementData.file_name;
      let data = {
        adjustmentAmount: recovery.adjustmentAmount,
        agreement_id: recovery.agreement_id,
        balanceDeposit: recovery.balanceDeposit,
        depositedAmount: recovery.depositedAmount,
        expansesRemark: recovery.expansesRemark,
        expenses: recovery.expenses,
        monthlyAdjustedRentalAmount: recovery.monthlyAdjustedRentalAmount,
        monthlyRent: recovery.monthlyRent,
        otherAdjustments: recovery.otherAdjustments,
        otherRemark: recovery.otherRemark,
        remainingMonth: recovery.remainingMonth,
        totalAdjustmentAmount: recovery.totalAdjustmentAmount,
        unpaid_amount: recovery.unpaid_amount,
        adjustment_document: agreementData.file,
        type: agreementData.type,
        adjustmentAmountRemark: recovery.adjustmentAmountRemark,
      };
      let response = await insertAdjustmentAmount(data);

      if (response?.status === 200) {
        const updateStatus = await updateMonthlyRent(
          {
            status: "Terminated By Manager",
            terminate_status: "Sent To Sr Manager For Termination",
            ...agreementData,
            selectedUnpaidIds: selectedUnpaidIds,
            selectedLockInMonths: selectedLockInIds,
            termination_remark: agreementData.termination_remark,
            additionalRemark: {
              remarks: agreementData.termination_remark,
              step_name: auth.name,
              user_id: auth.id,
              agreement_code: agreement[ids[0]].code,
              agreement_id: agreementData.id,
            },
          },
          agreementData.id,
          subType
        );
        const updateStatusAg = await send_to_bhu(
          {
            status: "Terminated By Manager",
            terminate_status: "Sent To Sr Manager For Termination",
            ...agreementData,
            selectedUnpaidIds: selectedUnpaidIds,
            selectedLockInMonths: selectedLockInIds,
            termination_remark: agreementData.termination_remark,
            additionalRemark: {
              remarks: agreementData.termination_remark,
              step_name: auth.name,
              user_id: auth.id,
              agreement_code: agreement[ids[0]].code,
              agreement_id: agreementData.id,
            },
          },
          agreementData.id,
          role,
          subType
        );
        if (updateStatus.data.success && updateStatusAg.data.success) {
          const notification = await notification_handler(
            "TR3",
            preData.id,
            preData.code,
            auth.id
          );
          dispatch(
            setAlert({
              variant: "success",
              open: true,
              message:
                response.data.message || "Sent To Sr Manager For Termination",
            })
          );
        }
        navigate(-1);
      } else {
        dispatch(
          setAlert({
            variant: "error",
            open: true,
            message: "Something went wrong !!!",
          })
        );
      }
    } else {
      dispatch(
        setAlert({
          variant: "error",
          open: true,
          message: "Please Upload invoice",
        })
      );
    }
  }

  const handleCancel = () => {
    setOpenPermissionModal(false);
  };

  async function handleStateSearch(e, i) {
    if (e.target.value.length > 4) {
      let response = await getLocation(e.target.value);
      if (response.data[0].PostOffice) {
        let address = response.data[0].PostOffice[0];
        return setPreData((old) => ({
          ...old,
          state: address.State,
          city: address.District,
        }));
      } else {
        return setPreData((old) => ({ ...old, state: "", city: "" }));
      }
    }
  }
  function checksum(g) {
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(
      g
    );
    if (regTest) {
      console.log(checkGridRowIdIsValid);
    }

    return regTest;
  }

  function handleOnBlur(e, i) {
    let error = { state: false, message: null };

    switch (e.target.name) {
      case "leeseName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "pincode":
        if (!e.target.value.match(/^.{0,6}$/))
          error = { state: true, message: "Value must be Correct" };
        if (e.target.value.length < 6 && e.target.value.length > 0)
          error = { ...error, message: "Pincode number must be of 6 digit." };
        else error = { ...error, message: null };
        break;
      case "aadharNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 12 && e.target.value.length > 0)
          error = { ...error, message: "Aadhaar number must be of 12 digit." };
        else error = { ...error, message: null };
        break;
      case "mobileNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "alternateMobile":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length < 10 && e.target.value.length > 0)
          error = { ...error, message: "Phone number must be of 10 digit." };
        if (e.target.value.match(/^[0-5]/))
          error = { ...error, message: "Incorrect Phone Number" };
        else error = { ...error, message: null };
        break;
      case "bankName":
        if (!e.target.value === "Not Found")
          error = { state: true, message: "Value must be Correct" };
        break;
      case "benificiaryName":
        if (!e.target.value.match(/^[a-zA-Z ]*$/))
          error = { state: true, message: "Value must be Correct" };
        break;
      case "accountNo":
        if (!e.target.value.match(/^[0-9]*$/))
          error = { ...error, state: true };
        if (e.target.value.length > 17 && e.target.value.length > 0)
          error = { ...error, message: "Account can be of 17 digit only." };
        else error = { ...error, message: null };
        break;

      case "email":
        if (
          !e.target.value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "Email should be like example@gmail.com.",
          };
        else error = { ...error, message: null };
        break;
      case "panNo":
        e.target.value = e.target.value.toUpperCase();
        if (
          !e.target.value.match(/^.{0,10}$/) &&
          !e.target.value.match(/^[^@#$%^&*<>'\"/;`%]*$/)
        )
          error = { state: true, message: null };
        if (
          !e.target.value.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/) &&
          e.target.value.length > 0
        )
          error = {
            ...error,
            message: "PAN number should be like NOOPS8327k.",
          };
        else error = { ...error, message: null };
        break;
      case "gstNo":
        e.target.value = e.target.value.toUpperCase();
        if (!e.target.value.match(/^.{0,15}$/))
          error = { state: true, message: null };
        // pattern match
        if (!checksum(e.target.value) && e.target.value.length > 0)
          error = { ...error, message: "GST should be like 18AABCU9603R1ZM." };
        else error = { ...error, message: null };
        break;
      case "ifscCode":
        if (
          !e.target.value.match(/^[A-Z]{4}0[A-Z0-9]{6}$/) &&
          e.target.value !== ""
        )
          error = { ...error, message: "Incorrect IFSC" };
        else error = { ...error, message: null };

        break;
      default:
        break;
    }

    if (error.message) {
      if (formError.landlord[i]) {
        setFormError((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: error.message,
              };
            }
            return row;
          }),
        }));
      } else {
        setFormError((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: error.message,
            },
          ],
        }));
      }
    } else {
      if (formError.landlord[i]) {
        setFormError((old) => ({
          ...old,
          landlord: old.landlord.map((row, id) => {
            if (i === id) {
              return {
                ...row,
                [e.target.name]: "",
              };
            }
            return row;
          }),
        }));
      } else {
        setFormError((old) => ({
          ...old,
          landlord: [
            ...old.landlord,
            {
              [e.target.name]: "",
            },
          ],
        }));
      }
    }
  }

  async function getBankeDetails(data, i) {
    let res = await getBankName(data);
    if (res) {
      setPreData((old) => ({
        ...old,
        landlord: old.landlord.map((row, index) => {
          if (index === i) {
            return { ...row, bankName: res.data.BANK };
          } else return row;
        }),
      }));
    }
  }

  function handleHold() {
    const {
      id,
      area,
      code,
      lockInYear,
      monthlyRent,
      noticePeriod,
      yearlyIncrement,
      deposit,
      gst_certificate,
      draft_agreement,
      electricity_bill,
      poa,
      maintaince_bill,
      tax_receipt,
      noc,
      tenure,
      pincode,
      state,
      address,
      location,
      city,
      landlord,
    } = preData;

    if (validate(preData) && validateFields(preData)) {
      APICall(
        {
          pincode,
          state,
          address,
          area,
          location,
          city,
          id,
          code,
          lockInYear,
          monthlyRent,
          noticePeriod,
          yearlyIncrement,
          deposit,
          gst_certificate,
          draft_agreement,
          electricity_bill,
          poa,
          maintaince_bill,
          tax_receipt,
          noc,
          tenure,
          percentage1: tenure <= 12 ? "" : yearValue.year1,
          percentage2: tenure <= 12 ? "" : yearValue.year2,
          percentage3: tenure <= 12 ? "" : yearValue.year3,
          percentage4: tenure <= 12 ? "" : yearValue.year4,
          percentage5: tenure <= 12 ? "" : yearValue.year5,
          percentage6: tenure <= 12 ? "" : yearValue.year6,
          percentage7: tenure <= 12 ? "" : yearValue.year7,
          percentage8: tenure <= 12 ? "" : yearValue.year8,
          percentage9: tenure <= 12 ? "" : yearValue.year9,
          percentage10: tenure <= 12 ? "" : yearValue.year10,
          ...increment,
          landlord,
          status: "Draft",
          remark: "",
        },
        landlord
      );
    }
  }

  function Docview(href, name) {
    return (
      <ImageView open={true} handleClose={() => {}} href={href} name={name} />
    );
  }

  return (
    <>
      <PermissionAlert
        handleClose={handleCancel}
        handleConfirm={
          operationSubmit ? handleOperationSubmit : handleSrManagerSubmit
        }
        open={openPermissionModal}
        message={"Please check agreement carefully before submission."}
      />
      <Stack sx={{ flexWrap: "nowrap", flexDirection: "row" }}>
        <HamburgerManager />
        <Box sx={{ flexGrow: 1 }}>
          <>
            <Grid container sx={{ justifyContent: "center", mt: 2 }}>
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  sx={{ justifyContent: "space-between", display: "flex" }}
                >
                  <MyHeader>
                    RMS{" "}
                    <span className="small-heading">
                      (Rental Management System)
                    </span>
                  </MyHeader>
                  <Typography mt="15px" mr="15px" fontWeight="600">
                    Welcome {auth.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
          <Divider />
          <Grid
            container
            sx={{
              px: 1,
              justifyContent: "space-between",
              my: 1,
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <Typography
                variant="body1"
                color="black"
                fontSize="20px"
                fontWeight="600"
                alignSelf="center"
                lineHeight="30px"
                sx={{
                  "@media(max-width:600px)": {
                    fontSize: "17px",
                    lineHeight: "25px",
                  },
                }}
              >
                {"Termination-Deposit Adjustments Form"}
              </Typography>
            </Grid>
          </Grid>
          <Divider />

          <Box className="backButton">
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              size={"large"}
            >
              <ArrowCircleLeftIcon
                sx={{ fontSize: "3rem" }}
                color="#FFFFF !important"
              />
            </IconButton>
          </Box>

          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={10}>
              <Box
                component="form"
                sx={{
                  py: 5,
                  backgroundColor: "white",
                  mx: 3,
                  borderRadius: "15px",
                  maxWidth: "1050px",
                  "@media(max-width:900px)": { mx: 0 },
                }}
              >
                {/* Basic details start here */}
                <Typography
                  variant="body1"
                  color="var(--main-color)"
                  fontSize="25px"
                  lineHeight="28px"
                  fontWeight="600"
                  my="10px"
                >
                  Property Details
                </Typography>

                <Grid container sx={{ px: 3 }} spacing={isSmall ? 2 : 4}>
                  <TextFieldWrapper
                    label="Code"
                    placeHolder=""
                    disabled={true}
                    backgroundColor="rgba(3, 193, 243, 0.2);"
                    value={preData.code}
                    name="code"
                  />

                  <TextFieldWrapper
                    label="Pincode"
                    placeHolder="Pincode"
                    backgroundColor="rgba(3, 193, 243, 0.2);"
                    value={preData.pincode}
                    disabled={true}
                    required={true}
                    maxLength={6}
                    name="pincode"
                    onChange={(e) => {
                      handleCommonChange(e);
                      handleStateSearch(e);
                    }}
                    error={formError.pincode}
                  />

                  <TextFieldWrapper
                    label="State"
                    disabled={true}
                    name="state"
                    required={true}
                    maxLength={6}
                    value={preData.state || ""}
                    error={formError.state}
                  />
                  <Grid
                    item
                    md={4}
                    xs={6}
                    sx={{
                      mb: "0px !important",
                      "@media(max-width:900px)": { my: 1 },
                    }}
                  >
                    <FormControl fullWidth className="textFieldWrapper">
                      <TextField
                        label="City"
                        required={true}
                        disabled={true}
                        error={formError.city}
                        fullWidth
                        name="city"
                        value={preData.city || ""}
                      />
                    </FormControl>
                  </Grid>

                  <TextFieldWrapper
                    disabled={true}
                    label="Location"
                    placeHolder="Enter Location"
                    name="location"
                    error={formError.location}
                    value={preData.location || ""}
                    onChange={handleCommonChange}
                    index={i}
                  />

                  <TextFieldWrapper
                    label="Area"
                    placeHolder="Area in sq. ft"
                    name="area"
                    maxLength={5}
                    notationVal="sq. ft"
                    textAlignRight={"textAlignRight"}
                    disabled={true}
                    error={formError.area}
                    required={true}
                    value={preData.area}
                    onChange={handleCommonChange}
                    index={i}
                  />

                  <TextFieldWrapper
                    label="Address"
                    placeHolder="Enter Address"
                    disabled={true}
                    error={formError.address}
                    required={true}
                    name="address"
                    value={preData.address}
                    onChange={handleCommonChange}
                    index={i}
                  />
                  <TextFieldWrapper
                    label="Deposit Amount"
                    placeHolder="Enter deposit Amount"
                    disabled={true}
                    name="deposit"
                    textAlignRight={"textAlignRight"}
                    error={formError.deposit}
                    value={preData.deposit}
                    onChange={handleCommonChange}
                  />
                  <TextFieldWrapper
                    label="Monthly Rental"
                    placeHolder="Enter Rental"
                    required={true}
                    name="monthlyRent"
                    textAlignRight={"textAlignRight"}
                    disabled={true}
                    error={formError.monthlyRent}
                    value={preData.monthlyRent}
                    onChange={handleCommonChange}
                  />
                  <TextFieldWrapper
                    label="Tenure"
                    placeHolder="Enter Name of tenure"
                    required={true}
                    disabled={true}
                    onBlur={(e) => handleOnBlur(e, i)}
                    name="name"
                    value={preData.tenure || ""}
                  />
                  <TextFieldWrapper
                    label="Lock In Month(If Applicable)"
                    disabled={true}
                    placeHolder="Enter Lock in Month"
                    name="lockInYear"
                    maxLength={2}
                    value={preData.lockInYear}
                    error={formError.lockInYear}
                    onChange={handleCommonChange}
                  />
                  <TextFieldWrapper
                    label="Notice Period In Month"
                    placeHolder="Enter Notice Period"
                    disabled={true}
                    error={formError.noticePeriod}
                    name="noticePeriod"
                    maxLength={2}
                    value={preData.noticePeriod}
                    onChange={handleCommonChange}
                  />
                  {preData.tenure === "" ? null : preData.tenure <=
                    12 ? null : preData.tenure === "11 Month" ? null : (
                    <SelectComponent
                      label={"Yearly Increment"}
                      disabled={true}
                      required={true}
                      name="yearlyIncrement"
                      options={["Percentage", "Value"]}
                      value={preData.yearlyIncrement}
                      onChange={handleCommonChange}
                    />
                  )}
                </Grid>

                {/* basic details end here */}

                {/* Increment Yearly */}
                <YearlyIncrement
                  yearValue={yearValue}
                  setYearValue={setYearValue}
                  tenure={preData.tenure || ""}
                  value={preData.yearlyIncrement || ""}
                  rent={preData.monthlyRent || ""}
                  increment={increment}
                  setIncrement={setIncrement}
                  monthlyRent={preData.monthlyRent || ""}
                  disabled={true}
                />

                {/* landlord Details start here*/}
                <Typography
                  variant="body1"
                  color="var(--main-color)"
                  fontSize="25px"
                  lineHeight="28px"
                  fontWeight="600"
                  my="20px"
                >
                  Landlord Details
                </Typography>

                {preData.landlord.length > 0 &&
                  preData.landlord.map((_, i) => (
                    <>
                      {preData.landlord.length > 0 && (
                        <Box
                          mb={2}
                          size="small"
                          fullWidth
                          variant="outlined"
                          component={Button}
                          onClick={() => setExpand(expand === i ? -1 : i)}
                          sx={{
                            color: "black",
                            justifyContent: "space-between",
                            backgroundColor: "#b0d6f773",
                          }}
                        >
                          <Typography color={"var( --main-color)"}>
                            {" "}
                            {preData.landlord.length > 0
                              ? preData.landlord[i].leeseName
                              : ""}{" "}
                            Personal Details
                          </Typography>
                          <IconButton
                            onClick={() => setExpand(expand === i ? -1 : i)}
                          >
                            {expand === i ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </Box>
                      )}
                      <Collapse in={expand === i} timeout="auto" unmountOnExit>
                        <Grid
                          container
                          sx={{ px: 3, mb: "25px" }}
                          spacing={isSmall ? 2 : 4}
                        >
                          <TextFieldWrapper
                            label="Name of Lessor"
                            placeHolder="Enter Name of Lessor"
                            required={true}
                            disabled={true}
                            onBlur={(e) => handleOnBlur(e, i)}
                            name="name"
                            value={preData.landlord[i].name}
                            onChange={(e) => handleChange(e, i)}
                          />
                          <TextFieldWrapper
                            label="Aadhar Number"
                            placeHolder="Enter Aadhar No."
                            required={true}
                            onBlur={(e) => handleOnBlur(e, i)}
                            name="aadharNo"
                            maxLength={12}
                            value={preData.landlord[i].aadharNo}
                            onChange={(e) => handleChange(e, i)}
                            disabled={true}
                            index={i}
                            error={
                              formError.landlord[i] &&
                              formError.landlord[i].aadharNo
                                ? formError.landlord[i].aadharNo
                                : ""
                            }
                          />
                          <TextFieldWrapper
                            disabled={true}
                            label="PAN Number"
                            placeHolder="Enter PAN No."
                            onBlur={(e) => handleOnBlur(e, i)}
                            error={
                              formError.landlord[i] &&
                              formError.landlord[i].panNo
                                ? formError.landlord[i].panNo
                                : ""
                            }
                            name="panNo"
                            maxLength={10}
                            value={preData.landlord[i].panNo}
                            onChange={(e) => handleChange(e, i)}
                            index={i}
                          />

                          <TextFieldWrapper
                            label="Mobile Number"
                            placeHolder="Enter Mobile No."
                            disabled={true}
                            required={true}
                            onBlur={(e) => handleOnBlur(e, i)}
                            name="mobileNo"
                            maxLength={10}
                            error={
                              formError.landlord[i] &&
                              formError.landlord[i].mobileNo
                                ? formError.landlord[i].mobileNo
                                : ""
                            }
                            value={preData.landlord[i].mobileNo}
                            onChange={(e) => handleChange(e, i)}
                            index={i}
                          />
                          <TextFieldWrapper
                            label="Alternate Number"
                            // error={formError.alternteMo}
                            placeHolder="Enter Alternate No."
                            disabled={true}
                            name="alternateMobile"
                            onBlur={(e) => handleOnBlur(e, i)}
                            maxLength={10}
                            value={preData.landlord[i].alternateMobile}
                            // error={formError.alternateMobile}
                            onChange={(e) => handleChange(e, i)}
                            index={i}
                          />

                          <TextFieldWrapper
                            label="Email"
                            placeHolder="Enter Email"
                            onBlur={(e) => handleOnBlur(e, i)}
                            required={true}
                            error={
                              formError.landlord[i] &&
                              formError.landlord[i].email
                                ? formError.landlord[i].email
                                : ""
                            }
                            disabled={true}
                            name="email"
                            value={preData.landlord[i].email}
                            onChange={(e) => handleChange(e, i)}
                            index={i}
                          />
                          <TextFieldWrapper
                            label="GST Number"
                            placeHolder="Enter GST No."
                            onBlur={(e) => handleOnBlur(e, i)}
                            error={
                              formError.landlord[i] &&
                              formError.landlord[i].gstNo
                                ? formError.landlord[i].gstNo
                                : ""
                            }
                            disabled={true}
                            name="gstNo"
                            maxLength={15}
                            value={preData.landlord[i].gstNo}
                            onChange={(e) => handleChange(e, i)}
                          />
                          <TextFieldWrapper
                            required={true}
                            label="Bank IFSC"
                            placeHolder="Enter IFSC Code"
                            onBlur={(e) => handleOnBlur(e, i)}
                            disabled={true}
                            name="ifscCode"
                            error={
                              formError.landlord[i] &&
                              formError.landlord[i].ifscCode
                                ? formError.landlord[i].ifscCode
                                : ""
                            }
                            value={preData.landlord[i].ifscCode}
                            onChange={(e) => handleChange(e, i)}
                          />

                          <TextFieldWrapper
                            label="Bank Name"
                            placeHolder="Enter Bank Name"
                            name="bankName"
                            onBlur={(e) => handleOnBlur(e, i)}
                            partLabel={
                              preData.landlord[i] &&
                              preData.landlord[i].branchName
                                ? preData.landlord[i].branchName
                                : ""
                            }
                            error={
                              formError.landlord[i] &&
                              formError.landlord[i].bankName
                                ? formError.landlord[i].bankName
                                : ""
                            }
                            required={true}
                            disabled={true}
                            value={preData.landlord[i].bankName}
                            onChange={(e) => handleChange(e, i)}
                          />

                          <TextFieldWrapper
                            required={true}
                            label="Beneficiary Name"
                            disabled={true}
                            onBlur={(e) => handleOnBlur(e, i)}
                            error={
                              formError.landlord[i] &&
                              formError.landlord[i].benificiaryName
                                ? formError.landlord[i].benificiaryName
                                : ""
                            }
                            placeHolder="Enter Beneficiary Name"
                            name="benificiaryName"
                            value={preData.landlord[i].benificiaryName}
                            onChange={(e) => handleChange(e, i)}
                          />
                          <TextFieldWrapper
                            label="Bank A/c Number "
                            disabled={true}
                            required={true}
                            placeHolder="Enter Account No."
                            name="accountNo"
                            error={
                              formError.landlord[i] &&
                              formError.landlord[i].accountNo
                                ? formError.landlord[i].accountNo
                                : ""
                            }
                            value={preData.landlord[i].accountNo}
                            onChange={(e) => handleChange(e, i)}
                          />
                        </Grid>
                      </Collapse>
                    </>
                  ))}

                {/* Bank Details ends here*/}

                {/* Document upload section start here */}

                {/* Document */}
                <Typography
                  variant="body1"
                  color="var(--main-color)"
                  fontSize="25px"
                  lineHeight="28px"
                  fontWeight="600"
                  my="20px"
                >
                  Upload Document
                </Typography>

                {preData.landlord.length > 0 &&
                  formError.landlord.length > 0 &&
                  preData.landlord.map((_, i) => (
                    <>
                      {preData.landlord.length > 0 && (
                        <Box
                          mb={2}
                          size="small"
                          fullWidth
                          variant="outlined"
                          component={Button}
                          onClick={() => setDocExpand(docExpand === i ? -1 : i)}
                          sx={{
                            color: "black",
                            justifyContent: "space-between",
                            backgroundColor: "#b0d6f773",
                          }}
                        >
                          <Typography color={"var( --main-color)"}>
                            {" "}
                            {preData.landlord.length > 0
                              ? preData.landlord[i].leeseName
                              : ""}{" "}
                            Upload Document
                          </Typography>
                          <IconButton
                            onClick={() =>
                              setDocExpand(docExpand === i ? -1 : i)
                            }
                          >
                            {docExpand === i ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </Box>
                      )}
                      <Collapse
                        in={docExpand === i}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Grid
                          container
                          spacing={isSmall ? 2 : 4}
                          sx={{ px: 1, justifyContent: "", mb: 3 }}
                        >
                          <Grid item xs={6}>
                            <DocumentUpload
                              uploaded={
                                preData[`aadhar_card${i}`] ||
                                preData.landlord[i]["aadhar_card"]
                                  ? true
                                  : false
                              }
                              label="Upload Aadhaar Card"
                              placeHolder="Upload Aadhaar Card"
                              handleChange={(e) => handleChangeCommonFile(e, i)}
                              name={"aadhar_card"}
                              fileName={preData[`aadhar_card${i}`]}
                              href={preData.landlord[i].aadhar_card}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DocumentUpload
                              label="Upload PAN Card"
                              uploaded={
                                preData[`pan_card${i}`] ||
                                preData.landlord[i]["pan_card"]
                                  ? true
                                  : false
                              }
                              name={"pan_card"}
                              fileName={preData[`pan_card${i}`]}
                              placeHolder={"Upload PAN Card"}
                              handleChange={(e) => handleChangeCommonFile(e, i)}
                              href={preData.landlord[i].pan_card}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DocumentUpload
                              uploaded={
                                preData[`gst${i}`] || preData.landlord[i]["gst"]
                                  ? true
                                  : false
                              }
                              label="Upload GST Certificate"
                              placeHolder="Upload GST Certificate"
                              handleChange={(e) => handleChangeCommonFile(e, i)}
                              name={"gst"}
                              fileName={preData[`gst${i}`]}
                              href={preData.landlord[i].gst}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DocumentUpload
                              uploaded={
                                preData[`cheque${i}`] ||
                                preData.landlord[i]["cheque"]
                                  ? true
                                  : false
                              }
                              label="Upload Cancel Cheque"
                              name={"cheque"}
                              fileName={preData[`cheque${i}`]}
                              placeHolder="Upload Cancel Cheque"
                              handleChange={(e) => handleChangeCommonFile(e, i)}
                              href={preData.landlord[i].cheque}
                            />
                          </Grid>
                        </Grid>
                      </Collapse>
                    </>
                  ))}

                {landblord.length > 0 ? (
                  <Typography
                    variant="body1"
                    color="var(--main-color)"
                    fontSize="25px"
                    lineHeight="28px"
                    fontWeight="600"
                    my="20px"
                  >
                    Upload Document
                  </Typography>
                ) : (
                  ""
                )}

                <Grid
                  container
                  spacing={isSmall ? 2 : 4}
                  sx={{ px: 1, justifyContent: "" }}
                >
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload Draft Agreement"
                      uploaded={preData.draft_agreement && true}
                      error={formError.draft_agreement}
                      placeHolder="Upload Draft Agreement"
                      fileName={preData.draft_agreement_name}
                      handleChange={handleChangeFile}
                      name={"draft_agreement"}
                      href={preData.draft_agreement}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload Electricity Bill"
                      uploaded={preData.electricity_bill && true}
                      error={formError.electricatiy_bill}
                      placeHolder={"Upload Electricity Bill"}
                      handleChange={handleChangeFile}
                      fileName={preData.electricity_bill_name}
                      name={"electricity_bill"}
                      href={preData.electricity_bill}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload POA"
                      placeHolder="Upload POA"
                      error={formError.poa}
                      uploaded={preData.poa && true}
                      fileName={preData.poa_name}
                      handleChange={handleChangeFile}
                      name={"poa"}
                      href={preData.poa}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DocumentUpload
                      label="Upload Maintenance Bill"
                      uploaded={preData.maintaince_bill && true}
                      placeHolder={"Upload Maintaince Bill"}
                      error={formError.maintaince_bill}
                      handleChange={handleChangeFile}
                      fileName={preData.maintaince_bill_name}
                      name={"maintaince_bill"}
                      href={preData.maintaince_bill}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DocumentUpload
                      disabled
                      label="Upload Property Tax Receipt"
                      uploaded={preData.tax_receipt && true}
                      placeHolder={"Upload Property Tax Receipt"}
                      handleChange={handleChangeFile}
                      fileName={preData.tax_receipt_name}
                      error={formError.tax_receipt}
                      name={"tax_receipt"}
                      href={preData.tax_receipt}
                    />
                  </Grid>
                  {preData.landlord.length > 1 && (
                    <Grid item xs={6}>
                      <DocumentUpload
                        uploaded={preData.noc && true}
                        label="Upload NOC (If Mutiple Oweners)"
                        error={formError.noc}
                        placeHolder="NOC"
                        fileName={preData.noc_name}
                        handleChange={handleChangeFile}
                        name={"noc"}
                        href={preData.noc}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  sx={{ mt: "25px", mb: "25px" }}
                  spacing={isSmall ? 2 : 4}
                  component={"form"}
                  onSubmit={handleSubmit}
                  method="post"
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      color="var(--main-color)"
                      fontSize="25px"
                      lineHeight="28px"
                      fontWeight="600"
                    >
                      Deposit Adjustments Form
                    </Typography>
                  </Grid>

                  {/* unpaid section */}
                  {upaid.length > 0 && (
                    <Grid mt={1} mb={1} item>
                      <Grid
                        coantiner
                        sx={{
                          display: "flex",
                          gap: "2rem",
                          flexDirection: "column",
                        }}
                      >
                        <Grid item>
                          <Typography color={"primary"}>
                            Unpaid Months
                          </Typography>
                        </Grid>
                        {upaid.map((row) => (
                          <Grid item sx={{ display: "flex" }}>
                            <Checkbox
                              onChange={(e) => selectedUnpaidMonthsFunc(e, row)}
                              name={row.id}
                              checked={selectedUnpaidIds.includes(row.id)}
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label={"Name"}
                              disabled={true}
                              value={row.name}
                              onChange={(e) => handleChange(e)}
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label={"Rent Month (Unpaid)"}
                              placeHolder="Deposit Amount"
                              disabled={true}
                              value={
                                month[new Date(row.rent_date).getMonth()] +
                                "-" +
                                new Date(row.rent_date).getFullYear()
                              }
                              onChange={(e) => handleChange(e)}
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label="Rent Amount"
                              disabled={true}
                              value={Math.abs(row.rent_amount).toFixed(0)}
                              onChange={(e) => handleChange(e)}
                            />
                            <TextFieldWrapper
                              marginRight={true}
                              label="Status"
                              disabled={true}
                              value={row.status}
                              onChange={(e) => handleChange(e)}
                            />
                            {row.gstNo ? (
                              <>
                                {row.invoice_number == null && (
                                  <Grid item sx={{ textAlign: "center" }}>
                                    {
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        style={{
                                          backgroundColor:
                                            row.invoice_number != null ||
                                            !selectedUnpaidIds.includes(row.id)
                                              ? "rgb(103 185 68 / 30%)"
                                              : "rgb(103 185 68 / 89%)",
                                          color: "white",
                                          fontSize: "12px",
                                          textTransform: "capitalize",
                                          minHeight: "37px",
                                          margin: "10px",
                                          minWidth: "100px",
                                        }}
                                        disabled={
                                          row.invoice_number != null ||
                                          !selectedUnpaidIds.includes(row.id)
                                        }
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setSelectID(row.id);
                                          setOpen(true);
                                          setInvoiceDetails({
                                            ...invoiceDetails,
                                            rentAmount: row.rent_amount,
                                            gstAmount:
                                              row?.gstNo !==
                                                (null || undefined) &&
                                              row?.gstNo.length > 0
                                                ? Number(
                                                    parseInt(
                                                      row?.rent_amount * 0.18
                                                    ).toFixed(2)
                                                  )
                                                : 0,
                                            totalAmount:
                                              Number(row?.rent_amount) +
                                              Number(
                                                parseInt(
                                                  row?.rent_amount * 0.18
                                                ).toFixed(2)
                                              ),
                                            user_id: auth.id,
                                            agreement_id:
                                              agreement?.[ids[0]]
                                                ?.agreement_id[0],
                                            code: agreement?.[ids[0]]?.code,
                                          });
                                          setRowDataForUpload(row);
                                          setMonthlyId(row.id);
                                          setInvoiceEdited(false);
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "14px" }}>
                                          {"Upload"}
                                        </Typography>
                                      </Button>
                                    }
                                    <Typography
                                      variant="caption"
                                      sx={{ color: "red", fontSize: "9px" }}
                                    >
                                      {selectedUnpaidIds.includes(row.id) &&
                                      row.invoice_number == null
                                        ? "Please Upload Invoice"
                                        : ""}
                                    </Typography>
                                  </Grid>
                                )}
                                <Grid item>
                                  {row.invoice_number != null && (
                                    <Button
                                      variant="contained"
                                      size="small"
                                      style={{
                                        backgroundColor: "grey",
                                        color: "white",
                                        fontSize: "12px",
                                        textTransform: "capitalize",
                                        minHeight: "37px",
                                        margin: "10px",
                                        minWidth: "100px",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation(); // don't select this row after clicking
                                        setSelectID(id);
                                        setOpen(true);
                                        setCode(row.code);
                                        setMonthlyId(row.id);
                                        setInvoiceEdited(true);
                                      }}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                </Grid>
                              </>
                            ) : (
                              <Grid item>
                                <Button
                                  style={{
                                    display: "block",
                                    minHeight: "37px",
                                    margin: "10px",
                                    minWidth: "100px",
                                    background: "transparent",
                                  }}
                                ></Button>
                              </Grid>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}

                  {/* unpaid section ends */}
                  {finalLockedInData.length > 0 && (
                    <Grid mt={1} mb={1} item>
                      <Grid
                        coantiner
                        sx={{
                          display: "flex",
                          gap: "2rem",
                          flexDirection: "column",
                        }}
                      >
                        <Grid item>
                          <Typography color={"primary"}>
                            Lock In Months
                          </Typography>
                        </Grid>
                        {finalLockedInData.map((row) => (
                          <>
                            <Grid item sx={{ display: "flex" }}>
                              <Checkbox
                                onChange={(e) => {
                                  selectedLockinMonthsFunc(e, row);
                                }}
                                name={row.id}
                                checked={selectedLockInIds.includes(row.id)}
                              />

                              <TextFieldWrapper
                                marginRight={true}
                                label={"Name"}
                                disabled={true}
                                value={row.name}
                                onChange={(e) => handleChange(e)}
                              />
                              <TextFieldWrapper
                                marginRight={true}
                                label={"Rent Month (Locked In Month)"}
                                placeHolder="Deposit Amount"
                                disabled={true}
                                value={
                                  month[new Date(row.rent_date).getUTCMonth()] +
                                  "-" +
                                  new Date(row.rent_date).getFullYear()
                                }
                                onChange={(e) => handleChange(e)}
                              />
                              <TextFieldWrapper
                                marginRight={true}
                                label="Rent Amount"
                                disabled={true}
                                value={Math.abs(row.rent_amount).toFixed(0)}
                                onChange={(e) => handleChange(e)}
                              />
                              <TextFieldWrapper
                                marginRight={true}
                                label="Status"
                                disabled={true}
                                value={row.status}
                                onChange={(e) => handleChange(e)}
                              />
                              {row.gstNo ? (
                                <>
                                  <Grid item sx={{ textAlign: "center" }}>
                                    {row.invoice_number == null && (
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        disabled={
                                          row.invoice_number != null ||
                                          !selectedLockInIds.includes(row.id)
                                        }
                                        style={{
                                          backgroundColor:
                                            row.invoice_number != null ||
                                            !selectedLockInIds.includes(row.id)
                                              ? "rgb(103 185 68 / 30%)"
                                              : "rgb(103 185 68 / 89%)",
                                          color: "white",
                                          fontSize: "12px",
                                          textTransform: "capitalize",
                                          minHeight: "37px",
                                          margin: "10px",
                                          minWidth: "100px",
                                        }}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setSelectID(row.id);
                                          setOpen(true);
                                          setInvoiceDetails({
                                            ...invoiceDetails,
                                            rentAmount: row.rent_amount,
                                            gstAmount:
                                              row?.gstNo !==
                                                (null || undefined) &&
                                              row?.gstNo.length > 0
                                                ? Number(
                                                    parseInt(
                                                      row?.rent_amount * 0.18
                                                    ).toFixed(2)
                                                  )
                                                : 0,
                                            totalAmount:
                                              Number(row?.rent_amount) +
                                              Number(
                                                parseInt(
                                                  row?.rent_amount * 0.18
                                                ).toFixed(2)
                                              ),
                                            user_id: auth.id,
                                            agreement_id:
                                              agreement?.[ids[0]]
                                                ?.agreement_id[0],
                                            code: agreement?.[ids[0]]?.code,
                                          });
                                          setRowDataForUpload(row);
                                          setMonthlyId(row.id);
                                          setInvoiceEdited(false);
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "14px" }}>
                                          {"Upload"}
                                        </Typography>
                                      </Button>
                                    )}
                                    <Typography
                                      variant="caption"
                                      sx={{ color: "red", fontSize: "9px" }}
                                    >
                                      {selectedLockInIds.includes(row.id) &&
                                      row.invoice_number == null
                                        ? "Please Upload Invoice"
                                        : ""}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    {row.invoice_number != null && (
                                      <Button
                                        variant="contained"
                                        size="small"
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          fontSize: "12px",
                                          textTransform: "capitalize",
                                          minHeight: "37px",
                                          margin: "10px",
                                          minWidth: "100px",
                                        }}
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          setSelectID(id);
                                          setOpen(true);
                                          setCode(row.code);
                                          setMonthlyId(row.id);
                                          setInvoiceEdited(true);
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    )}
                                  </Grid>
                                </>
                              ) : (
                                <Grid item>
                                  <Button
                                    style={{
                                      display: "block",
                                      minHeight: "37px",
                                      margin: "10px",
                                      minWidth: "100px",
                                      background: "transparent",
                                    }}
                                  ></Button>
                                </Grid>
                              )}
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} container>
                    <TextFieldWrapper
                      label="Deposit Amount (Paid)"
                      placeHolder="Deposit Amount"
                      name="depositedAmount"
                      disabled={true}
                      value={recovery.depositedAmount}
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} container spacing={3}>
                    <Grid item xs={12}>
                      <Typography color={"var( --main-color)"}>
                        {"Adjustment Amount"}
                      </Typography>
                    </Grid>
                    <TextFieldWrapper
                      label="Number of Months"
                      placeHolder="Number of Months"
                      error={formError.remainingMonth}
                      name="remainingMonth"
                      value={recovery.remainingMonth}
                      onChange={(e) => handleChange(e)}
                    />

                    <TextFieldWrapper
                      label="Adjustment Amount"
                      placeHolder="Adjustment Amount"
                      name="adjustmentAmount"
                      value={recovery.adjustmentAmount}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextFieldWrapper
                      label="Remark"
                      placeHolder="Remark"
                      error={formError.adjustmentAmountRemark}
                      name="adjustmentAmountRemark"
                      value={recovery.adjustmentAmountRemark}
                      onChange={(e) => handleRemarkChange(e)}
                    />
                  </Grid>
                  <UploadInvoice
                    open={open}
                    handleClose={() => setOpen(false)}
                    handleConfirm={uploadInvoiceDetails}
                    value={invoiceDetails}
                    rowDataForUpload={rowDataForUpload}
                    setRowDataForUpload={setRowDataForUpload}
                    setValue={setInvoiceDetails}
                    code={code}
                    monthlyId={monthlyId}
                  />
                  <Grid item xs={12} container spacing={2}>
                    <TextFieldWrapper
                      label="Monthly adjusted rental amount"
                      placeHolder="Monthly adjusted rental amount"
                      name="monthlyAdjustedRentalAmount"
                      value={recovery.monthlyAdjustedRentalAmount}
                      onChange={(e) => handleChange(e)}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={12} container spacing={2}>
                    <TextFieldWrapper
                      label="Adjust Towards Expenses"
                      placeHolder="Adjustment Amount"
                      name="expenses"
                      value={recovery.expenses}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextFieldWrapper
                      label="Remark"
                      placeHolder="Remark"
                      error={formError.expansesRemark}
                      name="expansesRemark"
                      value={recovery.expansesRemark}
                      onChange={(e) => handleRemarkChange(e)}
                    />
                  </Grid>

                  <Grid item xs={12} container spacing={2}>
                    <TextFieldWrapper
                      label="Other Adjustments"
                      placeHolder="Other Adjustments"
                      name="otherAdjustments"
                      value={recovery.otherAdjustments}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextFieldWrapper
                      label="Remark"
                      placeHolder="Remark"
                      error={formError.otherRemark}
                      name="otherRemark"
                      value={recovery.otherRemark}
                      onChange={(e) => handleRemarkChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} container spacing={2}>
                    <TextFieldWrapper
                      label="Total Adjustment Amount"
                      placeHolder="Adjustment Amount"
                      name="totalAdjustmentAmount"
                      value={recovery.totalAdjustmentAmount}
                      onChange={(e) => handleChange(e)}
                      disabled={true}
                    />
                    <TextFieldWrapper
                      label={"Balance Deposit Receivable"}
                      placeHolder="Balance Deposit"
                      name="balanceDeposit"
                      disabled={true}
                      value={recovery.balanceDeposit}
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <DocumentUpload
                    label="Adjustment Document *"
                    uploaded={
                      agreementData.file !== null && preData.file !== null
                    }
                    error={formError.file}
                    placeHolder="Adjustment Document"
                    handleChange={handleChangeFile}
                    fileName={
                      agreementData.file_name === '""'
                        ? ""
                        : agreementData.file_name
                    }
                    name="file"
                    href={agreementData.file || preData.file}
                  />
                </Grid>
                <Grid container xs={10} sx={{ mt: 5 }}>
                  <Grid item xs={12} mt={2} className={"textFieldWrapper"}>
                    <Typography
                      sx={{ fontSize: "1.4rem", fontWeight: 700 }}
                      color="primary"
                    >
                      Landlord Assets
                      <span style={{ color: "inherit", marginLeft: "4px" }}>
                        *
                      </span>
                    </Typography>
                    <Grid
                      item
                      xs={10}
                      sx={{ mt: 5 }}
                      className={"textFieldWrapper"}
                    >
                      <Grid item xs={8}>
                        <TextField
                          type="text"
                          multiline
                          rows={3}
                          fullWidth
                          variant="outlined"
                          label="Landlord Assets "
                          placeholder="Landlord Assets "
                          helperText={
                            <span style={{ color: "rgb(198, 89, 17)" }}>
                              {"old value: " + preData.assets}
                            </span>
                          }
                          defaultValue={preData.assets}
                          value={agreementData.assets}
                          name={"assets"}
                          onChange={(e) =>
                            setAgreementData((old) => ({
                              ...old,
                              assets: e.target.value,
                            }))
                          }
                          error={formError.assets ? true : false}
                        />
                      </Grid>
                    </Grid>
                    <Typography sx={{ color: "red" }} variant="caption">
                      {formError.assets}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Typography
                      sx={{ fontSize: "1.4rem", fontWeight: 700 }}
                      color="primary"
                    >
                      Termination Remark
                      <span style={{ color: "inherit", marginLeft: "4px" }}>
                        *
                      </span>
                    </Typography>

                    <TextField
                      type="text"
                      multiline
                      rows={3}
                      fullWidth
                      variant="outlined"
                      placeholder="Termination Remark*"
                      value={agreementData.termination_remark}
                      onChange={(e) =>
                        setAgreementData((old) => ({
                          ...old,
                          termination_remark: e.target.value,
                        }))
                      }
                    />
                    <Typography variant="caption" sx={{ color: "red" }}>
                      {formError.termination_remark}
                    </Typography>
                  </Grid>
                </Grid>
                {(preData?.status === "Sent Back From Sr Manager Termination" ||
                  preData?.terminate_status ===
                    "Request Approved for Termination" ||
                  preData?.status ===
                    "Sent Back From Finance Team Termination") &&
                  preData.status !==
                    "Sent Back From Operations Termination" && (
                    <Grid
                      container
                      sx={{ justifyContent: "center", mt: 2 }}
                      spacing={4}
                    >
                      <Grid item md={4} xs={6}>
                        <Button
                          onClick={handleSubmit}
                          variant="contained"
                          type="submit"
                          color="primary"
                          sx={{
                            height: "60px",
                            width: "100%",
                            borderRadius: "20px",
                            fontSize: "16px",
                            color: "#FFFFFF",
                            lineHeight: "32px",
                            textTransform: "capitalize",
                            "@media(max-width:900px)": {
                              fontSize: "11px",
                              lineHeight: "12px",
                              height: "40px",
                            },
                          }}
                        >
                          Send To Sr Manager
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                {preData.status === "Sent Back From Operations Termination" && (
                  <Grid
                    container
                    sx={{ justifyContent: "center", mt: 2 }}
                    spacing={4}
                  >
                    <Grid item md={4} xs={11}>
                      <Button
                        variant="contained"
                        sx={{
                          height: "55px",
                          borderRadius: "12px",
                          backgroundColor: "primary",
                          width: "100%",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                          fontSize: "18px",
                          lineHeight: "20px",
                        }}
                        onClick={handleSubmitOperations}
                      >
                        Send To Operations
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );
}

export default EditAgreement;
