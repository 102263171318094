/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentUpload, MyHeader, TextFieldWrapper } from "../StyledComponent";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import {
  get_rent_data_ID,
  sendMonthyPaymentForwordNew,
  uploadDoc,
} from "../../Services/Services";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../store/action/action";
import { DataFieldStyle } from "../StyleComponents/Rental";
import HamburgerManager from "../Manager/HamburgerManager";

export default function EditInvoice() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth } = useSelector((s) => s);
  const dispatch = useDispatch();
  const role = auth.role;

  const subType = "monthly";

  const [formError, setError] = useState({
    invoice_no: "",
    invoice_date: "",
    rent_amount: "",
    gst_amount: "",
    total_amount: "",
    invoice: "",
    fileName: "",
  });

  const [preData, setPredata] = useState({
    invoice_no: "",
    invoice_date: "",
    gst: "",
    rent_amount: "",
    gst_amount: "",
    total_amount: 0,
    invoice: "",
    fileName: "",
    status: "",
    remark: "",
    code: "",
  });

  function getTotal() {
    let total = parseFloat(
      Number(preData.rent_amount) + Number(preData.gst_amount)
    ).toFixed(2);

    setPredata({ ...preData, total_amount: total });
  }

  useEffect(() => {
    getTotal();
  }, [preData.rent_amount, preData.gst_amount]);

  function handleChange(e) {
    if (e.target.name === "invoice_date" || e.target.name === "invoice_no") {
      setPredata({
        ...preData,
        [e.target.name]: e.target.value,
      });
    }

    let error = false;

    if (!e.target.value.match(/^[0-9]*$/)) error = true;

    if (!error) {
      setPredata({
        ...preData,
        [e.target.name]: e.target.value,
      });
    }
  }

  async function handleChangeFile(e) {
    const FD = new FormData();
    FD.append("folderName", preData.code);
    FD.append("photo", e.target.files[0]);
    let response = await uploadDoc(FD);

    if (response.status === 200) {
      setError({ ...formError, [e.target.name]: "" });

      setPredata({
        ...preData,
        invoice: response.data.link,
        fileName: e.target.files[0].name,
      });
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message: response.data.message,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: response.data.message || "Something went wrong !!!",
        })
      );
    }
  }

  async function fetchData(id) {
    try {
      const response = await get_rent_data_ID(id);
      if (response.data.succes) {
        setPredata({
          ...preData,
          invoice: response.data.data[0].invoice,
          invoice_no: response.data.data[0].invoice_number,
          invoice_date: response.data.data[0].invoice_date,
          rent_amount: parseFloat(response.data.data[0].rent_amount).toFixed(2),
          gst_amount: response.data.data[0].gst_amount,
          gst: response.data.data[0].gst,
          status: response.data.data[0].status,
          remark: response.data.data[0].remark,
          code: response.data.data[0].code,
        });
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchData(id);
  }, []);

  async function handleSubmit(e) {
    const send = await sendMonthyPaymentForwordNew(
      id,
      auth.role,
      "monthly",
      {
        status:
          preData.status === "Sent Back From Operations"
            ? "Sent To Operations"
            : "Sent To Sr Manager",
        op_id: auth.id,
        rent_amount: preData.rent_amount,
        gst_amount: preData.gst_amount,
        invoice: preData.invoice,
        invoice_date: preData.invoice_date,
        invoice_no: preData.invoice_no,
        remark: "",
        additionalRemark: null,
      },
      id,
      role,
      subType
    );

    if (send.data.success) {
      dispatch(
        setAlert({
          open: true,
          variant: "success",
          message:
            preData.status === "Sent Back From Operations"
              ? "Payment Details Send To Operations Successfully."
              : "Payment Details Send To Sr Manager Successfully.",
        })
      );
      navigate(-1);
    } else if (send.data.success === false) {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: send.data.message,
        })
      );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      dispatch(
        setAlert({
          open: true,
          variant: "error",
          message: "Something Went Wrong Please Try Again Later.",
        })
      );
    }
  }

  return (
    <>
      <Stack sx={{ flexDirection: "row", mb: 4 }}>
        <HamburgerManager />
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "space-between", display: "flex" }}
          >
            <MyHeader>
              RMS{" "}
              <span className="small-heading">(Rental Management System)</span>
            </MyHeader>
            <Typography mt="15px" mr="15px" fontWeight="600">
              Welcome {auth.name}
            </Typography>
          </Grid>
          <Box className="backButton">
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => navigate(-1)}
              size={"large"}
            >
              <ArrowCircleLeftIcon
                sx={{ fontSize: "3rem" }}
                color="#FFFFF !important"
              />
            </IconButton>
          </Box>
          <Box component={"form"}>
            <Grid container sx={{ justifyContent: "center", mt: 3 }}>
              {/* Basic Details */}
              <Grid item md={10}>
                <Grid container spacing={2}>
                  {preData.gst.length > 0 && (
                    <>
                      <TextFieldWrapper
                        required={true}
                        label="Invoice Number"
                        placeHolder="Enter Invoice Number"
                        value={preData.invoice_no}
                        onChange={(e) => handleChange(e)}
                        // disabled={true}
                        name="invoice_no"
                      />
                      <Grid item xs={6} md={4}>
                        <FormControl fullWidth>
                          <input
                            type="date"
                            name="invoice_date"
                            value={preData.invoice_date}
                            className="DatePicker"
                            onChange={(e) => handleChange(e)}
                            onKeyDown={(e) => e.preventDefault()}
                            onFocus={(e) => e.currentTarget.blur()}
                            style={{ height: "55px" }}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <TextFieldWrapper
                    required={true}
                    label="Rent Amount"
                    placeHolder="Enter Rent Amount"
                    value={parseInt(preData.rent_amount).toLocaleString()}
                    onChange={(e) => handleChange(e)}
                    name="rent_amount"
                    disabled={true}
                    textAlignRight={"textAlignRight"}
                  />
                  <TextFieldWrapper
                    required={true}
                    label="GST Amount"
                    placeHolder="Enter GST Amount"
                    value={parseInt(preData.gst_amount).toLocaleString()}
                    name="gst_amount"
                    textAlignRight={"textAlignRight"}
                    disabled={true}
                  />
                  <TextFieldWrapper
                    required={true}
                    label="Total Amount"
                    placeHolder="Enter Total Amount"
                    value={parseInt(preData.total_amount).toLocaleString()}
                    disabled={true}
                    name="total_amount"
                    textAlignRight={"textAlignRight"}
                  />
                  {preData.gst.length > 0 && (
                    <Grid item xs={8} container>
                      <DocumentUpload
                        uploaded={!!preData.invoice}
                        label="Upload Invoice"
                        placeHolder="Upload Invoice"
                        handleChange={(e) => handleChangeFile(e)}
                        name={"invoice"}
                        fileName={preData.fileName}
                        href={preData.invoice}
                      />
                    </Grid>
                  )}

                  {preData.remark.length > 0 && (
                    <Grid item xs={12} container spacing={2} sx={{ mt: 4 }}>
                      <DataFieldStyle field={"Remark"} value={preData.remark} />{" "}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {(preData.status === "Sent To Sr Manager" ||
                preData.status === "Sent Back From Sr Manager" ||
                preData.status === "Sent Back From Operations" ||
                preData.status === "Sent Back From Finance") && (
                <>
                  <Grid item md={8} sx={{ mt: 4, mb: 2 }}>
                    <Grid
                      container
                      spacing={1}
                      sx={{ justifyContent: "center" }}
                    >
                      <Grid item md={6} xs={11}>
                        <Button
                          variant="contained"
                          sx={{
                            height: "55px",
                            borderRadius: "12px",
                            backgroundColor: "primary",
                            width: "100%",
                            color: "#FFFFFF",
                            textTransform: "capitalize",
                            fontSize: "18px",
                            lineHeight: "20px",
                          }}
                          onClick={handleSubmit}
                        >
                          {preData.status === "Sent Back From Operations"
                            ? " Send To Operations"
                            : " Send To Sr Manager"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* buttons end here */}
            </Grid>
          </Box>
        </Box>
      </Stack>
    </>
  );
}
