/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Link,
  Paper,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { saveAs } from "file-saver";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import Draggable from "react-draggable";

const DataFieldStyle = ({
  field,
  value,
  href,
  name,
  bold,
  cursor,
  partLabel,
}) => {
  const [open, setOpen] = useState(false);
  const typographyStyle = {
    textTransform: "capitalize",
    color: "var(--main-color)",
    fontWeight: "600",
  };

  const handleClick = () => {
    if (href && name) {
      const confirmed = window.confirm("Do you want to download the file?");
      if (confirmed) {
        const downloadLink = document.createElement("a");
        downloadLink.href = href;
        downloadLink.download = name;
        downloadLink.target = "_blank"; // Open in a new window/tab
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  };

  function handleView() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  const [zoomed, setZoomed] = useState(false);
  const handleZoomToggle = () => {
    setZoomed(!zoomed);
  };
  return (
    <Grid item md={3} xs={6} sx={{ p: "0 !important", overflow: "auto" }}>
      {href !== undefined && (
        <ImageView
          open={open}
          handleClose={handleClose}
          href={href !== undefined ? href : ""}
          name={name}
        />
      )}
      <Typography variant="h6" sx={typographyStyle}>
        {" "}
        {field}
      </Typography>
      <Stack direction="column" gap={1}>
        <Typography
          variant="body2"
          sx={{ color: "black", cursor: cursor && "pointer" }}
          fontWeight={bold ? "600" : ""}
          flex={1}
        >
          {" "}
          {value}
        </Typography>
        {href && href !== undefined && href.replace(/['"]+/g, "") && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title="View" placement="left">
              <IconButton color="primary" onClick={handleView}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download" placement="right">
              <IconButton color="primary" onClick={handleClick}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <span style={{ color: "rgb(198, 89, 17)", fontSize: "13px" }}>
          {partLabel}
        </span>
      </Stack>
    </Grid>
  );
};

const YearField = ({ year, amount, Increment, incrementType }) => {
  const fieldStyle = {
    fontSize: "17px",
    color: "var(--main-color)",
    fontWeight: "600",
  };

  return (
    <Grid item md={2} xs={4}>
      <Typography variant="body1" sx={fieldStyle}>
        {year}
      </Typography>
      <Typography variant="body1" sx={{ color: "black" }}>
        <strong>{incrementType}</strong> : {Increment}
      </Typography>
      <Typography variant="body1" sx={{ color: "black" }}>
        <strong>Monthly Rent</strong>: {amount}
      </Typography>
    </Grid>
  );
};

const DocumentView = ({ title, img, partLabel, disabled }) => {
  const [open, setOpen] = useState(false);
  function handleView() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  const [imgView, setImgView] = useState(false);
  function handleClick(partLabel, title) {
    saveAs(partLabel, title);
  }

  return (
    <Grid item xs={4}>
      {img !== undefined && (
        <ImageView
          open={open}
          handleClose={handleClose}
          href={img}
          name={title}
        />
      )}
      <Typography
        variant="body1"
        fontSize={"18px"}
        color={"primary"}
        fontWeight={"600"}
        textTransform={"capitalize"}
        sx={{ "@media(max-width:900px)": { fontSize: "16px" } }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          height: "100px",
          border: "1px solid var(--main-color)",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="text"
          disabled={disabled}
          sx={{
            textTransform: "capitalize",
            height: "100%",
            width: "50%",
            color: !img ? "rgba(128, 128, 128, 0.7)" : "rgb(16, 99, 173)",
            cursor: !img ? "not-allowed" : "pointer",
          }}
          onClick={() => {
            if (img) {
              handleView();
            }
          }}
        >
          <Link
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
              color: !img ? "rgba(128, 128, 128, 0.7)" : "rgba(16, 99, 173)",
            }}
            // onClick={handleView}
          >
            View
          </Link>
        </Button>
        <Button
          variant="text"
          disabled={disabled}
          sx={{
            textTransform: "capitalize",
            height: "100%",
            width: "50%",
            color: !img ? "rgba(128, 128, 128, 0.7)" : "rgb(16, 99, 173)",
            cursor: !img ? "not-allowed" : "pointer",
          }}
        >
          <Link
            id="downloadLink"
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
              color: !img ? "rgba(128, 128, 128, 0.7)" : "rgb(16, 99, 173)",
            }}
            // href={img}
            download={title}
            onClick={() => {
              if (img) {
                const confirmed = window.confirm(
                  "Do you want to download the file?"
                );
                if (confirmed) {
                  const downloadLink = document.createElement("a");
                  downloadLink.href = img;
                  // downloadLink.download = name;
                  downloadLink.target = "_blank"; // Open in a new window/tab
                  downloadLink.style.display = "none";
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
                }
              }
            }}
          >
            Download
          </Link>
        </Button>
      </Box>
      {partLabel && (
        <Box sx={{ mt: 1 }}>
          <VisibilityIcon
            color={"primary"}
            onClick={() => setImgView(true)}
            sx={{ mr: 1 }}
          />
          <DownloadIcon color={"primary"} onClick={handleClick} />
        </Box>
      )}
    </Grid>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

export function ImageView({ open, handleClose, href, name }) {
  const fileType = href ? href.includes("pdf") : false;

  function PaperComponent(props) {
    return (
      <Draggable handle="#modalBox">
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modalBox"
      aria-describedby="modal-modal-description"
      PaperComponent={PaperComponent}
      style={{ cursor: "move" }}
    >
      <div>
        <button
          style={{
            width: "15px",
            height: "15px",
            border: "none",
            padding: "12px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "5px",
            flexDirection: "row",
            float: "right",
            margin: "2px",
          }}
          onClick={handleClose}
        >
          X
        </button>
      </div>
      <DialogContent id="modalBox">
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mb={2}
        ></Box>
        <Box sx={{ height: "400px", width: "500px", marginTop: "10px" }}>
          {fileType ? (
            <Box sx={style}>
              <iframe src={href} title={name} height={400} width={500} />
            </Box>
          ) : (
            <Box sx={style}>
              <img
                src={href}
                alt={name}
                style={{ width: "500px", height: "400px" }}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export { DataFieldStyle, YearField, DocumentView };
